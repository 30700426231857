import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { withContext } from '@/common/utils/withContext';
import { Grid, FormControlLabel } from '@mui/material';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
} from '@stripe/react-stripe-js';
import { CSSProperties, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import styles from './paymentGatewayForm.module.scss';
import { useTranslation } from 'react-i18next';

const CreatePaymentMethodForm = (props: any) => {
	const [state, setStateData] = useState({
		isCardNumberValid: false,
		cardNumberError: '',
		isExpirationDateValid: false,
		expirationDataError: '',
		isCvcValid: false,
		cvcError: '',
	} as any);

	const paymentForm = props.paymentForm;

	const elements = useElements();

	const paymentModalStyle = {
		formControlLabel: { 
			marginRight: '0px'
		} as CSSProperties,
		checkbox: {
			paddingRight: '8px', 
			paddingLeft: '0px'
		} as CSSProperties,
	};

	useEffect(() => {
		props.changePaymentState(state.isCardNumberValid && state.isExpirationDateValid && state.isCvcValid && paymentForm.getValues().acceptConditions);
	}, [
		state,
		paymentForm.getValues().acceptConditions,
	]);

	const handleCardNumberDetails = (event: any) => {
		paymentForm.setValue('cardNumber', elements?.getElement(CardNumberElement));
		setStateData((currentData: any) => {
			return {
				...currentData,
				cardNumberError: event.error ? 'Card has error' : '',
				isCardNumberValid: !event.error && event.complete,
			};
		});
	};

	const handleCardExpirationDateDetails = (event: any) => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				expirationDateError: event.error ? 'Expiration date has error' : '',
				isExpirationDateValid: !event.error && event.complete,
			};
		});
	};

	const handleCardCvcDetails = (event: any) => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				cvcError: event.error ? 'Cvc has error' : '',
				isCvcValid: !event.error && event.complete,
			};
		});
	};

	const { t: translate } = useTranslation();

	return (
		<section className={styles.paymentGatewayForm}>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<div className={styles.paymentGatewayFormGroup}>
						<label className={styles.paymentGatewayFormLbl}>{`${translate('settings.billing.payment_methods_card')}`}</label>
						<CardNumberElement onChange={handleCardNumberDetails} />
						{state.cardNumberError && 
							<span style={{ color: '#d32f2f', fontSize: '0.9rem' }}>{`${translate('settings.billing.payment_methods_card_number_error')}`}</span>
						}
					</div>
				</Grid><Grid item xs={4}></Grid>
				<Grid item xs={4}>
					<div className={styles.paymentGatewayFormGroup}>
						<label className={styles.paymentGatewayFormLbl}>{`${translate('settings.billing.payment_methods_expiring_on')}`}</label>
						<CardExpiryElement onChange={handleCardExpirationDateDetails}/>
					</div>
				</Grid>
				<Grid item xs={4}>
					<div className={styles.paymentGatewayFormGroup}>
						<label className={styles.paymentGatewayFormLbl} htmlFor="">{`${translate('settings.billing.payment_methods_verification_code')}`}</label>
						<CardCvcElement onChange={handleCardCvcDetails} />
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className={styles.paymentGatewayFormDisclaimer}>{`${translate('settings.billing.payment_methods_conditions')}`}</div>
				</Grid>
				<Grid item xs={12}>
					<Controller
						name="acceptConditions"
						control={paymentForm.control}
						render={({ field }) => (
							<FormControlLabel
								style={paymentModalStyle.formControlLabel}
								control={
									<EvesCheckbox
										size='small'
										{...field}
										checked={paymentForm.watch('acceptConditions')}
										style={paymentModalStyle.checkbox}
									/>
								}
								label={`${translate('settings.billing.payment_methods_check')}`}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</section>
	);
};

export default withContext(CreatePaymentMethodForm);