export function IconAllConnectors(props: any) {
  const height = props.height || '20px';
  const width = props.width || '20px';
  const color = props.color || '#ffffff';

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height={height}
        viewBox='0 0 24 24'
        width={width}
        fill={color}
      >
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M12 2.02c-5.51 0-9.98 4.47-9.98 9.98s4.47 9.98 9.98 9.98 9.98-4.47 9.98-9.98S17.51 2.02 12 2.02zm0 17.96c-4.4 0-7.98-3.58-7.98-7.98S7.6 4.02 12 4.02 19.98 7.6 19.98 12 16.4 19.98 12 19.98zM12.75 5l-4.5 8.5h3.14V19l4.36-8.5h-3z' />
      </svg>
    </>
  );
}
