import { EvesButton } from '@/common/components/atoms/button/button';
import { Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { CSSProperties, useState } from 'react';

interface IErrorDetailProps {
	onClose: ()=> void;
	isErrorDetailModalOpen: boolean;
	errorCode: string;
	errorTypeDetailConstant: {[key: string] : {title: string, description: string, action: string}
	}
}
const ErrorDetailModal = (props: IErrorDetailProps) => {
	const [open, setOpen] = useState(props.isErrorDetailModalOpen);
	const handleClose = () => {setOpen(false); props.onClose();};

	const style= {
		modalBox: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '20vw',
			minHeight: '33vh',
			bgcolor: 'white',
			boxShadow: 24,
			p: 4,
		},
		closeBtn: {
			float: 'right', 
			minWidth: '100px'
		} as CSSProperties,
		closeBtnGrid: {
			marginTop:'2px',
		} as CSSProperties,
		descriptionGrid: {
			marginTop: '4px',
			fontSize: '14px',
		} as CSSProperties,
		seperatorLine : {
			backgroundColor: 'black', 
		} as CSSProperties,
		modal : {
			fontSize: '0.95rem',
		} as CSSProperties,
		header : {
			fontSize: '20px',
			fontWeight: '500',
		} as CSSProperties,
		subHeader: {
			fontWeight: 'bolder',
		} as CSSProperties,
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={style.modal}					
			>
				<Box sx={style.modalBox} data-cy='transaction-in-error-modal'>
					<Grid container spacing={2} direction={'column'}>
						<Grid item xs={12}>
							<h3 style={style.header}>
								{`${props.errorTypeDetailConstant[props.errorCode].title}`} 
							</h3>
							<hr style={style.seperatorLine} />
						</Grid>
						<Grid item style={style.descriptionGrid}>
							<Grid container spacing={1} direction={'row'}>
								<Grid xs={3} item >
									<span style={style.subHeader}>Description</span>
								</Grid>
								<Grid xs={9} item >
									{`${props.errorTypeDetailConstant[props.errorCode].description}`}
								</Grid>
								<Grid xs={3} item >
									<span style={style.subHeader}>Action</span>
								</Grid>
								<Grid xs={9} item >
									{`${props.errorTypeDetailConstant[props.errorCode].action}`}
								</Grid>
							</Grid>
							<Grid container item xs={12} direction={'row'} spacing={2} style={style.closeBtnGrid}>
								<Grid item xs={7} />
								<Grid item xs={5}>
									<EvesButton
										onClick={handleClose} 
										style={style.closeBtn}
										data-cy='Error-cancel-button'
									>
								Close
									</EvesButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
};

export default ErrorDetailModal;