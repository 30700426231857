
import styles from './tile.module.scss';
import { Tooltip} from '@mui/material';


export interface ITile {
    title?: any;
    subTitle?: any;
    tooltipTitle?: any;
	icon?:any;
    type?:any;
    imgSrc?:any;
	description?: any;
}

export const Tile = (props: ITile) => {
	return (
		<div className={`${styles.tile} ${props.type === 'success'? styles.tile_Success: ''}`}>
			<Tooltip title={props.tooltipTitle}>
				<>
					{props.icon && <div className={styles.tileIcon}><props.icon /></div>}
					{props.imgSrc && <div className={styles.tileImg}><img src={props.imgSrc}/></div>}
					{props.title && <div  className={styles.tileTitle}>{props.title}</div>}
					{props.subTitle && <div  className={styles.tileSubTitle}>({props.subTitle})</div>}
					{props.description && <div  className={styles.tileSubTitle}>{props.description}</div>}
				</>
			</Tooltip>
		</div>
	);
};