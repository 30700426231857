import { Formatter } from '@/common/utils/formatHelper';

export class CompanyModel {
	logo?: string;
	id: string = '';
	name?: string;
	address?: Record<string, any>;
	createdOn?: string;
	createdBy?: string;
	lastChangedOn?: string;
	lastChangedBy?: string;
	isSelected: boolean = false;
	issuer: boolean = false;
	canRead?: boolean=false;
	canDelete?:boolean= false;
	canUpdate?:boolean= false;
	constructor (input: CompanyModel) {
		Object.assign(this, input);
	}

	get city () {
		return this.address?.city;
	}

	get country () {
		return this.address?.country;
	}

	get coordinates () {
		return this.address?.coordinates;
	}

	get createdOnString () {
		if (this.createdOn) {
			return Formatter.toDateTimeString(this.createdOn);
		} else {
			return '';
		}
	}

	get createdByFullName () {
		if (this.createdBy) {
			return this.createdBy;
		}
		return '-';
	}

	get lastChangedOnString () {
		if (this.lastChangedOn) {
			return Formatter.toDateTimeString(this.lastChangedOn);
		} else {
			return '';
		}
	}

	get lastChangedByFullName () {
		if (this.lastChangedBy) {
			return this.lastChangedBy;
		}
		return '-';
	}

	get address1 () {
		if (this.address) {
			return this.address.address1;
		}
		return '';
	}
}

export class SiteModel {
	id: string = '';
	name?: string;
	public?: boolean;
	issuer?:boolean;
	autoUserSiteAssignment?: boolean;
	companyID?:string;
	company?:{ name: string };
	address?: Record<string, any>;
	createdOn?: string;
	createdBy?: string;
	lastChangedOn?: string;
	lastChangedBy?: string;
	isSelected: boolean = false;
	contact?:Record<string, any>;
	canCreate: boolean = false;
	canRead: boolean = false;
	canDelete: boolean = false;
	canUpdate: boolean = false;
	canAssignUsers: boolean = false;
	canExportOCPPParams: boolean = false;
	canGenerateQrCode: boolean = false;
	canMaintainPricingDefinitions: boolean = false;
	canReadUsers: boolean = false;
	canUnassignUsers: boolean = false;

	constructor (input: SiteModel) {
		Object.assign(this, input);
	}

	get city () {
		return this.address?.city;
	}

	get address1() {
		return this.address?.address1;
	}

	get country () {
		return this.address?.country;
	}

	get companyName () {
		return this.company?.name;
	}

	get phone1 () {
		return this.contact?.phone1;
	}
	get phone2 () {
		return this.contact?.phone2;
	}
	get email () {
		return this.contact?.email;
	}
	get coordinates () {
		return this.address?.coordinates;
	}

	get createdOnString () {
		if (this.createdOn) {
			return Formatter.toDateTimeString(this.createdOn);
		} else {
			return '';
		}
	}

	get createdByFullName () {
		if (this.createdBy) {
			return this.createdBy;
		}
		return '-';
	}

	get lastChangedOnString () {
		if (this.lastChangedOn) {
			return Formatter.toDateTimeString(this.lastChangedOn);
		} else {
			return '';
		}
	}

	get lastChangedByFullName () {
		if (this.lastChangedBy) {
			return this.lastChangedBy;
		}
		return '-';
	}
}

export class SubStationModel {
	address1?: string = '';
	address2?: string;
	city?: string;
	country?:string;
	name?: string;
	subStationId?:string;
	postalCode?: string;
	constructor (input: SiteModel) {
		Object.assign(this, input);
	}
}

export class FeederModel {
	address1?: string = '';
	address2?: string;
	city?: string;
	country?:string;
	name?: string;
	feederId?:string;
	postalCode?: string;
	constructor (input: SiteModel) {
		Object.assign(this, input);
	}
}

export class TransformerModel {
	address1?: string = '';
	address2?: string;
	city?: string;
	country?:string;
	name?: string;
	transformerId?:string;
	postalCode?: string;
	constructor (input: SiteModel) {
		Object.assign(this, input);
	}
}

export class UserModel {
	siteID: string = '';
	siteAdmin?: boolean;
	siteOwner?: boolean;
	user?: Record<string, any>;
	isSelected: boolean= false;

	constructor (input: UserModel) {
		Object.assign(this, input);
	}

	get email () {
		return this.user?.email;
	}

	get firstName () {
		return this.user?.firstName;
	}

	get name () {
		return this.user?.name;
	}

	get id () {
		return this.user?.id;
	}

	get role () {
		return this.user?.role;
	}
}

export class AddUserModel {
	email?: string ;
	firstName?: boolean;
	name?: boolean;
	issuer?: boolean;
	id:string = '';
	isSelected: boolean= false;

	constructor (input: AddUserModel) {
		Object.assign(this, input);
	}
}

export class EvMakerModel {
	carMaker?: string ;

	constructor (input: AddUserModel) {
		Object.assign(this, input);
	}
}

export class PriceModel {
	name: string = '';
	staticRestrictions?: any;
	dimensions?: IDimension;
	entityID?:string;
	constructor (input: PriceModel) {
		Object.assign(this, input);
	}

	get validFrom () {
		if (this.staticRestrictions?.validFrom) {
			return Formatter.toDateString(this.staticRestrictions.validFrom);
		}
		return '';
	}

	get validTo () {
		if (this.staticRestrictions?.validTo) {
			return Formatter.toDateString(this.staticRestrictions.validTo);
		}
		return '';
	}

	get flatFee () {
		if (this.dimensions?.flatFee && this.dimensions.flatFee.active) {
			return `${Formatter.toCurrencyString(this.dimensions.flatFee.price)}/session`;
		}
		return '-';
	}

	get energy () {
		if (this.dimensions?.energy && this.dimensions.energy.active) {
			return `${Formatter.toCurrencyString(this.dimensions.energy.price)}/kWh`;
		}
		return '-';
	}

	get chargingTime () {
		if (this.dimensions?.chargingTime && this.dimensions.chargingTime.active) {
			return `${Formatter.toCurrencyString(this.dimensions.chargingTime.price)}/hour`;
		}
		return '-';
	}

	get parkingTime () {
		if (this.dimensions?.parkingTime && this.dimensions.parkingTime.active) {
			return `${Formatter.toCurrencyString(this.dimensions.parkingTime.price)}/hour`;
		}
		return '-';
	}
}

interface IDimension {
    chargingTime?: {
        active: boolean;
        price: number;
    };
    flatFee?: {
        active: boolean;
        price: number
    };
    parkingTime?: {
        active: boolean;
        price: number
    };
    energy?: {
        active: boolean;
        price: number
    }
}

export class SiteAreaModel {
	id: string = '';
	name?: string;
	issuer?:boolean;
	maximumPower?: number;
	numberOfPhases?: number;
	maximumTotalPowerAmps?:number;
	maximumPowerAmpsPerPhase?:number;
	accessControl?: boolean;
	siteID?:string;
	site?:{name:string,id:string,public:boolean};
	address?: Record<string, any>;
	createdOn?: string;
	createdBy?: string;
	lastChangedOn?: string;
	lastChangedBy?: string;
	smartCharging?:boolean;
	isSelected: boolean = false;
	voltage?:number;
	tariffID?:any;
	parentSiteArea?:{name:string};
	parentSiteAreaID?:string;
	public?:boolean;
	registrationToken?: Record<string, any>;
	canAssignAssets: boolean = false;
	canAssignChargingStations: boolean = false;
	canDelete: boolean = false;
	canExportOCPPParams: boolean = false;
	canGenerateQrCode: boolean = false;
	canRead: boolean = false;
	canReadAssets: boolean = false;
	canReadChargingStations: boolean = false;
	canUnassignAssets: boolean = false;
	canUnassignChargingStations: boolean = false;
	canUpdate: boolean = false;
   feederID?: string;
   transformerID?: string;
   subStationID?: string;

	constructor (input: SiteAreaModel) {
		Object.assign(this, input);
	}

	get city () {
		return this.address?.city;
	}

	get paretSiteAreaName () {
		return	this.parentSiteArea?.name;
	}
	
	get registrationId(){
		return this.registrationToken?.id;
	}
	get ocpp15SOAPSecureUrl(){
		return this.registrationToken?.ocpp15SOAPSecureUrl;
	}
	get ocpp16SOAPSecureUrl(){
		return this.registrationToken?.ocpp16SOAPSecureUrl;
	} 
	get ocpp16JSONSecureUrl(){
		return this.registrationToken?.ocpp16JSONSecureUrl;
	}

	get country () {
		return this.address?.country;
	}

	get siteName () {
		return this.site?.name;
	}

	get LimiteMax () {
		if(this.maximumPower){
			return Math.round(this.maximumPower/1000)+'kW';
		}
		return '0kW';
	}
	get coordinates () {
		return this.address?.coordinates;
	}

	get createdOnString () {
		if (this.createdOn) {
			return Formatter.toDateTimeString(this.createdOn);
		} else {
			return '';
		}
	}
	get publicSite()
	{
		return this.site?.public;
	}
	get createdByFullName () {
		if (this.createdBy) {
			return this.createdBy;
		}
		return '-';
	}

	get lastChangedOnString () {
		if (this.lastChangedOn) {
			return Formatter.toDateTimeString(this.lastChangedOn);
		} else {
			return '';
		}
	}

	get lastChangedByFullName () {
		if (this.lastChangedBy) {
			return this.lastChangedBy;
		}
		return '-';
	}

   
   get getFeederId(){
      return this.feederID ? this.feederID : '-';
   }

   
   get getSubStationID(){
      return this.subStationID ? this.subStationID : '-';
   }

   
   get getTransformerID(){
      return this.transformerID ? this.transformerID : '-';
   }
}

export class ParentSiteModel {
	id: string = '';
	name?: string;
	address?: Record<string, any>;
	createdOn?: string;
	createdBy?: string;
	lastChangedOn?: string;
	lastChangedBy?: string;
	isSelected: boolean = false;
	smartCharging?:boolean=false;
	site?:{name:string,public:boolean,id:string};

	constructor (input: SiteModel) {
		Object.assign(this, input);
	}

	get city () {
		return this.address?.city;
	}

	get address1 () {
		if (this.address) {
			return this.address.address1;
		}
		return '';
	}
	get smartChargings () {
		return this.smartCharging?'Yes':'No';
	}
	get country () {
		return this.address?.country;
	}

	get siteName () {
		return this.site?.name;
	}
	// get companyName () {
	//     return this.company?.name;
	// }

	get coordinates () {
		return this.address?.coordinates;
	}

	get createdOnString () {
		if (this.createdOn) {
			return Formatter.toDateTimeString(this.createdOn);
		} else {
			return '';
		}
	}

	get createdByFullName () {
		if (this.createdBy) {
			return this.createdBy;
		}
		return '-';
	}

	get lastChangedOnString () {
		if (this.lastChangedOn) {
			return Formatter.toDateTimeString(this.lastChangedOn);
		} else {
			return '';
		}
	}

	get lastChangedByFullName () {
		if (this.lastChangedBy) {
			return this.lastChangedBy;
		}
		return '-';
	}
}

export class ChargingStationModel {
	id: string = '';
	chargePointVendor: string = '';
	isSelected: boolean= false;

	constructor (input: ChargingStationModel) {
		Object.assign(this, input);
	}
}

export class ActionModal {
	action: string  = '';
	constructor (input: ActionModal) {
		Object.assign(this, input);
	}
}

export class AssetsModel {
	id: string = '';
	isSelected: boolean = false;
	assetType: string = '';
	name: string = '';
	siteAreaID: string = '';
	siteId: string = '';
	count: number = 0;

	constructor(input: AssetsModel) {
		Object.assign(this, input);
	}

	get assetTypes() {
		switch (this.assetType) {
		case 'PR':
			return 'Produce Energy';
		case 'CO':
			return 'Consume Energy';
		case 'CO-PR':
			return 'Consume and Produce Energy';
		default: return '';
		}
	}
}

export class AddAssetsModel {
	name?: boolean;
	id: string = '';
	isSelected: boolean = false;

	constructor(input: AddAssetsModel) {
		Object.assign(this, input);
	}
}

export class ConnectedAccountModel {
	id: string = '';
	status: string = '';
	companyName: string = '';
	taxID?: string;
	accountExternalID?: string;
	businessOwner!: BusinessOwnerModel;
	constructor (input: ConnectedAccountModel) {
		Object.assign(this, input);
	}

	get email () {
		if (this.businessOwner) {
			return this.businessOwner.email;
		}
		return '';
	}

	get businessOwnerFullname () {
		if (this.businessOwner) {
			return `${this.businessOwner?.firstName} ${this.businessOwner?.name}`;
		}
		return '';
	}
}

export class BusinessOwnerModel {
	id: string ='';
	email: string = '';
	firstName: string ='';
	name: string = '';
	constructor (input: BusinessOwnerModel) {
		Object.assign(this, input);
	}
}

