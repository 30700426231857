import { blobToBase64String, getSubdomain } from '@/common/utils/utils';
import { getTenantLogoBySubdomain, resetPassword } from '@/services/auth';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';

// Local imports
import img from '@/assets/img/theme/no-image.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordFormModel } from '../../shared/models/models';
import { formSchema } from '../../shared/schemas/resetPasswordFormSchema';
import { showNotification } from '@/store/app/reducer';
import store from '@/store';
import Config from '@/common/constants/config';

// this import is temp, must be changed by UI dev
import { useTranslation } from 'react-i18next';
import CommonLogin from '../CommonLogin';

const ForgotPassword = () => {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    tenantLogo: img,
  });

  const navigate = useNavigate();
  const subdomain = getSubdomain();

  const { control, formState, getValues, setValue } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: new ResetPasswordFormModel(),
    mode: 'onTouched',
  });

  const { errors, isValid } = formState;
  const isAgreementAccepted: boolean = true;
  const canSubmitForm = isValid;

  const { refetch: fetchTenantLogo } = useQuery(
    'tenantLogo',
    () => {
      if (subdomain) {
        return getTenantLogoBySubdomain(subdomain);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if(data){
          if (data?.status >= 400 && data?.status < 500) {
            setStateData((currentData: any) => {
              return {
                ...currentData,
                tenantLogo: img,
              };
            });
          } else if (data) {
            const base64String = await blobToBase64String(data);
            setStateData((currentData) => {
              return {
                ...currentData,
                tenantLogo: base64String,
              };
            });
          }
        }
       
      },
    }
  );

  useEffect(() => {
    fetchTenantLogo();
  }, []);

  // capturing token from captcha component and setting it to registerForm.captcha
  const onCapResolve = (capToken: string) => {
    setValue('captcha', capToken);
  };

  const capKey = Config.user.captchaSiteKey;

  const onResetPassword = () => {
    const resetForm = getValues();
  
    if (isValid) {
      resetPassword(resetForm.email, resetForm.captcha)
        .then((res: any) => {
          if (res.status === 200 || res.status === 'Success') {
            store.dispatch(
              showNotification({
                showNotification: true,
                message: `${translate('authentication.reset_password_success')}`,
                type: 'success',
              })
            );
            
            navigate('/login');
          } else {
            const errorData = JSON.parse(res.data);
            store.dispatch(
              showNotification({
                showNotification: true,
                message: errorData.errorMessage || `${translate('authentication.reset_password_404_error')}`,
                type: 'error',
              })
            );
          }
        })
        .catch((error) => {
          const errorData = JSON.parse(error.data);
          if (error.response) {
            try {
             
              if (errorData.errorCode === 404) {
                store.dispatch(
                  showNotification({
                    showNotification: true,
                    message: errorData.errorMessage || `${translate('authentication.reset_password_404_error')}`,
                    type: 'error',
                  })
                );
              } else {
                store.dispatch(
                  showNotification({
                    showNotification: true,
                    message: errorData.errorMessage || `${translate('authentication.reset_password_error')}`,
                    type: 'error',
                  })
                );
              }
            } catch (parseError) {
              console.error('Error parsing response:', parseError);
              store.dispatch(
                showNotification({
                  showNotification: true,
                  message: `${translate('authentication.reset_password_error')}`, // Default error message
                  type: 'error',
                })
              );
            }
          } else {
            store.dispatch(
              showNotification({
                showNotification: true,
                message: `${translate('authentication.reset_password_error')}`, // Default error message
                type: 'error',
              })
            );
          }
        });
    }
  };
  
  

  return (
    <GoogleReCaptchaProvider reCaptchaKey={`${capKey}`}>
      <CommonLogin
        {...{
          state,
          control,
          errors,
          canSubmitForm,
          isForgot: true,
          onResetPassword,
          emailName: 'email',
          headLabel: 'Forgot Password?',
        }}
      />
    </GoogleReCaptchaProvider>
  );
};

export default ForgotPassword;
