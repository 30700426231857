import  * as schema from 'yup';
import { AssetFormModel, greencomConnectionModel, iothinkConnectionModel, lacroixConnectionModel, schneiderConnectionModel, witConnectionModel } from '../models/integrationSettings';
import { findRequiredFields } from '@/common/utils/validationHelper';

export const formSchema = schema.object<Partial<Record<keyof AssetFormModel, schema.AnySchema>>>({
	name: schema.string().required('general.mandatory_field').max(100, 'general.error_max_length'),
	description:schema.string().required('general.mandatory_field'),
	url: schema.string().required('general.mandatory_field').matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern'),
	type : schema.string().required('general.mandatory_field'),
	refreshIntervalMins:schema.string(),
	schneiderConnection: schema.object<Partial<Record<keyof schneiderConnectionModel, schema.AnySchema>>>({
		schneiderConnection: schema.boolean(),
		user: schema.string().when('schneiderConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string()
		}),
		password: schema.string().when('schneiderConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string()
		})
	}),
	greencomConnection: schema.object<Partial<Record<keyof greencomConnectionModel, schema.AnySchema>>>({
		greencomConnection: schema.boolean(),
		clientId: schema.string().when('greencomConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string()
		}),
		clientSecret: schema.string().when('greencomConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string()
		})
	}),
	iothinkConnection: schema.object<Partial<Record<keyof iothinkConnectionModel, schema.AnySchema>>>({
		iothinkConnection: schema.boolean(),
		user: schema.string().when('iothinkConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		password: schema.string().when('iothinkConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
	}),
	lacroixConnection: schema.object<Partial<Record<keyof lacroixConnectionModel, schema.AnySchema>>>({
		lacroixConnection: schema.boolean(),
		user: schema.string().when('lacroixConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		password: schema.string().when('lacroixConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
	}),
	witConnection: schema.object<Partial<Record<keyof witConnectionModel, schema.AnySchema>>>({
		witConnection: schema.boolean(),
		user: schema.string().when('witConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		password: schema.string().when('witConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		clientId: schema.string().when('witConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		clientSecret: schema.string().when('witConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		authenticationUrl: schema.string().when('witConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]+$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		})
	})
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);