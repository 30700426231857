import i18next from 'i18next';

export class ChargingStationModel {
	id: string = '';
	inactive?: boolean;
	isSelected?: boolean;
	issuer?: boolean;
	issuerName?: string;
	isResetBtnDisabled?: true;
	sortDescending?: boolean;
	sortFields?: string;
	siteId?: string;
	limit?: number;
	showChargingForm?: boolean;
	withUser?: boolean;
	withSite?: boolean;
	withSiteArea?: boolean;
	name?: string;
	site?: { name: string; id: string; public: boolean };
	siteArea?: Record<string, any>;
	firmwareVersion?: string;
	connectors?: {
		connectorId: number;
		status: string;
		type: string;
		connectorA: number;
		connectorB: number;
	};
	ocppProtocol?: string;
	ocppVersion?: string;
	chargePointVendor?:string;
	chargePointModel?:string;
	endpoint?:string;
	currentIPAddress?:string;
	lastReboot?:string;
	createdOn?:string;
	chargingStationURL?:string;
	chargeBoxSerialNumber?:string;
	capabilities?:string;
	canGenerateQrCode: boolean = false;
	canExport: boolean = false;
	canListCompanies: boolean = false;
	canListSiteAreas: boolean = false;
	canListSites: boolean = false;
	canListUsers: boolean = false;
	isTransactionDisplayAuthorized?: boolean;
	constructor (input: ChargingStationModel) {
		Object.assign(this, input);
	}
	get siteName() {
		return this.site?.name;
	}

	get siteAreaName() {
		return this.siteArea?.name;
	}

	get connectorId() {
		return this.connectors?.connectorId;
	}

	get connectorStatus() {
		return this.connectors?.status;
	}

	get connectorType() {
		return this.connectors?.type;
	}

	get connectorA() {
		if (this.connectors?.connectorId === 1) {
			return this.connectors?.connectorA;
		}
	}
}

export interface IChargingStationResetModel {
	args: { type: string; };
}

export interface IChargingStationAvailabilityModel {
	args: { connectorId: number; type: string };
}

export interface IConnectorConsumptionModel {
	issuer: boolean;
	companyID: string;
	siteID: string;
	siteAreaID: string;
	connectorId: number;
	tagID: string;
	carID?: any;
	carCatalogID?: any;
	userID: string;
	chargeBoxID: string;
	meterStart: number;
	timestamp: Date;
	priceUnit: string;
	stateOfCharge: number;
	timezone: string;
	currentStateOfCharge: number;
	currentTotalInactivitySecs: number;
	currentInactivityStatus: string;
	currentInstantWatts: number;
	currentTotalConsumptionWh: number;
	currentTotalDurationSecs: number;
	id: number;
	values: IConnectorConsumptionValue[];
}
interface IConnectorConsumptionValue {
	_id: string;
	cumulatedAmount: number;
	cumulatedConsumptionAmps: number;
	cumulatedConsumptionWh: number;
	endedAt: Date;
	instantAmps: number;
	instantAmpsDC: number;
	instantAmpsL1: number;
	instantAmpsL2: number;
	instantAmpsL3: number;
	instantVolts: number;
	instantVoltsDC: number;
	instantVoltsL1: number;
	instantVoltsL2: number;
	instantVoltsL3: number;
	instantWatts: number;
	instantWattsDC: number;
	instantWattsL1: number;
	instantWattsL2: number;
	instantWattsL3: number;
	limitAmps: number;
	limitWatts: number;
	startedAt: Date;
	stateOfCharge?: any;
}

export interface IChargingStationAdvancedTabModel {
	args: {
		connectorId: number; duration: number; chargingRateUnit: string };
}

export interface IDefaultCarCatalogModel {
	ChargingStationID: string
}

interface ITag {
	active: boolean;
	default: boolean;
	description: string;
	visualID: string;
}

interface IConverter {
	powerWatts: number;
	numberOfPhases: number;
}

interface ICarCatalog {
	vehicleMake: string;
	vehicleModel: string;
	vehicleModelVersion: string;
	batteryCapacityFull: number;
	image: string;
}

interface ICar {
	converter: IConverter;
	default: boolean;
	licensePlate: string;
	type: string;
	carCatalog: ICarCatalog;
	id: string;
}

export interface IDefaultCarCatalogResponseModel {
	tag: ITag;
	car: ICar;
	errorCodes: any[];
}

export interface IUsersParam {
	Issuer:boolean,
	Limit:number,
	SortFields: string,
}

export class UserModel {
	id: string ='';
	email?: string;
	name?: string = '';
	createdBy?: string;
	createdOn?: string;
	firstName?: string = '';
	issuer: boolean = false;
	lastChangedOn?: string;
	locale?: string='';
	status?: string;
	role?: string;
	freeAccess?: boolean = false;
	technical?: boolean = false;
	eulaAcceptedOn?: string ='';
	eulaAcceptedVersion?: number;
	canRead?: boolean = false;
	canUpdate?: boolean = false;
	canDelete?: boolean = false;
	billingData? : {customerID:string, lastChangedOn: string};
	lastChangedBy?: string;
   
	constructor (input: UserModel) {
		Object.assign(this, input);
		this.role = (input.role ==='S') ? `${i18next.t('users.role_super_admin')}`  : 
			(input.role === 'A') ? `${i18next.t('users.role_admin')}`  : 
				(input.role ==='B') ? `${i18next.t('users.role_basic')}`  : `${i18next.t('users.role_demo')}` ;
	}
	get customerID () {
		return (this.billingData)? this.billingData.customerID: '';
	}
	get billLastChangedOn () {
		return this.formatDate((this.billingData)? this.billingData.lastChangedOn: '');
	}
	get createdOnDateFormated () {
		return this.formatDate((this.createdOn)? this.createdOn : '');
	}
	get lastChangedOnDateFormated () {
		return this.formatDate((this.lastChangedOn)? this.lastChangedOn : '');
	}
	get eulaAcceptedOnDateFormatedWithVersion () {
		return '' + (this.formatDate((this.eulaAcceptedOn)? this.eulaAcceptedOn : '')) + ((this.eulaAcceptedVersion)? ` (Version ${this.eulaAcceptedVersion})` : '');
	}
	get createdByFullname () {
      return this.createdBy ? this.createdBy : '-';
	}
	get lastChangedByFullname () {
		return this.lastChangedBy ? this.lastChangedBy : '-';
	}
	get getIsAPIUserFormated () {
		return this.technical ? `${i18next.t('general.yes')}` :`${i18next.t('general.no')}`;
	}

	private formatDate = (date : string):string => {
		const resultDate = ( date.length > 0) ?  new Date(date): '-';
		if( resultDate === '-') return resultDate;
		let hours = resultDate.getHours();
		const minutes = resultDate.getMinutes();
      const seconds = resultDate.getSeconds();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12;
		return (resultDate.getMonth()+1)+'/'+ resultDate.getDate() + '/'+ resultDate.getFullYear()%100+', ' +hours + ':' + ((minutes < 10) ? '0'+minutes : minutes) + ':' + ((seconds < 10) ? '0'+seconds : seconds) + ' ' + ampm;
	};
}

export class EvMakersModel {
	carMaker: string = '';
	constructor (input: EvMakersModel) {
		Object.assign(this, input);
	}
}

interface User {
	email: string;
	firstName: string;
	name: string;
}

export class SeesionTagModel {
	id: string = '';
	userID: string ='';
	visualID: string ='';
	description: string ='';
	user!: User;
	active?: boolean;
	createdOn?: string;
	default?: boolean;
	lastChangedOn?: string;
	ocpiToken?: any;
	issuer: boolean = false;
	canRead?: boolean = false;
	canDelete?: boolean = false;
	canUpdate?: boolean = false;
	canListUsers?: boolean = false;
	constructor (input: SeesionTagModel) {
		Object.assign(this, input);
	}

	get email (){
		return this.user?.email;
	}
	get defaultCustomValue () {
		return this.default ? 'Yes' : 'No';
	}
	get statusCustomValue() {
		return this.active ? 'Active' : 'Inactive';
	}
}

interface Converter {
	powerWatts: number;
	amperagePerPhase: number;
	numberOfPhases: number;
	type: string;
}

interface CarCatalog {
	vehicleMake: string;
	vehicleModel: string;
	vehicleModelVersion: string;
	batteryCapacityFull: number;
	image: string;
	id: string;
}

export class SeesionCarModel {
	id: string='';
	userID: string = '';
	vin: string = '';
	type: string ='';
	licensePlate: string = '';
	converter!: Converter;
	carCatalog!: CarCatalog;
	createdBy?: string;
	createdOn?: string;
	default?: boolean;
	lastChangedOn?: any;
	canRead?: boolean = false;
	canDelete?: boolean = false;
	canUpdate?: boolean = false;
	canListUsers?: boolean = false;
	constructor (input: SeesionTagModel) {
		Object.assign(this, input);
	}

	get image (){
		return this.carCatalog.image;
	}
	get vehicleMake (){
		return this.carCatalog.vehicleMake;
	}
	get vehicleModel (){
		return this.carCatalog.vehicleModel;
	}
	get vehicleModelVersion (){
		return this.carCatalog.vehicleModelVersion;
	}
}

export interface IStartSessionTransactionParam {
	args:{ connectorId: number; visualTagID: string};
	carID: string;
	chargingStationID: string;
	userID: string;
}

export interface IgetSessionTransactionDetailsParam {
	WithUser: boolean;
	WithTag: boolean;
	WithCar: boolean;
}

export class ChargingStationInErrorModel {
	id: string = '';
	connectors?: {
		connectorId: number;
		errorCode: string;
		power: number;
		status: string;
		type: string;
	};
	errorCode?: string;

	constructor(input: ChargingStationInErrorModel) {
		Object.assign(this, input);
	}
}

export class RegistrationTokenResponseModalResult {
	id: string = '';
	createdBy?: string;
	lastChangedBy?: string;
	createdOn: string = '';
	description: string = '';
	expirationDate: string = '';
	lastChangedOn?: string = '';
	revocationDate?: string = '';
	siteAreaID?: string;
	canRead?: boolean = false;
	canDelete?: boolean = false;
	canUpdate?: boolean = false;
	canRevoke?: boolean = false;
	ocpp15SOAPSecureUrl: string = '';
	ocpp16SOAPSecureUrl: string = '';
	ocpp16JSONSecureUrl: string = '';
	siteArea?: {name: string};
	statusValue:string = '';
	constructor(input: RegistrationTokenResponseModalResult) {
		Object.assign(this, input);
		this.statusValue = this.status;
	}
	get status() {
		if(this.expirationDate && this.isExpired(this.expirationDate)) return 'Expired';
		if(this.revocationDate && this.isRevoked(this.revocationDate)) return 'Revoked';
		return 'Valid';
	}

	get siteAreaName(){
		return (this.siteArea?.name) ? this.siteArea.name : '';
	}
	get createdByFullName (){
      return this.createdBy ? this.createdBy : '-';
	}
	get lastChangedByName () {
		return this.lastChangedBy ? this.lastChangedBy : '-';
	}
	get expirationDateFormated (){
		return this.formatDate(this.expirationDate);
	}
	get revocationDateFormated (){
		return (this.revocationDate ? this.formatDate(this.revocationDate):' ');
	}
	get createdOnDateFormated (){
		return this.formatDate(this.createdOn);
	}
	get lastChangedOnDateFormated (){
		return this.formatDate(this.lastChangedOn ? this.lastChangedOn : '');
	}
	private isExpired = (expirationDate: string): boolean =>{
		return (new Date() > (new Date(expirationDate)));
	};
	private isRevoked = (revocationDate: string): boolean =>{
		return (new Date() > (new Date(revocationDate)));
	};

	private formatDate = (date : string):string => {
		const resultDate = ( date.length > 0) ?  new Date(date): '-';
		if( resultDate === '-') return resultDate;
		let hours = resultDate.getHours();
		const minutes = resultDate.getMinutes();
      const seconds = resultDate.getSeconds();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12;
		return (resultDate.getMonth()+1)+'/'+ resultDate.getDate() + '/'+ resultDate.getFullYear()%100+', ' +hours + ':' + ((minutes < 10) ? '0'+minutes : minutes) + ':' + ((seconds < 10) ? '0'+seconds : seconds) + ' ' + ampm;
	};
}

export interface IRegistrationTokenResponseModal {
  count: number;
  canCreate: boolean;
  result: RegistrationTokenResponseModalResult[];
  projectFields: string[];
}

export class UserInErrorModel {
	id: string = '';
	firstName?: string;
	errorCode?: string;
	email?: string;
	role?: string;
	status?: string;
	name?: string;

	constructor(input: UserInErrorModel) {
		Object.assign(this, input);
	}
}

export interface IOnBoardStationStateModel {
    rows: Record<string, any>[];
    defaultPageSize: number;
    issuer: boolean;
    limit: number;
    sortFields: string;
    search: any;
    isResetBtnDisabled: boolean;
    isSiteAreaLookupModalOpen: boolean;
    siteAreaId?: string;
    siteAreaName:string;
    totalRowsCount: number;
    withSiteArea: boolean;
  }
