import { Close } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { EvesTextbox } from './atoms/textbox/textbox';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import Config from '../constants/config';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// eslint-disable-next-line react/display-name
export const SearchBox = forwardRef((props: any, ref: any) => {
	const [searchState, setSearchState] = useState('');
	const searchBoxRef = useRef(null);
	const { t: translate } = useTranslation();
	const onChange = (e) => {
		const text = e.target.value;
		props.onChange(text);
	};

	const debouncedOnChange = useMemo(() => debounce(onChange, Config.advanced.debounceTimeSearchMillis), []);

	const onRemoveTextClick = () => {
		const input = searchBoxRef.current as any;
		input.setFocus();
		if (searchState) {
			setSearchState('');
			props.onChange('');
		} else {
			props.onRemoveClick();
		}
	};

	useImperativeHandle(ref, () => ({
		resetSearch: () => {
			if (searchState) {
				setSearchState('');
				props.onChange('');
			}
		},
		setValue: (value: any) => {
			setSearchState(value);
		}
	}));

	return (
		<EvesTextbox
			id='standard-search'
			label={`${translate('general.search')}`}
			autoFocus={true}
			variant='standard'
			isFilter={props?.isFilter ? props.isFilter : true}
			value={searchState}
			onChange={(e) => { setSearchState(e.target.value); debouncedOnChange(e); }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton  onClick={onRemoveTextClick}><Close data-cy='remove-search' sx={{ fontSize: 15 }}/></IconButton>
					</InputAdornment>
				),
				startAdornment: props?.isTransaction && (
					<InputAdornment position="start" style={{cursor:'pointer'}} onClick={props.handleClickStart}>
					<InfoOutlinedIcon style={{color:'#999999'}} />
					</InputAdornment>
				),
				
			}}
			
			ref={searchBoxRef}
			data-cy={props['data-cy']}
		/>
	);
});