import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useRef } from 'react';
import { EvesCheckbox } from './../checkbox/checkbox';
import styles from './multiselect.module.scss';

export default function EvesMultiselect(props: any) {
  const options = props.options;
  const itemsRef = useRef<Array<HTMLLIElement | null>>([]);

  const handleScroll = (
    index: number,
    scrollBehavior: 'auto' | 'smooth' = 'auto'
  ) => {
    itemsRef.current[index]?.scrollIntoView({
      block: 'center',
      behavior: scrollBehavior,
    });
  };

  useEffect(() => {
    itemsRef.current = itemsRef?.current.slice(0, props.options.length);
  }, props.options);

  return (
    <>
      <FormControl
        variant='standard'
        fullWidth
        className={`${styles.multiselect} ${
          props.isFilter ? styles.multiselectFilter : ''
        }`}
        data-cy={`${props['data-cy']}`}
      >
        <InputLabel
          className={styles.inputLabel}
          shrink={props.shrink}
          id={props.label + 'MultiSelectInputLabel'}
          sx={{
            fontSize:'16px',
            '@media (max-width: 1440px)': {
              fontSize:'12px'
            },
          }}
        >
          {props.label}
        </InputLabel>
        <Select
          className={styles.multiselectInput}
          labelId={props.label + 'MultiSelectInputLabel'}
          id={props.label + 'Multiselect'}
          multiple
          value={props.value}
          onChange={props.onChange}
          renderValue={props.renderValue}
          MenuProps={props.MenuProps}
          data-cy='filter-main'
        >
          {options.map((item: any, index: number) => (
            <MenuItem
              ref={(el) => (itemsRef.current[index] = el)}
              onClick={() => handleScroll(index, 'smooth')}
              key={item}
              value={item}
              className={styles.multiselectInputItem}
              data-cy='dropdown-list-item'
            >
              <EvesCheckbox
                checked={props.checked(item)}
                onClick={() => handleScroll(index)}
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
