import { Grid, IconButton, InputAdornment, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import {
  GoogleMap,
  LoadScriptNext,
  StandaloneSearchBox,
  MarkerF,
} from '@react-google-maps/api';
import { EvesTextbox } from '../../atoms/textbox/textbox';
import { EvesButton } from '../../atoms/button/button';
import { EvesModal } from './../../molecules/modal/modal';
import styles from './geoMap.module.scss';
import { Search, ZoomOut } from '@mui/icons-material';
import { ZoomIn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
interface GeoMapProps {
  label?: string;
  name?: string;
  isOpen: boolean;
  defaultLatLng?: any;
  handleClose: () => void;
  onLocationPick: (latlng: any) => void;
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

const mapOptions: google.maps.MapOptions = {
  fullscreenControl: true,
  streetViewControl: false,
  mapTypeControl: true,
  zoomControl: false,
};

const libs: any = ['places'];
const MAPS_API_KEY: string = process.env.REACT_APP_MAPS_API_KEY ?? '';

const GeoMap = (props: GeoMapProps) => {
  const { t: translate } = useTranslation();
  const title = props.name
    ? `${translate('geomap.dialog_geolocation_title')
        .replace(/{{componentName}}/g, `${props.label}`)
        .replace(/{{itemComponentName}}/g, `${props.name}`)}`
    : `${translate('geomap.select_geolocation')}`;
  const [state, setStateData] = useState({
    latlng: {
      lat: props.defaultLatLng?.lat || 0,
      lng: props.defaultLatLng?.lng || 0,
    },
    center: {
      lat: props.defaultLatLng?.lat || 37.09024,
      lng: props.defaultLatLng?.lng || -95.712891,
    },
    zoom: 4,
  });

  const searchBoxRef = useRef<any>();

  const placeSearchBoxRef: google.maps.places.SearchBox | any = null;
  const [placeSearch, setPlaceSearch] = useState(placeSearchBoxRef);

  const onPlaceSelect = () => {
    if (placeSearch !== null) {
      const places = placeSearch.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        const lat = selectedPlace?.geometry?.location?.lat();
        const lng = selectedPlace?.geometry?.location?.lng();
        setStateData((currentData) => {
          return {
            ...currentData,
            latlng: {
              lat: lat,
              lng: lng,
            },
            center: {
              lat: lat,
              lng: lng,
            },
          };
        });
      }
    }
  };

  const onPlaceSearchBoxLoad = (searchBox: google.maps.places.SearchBox) => {
    setPlaceSearch(searchBox);
  };

  const onMapClick = (ev: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        latlng: {
          lat: ev.latLng.lat(),
          lng: ev.latLng.lng(),
        },
      };
    });
  };
  const onLocationPick = () => {
    props.onLocationPick(state.latlng);
  };

  const maxZoom = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        zoom: 20,
      };
    });
  };

  const minZoom = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        zoom: 4,
      };
    });
  };

  const onSearchClick = () => {
    searchBoxRef.current.focus();
    onPlaceSearchBoxLoad(placeSearchBoxRef);
  };

  return (
    <>
      <EvesModal
        isOpen={props.isOpen}
        title={title}
        onHandleClose={props.handleClose}
        isSaveDisabled={true}
        isSaveHidden={true}
      >
        <LoadScriptNext googleMapsApiKey={MAPS_API_KEY} libraries={libs}>
          <section className={styles.geomap}>
            <div className={styles.geomapHeader}>
              <Grid container>
                <Grid item xs={12}>
                  <StandaloneSearchBox
                    onLoad={onPlaceSearchBoxLoad}
                    onPlacesChanged={onPlaceSelect}
                  >
                    <EvesTextbox
                      ref={searchBoxRef}
                      id='location1'
                      name='location1'
                      label={`${translate('geomap.search')}`}
                      placeholder=''
                      fullWidth
                      variant='standard'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={onSearchClick}>
                              <Search sx={{ fontSize: 15 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StandaloneSearchBox>
                </Grid>
              </Grid>
            </div>

            <div className={styles.geomapBody} data-cy='gmap-body'>
              <GoogleMap
                mapContainerStyle={containerStyle}
                clickableIcons={false}
                options={mapOptions}
                onClick={onMapClick}
                center={state.center}
                zoom={state.zoom}
              >
                <MarkerF
                  visible={state.latlng.lat !== 0}
                  position={state.latlng}
                />
              </GoogleMap>
            </div>

            <div className={styles.geomapFooter}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack direction='row' spacing={1}>
                    <EvesButton onClick={minZoom}>
                      <ZoomOut fontSize='small' />
                      {`${translate('geomap.min_zoom')}`}
                    </EvesButton>
                    <EvesButton onClick={maxZoom}>
                      <ZoomIn fontSize='small' data-cy='gmap-max-zoom' />
                      {`${translate('geomap.max_zoom')}`}
                    </EvesButton>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction='row' spacing={1} justifyContent='flex-end'>
                    <EvesButton onClick={onLocationPick}>{`${translate(
                      'general.set_coordinates'
                    )}`}</EvesButton>
                    <EvesButton
                      onClick={props.handleClose}
                      variant='outlined'
                    >{`${translate('general.cancel')}`}</EvesButton>
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </section>
        </LoadScriptNext>
      </EvesModal>
    </>
  );
};

export default GeoMap;
