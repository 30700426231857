import { InactivityStatus } from '@/common/enums/enums';
import { getDuration, getpercentage } from '@/common/utils/utils';
import React from 'react';

const InActivityCell = (row: any) => {

	const buildClassColorByStatus = (status?: any): React.CSSProperties => {
		let classResult: React.CSSProperties | undefined = { };
		switch (status) {
		case InactivityStatus.INFO:
			classResult = { color: '#388e3c'};
			break;
		case InactivityStatus.ERROR:
			classResult = { color: '#D32F2F'};
			break;
		case InactivityStatus.WARNING:
			classResult = { color: '#F57C00'};
			break;
		}
		return classResult;
	};

	const getInactivityText = (transaction: any): string => {
		let totalDurationSecs = 0;
		let totalInactivitySecs = 0;
		if (transaction.stop) {
			totalDurationSecs = transaction.stop.totalDurationSecs;
			totalInactivitySecs = transaction.stop.totalInactivitySecs + transaction.stop.extraInactivitySecs;
		} else {
			totalDurationSecs = transaction.currentTotalDurationSecs;
			totalInactivitySecs = transaction.currentTotalInactivitySecs;
		}
		if (totalDurationSecs) {
			const percentage = totalDurationSecs > 0 ? (totalInactivitySecs / totalDurationSecs) : 0;
			return getDuration(totalInactivitySecs) +
        ` (${getpercentage(percentage, 0)})`;
		}
		return getDuration(totalInactivitySecs);
	};

	return(
		<>
			<span>
				<div>
					<span style={buildClassColorByStatus(row.stop ? row.stop.inactivityStatus : row.currentInactivityStatus)}>
						{getInactivityText(row)}
					</span>
				</div>
			</span>
		</>
	);
};

export default InActivityCell;