import  * as schema from 'yup';
import { RefundingFormModel } from '../models/integrationSettings';
import { findRequiredFields } from '@/common/utils/validationHelper';

export const formSchema = schema.object<Partial<Record<keyof RefundingFormModel, schema.AnySchema>>>({
	authenticationUrl: schema.string().required('general.mandatory_field'),
	apiUrl: schema.string().required('general.mandatory_field'),
	clientId: schema.string().required('general.mandatory_field'),
	clientSecret: schema.string().required('general.mandatory_field'),
	paymentTypeId: schema.string().required('general.mandatory_field'),
	expenseTypeCode: schema.string().required('general.mandatory_field'),
	policyId: schema.string().required('general.mandatory_field'),
	reportName: schema.string().required('general.mandatory_field'),
	appUrl: schema.string().required('general.mandatory_field'),
    
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);