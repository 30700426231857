import { httpClient } from './httpClient';

export const getUserInformation = (userId: string) => {
	return httpClient.get(`/api/users/${userId}`);
};

export const getIntegrationConnections = (userId: string) => {
	return httpClient.get(`/api/connections?UserID=${userId}`);
};

export const getRefundSettings = () => {
	return httpClient.get('/api/settings?Identifier=refund');
};

export const getCarConnectorSettings = () => {
	return httpClient.get('/api/settings?Identifier=carConnector');
};

export const deleteIntegrationConnection = (connectionId: string) => {
	return httpClient.get(`/api/connections/${connectionId}`);
};

export const exportUsers = (params: any) => {
	return httpClient.get('/api/users/action/export', params);
};

export const getBillingSettings = () => {
	return httpClient.get('/api/billing-setting');
};

export const deletePaymentMethod = (paymentMethodInfo: any) => {
	return httpClient.delete(`/api/users/${paymentMethodInfo.userID}/payment-methods/${paymentMethodInfo.paymentID}`);
};

export const getPaymentMethods = (userId: string) => {
	return httpClient.get(`/api/users/${userId}/payment-methods?Limit=50`);
};

export const setupPaymentMethod = (paymentMethodInfo: any) => {
	return httpClient.post(`/api/users/${paymentMethodInfo.userID}/payment-methods/setup`, paymentMethodInfo);
};

export const attachPaymentMethod = (paymentMethodInfo: any) => {
	return httpClient.post(`/api/users/${paymentMethodInfo.userID}/payment-methods/setup`, paymentMethodInfo);
};

export const getUserImage = (id: string) => {
	return httpClient.getImage(`/api/users/${id}/image`);
};

export const updateUser = (userFormData: any) => {
	return httpClient.put(`/api/users/${userFormData.id}`, userFormData);
};

export const importUsers = (formData: any) => {
	return httpClient.uploadFile('/api/users/action/import', formData, {
		headers: { 'Content-Type': 'multipart/form-data;' }
	});
};

export const createUser = (userFormData: any) => {
	return httpClient.post('/api/users', userFormData);
};