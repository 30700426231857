import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  blobToBase64String,
  buildMobileAppDeepLink,
  getSubdomain,
  isInMobileApp,
} from '@/common/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// Local imports
import img from '@/assets/img/theme/no-image.png';
import { formSchema } from '../../shared/schemas/definePasswordFormSchema';
import { DefinePasswordFormModel } from '../../shared/models/models';
import { getTenantLogoBySubdomain, resetUserPassword } from '@/services/auth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Logo } from '@/common/components/atoms/logo/logo';
import { LockOutlined } from '@mui/icons-material';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesButton } from '@/common/components/atoms/button/button';
import { IconButton, InputAdornment } from '@mui/material';
import { showNotification } from '@/store/app/reducer';
import store from '@/store';

// this import is temp, must be changed by UI dev
import styles from '../signUp/signUp.module.scss';

const DefinePassword = () => {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    tenantLogo: img,
    isPasswordVisible: false,
    isRepeatPasswordVisible: false,
  });

  const navigate = useNavigate();
  const subDomain: any = getSubdomain();

  const [searchParams] = useSearchParams();
  const hashID: any = searchParams.get('hash');

  // to set URL for mobile user
  let currentWindowURL: any;

  // added to avoid console warning
  currentWindowURL = window.location.href;

  const { control, formState, getValues, setValue, trigger } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: new DefinePasswordFormModel(),
    mode: 'onTouched',
  });

  const { errors, isValid } = formState;
  const canSubmitForm = isValid;

  const { refetch: fetchTenantLogo } = useQuery(
    'tenantLogo',
    () => {
      if (subDomain) {
        return getTenantLogoBySubdomain(subDomain);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if (data) {
          const base64String = await blobToBase64String(data);
          setStateData((currentData) => {
            return {
              ...currentData,
              tenantLogo: base64String,
            };
          });
        }
      },
    }
  );

  useEffect(() => {
    fetchTenantLogo();
  }, []);

  // password visibility toggle
  const handleClickShowPassword = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isPasswordVisible: !currentData.isPasswordVisible,
      };
    });
  };

  const handleClickShowRepeatPassword = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isRepeatPasswordVisible: !currentData.isRepeatPasswordVisible,
      };
    });
  };

  const resetPassword = (data: any) => {
    data['resetPasswordHash'] = hashID;
    setValue('resetPasswordHash', hashID);
    const resetForm = getValues();

    // Handle Deep Linking
    if (isInMobileApp(subDomain)) {
      // Forward to Mobile App
      const mobileAppURL = buildMobileAppDeepLink(
        `resetPassword/${subDomain}/${hashID}`
      );
      currentWindowURL = mobileAppURL;
    }

    resetUserPassword(
      resetForm.password,
      resetForm.repeatPassword,
      resetForm.resetPasswordHash
    ).then((res: any) => {
      // show notification when password changed successfully
      if (res.status === 200 || res.status === 'Success') {
        store.dispatch(
          showNotification({
            showNotification: true,
            message: `${translate('authentication.define_password_success')}`,
            type: 'success',
          })
        );

        // re-direct user to login screen after successful password change
        navigate('/login');
      } else {
        // show notification when encoutered error
        store.dispatch(
          showNotification({
            showNotification: true,
            message: `${translate('authentication.define_password_error')}`,
            type: 'error',
          })
        );
      }
    });
  };

  const validatePasswords = () => {
    trigger('password');
    if (formState.touchedFields['repeatPassword']) trigger('repeatPassword');
  };

  return (
    <div className={styles.register}>
      <div className={styles.registerCard}>
        <div className={styles.registerCardHeader}>
          <Logo src={state.tenantLogo} alt='tenant-logo' />
        </div>
        <h4 className={styles.registerTitle}>{`${translate(
          'authentication.define_password_title'
        )}`}</h4>

        <form id='reset-password' data-cy='reset-password-main'>
          <div className={styles.registerFormRow}>
            <Controller
              name='password'
              control={control}
              key='password'
              render={({ field }) => (
                <div className={styles.registerFormControl}>
                  <LockOutlined className={styles.signup_icons} />
                  <EvesTextbox
                    {...field}
                    id='password'
                    label={`${translate('authentication.password')}`}
                    type={state.isPasswordVisible ? 'text' : 'password'}
                    fullWidth
                    required={true}
                    variant='standard'
                    error={!!errors?.password}
                    onChange={(event: any) => {
                      field.onChange(event);
                      validatePasswords();
                    }}
                    autoFocus
                    helperText={`${translate(
                      errors?.password?.message || ' '
                    )}`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <IconButton onClick={handleClickShowPassword}>
                            {!state.isPasswordVisible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className={styles.registerFormRow}>
            <Controller
              name='repeatPassword'
              control={control}
              key='repeatPassword'
              render={({ field }) => (
                <div className={styles.registerFormControl}>
                  <LockOutlined className={styles.registerIconInvisible} />
                  <EvesTextbox
                    {...field}
                    id='repeatPassword'
                    label={`${translate('authentication.repeat_password')}`}
                    type={state.isRepeatPasswordVisible ? 'text' : 'password'}
                    fullWidth
                    required={true}
                    variant='standard'
                    error={!!errors?.repeatPassword}
                    helperText={`${translate(
                      errors?.repeatPassword?.message || ' '
                    )}`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <IconButton onClick={handleClickShowRepeatPassword}>
                            {!state.isRepeatPasswordVisible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className={styles.recaptcha_text}>
            {`${translate('general.captcha_text_1')}`}{' '}
            <a href='https://policies.google.com/privacy'>{`${translate(
              'general.captcha_text_2'
            )}`}</a>{' '}
            {`${translate('general.captcha_text_3')}`}{' '}
            <a href='https://policies.google.com/terms'>{`${translate(
              'general.captcha_text_4'
            )}`}</a>
            {`${translate('general.captcha_text_5')}`}
          </div>
          <div className={styles.registerCenterRegBtn}>
            <EvesButton
              data-cy='reset-password'
              size='medium'
              onClick={resetPassword}
              disabled={!canSubmitForm}
              id='btnLogin'
              variant='primary'
            >
              {`${translate('general.save')}`}
            </EvesButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DefinePassword;
