import { IContextPropsModel, IFormPropsModel } from '@/common/utils/withContext';

export class ElectricVehicleModel {
  evCatalog?:string='';
  vehicleMake?:string='';
  id?:string='';
  converter?:string='';
  vin?:string='';
  licensePlate?:string='';
  user?:string='';
  defaultEv?:boolean=false;
  accessControl?:any='';
  evCatalogId?:any='';
  image?:any='';
  type?:any='C';
  userId?:string='';
  amperagePerPhase?:number;
  numberOfPhases?:number;
  powerWatts?:number;
  converterType?:string='';
  connectorMeterId?:string='';
  connectionName?:string='';
}
export interface IElectricVehicleModel extends IContextPropsModel,IFormPropsModel<ElectricVehicleModel>{
  electricVehicleId:string,
  openElectricVehicle?:boolean,
  onFormClose?:any,
  pageLimit?:any
  canCreate?:boolean,
  resetElectricVehicleForm?:any,
  fetchAllElectricVehicles?:any
}