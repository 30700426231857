import { Controller, UseFormReturn } from 'react-hook-form';
import { PriceFormModel } from '@/modules/organization/shared/models/price';
import { FormControlLabel, Grid, Box } from '@mui/material';
import { requiredFields } from '@/modules/organization/shared/schemas/priceFormSchema';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AllConnectorsIcon from '@/assets/img/connectors/all_connectors.svg';
import Type2Icon from '@/assets/img/connectors/type2.svg';
import Type2ComboCcsIcon from '@/assets/img/connectors/combo-ccs.svg';
import ChademoIcon from '@/assets/img/connectors/chademo.svg';
import EvesSelect from '@/common/components/atoms/select/select';
import Type1Icon from '@/assets/img/connectors/type1.svg';
import Type1CCSIcon from '@/assets/img/connectors/type1-ccs-new.svg';
import NacsIcon from '@/assets/img/connectors/nscc.svg';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesSwitch } from '@/common/components/atoms/switch/switch';
import { useTranslation } from 'react-i18next';
import { getCalendarLocale } from '@/common/utils/utils';

interface IPricingPropsModel {
  priceForm: UseFormReturn<PriceFormModel, any>;
}

export default function Pricing(props: IPricingPropsModel) {
  const { t: translate } = useTranslation();
  const { control, formState, watch } = props.priceForm;
  const { errors } = formState;
  const minDate = watch('staticRestrictions.validFrom');
  const connectorPowerEnabled = watch(
    'staticRestrictions.connectorPowerEnabled'
  );
  const triggerDateValidation = () => {
    props.priceForm.trigger('staticRestrictions.validTo');
    props.priceForm.trigger('staticRestrictions.validFrom');
  };

  const updateDate = (e: any, type: string) => {
    const selectedDate = e.currentTarget.value.replace(/\D/g, '');
    if (selectedDate.length > 0 && selectedDate.length == 8) {
      const date =
        selectedDate.substr(0, 2) +
        '/' +
        selectedDate.substr(2, 2) +
        '/' +
        selectedDate.substr(4, 4);
      if (type == 'validFrom') {
        props.priceForm.setValue('staticRestrictions.validFrom', date);
      } else if (type == 'validTo') {
        props.priceForm.setValue('staticRestrictions.validTo', date);
      }
    }
    triggerDateValidation();
  };

  const connectorTypeOptions = [
    {
      label: `${translate('chargers.connector_type_all')}`,
      value: 'A',
      img: AllConnectorsIcon,
    },
    {
      label: `${translate('chargers.connector_type_type2')}`,
      value: 'T2',
      img: Type2Icon,
    },
    {
      label: `${translate('chargers.connector_type_combo')}`,
      value: 'CCS',
      img: Type2ComboCcsIcon,
    },
    {
      label: `${translate('chargers.connector_type_chademo')}`,
      value: 'C',
      img: ChademoIcon,
    },
    {
      label: `${translate('chargers.connector_type_type1')}`,
      value: 'T1',
      img: Type1Icon,
    },
    {
      label: `${translate('chargers.connector_type_type1ccs')}`,
      value: 'T1CCS',
      img: Type1CCSIcon,
    },
    {
      label: `${translate('chargers.connector_type_nacs')}`,
      value: 'NACS',
      img: NacsIcon,
    },
  ];

  return (
    <Grid container direction='row' rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='name'
              label={`${translate('settings.pricing.pricing_definition_name')}`}
              fullWidth
              required={requiredFields['name']}
              variant='standard'
              error={!!errors?.name}
              helperText={`${translate(errors?.name?.message || ' ')}`}
              autoComplete='off'
              autoFocus={true}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='description'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='description'
              label={`${translate(
                'settings.pricing.pricing_definition_description'
              )}`}
              fullWidth
              required={requiredFields['description']}
              variant='standard'
              error={!!errors?.description}
              helperText={`${translate(errors?.description?.message || ' ')}`}
              autoComplete='off'
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name='staticRestrictions.validFrom'
          control={control}
          render={({ field }) => {
            return (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={getCalendarLocale()}
              >
                <DatePicker
                  label={`${translate('settings.pricing.valid_from')}`}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    triggerDateValidation();
                  }}
                  mask='/'
                  renderInput={(params: any) => (
                    <EvesTextbox
                      variant='standard'
                      {...params}
                      name={field.name}
                      onBlur={(e) => {
                        updateDate(e, 'validFrom');
                      }}
                      onChange={() => triggerDateValidation()}
                      error={!!errors.staticRestrictions?.validFrom}
                      helperText={`${translate(
                        errors?.staticRestrictions?.validFrom?.message || ' '
                      )}`}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name='staticRestrictions.validTo'
          control={control}
          render={({ field }) => (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={getCalendarLocale()}
            >
              <DatePicker
                label={`${translate('settings.pricing.valid_to')}`}
                {...field}
                minDate={minDate}
                mask='/'
                onChange={(e) => {
                  field.onChange(e);
                  triggerDateValidation();
                }}
                renderInput={(params: any) => (
                  <EvesTextbox
                    variant='standard'
                    {...params}
                    name={field.name}
                    onBlur={(e) => {
                      updateDate(e, 'validTo');
                    }}
                    onChange={() => triggerDateValidation()}
                    error={!!errors.staticRestrictions?.validTo}
                    helperText={`${translate(
                      errors?.staticRestrictions?.validTo?.message || ' '
                    )}`}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ paddingLeft: '50px' }}>
          <Grid container direction='row' rowSpacing={1}>
            <Grid item xs={12}>
              <Controller
                name='staticRestrictions.connectorType'
                control={control}
                render={({ field }) => {
                  return (
                    <EvesSelect
                      label={`${translate(
                        'settings.pricing.connector_type'
                      )} *`}
                      defaultValue='A'
                      value={field.value || 'A'}
                      options={connectorTypeOptions}
                      onChange={(e: any) =>
                        field.onChange(
                          e.target.value === 'A' ? '' : e.target.value
                        )
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='staticRestrictions.connectorPowerEnabled'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                      <EvesSwitch
                        {...field}
                        checked={field.value}
                        id='connectorPower'
                      />
                    }
                    label={`${translate('settings.pricing.connector_power')}`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='staticRestrictions.connectorPowerkW'
                control={control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id='connectorPowerkW'
                    label={`${translate(
                      'settings.pricing.connector_power_unit'
                    )}`}
                    fullWidth
                    required={connectorPowerEnabled}
                    variant='standard'
                    disabled={!connectorPowerEnabled}
                    error={
                      connectorPowerEnabled &&
                      !!errors?.staticRestrictions?.connectorPowerkW
                    }
                    helperText={
                      connectorPowerEnabled &&
                      `${translate(
                        errors?.staticRestrictions?.connectorPowerkW?.message ||
                          ' '
                      )}`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
