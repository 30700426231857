import { EvesButton } from '@/common/components/atoms/button/button';
import { EvesChip } from '@/common/components/atoms/chip/chip';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { DefaultPageSize } from '@/common/constants/constants';
import { Formatter } from '@/common/utils/formatHelper';
import { withContext } from '@/common/utils/withContext';
import { deletePaymentMethod, getBillingSettings, getPaymentMethods } from '@/services/userProfile';
import { Add, Delete, Refresh } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import AddPaymentMethodForm from './paymentForm/addPaymentMethodForm';
import { Card } from '@/common/components/molecules/card/card';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { columnsType } from '@/common/models/model';
import { useTranslation } from 'react-i18next';

const Billing = (props: any) => {
	const { t: translate } = useTranslation();
	const [state, setStateData] = useState({
		rows: [],
		billingSettings: {
			stripe: {
				publicKey: '',
			}
		},
		limit: DefaultPageSize,
		paymentMethods: {},
		showPaymentForm: false,
		totalRowsCount: 0,
		projectFields:[],
	} as any);

	const userForm = props.userForm;
	const { refetch: fetchBillingSettings } = useQuery(
		'Billing Settings',
		() => {
			return getBillingSettings();
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: false,
			onSuccess: async (data: any) => {
				if (data) {
					setStateData((currentData: any) => {
						return {
							...currentData,
							billingSettings: data,
							projectFields: data.projectFields && [...data.projectFields],
						};
					});
				}
			},
		}
	);

	const { refetch: fetchPaymentMethods } = useQuery(
		'Payment Methods',
		() => {
			return getPaymentMethods(userForm.getValues().id);
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: false,
			onSuccess: async (data: any) => {
				if(data.status === 505){
					props.notificationService?.notify({
						message: `${translate('settings.billing.not_properly_set')}`,
						type: 'error',
					});
				}else if (data) {
					const paymentMethods = data.result;
					setStateData((currentData: any) => {
						return {
							...currentData,
							rows: paymentMethods,
							totalRowsCount: data.count,
						};
					});
				}
			},
		}
	);

	useEffect(() => {
		fetchBillingSettings();
		fetchPaymentMethods();
	}, []);

	const doDeletePaymentMethod = (record: any) => {	
		props.dialogService?.showConfirm(
			{
				title: `${translate('settings.billing.payment_methods_delete_title')}`,
				description: `${translate('settings.billing.payment_methods_delete_confirm').replace(/{{last4}}/g, `'${record.last4}'`)}`,
				confirmType: 'YES_NO',
			},
			(result: any) => {
				if (result === 'YES') {
					deletePaymentMethod({paymentID: record.id, userID: userForm.getValues().id}).then((res: any) => {
						if(res.succeeded){
							props.notificationService?.notify({
								message: `${translate('settings.billing.payment_methods_delete_success').replace(/{{last4}}/g, `'${record.last4}'`)}`,
								type: 'success',
							});
							fetchPaymentMethods();
						}else {
							props.notificationService?.notify({
								message: `${translate('settings.billing.payment_methods_delete_error')}`,
								type: 'error',
							});
						}
					});
				}
			}
		);
	};

	const primaryActions: ITableActionMenuModel[] = [
		{ 
			title: (record: any) => record.canDelete ? 'Delete' : '',
			icon: (record: any) => record.canDelete ? Delete : '',
			iconColor: 'error',
			onClick: doDeletePaymentMethod,
			visible: (record: any) => record.canDelete
		},
		{ 
			title: () =>'',
			icon: () => '',
			disabled: (record: any) => !record.canDelete,
			visible: (record: any) => !record.canDelete
		}
	];

	const Type = (row: any) => {
		return <div>{row.type[0].toUpperCase() + row.type.slice(1, row.type.length)}</div>;
	};

	const Brand = (row: any) => {
		return <div>{row.brand[0].toUpperCase() + row.brand.slice(1, row.brand.length)}</div>;
	};

	const Status = (row: any) => {
		if (isExpired(row)) {
			return <EvesChip label={`${translate('settings.billing.payment_methods_expired')}`} type={'Expired'}/>;
		} else if (expireSoon(row)) {
			return <EvesChip label={`${translate('settings.billing.payment_methods_expire_soon')}`} type={'Expires soon'}/>;
		} else {
			return <EvesChip label={`${translate('settings.billing.payment_methods_valid')}`} type={'Available'}/>;
		}
	};

	const ExpiringOn = (row: any) => {
		const dateTime = Formatter.toDateTimeString(row.expiringOn);
		return <div>{dateTime}</div>;
	};

	const CreatedOn = (row: any) => {
		const dateTime = Formatter.toDateTimeString(row.createdOn);
		return <div>{dateTime}</div>;
	};

	const Default = (row: any) => {
		if(row.isDefault)
			return <div>Yes</div>;
		return <div>No</div>;
	};

	const isExpired = (paymentMethod: any) => {
		return paymentMethod.expiringOn && dayjs().isAfter(dayjs(paymentMethod.expiringOn));
	};

	const expireSoon = (paymentMethod: any) => {
		return paymentMethod.expiringOn && (dayjs(paymentMethod.expiringOn).isBefore(dayjs().add(2, 'months')));
	};

	const columns: columnsType[] = [
		{ 
			id: 'actions',
			label: `${translate('general.actions')}`, 
			minWidth: 20,
			projectField:'action',
		},
		{
			id: 'status',
			label: `${translate('general.status')}`,
			textAlign: 'center',
			minWidth: 70,
			maxWidth: 240,
			projectField: 'status',
		},
		{
			id: 'default',
			label: `${translate('general.default')}`,
			textAlign: 'center',
			minWidth: 50,
			maxWidth: 210,
			projectField: 'default',
		},
		{
			id: 'type',
			label: `${translate('settings.billing.payment_methods_type')}`,
			textAlign: 'center',
			minWidth: 100,
			maxWidth: 210,
			projectField: 'type',
		},
		{
			id: 'brand',
			label: `${translate('settings.billing.payment_methods_brand')}`,
			textAlign: 'center',
			minWidth: 120,
			maxWidth: 210,
			projectField: 'brand',
		},
		{
			id: 'last4',
			label: `${translate('settings.billing.payment_methods_ending_with')}`,
			textAlign: 'center',
			minWidth: 100,
			maxWidth: 210,
			projectField: 'last4',
		},
		{
			id: 'expiringOn',
			label: `${translate('settings.billing.payment_methods_expiring_on')}`,
			textAlign: 'center',
			minWidth: 100,
			maxWidth: 210,
			projectField: 'expiringOn',
		},
		{
			id: 'createdOn',
			label: `${translate('general.created_on')}`,
			textAlign: 'center',
			minWidth: 100,
			maxWidth: 210,
			projectField: 'createdOn',
		},
	];

	const columnsInError: columnsType[] = [
		{ 
			id: 'actions',
			label: `${translate('general.actions')}`, 
			textAlign: 'center',
			projectField:'action',
		},
	];

	const onFormClose=()=>{
		setStateData((currentData) => {
			return {
				...currentData,
				showPaymentForm:false,
			};
		});
	};

	const resetPaymentForm = () => {
		setStateData((currentData: any) => {
			return {
				...currentData,
			};
		});
	};

	const createPaymentMethod = () => {
		setStateData((currentData) => {
			return {
				...currentData,
				showPaymentForm: true,
			};
		});
	};

	let stripePromise;
	
	if(state.billingSettings.stripe.publicKey)
		stripePromise = loadStripe(state.billingSettings.stripe.publicKey);

	if(!state.billingSettings.stripe.publicKey)
		return <Card icon={<CreditCardIcon />} title={`${translate('settings.billing.payment_methods')}`} isTransparent={true} sx={{paddingBottom:'0'}}>
			<>
				<Stack direction={'row'} justifyContent={'end'} marginBottom={'10px'}>
					<EvesButton
						data-cy="payment-refresh-btn"
						onClick={fetchPaymentMethods}
					><Refresh/></EvesButton>
				</Stack>
				<DataTable
					className='companies-table'
					data-cy='companies-table'
					rows={state.rows}
					columns={columnsInError}
					actionsMenu={primaryActions}
					totalRowsCount={state.rows.length}
					projectFields={state.projectFields}
					onChange={(e) => console.log(e)}
					height={{height:'calc(100vh - 328px)'}}
				>
					<Status slot='status'/>
					<Default slot='default'/>
					<ExpiringOn slot='expiringOn'/>
					<CreatedOn slot='createdOn'/>
					<Type slot='type'/>
					<Brand slot='brand'/>
				</DataTable>
			</>
		</Card>;
		
	return <>{state.billingSettings.stripe.publicKey && 
		<Elements stripe={stripePromise}><>
			<Card icon={<CreditCardIcon />} title={`${translate('settings.billing.payment_methods')}`} isTransparent={true} sx={{paddingBottom:'0'}}>
				<>
					<Stack direction={'row'} justifyContent={'space-between'} marginBottom={'10px'}>
						<EvesButton
							startIcon={<Add style={{ fontSize: '22.4px' }} />}
							data-cy='payment-add-btn'
							onClick={createPaymentMethod}
							style={{ padding: '8px 24px' }}
						>
							{`${translate('general.add')}`}
						</EvesButton>
						<EvesButton
							data-cy="payment-refresh-btn"
							onClick={fetchPaymentMethods}
						><Refresh/></EvesButton>
					</Stack>
					<DataTable
						className='companies-table'
						data-cy='companies-table'
						rows={state.rows}
						columns={columns}
						actionsMenu={primaryActions}
						totalRowsCount={state.totalRowsCount}
						projectFields={state.projectFields}
						onChange={(e) => console.log(e)}
						height={{height:'calc(100vh - 328px)'}}
					>
						<Status slot='status'/>
						<Default slot='default'/>
						<ExpiringOn slot='expiringOn'/>
						<CreatedOn slot='createdOn'/>
						<Type slot='type'/>
						<Brand slot='brand'/>
					</DataTable>
				</>
			</Card>
			{state.showPaymentForm && (
				<AddPaymentMethodForm
					userId={userForm.getValues().id}
					resetPaymentForm={resetPaymentForm}
					showCompanyForm={state.showPaymentForm}
					onFormClose={onFormClose}
					fetchAllPaymentMethods={fetchPaymentMethods}
					canCreate={state.canCreate}
				/>
			)}</></Elements>}</>;
};

export default withContext(Billing);