import { CircularProgress } from '@mui/material';
import styles from './index.module.scss';
export const SemiCircular = () => {
    return (
        <>
            <div className={styles.semiCircularContainer} style={{ position: 'relative' }}>
                {/* Unfilled portion */}
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={100}
                    thickness={2.5}
                    style={{ position: 'absolute', top: '0', left: '0', transform: 'rotate(-180deg)' }}
                    sx={{
                        '& .MuiCircularProgress-svg': {
                            transformOrigin: 'center',
                            '& .MuiCircularProgress-circle': {
                                strokeLinecap: 'round',
                                stroke: '#40404026',
                            },
                        },
                    }}
                    className={styles.unfilledProgress}
                />
                {/* Filled portion */}
                <CircularProgress
                    variant="determinate"
                    value={30}
                    size={100}
                    thickness={2.5}
                    style={{ position: 'absolute', top: '0', left: '0', transform: 'rotate(-180deg)', marginBottom: '5px' }}
                    sx={{
                        '& .MuiCircularProgress-svg': {
                            transformOrigin: 'center',
                            '& .MuiCircularProgress-circle': {
                                strokeLinecap: 'round',
                                stroke: '#F79009',
                                borderRadius: '10px'
                            },
                        },
                    }}
                    className={styles.filledProgress}
                />
                <div style={{ position: 'absolute', top: '90%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '12px', color: '#000' }} className={styles.CenterText}>
                    {`${35}%`}
                </div>
            </div>
        </>
    );
};