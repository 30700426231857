// ErrorBoundary.tsx
import React, { Component, ReactNode } from 'react';
import { ErrorContent } from './ErrorContent';
import Config from '@/common/constants/config';
import ErrorLogging from './ErrorLogging';
import { withContext } from '@/common/utils/withContext';
import { ShowConfirmType } from '@/common/components/molecules/dialog/dialog';
import { DoNotifyType } from '@/common/utils/notificationService';

interface ErrorBoundaryState {
  hasError: boolean;
}
export interface IContextPropsModel {
  dialogService?: {
    showConfirm: ShowConfirmType;
  };
  notificationService?: {
    notify: DoNotifyType;
  };
}

interface ErrorBoundaryProps extends IContextPropsModel {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.logError(error, errorInfo);
  }

  async logError(error: Error, errorInfo: React.ErrorInfo) {
    const data = {
      messageType: "Error",
      message: `Error: ${error.message}, Stack: ${errorInfo.componentStack}`
    };
  
    await ErrorLogging(data, this.props);
  }

  render() {
    const showError = Config.showError.hasError;
    if (this.state.hasError && showError) {
      return <ErrorContent />;
    }
    return this.props.children;
  }
}

export default withContext(ErrorBoundary);
