import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TronityConnection = (props: any) => {
	const { t: translate } = useTranslation();

	return <Grid container rowSpacing={1} columnSpacing={2} sx={{ marginTop:'8px'}}>
		<Grid item xs={12}>
			<Controller name="tronityConnection.apiUrl" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="apiUrl" label={`${translate('settings.car_connector.connection.api_url')}`}
					fullWidth
					variant="standard"
					data-cy="apiUrl"
					error={!!props.carConnectorForm.formState?.errors?.tronityConnection?.apiUrl}
					helperText={`${translate(props.carConnectorForm.formState?.errors?.tronityConnection?.apiUrl?.message || ' ')}`}
				/>
			)} />
		</Grid>
		<Grid item xs={6}>
			<Controller name="tronityConnection.clientId" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="clientId" label={`${translate('settings.car_connector.connection.client_id')}`}
					data-cy="clientId"
					fullWidth
					variant="standard"
					autoComplete="new-password"
					error={!!props.carConnectorForm.formState?.errors?.tronityConnection?.clientId}
					helperText={`${translate(props.carConnectorForm.formState?.errors?.tronityConnection?.clientId?.message || ' ')}`}
				/>
			)} />
		</Grid>
		<Grid item xs={6}>
			<Controller name="tronityConnection.clientSecret" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="clientSecret" label={`${translate('settings.car_connector.connection.client_secret')}`}
					data-cy="clientSecret"
					type="password"
					fullWidth
					variant="standard"
					autoComplete="new-password"
					error={!!props.carConnectorForm.formState?.errors?.tronityConnection?.clientSecret}
					helperText={`${translate(props.carConnectorForm.formState?.errors?.tronityConnection?.clientSecret?.message || ' ')}`}
				/>
			)} />
		</Grid>
	</Grid>;
};

export default TronityConnection;