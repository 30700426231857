import { Grid, TextField } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import EvesSelect from '@/common/components/atoms/select/select';
import { useTranslation } from 'react-i18next';

interface ILimitsPropsModel {
  siteAreaForm: UseFormReturn<any, any>;
  isDisabled: boolean;
}

export default function EvConnector(props: ILimitsPropsModel) {
  const { control } = props.siteAreaForm;
  const { t: translate } = useTranslation();

  const ConnectionName = [
    { label: 'Motive', value: 'Motive' },
    { label: 'Samsara', value: 'Samsara' },
    { label: 'Smart Car connector', value: 'Smart Car connector' },
  ];

  return (
    <>
      <Grid container direction='row' rowSpacing={1} columnSpacing={2}>
        <Grid item xs={4}>
          <Controller
            name='connectionName'
            control={control}
            render={({ field }) => (
              <EvesSelect
                data-cy='voltage-option'
                label={`${translate('cars.car_connector_name')}`}
                options={ConnectionName}
                {...field}
                onChange={(event: any) => {
                  field.onChange(event);
                }}
                disabled={props.isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Controller
            name='connectorMeterId'
            control={props.siteAreaForm.control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                }}
                id='connectorMeterId'
                label={`${translate('cars.car_connector_meter_id')}`}
                type='number'
                fullWidth
                variant='standard'
                disabled={props.isDisabled}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
