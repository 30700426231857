import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import styles from './account.module.scss';
import { Grid, CardContent, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { onboardStatus, refreshBillingAccount } from '@/services/integrationSettings';
const AccountOnboarding = () => {
  const [onboardingHasBeenDone, setOnboardingHasBeenDone] = useState(false);
  const [accountActivationFailed, setAccountActivationFailed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const tenantID = query.get('TenantID');
  const accountID = query.get('AccountID');
  const operationResult = query.get('OperationResult'); // corrected casing
  const { t } = useTranslation();

  useEffect(() => {
    if (operationResult === 'Success') {
      setOnboardingHasBeenDone(true);
      onboardStatus(tenantID,accountID);
    } else if (operationResult === 'failure') {
      setAccountActivationFailed(true);
    }
  }, [operationResult]);

  const navigateToOnboardingPage = async () => {
    try {
      const payload = { TenantID: tenantID };
      const response = await refreshBillingAccount(accountID, payload);
      const billingAccount: any = response; // Access the data property
      if (billingAccount?.activationLink) {
        window.location.href = billingAccount.activationLink;
      } else {
        setAccountActivationFailed(true);
      }
    } catch (error) {
      setAccountActivationFailed(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={12} sm={8} md={6}>
        <form>
          <div className={styles.signin}>
            <div className={styles.signinCard}>
              <Typography p={2} fontSize="14px" fontWeight={600} textAlign="center">
                {t('accounts.onboarding.onboarding_title')}
              </Typography>
              <CardContent>
                {!onboardingHasBeenDone ? (
                  <Typography fontSize="12px">
                    {t('accounts.onboarding.onboarding_message')}
                    <br />
                    <br />
                    {t('accounts.onboarding.onboarding_message_to_proceed')}
                  </Typography>
                ) : accountActivationFailed ? (
                  <Typography fontSize="12px">
                    {t('accounts.onboarding.onboarding_process_failed')}
                  </Typography>
                ) : (
                  <Typography fontSize="12px">
                    {t('accounts.onboarding.onboarding_congratulations')}
                    <br />
                    <br />
                    {t('accounts.onboarding.onboarding_process_completed')}
                  </Typography>
                )}
              </CardContent>
              <Grid container justifyContent="center" style={{ paddingBottom: '16px',cursor:'pointer' }}>
                {!onboardingHasBeenDone ? (
                  <Typography fontSize="12px" fontWeight={600} onClick={navigateToOnboardingPage}>
                    {t('accounts.onboarding.onboarding_button_proceed')}
                  </Typography>
                ) : (
                  <Typography fontSize="12px" fontWeight={600} onClick={()=> navigate('/dashboard')}>
                    {t('accounts.onboarding.onboarding_navigate_to_dashboard')}
                  </Typography>
                )}
              </Grid>
            </div>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default AccountOnboarding;
