import { requiredFields } from '@/modules/organization/shared/schemas/siteAreaFormSchema';
import Grid from '@mui/material/Grid';
import AddressComponent from '@/common/components/addressComponent';
import { withContext } from '@/common/utils/withContext';
import { useTranslation } from 'react-i18next';

const Address = (props: any) => {
	const { t: translate } = useTranslation();
	const userForm = props.userForm;

	return <>
		<Grid container rowSpacing={1} columnSpacing={2}>
			<AddressComponent label={`${translate('companies.company')}`} name={userForm.getValues().name} isDisabled={false} requiredFields={requiredFields} hostForm={userForm as any} hideGeoLocation={true}/>
		</Grid>
	</>;
};

export default withContext(Address);