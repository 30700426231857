import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@mui/material';
import React, { Context } from 'react';
import { createContext, useContext } from 'react';
import { EvesButton } from '@/common/components/atoms/button/button';
import styles from './dialog.module.scss';
import i18next from 'i18next';

export const ConfirmDialogContext = createContext({} as any);
export type ConfirmType = 'OK' | 'YES_NO' | 'INVALID_CHANGE_CLOSE' | 'DIRTY_CHANGE_CLOSE' | 'SUB_SITEAREA_ACTION' | 'CLOSE'| 'Upload File' | 'DIRTY_CHANGE_CLOSE_NEWCHARGING';
export type ConfirmResultType = 'OK' | 'CANCEL' | 'CLOSE' | 'YES' | 'NO' | 'SAVE' | 'CLEAR' | 'ATTACH' |'UPDATE' | 'CLOSE';

interface IConfirmDialogState {
    title?: string;
    description?: any;
    isOpen?: boolean;
    confirmType: ConfirmType;
	body?: any;
}

type ActionCallBackType = (result: ConfirmResultType) => void;

export type ShowConfirmType = (data: IConfirmDialogState, callback?: ActionCallBackType) => void;

export class ConfirmDialogProvider extends React.Component<any, IConfirmDialogState> {

	onAction?: (result: any) => void = undefined;

	constructor(props: any) {
		super(props);
		this.state = {
			title: '',
			description: '',
			isOpen: false,
			confirmType: 'OK',
			body: ''
		};

		this.showConfirm = this.showConfirm.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.getDefaultTitle = this.getDefaultTitle.bind(this);
		this.getDefaultDescription = this.getDefaultDescription.bind(this);
	}

	handleClose = (result: ConfirmResultType) => {
		if (this.onAction) {
			this.onAction(result);
		}
		this.setState({ isOpen: false });
	};

	getDefaultTitle(confirmType: ConfirmType) {
		let title = '';
		if (confirmType === 'DIRTY_CHANGE_CLOSE') {
			title = i18next.t('general.change_pending_title');
		} else if (confirmType === 'INVALID_CHANGE_CLOSE') {
			title = i18next.t('general.change_invalid_pending_title');
		}
		else if (confirmType === 'DIRTY_CHANGE_CLOSE_NEWCHARGING') {
			title = i18next.t('general.change_invalid_pending_title');
		} else {
			title = 'Confirm ?';
		}
		return title;
	}

	getDefaultDescription(confirmType: ConfirmType) {
		let description = '';
		if (confirmType === 'DIRTY_CHANGE_CLOSE') {
			description = i18next.t('general.change_pending_text');
		} else if (confirmType === 'INVALID_CHANGE_CLOSE') {
			description = i18next.t('general.change_invalid_pending_text');
		}
		else if (confirmType === 'DIRTY_CHANGE_CLOSE_NEWCHARGING') {
			description = i18next.t('general.change_pending_text');
		} else {
			description = 'Confirm ?';
		}
		return description;
	}

	showConfirm(data: IConfirmDialogState, callback: ActionCallBackType) {
		this.setState({
			title: data.title ?? this.getDefaultTitle(data.confirmType, ),
			description: data.description ?? this.getDefaultDescription(data.confirmType),
			confirmType: data.confirmType,
			isOpen: true,
			body: data.body
		});

		this.onAction = callback;
	}

	buttons = () => {
		if (this.state.confirmType === 'OK') {
			return (
				<EvesButton variant="contained" onClick={() => this.handleClose('OK')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.ok')}</EvesButton>
			);
		} 
		else if (this.state.confirmType === 'CLOSE') {
			return (
				<EvesButton variant="contained" onClick={() => this.handleClose('CLOSE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.close')}</EvesButton>
			);
		}
		else if (this.state.confirmType === 'YES_NO') {
			return (
				<>
					<EvesButton
						variant='contained'
						color='error'
						onClick={() => this.handleClose('YES')}
						sx={{ margin: '5px', textTransform: 'capitalize' }}
						style={{width: '100px',background:'#d32f2f'}}
						data-cy='btn-yes'
					>
						{i18next.t('general.yes')}
					</EvesButton>
					<EvesButton
						variant='contained'
						onClick={() => this.handleClose('NO')}
						sx={{ margin: '5px', textTransform: 'capitalize' }}
						data-cy='btn-no'
						style={{ width: '100px', background: '#fff', color: '#000' }}
					>
						{i18next.t('general.no')}
					</EvesButton>
				</>
			);
		} else if (this.state.confirmType === 'DIRTY_CHANGE_CLOSE') {
			return (
				<>
					<EvesButton variant="contained" onClick={() => this.handleClose('SAVE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.save_and_close')}</EvesButton>
					<EvesButton variant="contained" color="error" onClick={() => this.handleClose('CLOSE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.do_not_save_and_close')}</EvesButton>
					<EvesButton variant="outlined" onClick={() => this.handleClose('CANCEL')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.cancel')}</EvesButton>
				</>
			);
		}
		else if (this.state.confirmType === 'DIRTY_CHANGE_CLOSE_NEWCHARGING') {
			return (
				<>
					<EvesButton variant="contained" color="error" onClick={() => this.handleClose('CLOSE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('Close without uploading')}</EvesButton>
					<EvesButton variant="outlined" onClick={() => this.handleClose('CANCEL')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.cancel')}</EvesButton>
				</>
			);
		} else if (this.state.confirmType === 'INVALID_CHANGE_CLOSE') {
			return (
				<>
					<EvesButton variant="contained" color="error" onClick={() => this.handleClose('CLOSE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.do_not_save_and_close')}</EvesButton>
					<EvesButton variant="outlined" onClick={() => this.handleClose('CANCEL')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.cancel')}</EvesButton>
				</>
			);
		}
		else if (this.state.confirmType === 'SUB_SITEAREA_ACTION') {
			return (
				<>
					<EvesButton variant="contained" color='primary' onClick={() => this.handleClose('UPDATE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('site_areas.site_area_tree_error_site_update')}</EvesButton>
					<EvesButton variant="contained" color='primary' onClick={() => this.handleClose('ATTACH')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('site_areas.site_area_tree_error_site_attach')}</EvesButton>
					<EvesButton variant="contained" color="error" onClick={() => this.handleClose('CLEAR')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('site_areas.site_area_tree_error_site_clear')}</EvesButton>
					<EvesButton variant="outlined" onClick={() => this.handleClose('CANCEL')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('general.cancel')}</EvesButton>
				</>
			);
		}
		else if (this.state.confirmType === 'Upload File') {
			return (
				<Grid container display={'flex'} justifyContent={'center'}>
					<EvesButton variant="contained" color="error" onClick={() => this.handleClose('SAVE')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('Yes')}</EvesButton>
					<EvesButton variant="outlined" onClick={() => this.handleClose('CANCEL')} sx={{ margin: '5px', textTransform: 'capitalize' }}>{i18next.t('No')}</EvesButton>
				</Grid>
			);
		}
	};

	render() {
		return (
			<ConfirmDialogContext.Provider value={{ showConfirm: this.showConfirm } as { showConfirm: ShowConfirmType }}>
				{this.props.children}
				<Dialog className={styles.dialog} open={this.state.isOpen === true} data-cy="dialog" maxWidth='md' PaperProps={{
					style: { borderRadius: '0px' },
				}}>
					<DialogTitle data-cy="dialog-title">
						<div className={styles.dialogTitle}>{this.state.title}</div>
					</DialogTitle>
					<DialogContent>
						{!this.state.body &&
						<DialogContentText data-cy="dialog-description" className={styles.dialogBody}>
							{this.state.description}
						</DialogContentText>}
						{this.state.body &&
						<div data-cy="dialog-body" className={styles.dialogBody}>
							{this.state.body()}
						</div>}
					</DialogContent>
					<DialogActions className={styles.dialogActions} data-cy="dialog-actions">
						{this.buttons()}
					</DialogActions>
				</Dialog>
			</ConfirmDialogContext.Provider>
		);
	}
}

export default function useConfirm() {
	return useContext(ConfirmDialogContext as Context<{ showConfirm: ShowConfirmType }>);
}