export enum Entity {
	SITE = 'Site',
	SITE_AREA = 'SiteArea',
	COMPANY = 'Company',
	CHARGING_STATION = 'ChargingStation',
	CONNECTOR = 'Connector',
	TENANT = 'Tenant',
	TRANSACTION = 'Transaction',
	REPORT = 'Report',
	USER = 'User',
	USERS_SITES = 'UserSite',
	LOGGING = 'Logging',
	PRICING = 'Pricing',
	PRICING_DEFINITION = 'PricingDefinition',
	BILLING = 'Billing',
	BILLING_PLATFORM = 'BillingPlatform',
	BILLING_ACCOUNT = 'BillingAccount',
	BILLING_TRANSFER = 'BillingTransfer',
	SETTING = 'Setting',
	ASYNC_TASK = 'AsyncTask',
	OCPI_ENDPOINT = 'OcpiEndpoint',
	OICP_ENDPOINT = 'OicpEndpoint',
	CONNECTION = 'Connection',
	ASSET = 'Asset',
	CAR_CATALOG = 'CarCatalog',
	ELECTRIC_VEHICLE='ElectricVehicle',
	CAR = 'Car',
	INVOICE = 'Invoice',
	INVOICE_LIST = 'InvoiceList',
	TRANSFERS = 'Transfers',
	TRANSFER_LIST = 'TransferList',
	TAX = 'Tax',
	REGISTRATION_TOKEN = 'RegistrationToken',
	CHARGING_PROFILE = 'ChargingProfile',
	NOTIFICATION = 'Notification',
	TAG = 'Tag',
	PAYMENT_METHOD = 'PaymentMethod',
	SOURCE = 'Source',
	TRANSACTION_LIST = 'TransactionList',
	DASHBOARD = 'Dashboard',
	TELEMATICS='Telematics',
	GRIDIFY='Gridify'
}

export enum UserRole {
	SUPER_ADMIN = 'S',
	ADMIN = 'A',
	BASIC = 'B',
	DEMO = 'D',
	UNKNOWN = 'U',
	ALL_ROLES = '',
	ALL_DEFAULT_ROLES = ' '
}

export enum IntegrationConnectionType {
	MERCEDES = 'mercedes',
	CONCUR = 'concur'
}

export enum UserStatus {
	PENDING = 'P',
	ACTIVE = 'A',
	DELETED = 'D',
	INACTIVE = 'I',
	BLOCKED = 'B',
	LOCKED = 'L',
	UNKNOWN = 'U',
}

export enum Action {
	READ = 'Read',
	CREATE = 'Create',
	UPDATE = 'Update',
	UPDATE_BY_VISUAL_ID = 'UpdateByVisualID',
	REPLACE = 'Replace',
	REVOKE = 'Revoke',
	DELETE = 'Delete',
	LOGOUT = 'Logout',
	LOGIN = 'Login',
	LIST = 'List',
	IN_ERROR = 'InError',
	RESET = 'Reset',
	ASSIGN = 'AssignSitesToUser',
	UNASSIGN = 'UnassignSitesFromUser',
	CLEAR_CACHE = 'ClearCache',
	TRIGGER_DATA_TRANSFER = 'DataTransfer',
	SYNCHRONIZE = 'Synchronize',
	GET_CONFIGURATION = 'GetConfiguration',
	CHANGE_CONFIGURATION = 'ChangeConfiguration',
	SYNCHRONIZE_CAR_CATALOGS = 'SynchronizeCarCatalogs',
	REMOTE_START_TRANSACTION = 'RemoteStartTransaction',
	REMOTE_STOP_TRANSACTION = 'RemoteStopTransaction',
	START_TRANSACTION = 'StartTransaction',
	STOP_TRANSACTION = 'StopTransaction',
	UNLOCK_CONNECTOR = 'UnlockConnector',
	AUTHORIZE = 'Authorize',
	SET_CHARGING_PROFILE = 'SetChargingProfile',
	GET_COMPOSITE_SCHEDULE = 'GetCompositeSchedule',
	CLEAR_CHARGING_PROFILE = 'ClearChargingProfile',
	GET_DIAGNOSTICS = 'GetDiagnostics',
	UPDATE_FIRMWARE = 'UpdateFirmware',
	EXPORT = 'Export',
	CHANGE_AVAILABILITY = 'ChangeAvailability',
	REFUND_TRANSACTION = 'RefundTransaction',
	SYNCHRONIZE_BILLING_USER = 'SynchronizeBillingUser',
	BILLING_SETUP_PAYMENT_METHOD = 'BillingSetupPaymentMethod',
	BILLING_PAYMENT_METHODS = 'BillingPaymentMethods',
	BILLING_DELETE_PAYMENT_METHOD = 'BillingDeletePaymentMethod',
	BILLING_CHARGE_INVOICE = 'BillingChargeInvoice',
	BILLING_FINALIZE_TRANSFER = 'BillingFinalizeTransfer',
	BILLING_SEND_TRANSFER = 'BillingSendTransfer',
	CHECK_CONNECTION = 'CheckConnection',
	CLEAR_BILLING_TEST_DATA = 'ClearBillingTestData',
	RETRIEVE_CONSUMPTION = 'RetrieveConsumption',
	READ_CONSUMPTION = 'ReadConsumption',
	CREATE_CONSUMPTION = 'CreateConsumption',
	PING = 'Ping',
	GENERATE_LOCAL_TOKEN = 'GenerateLocalToken',
	REGISTER = 'Register',
	TRIGGER_JOB = 'TriggerJob',
	DOWNLOAD = 'Download',
	IMPORT = 'Import',
	ASSIGN_USERS_TO_SITE = 'AssignUsers',
	UNASSIGN_USERS_FROM_SITE = 'UnassignUsers',
	ASSIGN_ASSETS_TO_SITE_AREA = 'AssignAssets',
	UNASSIGN_ASSETS_FROM_SITE_AREA = 'UnassignAssets',
	READ_ASSETS_FROM_SITE_AREA = 'ReadAssets',
	ASSIGN_CHARGING_STATIONS_TO_SITE_AREA = 'AssignChargingStations',
	UNASSIGN_CHARGING_STATIONS_FROM_SITE_AREA = 'UnassignChargingStations',
	READ_CHARGING_STATIONS_FROM_SITE_AREA = 'ReadChargingStationsFromSiteArea',
	EXPORT_OCPP_PARAMS = 'ExportOCPPParams',
	GENERATE_QR = 'GenerateQrCode',
	MAINTAIN_PRICING_DEFINITIONS = 'MaintainPricingDefinitions',
	GETPOWER_MANAGEMENT = 'GetPowerManagement',
	GETCHARGER_CONFIGURATION = 'GetChargerConfiguration'
}

export enum LookupEntityType {
	COMPANY = 'company',
	OPERATIONMODE='operationmode',
	SITE = 'site',
	SITEAREA = 'sitearea',
	Account = 'account',
	SITEAREACHARGINGSTATION = 'siteAreaChargingStation',
    SUBSTATIONID = 'subStation',
    FEEDERID='feederid',
	TRANSFORMERID='transformerId',
	SITEAREAASSET = 'siteAreaAsset',
	SITEUSER = 'siteUser',
	CHARGINGSTATION = 'chargingStation',
	SESSION_USER = 'getUsers',
	SESSION_RFID_TAG = 'getTags',
	SESSION_CAR = 'getCars',
	USERSITE = 'userSites',
	SELECT_USERSITE = 'addUserSites',
	SESSION_REPORTID = 'getSessionReportId',
	LOGS_ACTION = 'logsAction',
	EV_MAKER='evMaker',
	EV_CATALOG='evCatalog'
}

export enum ServerAction {
	UNKNOWN_ACTION = 'Unknown',

	SAP_CONCUR_REQUEST = 'SapConcurRequest',
	RECAPTCHA_REQUEST = 'RecaptchaRequest',
	GREENCOM_REQUEST = 'GreencomRequest',
	STRIPE_REQUEST = 'StripeRequest',
	IOTHINK_REQUEST = 'IOThinkRequest',
	LACROIX_REQUEST = 'LacroixRequest',
	EV_DATABASE_REQUEST = 'EVDatabaseRequest',
	WIT_REQUEST = 'WitRequest',
	SAP_SMART_CHARGING_REQUEST = 'SapSmartChargingRequest',

	DEPRECATED_REST_ENDPOINT = 'DeprecatedRestEndpoint',
	LOGIN = 'Login',
	LOGOUT = 'Logout',
	PASSWORD_RESET = 'Reset',
	PING = 'Ping',
	CHECK_CONNECTION = 'CheckConnection',

	OBJECT_CLONE = 'ObjectClone',

	CHARGING_STATION_RESET = 'OcppReset',
	CHARGING_STATION_CLEAR_CACHE = 'OcppClearCache',
	CHARGING_STATION_TRIGGER_DATA_TRANSFER = 'OcppDataTransfer',
	CHARGING_STATION_GET_CONFIGURATION = 'OcppGetConfiguration',
	CHARGING_STATION_CHANGE_CONFIGURATION = 'OcppChangeConfiguration',
	CHARGING_STATION_DATA_TRANSFER = 'OcppChangeDataTransfer',
	CHARGING_STATION_REMOTE_START_TRANSACTION = 'OcppRemoteStartTransaction',
	CHARGING_STATION_REMOTE_STOP_TRANSACTION = 'OcppRemoteStopTransaction',
	CHARGING_STATION_UNLOCK_CONNECTOR = 'OcppUnlockConnector',
	CHARGING_STATION_SET_CHARGING_PROFILE = 'OcppSetChargingProfile',
	CHARGING_STATION_GET_COMPOSITE_SCHEDULE = 'OcppGetCompositeSchedule',
	CHARGING_STATION_CLEAR_CHARGING_PROFILE = 'OcppClearChargingProfile',
	CHARGING_STATION_GET_DIAGNOSTICS = 'OcppGetDiagnostics',
	CHARGING_STATION_UPDATE_FIRMWARE = 'OcppUpdateFirmware',
	CHARGING_STATION_CHANGE_AVAILABILITY = 'OcppChangeAvailability',

	CHARGING_STATION_REQUEST_OCPP_PARAMETERS = 'ChargingStationRequestOcppParameters',
	CHARGING_STATION_CLIENT_INITIALIZATION = 'ChargingStationClientInitialization',
	CHARGING_STATION_DOWNLOAD_QR_CODE_PDF = 'ChargingStationDownloadQrCodePdf',
	CHARGING_STATIONS_EXPORT = 'ChargingStationsExport',
	CHARGING_STATIONS_OCPP_PARAMS_EXPORT = 'ChargingStationsOcppParamsExport',
	CHARGING_STATION = 'ChargingStation',
	CHARGING_STATIONS_OCPP_PARAMETERS = 'ChargingStationOcppParameters',
	CHARGING_STATIONS_IN_ERROR = 'ChargingStationsInError',
	CHARGING_STATION_UPDATE_PARAMS = 'ChargingStationUpdateParams',
	CHARGING_STATION_LIMIT_POWER = 'ChargingStationLimitPower',
	CHARGING_STATION_DELETE = 'ChargingStationDelete',
	CHARGING_STATION_RESERVE_NOW = 'ChargingStationReserveNow',
	CHARGING_STATION_CANCEL_RESERVATION = 'ChargingStationCancelReservation',

	CHECK_SMART_CHARGING_CONNECTION = 'CheckSmartChargingConnection',
	TRIGGER_SMART_CHARGING = 'TriggerSmartCharging',

	REGISTRATION_TOKEN = 'RegistrationToken',
	REGISTRATION_TOKENS = 'RegistrationTokens',
	REGISTRATION_TOKEN_DELETE = 'RegistrationTokenDelete',
	REGISTRATION_TOKEN_REVOKE = 'RegistrationTokenRevoke',
	REGISTRATION_TOKEN_UPDATE = 'RegistrationTokenUpdate',

	BOOT_NOTIFICATIONS = 'BootNotifications',
	STATUS_NOTIFICATIONS = 'StatusNotifications',

	TRANSACTION_START = 'TransactionStart',
	TRANSACTION_STOP = 'TransactionStop',
	TRANSACTION_SOFT_STOP = 'TransactionSoftStop',
	TRANSACTION_DELETE = 'TransactionDelete',
	TRANSACTIONS_DELETE = 'TransactionsDelete',
	UPDATE_TRANSACTION = 'UpdateTransaction',

	LOGS = 'Logs',
	LOG = 'Log',
	LOGS_EXPORT = 'LogsExport',

	CHARGING_STATIONS = 'ChargingStations',

	CAR_CATALOGS = 'CarCatalogs',
	CAR_CATALOG = 'CarCatalog',
	CAR_CATALOG_IMAGE = 'CarCatalogImage',
	CAR_CATALOG_IMAGES = 'CarCatalogImages',
	CAR_MAKERS = 'CarMakers',
	CAR_CREATE = 'CarCreate',
	CAR_UPDATE = 'CarUpdate',
	CAR_DELETE = 'CarDelete',
	CARS = 'Cars',
	CAR = 'Car',
	SYNCHRONIZE_CAR_CATALOGS = 'SynchronizeCarCatalogs',

	GET_CONNECTOR_CURRENT_LIMIT = 'GetConnectorCurrentLimit',
	REGISTER_USER = 'RegisterUser',
	CHARGING_PROFILES = 'ChargingProfiles',
	CHARGING_PROFILE_DELETE = 'ChargingProfileDelete',
	CHARGING_PROFILE_UPDATE = 'ChargingProfileUpdate',
	CHARGING_PROFILE_CREATE = 'ChargingProfileCreate',
	GENERATE_QR_CODE_FOR_CONNECTOR = 'GenerateQrCodeForConnector',
	OCPP_PARAM_UPDATE = 'OcppParamUpdate',
	RESEND_VERIFICATION_MAIL = 'ResendVerificationEmail',
	END_USER_LICENSE_AGREEMENT = 'EndUserLicenseAgreement',
	CHECK_END_USER_LICENSE_AGREEMENT = 'CheckEndUserLicenseAgreement',
	VERIFY_EMAIL = 'VerifyEmail',
	FIRMWARE_DOWNLOAD = 'FirmwareDownload',

	OFFLINE_CHARGING_STATION = 'OfflineChargingStation',

	MISSING_CONFIGURATION = 'MissingConfiguration',

	LOGS_CLEANUP = 'LogsCleanup',
	PERFORMANCES_CLEANUP = 'PerformancesCleanup',
	PERFORMANCES = 'Performances',

	SCHEDULER = 'Scheduler',
	ASYNC_TASK = 'AsyncTask',

	REMOTE_PUSH_NOTIFICATION = 'RemotePushNotification',
	EMAIL_NOTIFICATION = 'EmailNotification',
	END_USER_REPORT_ERROR = 'EndUserReportError',

	SYNCHRONIZE_REFUND = 'RefundSynchronize',

	REGISTRATION_TOKEN_CREATE = 'RegistrationTokenCreate',

	INTEGRATION_CONNECTION_CREATE = 'IntegrationConnectionCreate',
	INTEGRATION_CONNECTIONS = 'IntegrationConnections',
	INTEGRATION_CONNECTION = 'IntegrationConnection',
	INTEGRATION_CONNECTION_DELETE = 'IntegrationConnectionDelete',

	ROAMING = 'Roaming',

	OCPI_SETTINGS = 'OcpiSettings',
	OCPI_CLIENT_INITIALIZATION = 'OcpiClientInitialization',
	OCPI_ENDPOINT_CREATE = 'OcpiEndpointCreate',
	OCPI_ENDPOINT_PING = 'OcpiEndpointPing',
	OCPI_ENDPOINT_CHECK_CDRS = 'OcpiEndpointCheckCdrs',
	OCPI_ENDPOINT_CHECK_LOCATIONS = 'OcpiEndpointCheckLocations',
	OCPI_ENDPOINT_CHECK_SESSIONS = 'OcpiEndpointCheckSessions',
	OCPI_ENDPOINT_PULL_CDRS = 'OcpiEndpointPullCdrs',
	OCPI_ENDPOINT_PULL_LOCATIONS = 'OcpiEndpointPullLocations',
	OCPI_ENDPOINT_PULL_SESSIONS = 'OcpiEndpointPullSessions',
	OCPI_ENDPOINT_PULL_TOKENS = 'OcpiEndpointPullTokens',
	OCPI_ENDPOINT_SEND_EVSE_STATUSES = 'OcpiEndpointSendEVSEStatuses',
	OCPI_ENDPOINT_SEND_TOKENS = 'OcpiEndpointSendTokens',
	OCPI_ENDPOINT_GENERATE_LOCAL_TOKEN = 'OcpiEndpointGenerateLocalToken',
	OCPI_ENDPOINT_UPDATE = 'OcpiEndpointUpdate',
	OCPI_ENDPOINT_REGISTER = 'OcpiEndpointRegister',
	OCPI_ENDPOINT_UNREGISTER = 'OcpiEndpointUnregister',
	OCPI_ENDPOINT_CREDENTIALS = 'OcpiEndpointCredentials',
	OCPI_ENDPOINT_DELETE = 'OcpiEndpointDelete',
	OCPI_ENDPOINTS = 'OcpiEndpoints',
	OCPI_ENDPOINT = 'OcpiEndpoint',
	OCPI_REGISTER = 'OcpiRegister',
	OCPI_UNREGISTER = 'OcpiUnregister',
	OCPI_GET_VERSIONS = 'OcpiGetVersions',
	OCPI_GET_ENDPOINT_VERSIONS = 'OcpiGetEndpointVersions',
	OCPI_CREATE_CREDENTIALS = 'OcpiCreateCredentials',
	OCPI_UPDATE_CREDENTIALS = 'OcpiUpdateCredentials',
	OCPI_DELETE_CREDENTIALS = 'OcpiDeleteCredentials',

	OCPI_CPO_REQUEST = 'OcpiCpoRequest',
	OCPI_CPO_GET_SERVICES = 'OcpiCpoGetServices',
	OCPI_CPO_COMMAND = 'OcpiCpoCommand',
	OCPI_CPO_UPDATE_STATUS = 'OcpiCpoUpdateStatus',
	OCPI_CPO_CHECK_CDRS = 'OcpiCpoCheckCdrs',
	OCPI_CPO_CHECK_SESSIONS = 'OcpiCpoCheckSessions',
	OCPI_CPO_GET_SESSIONS = 'OcpiCpoGetSessions',
	OCPI_CPO_PUSH_SESSIONS = 'OcpiCpoPushSessions',
	OCPI_CPO_START_SESSION = 'OcpiCpoStartSession',
	OCPI_CPO_STOP_SESSION = 'OcpiCpoStopSession',
	OCPI_CPO_CHECK_LOCATIONS = 'OcpiCpoCheckLocations',
	OCPI_CPO_GET_LOCATIONS = 'OcpiCpoGetLocations',
	OCPI_CPO_GET_CDRS = 'OcpiCpoGetCdrs',
	OCPI_CPO_PUSH_EVSE_STATUSES = 'OcpiCpoPushEVSEStatuses',
	OCPI_CPO_PUSH_CDRS = 'OcpiCpoPushCdrs',
	OCPI_CPO_GET_TOKENS = 'OcpiCpoGetTokens',
	OCPI_CPO_GET_TOKEN = 'OcpiCpoGetToken',
	OCPI_CPO_UPDATE_TOKEN = 'OcpiCpoUpdateToken',
	OCPI_CPO_AUTHORIZE_TOKEN = 'OcpiCpoAuthorizeToken',
	OCPI_CPO_GET_TARIFFS = 'OcpiCpoGetTariffs',

	OCPI_EMSP_REQUEST = 'OcpiEmspRequest',
	OCPI_EMSP_GET_SERVICES = 'OcpiEmspGetServices',
	OCPI_EMSP_COMMAND = 'OcpiEmspCommand',
	OCPI_EMSP_AUTHORIZE_TOKEN = 'OcpiEmspAuthorizeToken',
	OCPI_EMSP_UPDATE_LOCATION = 'OcpiEmspUpdateLocation',
	OCPI_EMSP_GET_LOCATIONS = 'OcpiEmspGetLocations',
	OCPI_EMSP_GET_SESSIONS = 'OcpiEmspGetSessions',
	OCPI_EMSP_GET_SESSION = 'OcpiEmspGetSession',
	OCPI_EMSP_UPDATE_SESSION = 'OcpiEmspUpdateSession',
	OCPI_EMSP_START_SESSION = 'OcpiEmspStartSession',
	OCPI_EMSP_STOP_SESSION = 'OcpiEmspStopSession',
	OCPI_EMSP_UPDATE_TOKENS = 'OcpiEmspUpdateTokens',
	OCPI_EMSP_GET_CDR = 'OcpiEmspGetCdr',
	OCPI_EMSP_GET_CDRS = 'OcpiEmspGetCdrs',
	OCPI_EMSP_GET_TOKENS = 'OcpiEmspGetTokens',
	OCPI_EMSP_CREATE_CDR = 'OcpiEmspCreateCdr',
	OCPI_EMSP_RESERVE_NOW = 'OcpiEmspReserveNow',
	OCPI_EMSP_UNLOCK_CONNECTOR = 'OcpiEmspUnlockConnector',
	OCPI_EMSP_GET_TARIFF = 'OcpiEmspGetTariff',

	OICP_CPO_REQUEST = 'OicpCpoRequest',
	OICP_SETTINGS = 'OicpSettings',
	OICP_ENDPOINT_CREATE = 'OicpEndpointCreate',
	OICP_ENDPOINT_PING = 'OicpEndpointPing',
	OICP_ENDPOINT = 'OicpEndpoint',
	OICP_ENDPOINTS = 'OicpEndpoints',
	OICP_ENDPOINT_START = 'OicpEndpointStart',
	OICP_PUSH_EVSE_DATA = 'OicpPushEvseData',
	OICP_PUSH_EVSE_STATUSES = 'OicpPushEvseStatuses',
	OICP_UPDATE_EVSE_STATUS = 'OicpUpdateEvseStatus',
	OICP_AUTHORIZE_START = 'OicpAuthorizeStart',
	OICP_AUTHORIZE_STOP = 'OicpAuthorizeStop',
	OICP_AUTHORIZE_REMOTE_START = 'OicpAuthorizeRemoteStart',
	OICP_AUTHORIZE_REMOTE_STOP = 'OicpAuthorizeRemoteStop',
	OICP_PUSH_CDRS = 'OicpPushCdrs',
	OICP_PUSH_EVSE_PRICING = 'OicpPushEvsePricing',
	OICP_PUSH_PRICING_PRODUCT_DATA = 'OicpPushPricingProductData',
	OICP_SEND_CHARGING_NOTIFICATION_START = 'OicpSendChargingNotificationStart',
	OICP_SEND_CHARGING_NOTIFICATION_PROGRESS = 'OicpSendChargingNotificationProgress',
	OICP_SEND_CHARGING_NOTIFICATION_END = 'OicpSendChargingNotificationEnd',
	OICP_SEND_CHARGING_NOTIFICATION_ERROR = 'OicpSendChargingNotificationError',
	OICP_ENDPOINT_SEND_EVSE_STATUSES = 'OicpEndpointSendEVSEStatuses',
	OICP_ENDPOINT_SEND_EVSES = 'OicpEndpointSendEVSEs',
	OICP_PUSH_SESSIONS = 'OicpPushSessions',
	OICP_CREATE_AXIOS_INSTANCE = 'OicpCreateAxiosInstance',
	OICP_ENDPOINT_UPDATE = 'OicpEndpointUpdate',
	OICP_ENDPOINT_REGISTER = 'OicpEndpointRegister',
	OICP_ENDPOINT_UNREGISTER = 'OicpEndpointUnregister',
	OICP_ENDPOINT_DELETE = 'OicpEndpointDelete',

	OCPP_SERVICE = 'OcppService',

	AUTHORIZATIONS = 'Authorizations',

	DB_WATCH = 'DBWatch',
	DB_MONITOR = 'DBMonitor',
	MONITORING = 'Monitoring',

	EXPRESS_SERVER = 'ExpressServer',
	ODATA_SERVER = 'ODataServer',

	LOCKING = 'Locking',

	STARTUP = 'Startup',

	BOOTSTRAP_STARTUP = 'BootstrapStartup',

	OCPP_BOOT_NOTIFICATION = 'OcppBootNotification',
	OCPP_AUTHORIZE = 'OcppAuthorize',
	OCPP_HEARTBEAT = 'OcppHeartbeat',
	OCPP_DIAGNOSTICS_STATUS_NOTIFICATION = 'OcppDiagnosticsStatusNotification',
	OCPP_FIRMWARE_STATUS_NOTIFICATION = 'OcppFirmwareStatusNotification',
	OCPP_STATUS_NOTIFICATION = 'OcppStatusNotification',
	OCPP_START_TRANSACTION = 'OcppStartTransaction',
	OCPP_STOP_TRANSACTION = 'OcppStopTransaction',
	OCPP_METER_VALUES = 'OcppMeterValues',
	OCPP_DATA_TRANSFER = 'OcppDataTransfer',

	EXTRA_INACTIVITY = 'ExtraInactivity',

	CONSUMPTION = 'Consumption',
	REBUILD_TRANSACTION_CONSUMPTIONS = 'RebuildTransactionConsumptions',

	WS_SERVER_MESSAGE = 'WsServerMessage',
	WS_SERVER_CONNECTION = 'WsServerConnection',
	WS_SERVER_CONNECTION_PING = 'WsServerConnectionPing',
	WS_SERVER_CONNECTION_CLOSE = 'WsServerConnectionClose',
	WS_SERVER_CONNECTION_OPEN = 'WsServerConnectionOpen',
	WS_SERVER_CONNECTION_ERROR = 'WsServerConnectionError',

	WS_CLIENT_ERROR = 'WsClientError',
	WS_CLIENT_MESSAGE = 'WsClientMessage',
	WS_CLIENT_CONNECTION = 'WsClientConnection',
	WS_CLIENT_CONNECTION_CLOSE = 'WsClientConnectionClose',
	WS_CLIENT_CONNECTION_OPEN = 'WsClientConnectionOpen',
	WS_CLIENT_CONNECTION_ERROR = 'WsClientConnectionError',

	NOTIFICATION = 'Notification',
	CHARGING_STATION_STATUS_ERROR = 'ChargingStationStatusError',
	CHARGING_STATION_REGISTERED = 'ChargingStationRegistered',
	END_OF_CHARGE = 'EndOfCharge',
	OPTIMAL_CHARGE_REACHED = 'OptimalChargeReached',
	END_OF_SESSION = 'EndOfSession',
	REQUEST_PASSWORD = 'RequestPassword',
	USER_ACCOUNT_STATUS_CHANGED = 'UserAccountStatusChanged',
	NEW_REGISTERED_USER = 'NewRegisteredUser',
	UNKNOWN_USER_BADGED = 'UnknownUserBadged',
	TRANSACTION_STARTED = 'TransactionStarted',
	VERIFICATION_EMAIL_USER_IMPORT = 'VerificationEmailUserImport',
	PATCH_EVSE_STATUS_ERROR = 'PatchEVSEStatusError',
	PATCH_EVSE_ERROR = 'PatchEVSEError',
	USER_ACCOUNT_INACTIVITY = 'UserAccountInactivity',
	PREPARING_SESSION_NOT_STARTED = 'PreparingSessionNotStarted',
	OFFLINE_CHARGING_STATIONS = 'OfflineChargingStations',
	BILLING_USER_SYNCHRONIZATION_FAILED = 'BillingUserSynchronizationFailed',
	BILLING_INVOICE_SYNCHRONIZATION_FAILED = 'BillingInvoiceSynchronizationFailed',
	USER_ACCOUNT_VERIFICATION = 'UserAccountVerification',
	USER_CREATE_PASSWORD = 'UserCreatePassword',
	ADMIN_ACCOUNT_VERIFICATION = 'AdminAccountVerificationNotification',

	UPDATE_LOCAL_CAR_CATALOGS = 'UpdateLocalCarCatalogs',
	CAR_CATALOG_SYNCHRONIZATION_FAILED = 'CarCatalogSynchronizationFailed',
	CAR_CATALOG_SYNCHRONIZATION = 'CarCatalogSynchronization',
	SESSION_NOT_STARTED_AFTER_AUTHORIZE = 'SessionNotStartedAfterAuthorize',

	UPDATE_CHARGING_STATION_WITH_TEMPLATE = 'UpdateChargingStationWithTemplate',
	UPDATE_CHARGING_STATION_TEMPLATES = 'UpdateChargingStationTemplates',

	MIGRATION = 'Migration',

	SESSION_HASH_SERVICE = 'SessionHashService',

	CLEANUP_TRANSACTION = 'CleanupTransaction',
	TRANSACTIONS_COMPLETED = 'TransactionsCompleted',
	TRANSACTIONS_TO_REFUND = 'TransactionsToRefund',
	TRANSACTIONS_TO_REFUND_EXPORT = 'TransactionsToRefundExport',
	TRANSACTIONS_TO_REFUND_REPORTS = 'TransactionsRefundReports',
	TRANSACTIONS_EXPORT = 'TransactionsExport',
	TRANSACTIONS_ACTIVE = 'TransactionsActive',
	TRANSACTIONS_IN_ERROR = 'TransactionsInError',
	TRANSACTION_YEARS = 'TransactionYears',
	TRANSACTION = 'Transaction',
	TRANSACTIONS = 'Transactions',
	TRANSACTION_CONSUMPTION = 'TransactionConsumption',

	TRANSACTION_OCPI_CDR_EXPORT = 'TransactionOcpiCdrExport',

	CHARGING_STATION_CONSUMPTION_STATISTICS = 'ChargingStationConsumptionStatistics',
	CHARGING_STATION_USAGE_STATISTICS = 'ChargingStationUsageStatistics',
	CHARGING_STATION_INACTIVITY_STATISTICS = 'ChargingStationInactivityStatistics',
	CHARGING_STATION_TRANSACTIONS_STATISTICS = 'ChargingStationTransactionsStatistics',
	CHARGING_STATION_PRICING_STATISTICS = 'ChargingStationPricingStatistics',
	STATISTICS_EXPORT = 'StatisticsExport',
	USER_CONSUMPTION_STATISTICS = 'UserConsumptionStatistics',
	USER_USAGE_STATISTICS = 'UserUsageStatistics',
	USER_INACTIVITY_STATISTICS = 'UserInactivityStatistics',
	USER_TRANSACTIONS_STATISTICS = 'UserTransactionsStatistics',
	USER_PRICING_STATISTICS = 'UserPricingStatistics',

	CHARGING_STATION_TRANSACTIONS = 'ChargingStationTransactions',

	ADD_CHARGING_STATIONS_TO_SITE_AREA = 'AddChargingStationsToSiteArea',
	REMOVE_CHARGING_STATIONS_FROM_SITE_AREA = 'RemoveChargingStationsFromSiteArea',

	ADD_ASSET_TO_SITE_AREA = 'AddAssetsToSiteArea',
	REMOVE_ASSET_TO_SITE_AREA = 'RemoveAssetsFromSiteArea',
	ASSET_CREATE = 'AssetCreate',
	ASSETS = 'Assets',
	ASSET = 'Asset',
	ASSET_IMAGE = 'AssetImage',
	ASSETS_IN_ERROR = 'AssetsInError',
	ASSET_UPDATE = 'AssetUpdate',
	ASSET_DELETE = 'AssetDelete',
	CHECK_ASSET_CONNECTION = 'CheckAssetConnection',
	RETRIEVE_ASSET_CONSUMPTION = 'RetrieveAssetConsumption',
	ASSET_CONSUMPTION = 'AssetConsumption',

	TENANT_CREATE = 'TenantCreate',
	TENANTS = 'Tenants',
	TENANT = 'Tenant',
	TENANT_UPDATE = 'TenantUpdate',
	TENANT_DELETE = 'TenantDelete',
	TENANT_LOGO = 'TenantLogo',

	COMPANY_CREATE = 'CompanyCreate',
	COMPANIES = 'Companies',
	COMPANY = 'Company',
	COMPANY_LOGO = 'CompanyLogo',
	COMPANY_UPDATE = 'CompanyUpdate',
	COMPANY_DELETE = 'CompanyDelete',

	SITE_CREATE = 'SiteCreate',
	ADD_SITES_TO_USER = 'AddSitesToUser',
	REMOVE_SITES_FROM_USER = 'RemoveSitesFromUser',
	SITES = 'Sites',
	SITE = 'Site',
	SITE_IMAGE = 'SiteImage',
	SITE_USERS = 'SiteUsers',
	SITE_UPDATE = 'SiteUpdate',
	SITE_USER_ADMIN = 'SiteUserAdmin',
	SITE_OWNER = 'SiteOwner',
	SITE_DELETE = 'SiteDelete',

	SITE_AREA_CREATE = 'SiteAreaCreate',
	SITE_AREAS = 'SiteAreas',
	SITE_AREA = 'SiteArea',
	SITE_AREA_IMAGE = 'SiteAreaImage',
	SITE_AREA_CONSUMPTION = 'SiteAreaConsumption',
	SITE_AREA_UPDATE = 'SiteAreaUpdate',
	SITE_AREA_DELETE = 'SiteAreaDelete',

	TRANSACTIONS_REFUND = 'TransactionsRefund',
	TRANSACTION_PUSH_CDR = 'TransactionPushCdr',
	SYNCHRONIZE_REFUNDED_TRANSACTIONS = 'SynchronizeRefundedTransactions',

	SETTING_CREATE = 'SettingCreate',
	SETTING_BY_IDENTIFIER = 'SettingByIdentifier',
	SETTINGS = 'Settings',
	SETTING = 'Setting',
	SETTING_UPDATE = 'SettingUpdate',
	SETTING_DELETE = 'SettingDelete',

	ADD_USERS_TO_SITE = 'AddUsersToSite',
	REMOVE_USERS_FROM_SITE = 'RemoveUsersFromSite',

	REFUND = 'Refund',
	CAR_CONNECTOR = 'CarConnector',

	USER_READ = 'UserRead',
	USER_CREATE = 'UserCreate',
	USER_DELETE = 'UserDelete',
	USER_UPDATE = 'UserUpdate',
	USER_UPDATE_MOBILE_TOKEN = 'UpdateUserMobileToken',
	USERS = 'Users',
	USER_SITES = 'UserSites',
	USERS_IN_ERROR = 'UsersInError',
	USER_IMAGE = 'UserImage',
	TAGS = 'Tags',
	TAG = 'Tag',
	TAG_BY_VISUAL_ID = 'TagByVisualID',
	USER_DEFAULT_TAG_CAR = 'UserDefaultTagCar',
	TAG_CREATE = 'TagCreate',
	TAG_UPDATE = 'TagUpdate',
	TAG_UPDATE_BY_VISUAL_ID = 'TagUpdateByVisualID',
	TAG_DELETE = 'TagDelete',
	TAGS_UNASSIGN = 'TagsUnassign',
	TAG_UNASSIGN = 'TagUnassign',
	TAG_ASSIGN = 'TagAssign',
	TAGS_DELETE = 'TagsDelete',
	TAGS_IMPORT = 'TagsImport',
	TAGS_EXPORT = 'TagsExport',
	USER = 'User',
	USERS_EXPORT = 'UsersExport',
	USERS_IMPORT = 'UsersImport',

	NOTIFICATIONS = 'Notifications',

	BILLING = 'Billing',
	BILLING_TRANSACTION = 'BillingTransaction',
	BILLING_SYNCHRONIZE_USER = 'BillingSynchronizeUser',
	BILLING_FORCE_SYNCHRONIZE_USER = 'BillingForceSynchronizeUser',
	CHECK_BILLING_CONNECTION = 'CheckBillingConnection',
	BILLING_TAXES = 'BillingTaxes',
	BILLING_INVOICES = 'BillingInvoices',
	BILLING_INVOICE = 'BillingInvoice',
	BILLING_PERFORM_OPERATIONS = 'BillingPeriodicOperations',
	BILLING_DOWNLOAD_INVOICE = 'BillingDownloadInvoice',
	BILLING_NEW_INVOICE = 'BillingNewInvoice',
	BILLING_SETUP_PAYMENT_METHOD = 'BillingSetupPaymentMethod',
	BILLING_PAYMENT_METHODS = 'BillingPaymentMethods',
	BILLING_DELETE_PAYMENT_METHOD = 'BillingDeletePaymentMethod',
	BILLING_CHARGE_INVOICE = 'BillingChargeInvoice',
	BILLING_TEST_DATA_CLEANUP = 'BillingTestDataCleanup',
	BILLING_BILL_PENDING_TRANSACTION = 'BillingBillPendingTransaction',
	BILLING_ACCOUNT_CREATE = 'BillingAccountCreate',
	BILLING_ACCOUNT_REFRESH = 'BillingAccountRefresh',
	BILLING_ACCOUNT_ACTIVATE = 'BillingAccountActivate',
	BILLING_ACCOUNT_ONBOARD = 'BillingAccountOnboard',
	BILLING_ACCOUNT = 'BillingAccount',
	BILLING_ACCOUNTS = 'BillingAccounts',
	BILLING_TRANSFERS = 'BillingTransfers',
	BILLING_TRANSFER = 'BillingTransfer',
	BILLING_TRANSFER_PREPARE = 'BillingTransferPrepare',
	BILLING_TRANSFER_FINALIZE = 'BillingTransferFinalize',
	BILLING_TRANSFER_SEND = 'BillingTransferSend',

	PRICING = 'Pricing',
	PRICING_DEFINITION = 'PricingDefinition',
	PRICING_DEFINITIONS = 'PricingDefinitions',
	PRICING_DEFINITION_CREATE = 'PricingDefinitionCreate',
	PRICING_DEFINITION_UPDATE = 'PricingDefinitionUpdate',
	PRICING_DEFINITION_DELETE = 'PricingDefinitionDelete',
	PRICING_MODEL_RESOLVE = 'PricingModelResolve',

	MONGO_DB = 'MongoDB',

	CHECK_AND_APPLY_SMART_CHARGING = 'CheckAndApplySmartCharging',
	COMPUTE_AND_APPLY_CHARGING_PROFILES_FAILED = 'ComputeAndApplyChargingProfilesFailed',
	SMART_CHARGING = 'SmartCharging',

	INSTANTIATE_DUMMY_MODULE = 'InstantiateDummyModule',

	HTTP_REQUEST = 'HttpRequest',
	HTTP_RESPONSE = 'HttpResponse',
	HTTP_ERROR = 'HttpError',

	EXPORT_TO_CSV = 'ExportToCSV'
}

export enum BillingAccountStatus {
	IDLE = 'idle',
	PENDING = 'pending',
	ACTIVE = 'active'
}

export enum ChipType {
	PRIMARY = 'primary',
	DEFAULT = 'default',
	INFO = 'info',
	SUCCESS = 'success',
	DANGER = 'error',
	WARNING = 'warning',
	GREY = 'grey',
	REVOKE = 'Finishing'
}

export enum ChargingStationResetType {
	SOFT = 'Soft',
	HARD = 'Hard',
}

export enum ChargingStationAvailabiliType {
	OPERATIVE = 'Operative',
	INOPERATIVE = 'Inoperative',
}

export enum ChargePointStatus {
	AVAILABLE = 'Available',
	OCCUPIED = 'Occupied',
	CHARGING = 'Charging',
	FAULTED = 'Faulted',
	RESERVED = 'Reserved',
	FINISHING = 'Finishing',
	PREPARING = 'Preparing',
	SUSPENDED_EVSE = 'SuspendedEVSE',
	SUSPENDED_EV = 'SuspendedEV',
	UNAVAILABLE = 'Unavailable',
}

export enum ConnectorType {
	SELECT_ALL = 'A',
	TYPE_2 = 'T2',
	COMBO_CCS = 'CCS',
	CHADEMO = 'C',
	TYPE_1 = 'T1',
	TYPE_1_CCS = 'T1CCS',
	DOMESTIC = 'D',
	UNKNOWN = 'U',
	NACS = 'NACS'
}

export enum Voltage {
	VOLTAGE_230 = 230,
	VOLTAGE_110 = 110,
}

export enum CurrentType {
	AC = 'AC',
	DC = 'DC',
}

export enum OCPPPhase {
	L1 = 'L1',
	L2 = 'L2',
	L3 = 'L3',
	L4 = 'L4',
	L5 = 'L5',
	L6 = 'L6',
	N = 'N',
	L1_N = 'L1-N',
	L2_N = 'L2-N',
	L3_N = 'L3-N',
	L1_L2 = 'L1-L2',
	L2_L3 = 'L2-L3',
	L3_L1 = 'L3-L1'
}

export enum OCPPProtocol {
	SOAP = 'soap',
	JSON = 'json',
}


export enum RestResponse {
	SUCCESS = 'Success',
}

export enum ChargingProfileKindType {
	ABSOLUTE = 'Absolute',
	RECURRING = 'Recurring',
	RELATIVE = 'Relative',
}

export enum RecurrencyKindType {
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
}

export enum ChargingProfilePurposeType {
	CHARGE_POINT_MAX_PROFILE = 'ChargePointMaxProfile',
	TX_DEFAULT_PROFILE = 'TxDefaultProfile',
	TX_PROFILE = 'TxProfile',
}

export enum ChargingRateUnitType {
	WATT = 'W',
	AMPERE = 'A',
}

export enum ConsumptionChartDatasetOrder {
	INSTANT_WATTS = 0,
	INSTANT_WATTS_L1 = 1,
	INSTANT_WATTS_L2 = 2,
	INSTANT_WATTS_L3 = 3,
	INSTANT_AMPS = 4,
	INSTANT_AMPS_L1 = 5,
	INSTANT_AMPS_L2 = 6,
	INSTANT_AMPS_L3 = 7,
	INSTANT_AMPS_DC = 8,
	CUMULATED_CONSUMPTION_WH = 9,
	CUMULATED_CONSUMPTION_AMPS = 10,
	LIMIT_WATTS = 11,
	LIMIT_AMPS = 12,
	STATE_OF_CHARGE = 13,
	INSTANT_VOLTS = 14,
	INSTANT_VOLTS_DC = 15,
	INSTANT_VOLTS_L1 = 16,
	INSTANT_VOLTS_L2 = 17,
	INSTANT_VOLTS_L3 = 18,
	AMOUNT = 19,
	CUMULATED_AMOUNT = 20,
	ASSET_CONSUMPTION_WATTS = 21,
	ASSET_PRODUCTION_WATTS = 22,
	CHARGING_STATION_CONSUMPTION_WATTS = 23,
	NET_CONSUMPTION_WATTS = 24,
	ASSET_CONSUMPTION_AMPS = 25,
	ASSET_PRODUCTION_AMPS = 26,
	CHARGING_STATION_CONSUMPTION_AMPS = 27,
	NET_CONSUMPTION_AMPS = 28,
	PLAN_WATTS = 29,
	PLAN_AMPS = 30,
}

export enum ConsumptionUnit {
	AMPERE = 'A',
	KILOWATT = 'kW',
}

export enum ConsumptionChartAxis {
	X = 'x',
	AMPERAGE = 'Amperage',
	POWER = 'Power',
	PERCENTAGE = 'Percentage',
	VOLTAGE = 'Voltage',
	AMOUNT = 'Amount',
}

export enum StaticLimitAmps {
	MIN_LIMIT_PER_PHASE = 6,
}

export enum Unit {
	W,
	Wh,
}

export enum Size {
	BASIS = 1,
	C = 10 * Size.BASIS,
	D = 10 * Size.C,
	K = 10 * Size.D,
	M = 10 * Size.K,
}

export enum StartTransactionErrorCode {
	BILLING_NO_PAYMENT_METHOD = 'no_payment_method',
	ERROR_START_GENERAL = 'error_start_general',
	BILLING_NO_TAX = 'billing_no_tax',
	BILLING_NO_SETTINGS = 'billing_no_settings',
	BILLING_INCONSISTENT_SETTINGS = 'billing_inconsistent_settings',
}

export enum TenantComponents {
	OCPI = 'ocpi',
	OICP = 'oicp',
	REFUND = 'refund',
	PRICING = 'pricing',
	ORGANIZATION = 'organization',
	STATISTICS = 'statistics',
	ANALYTICS = 'analytics',
	BILLING = 'billing',
	BILLING_PLATFORM = 'billingPlatform',
	ASSET = 'asset',
	SMART_CHARGING = 'smartCharging',
	CAR = 'car',
	CAR_CONNECTOR = 'carConnector',
	GRIDIFY = 'gridify'
}

export enum Filters {
	OWNER = 'Owner',
	ROLES = 'users.roles',
	STATUS = 'general.status',
	API_USER = 'users.technical_user',
	ACCESS_MODE = 'Access Mode',
	INACTIVITY = 'chargers.inactivity',
	ERROR = 'User Error',
	INERROR_ERROR = 'chargers.connector_error_title',
	CHARGINGSTATIONERROR = 'general.error',
	RFIDSTATUS = 'RFID Status',
	SESSION_REFUNDING_STATUS = 'RefundStatus',
	CONNECTORS = 'chargers.connector',
	LEVELS = 'logs.levels',
	SOURCES = 'logs.source',
    INVOICE_STATUS='Invoice Status',
	TRANSACTION_STATUS='Transaction Status',
    TRANSFER_STATUS='Transfer Status',
	SMART_CHARGING='Smart Charging',
    SITES='Site_data',
	NEWCHARGINGTAB='newchargingtab',
	ASSET='asset',
    CHARGINGSTATION_STATUS = 'chargingStation Status'
}

export enum ChargingStationStatus {
	CONNECTED = 'Connected',
	COMMISSIONED = 'Commissioned',
	ACTIVE = 'Active',//connected
	INACTIVE = 'Inactive',//disconnected
	RMA = 'RMA'//force full inactive
  }

export enum CarConnectorConnectionType {
	NONE = '',
	MERCEDES = 'mercedes',
	TRONITY = 'tronity',
	TARGA = 'targaTelematics',
	SMART = 'smartCar',
}

export enum UserInErrorType {
	NOT_ACTIVE = 'inactive_user',
	NOT_ASSIGNED = 'unassigned_user',
	INACTIVE_USER_ACCOUNT = 'inactive_user_account',
	FAILED_BILLING_SYNCHRO = 'failed_billing_synchro',
}

export enum TechnicalSettings {
	USER = 'user',
	CRYPTO = 'crypto'
}

export enum CryptoSettingsType {
	CRYPTO = 'crypto',
}

export enum UserSettingsType {
	USER = 'user',
}

export enum ChargingStationInErrorType {
	MISSING_SETTINGS = 'missing_settings',
	CONNECTION_BROKEN = 'connection_broken',
	MISSING_SITE_AREA = 'missing_site_area',
	CONNECTOR_ERROR = 'connector_error',
}

export enum AssetInErrorType {
	
	MISSING_SITE_AREA = 'missing_site_area',
}

export enum TransactionInErrorType {
	NO_CONSUMPTION = 'no_consumption',
	LOW_CONSUMPTION = 'low_consumption',
	OVER_CONSUMPTION = 'average_consumption_greater_than_connector_capacity',
	NEGATIVE_INACTIVITY = 'negative_inactivity',
	LONG_INACTIVITY = 'long_inactivity',
	NEGATIVE_DURATION = 'negative_duration',
	LOW_DURATION = 'low_duration',
	INVALID_START_DATE = 'incorrect_starting_date',
	MISSING_PRICE = 'missing_price',
	MISSING_USER = 'missing_user',
	NO_BILLING_DATA = 'no_billing_data'
}

export enum TransactionStatisticsType {
	REFUND = 'refund',
	HISTORY = 'history',
	ONGOING = 'ongoing',
}

export enum InactivityStatus {
	INFO = 'I',
	WARNING = 'W',
	ERROR = 'E',
}

export enum RefundSettingsType {
	CONCUR = 'concur',
	STRIPE_REFUND = 'stripeRefund',
}

export enum RefundType {
	REFUNDED = 'refunded',
	NOT_REFUNDED = 'notRefunded',
}

export enum RefundStatus {
	SUBMITTED = 'submitted',
	NOT_SUBMITTED = 'notSubmitted',
	CANCELLED = 'cancelled',
	APPROVED = 'approved',
}

export enum PricingSettingsType {
	SIMPLE = 'simple',
}

export enum BillingSettingsType {
	STRIPE = 'stripe',
}

export enum AnalyticsSettingsType {
	SAC = 'sac',
}

export enum SmartChargingSettingsType {
	SAP_SMART_CHARGING = 'sapSmartCharging',
}

export enum RoamingSettingsType {
	OCPI = 'ocpi',
	OICP = 'oicp',
}

export enum BillingInvoiceStatus {
	PAID = 'paid',
	OPEN = 'open',
	DRAFT = 'draft',
	UNCOLLECTIBLE = 'uncollectible',
	DELETED = 'deleted',
	VOID = 'void',
}

export enum TransactionStatus {
	PAID = 'paid',
	FAILED = 'failed',
	DECLINED = 'declined',
	PENDING = 'pending'
}

export enum BillingTransferStatus {
	DRAFT = 'draft',
	PENDING = 'pending',
	FINALIZED = 'finalized',
	TRANSFERRED = 'transferred'
}

export enum AssetConnectionType {
	NONE = '',
	SCHNEIDER = 'schneider',
	GREENCOM = 'greencom',
	IOTHINK = 'iothink',
	WIT = 'wit',
	LACROIX = 'lacroix',
}

export enum OCPIRegistrationStatus {
	NEW = 'new',
	REGISTERED = 'registered',
	UNREGISTERED = 'unregistered',
}

export enum OCPIRole {
	CPO = 'CPO',
	EMSP = 'EMSP'
}

export enum ChartTypeValues {
	PIE = 'pie',
	BAR = 'bar',
	STACKED_BAR = 'stackedBar'
}

export enum MobileType {
	IOS = 'iOS',
	ANDROID = 'Android',
}

export enum HTTPError {
	GENERAL_ERROR = 500,
	NOT_IMPLEMENTED_ERROR = 501,
	MISSING_SETTINGS = 505,
	FEATURE_NOT_SUPPORTED_ERROR = 585,

	REFUND_SESSION_OTHER_USER_ERROR = 551,
	REFUND_CONNECTION_ERROR = 552,
	CONCUR_CITY_UNKNOWN_ERROR = 553,
	CANNOT_DELETED_REFUNDED_SESSION = 555,

	PRICING_REQUEST_INVOICE_ERROR = 561,

	CYPHER_INVALID_SENSITIVE_DATA_ERROR = 555,

	EXISTING_TRANSACTION_ERROR = 570,

	SET_CHARGING_PROFILE_ERROR = 554,
	LIMIT_POWER_ERROR = 556,

	USER_EMAIL_ALREADY_EXIST_ERROR = 510,
	USER_EULA_ERROR = 520,
	USER_NO_BADGE_ERROR = 570,

	TAG_ALREADY_EXIST_ERROR = 576,
	TAG_VISUAL_ID_ALREADY_EXIST_ERROR = 577,
	TAG_VISUAL_ID_DOES_NOT_MATCH_TAG_ERROR = 578,
	TAG_INACTIVE = 579,

	USER_ACCOUNT_LOCKED_ERROR = 590,
	USER_ACCOUNT_ALREADY_ACTIVE_ERROR = 591,
	USER_ACCOUNT_PENDING_ERROR = 592,
	USER_ACCOUNT_BLOCKED_ERROR = 593,
	USER_ACCOUNT_INACTIVE_ERROR = 594,
	USER_ACCOUNT_CHANGED = 595,
	TENANT_COMPONENT_CHANGED = 596,
	TENANT_ALREADY_EXIST = 597,
	TECHNICAL_USER_CANNOT_LOG_TO_UI_ERROR = 598,

	INVALID_CAPTCHA = 530,
	INVALID_TOKEN_ERROR = 540,
	CHARGER_WITH_NO_SITE_AREA_ERROR = 525,
	SITE_AREA_WITH_NO_SITE_ERROR = 525,
	USER_WITH_NO_SITE_ERROR = 525,

	BILLING_DELETE_ERROR = 510, // FIXME: override an already defined HTTP status code: Not extended (RFC 2774)

	CLEAR_CHARGING_PROFILE_NOT_SUCCESSFUL = 537,
	SMART_CHARGING_STILL_ACTIVE_FOR_SITE_AREA = 538,

	CAR_ALREADY_EXIST_ERROR = 591,
	USER_NOT_OWNER_OF_THE_CAR = 593,
	USER_ALREADY_ASSIGNED_TO_CAR = 594,
	NO_CAR_FOR_USER = 595,

	TRANSACTION_NOT_FROM_TENANT = 580,
	TRANSACTION_WITH_NO_OCPI_DATA = 581,
	TRANSACTION_CDR_ALREADY_PUSHED = 582,

	THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA = 539,

	CRYPTO_MIGRATION_IN_PROGRESS = 511,
	CRYPTO_ALGORITHM_NOT_SUPPORTED = 512,
	CRYPTO_KEY_LENGTH_INVALID = 513,
	CRYPTO_CHECK_FAILED = 514,

	CANNOT_ACQUIRE_LOCK = 510, // FIXME: override an already defined HTTP status code: Not extended (RFC 2774)
	INVALID_FILE_FORMAT = 583,
	INVALID_FILE_CSV_HEADER_FORMAT = 584,

	CHARGE_POINT_NOT_VALID = 584,

	OCPI_ENDPOINT_ALREADY_REGISTERED = 530,
	OCPI_ENDPOINT_ALREADY_UNREGISTERED = 531,

	SITE_AREA_TREE_ERROR = 540,
	SITE_AREA_TREE_ERROR_SMART_SAME_SITE_AREA = 541,
	SITE_AREA_TREE_ERROR_SITE = 542,
	SITE_AREA_TREE_ERROR_SMART_CHARGING = 543,
	SITE_AREA_TREE_ERROR_SMART_NBR_PHASES = 544,
	SITE_AREA_TREE_ERROR_VOLTAGE = 545,
	SITE_AREA_TREE_ERROR_MULTIPLE_ACTIONS_NOT_SUPPORTED = 546,

	CANNOT_RETRIEVE_CONSUMPTION = 533,
}