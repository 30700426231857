export function IconChademo(props: any) {
  const height = props.height || '20px';
  const width = props.width || '20px';

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 52.9 52.9'
      >
        <g transform='matrix(.07829 0 0 .07825 -2.6 -13)' stroke='currentColor'>
          <ellipse
            ry='72.7'
            rx='69.1'
            cy='506.6'
            cx='512.1'
            fill='none'
            strokeWidth='65'
          />
          <ellipse
            cx='233.6'
            cy='506.6'
            rx='69.1'
            ry='72.7'
            fill='none'
            strokeWidth='65'
          />
          <circle cx='371.4' cy='330.9' r='70' fill='none' strokeWidth='10' />
          <ellipse
            ry='17.1'
            rx='16.4'
            cy='330.9'
            cx='319.9'
            fill='none'
            strokeWidth='7.3'
          />
          <ellipse
            cx='421.6'
            cy='330.9'
            rx='16.4'
            ry='17.1'
            fill='none'
            strokeWidth='7.3'
          />
          <ellipse
            ry='17.1'
            rx='16.4'
            cy='380.4'
            cx='372.1'
            fill='none'
            strokeWidth='7.3'
          />
          <ellipse
            ry='17.1'
            rx='16.4'
            cy='276.6'
            cx='372.1'
            fill='none'
            strokeWidth='7.3'
            transform='translate(0 4)'
          />
          <circle
            r='70'
            cy='330.9'
            cx='371.4'
            fill='none'
            strokeWidth='10'
            transform='translate(0 350)'
          />
          <ellipse
            cx='319.9'
            cy='680.9'
            rx='16.4'
            ry='17.1'
            fill='none'
            strokeWidth='7.3'
          />
          <ellipse
            ry='17.1'
            rx='16.4'
            cy='680.9'
            cx='421.6'
            fill='none'
            strokeWidth='7.3'
          />
          <ellipse
            cx='372.1'
            cy='730.4'
            rx='16.4'
            ry='17.1'
            fill='none'
            strokeWidth='7.3'
          />
          <ellipse
            cx='372.1'
            cy='276.6'
            rx='16.4'
            ry='17.1'
            fill='none'
            strokeWidth='7.3'
            transform='translate(0 354)'
          />
          <path
            d='M198.6 253.8s52.6-29.4 81-38.6c28-9 57.4-15.4 86.8-15.7 33.7-.4 67.6 5.7 99.7 15.7 28.1 8.8 79.6 38.6 79.6 38.6l38.6-8.6 54.3 54.3-11.5 40s31.2 55.8 39.3 86.4c7.4 28 9 57.6 7.9 86.5a337 337 0 0 1-17.2 98.5 281.2 281.2 0 0 1-41.4 74.3 328.6 328.6 0 0 1-59.1 59.4A285.1 285.1 0 0 1 497 781a318.6 318.6 0 0 1-177.7 21.8 286 286 0 0 1-54.4-15.3 307.7 307.7 0 0 1-56.4-29.3 346.7 346.7 0 0 1-65.7-54.3 291.4 291.4 0 0 1-48.6-71.4A290.6 290.6 0 0 1 72 555.9c-4.3-26-5.9-52.9-3.5-79.3 2.4-26.7 9.3-53 17.8-78.5a627.2 627.2 0 0 1 27.9-61.5l-9.4-41.7 49.4-49.7z'
            fill='none'
            strokeWidth='18'
          />
        </g>
      </svg>
    </>
  );
}
