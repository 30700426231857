import  * as schema from 'yup';
import { AnalyticsLinkModel } from '../models/integrationSettings';
import { findRequiredFields } from '@/common/utils/validationHelper';

export const AnalyticsSchemaConstants = {
	nameMaxLength:100
};

export const formSchema = schema.object<Partial<Record<keyof AnalyticsLinkModel, schema.AnySchema>>>({
	name: schema.string().max(100,'general.error_max_length').required('general.mandatory_field'),
	description:schema.string().required('general.mandatory_field'),
	url: schema.string().required('general.mandatory_field').matches(/^(ftp|http|https):\/\/[^ "]+$/,'general.error_url_pattern'),
});
export const requiredFields = findRequiredFields('', formSchema.describe().fields);
