export class UserSitesModel {
	userID: string = '';
	siteAdmin?: boolean;
	siteOwner?: boolean;
	site?: Record<string, any>;
	isSelected: boolean = false;


	constructor(input: UserSitesModel) {
		Object.assign(this, input);
	}

	get name() {
		return this.site?.name;
	}

	get city() {
		return this.site?.address.city;
	}

	get country() {
		return this.site?.address.country;
	}

	get id() {
		return this.site?.id;
	}
}

export class AddUserSitesModel {
	id: string = '';
	name?: string;
	address?: Record<string, any>;
	issuer?: boolean;

	constructor(input: AddUserSitesModel) {
		Object.assign(this, input);
	}

	get address1() {
		if (this.address) {
			return this.address.address1;
		}
		return '';
	}

	get city() {
		return this.address?.city;
	}

	get country() {
		return this.address?.country;
	}
}