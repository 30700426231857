import { Grid } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { GetEnergyUsageTotals } from '@/services/dashboard';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const YearlyGraph = ({ groupBy, vehicaldata, demandLimit }: any) => {
  const [_, setData] = useState<any>({
    totalEnergyConsumerInKWh: 0,
  });

  const [data, setDataC] = useState(
    [
      {
        type:'bar',
        label: 'Data',
        data: [4000, 3000, 5000, 6000, 9000, 3000, 5000, 6000, 1000, 3500, 2000, 2500],
        borderColor: '#2D9CDB',
        borderWidth: 0,
        fill: true,
        backgroundColor: '#2D9CDB',
        borderRadius: 5,
        barPercentage: groupBy == '_30days' ? 1 : 0.35,
      },
    ]
  );

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand (KW)',
          color: 'black',
          padding: 5,
        },
        grid: {
          display: false,
        },
        ticks: {
          // stepSize: 500,
        },
      },
    },
  };

  const labeldata: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const chartData:any = {
    labels: labeldata,
    datasets: data,
  };



  useEffect(() => {
    let dataF: any = [...data];
    const dataExist = dataF.filter((item) => item.type === 'line');
    if (demandLimit && dataExist.length == 0) {
      dataF.push({
        type: 'line',
        label: 'Line Dataset',
        borderColor: '#F79009',
        borderWidth: 2,
        backgroundColor: '#F79009',
        borderRadius: 0,
        data: [2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800],
      });
    } else if (!demandLimit) {
      dataF = dataF.filter((item) => item.type !== 'line');
    }
    setDataC(dataF);
  }, [demandLimit]);


  return (
    <Grid container sx={{ display: 'flex' }}>
      <Grid
        className={styles.graphContainer}
        data-cy='energy-usage-bargraph'
      >
        <Line data={chartData} options={options} />
        {/* <Bar
          style={{
            padding: '0 0px',
            display: 'flex',
            gap: '12px',
          }}
          data={chartData}
          options={options}
        /> */}
      </Grid>
    </Grid>
  );
};

export default YearlyGraph;
