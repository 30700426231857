import { Grid, useMediaQuery } from '@mui/material';
import styles from './styles.module.scss';
import TabPanel from '@/modules/dashboard/components/common/dashboard-tabpanel/tabpanel';
import { tabs, LIVE, YEAR, MONTH } from '../../../../shared/constants';
import { useState } from 'react';
import DemandViewSelect from '@/modules/gridify/shared/components/FormSelect';
import YearlyGraph from './graphs/YearlyGraph';
import MonthlyGraph from './graphs/MonthlyGraph';
import { LiveGraph } from './graphs/LiveGraph';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';

export const GraphSection = ({ setGroupBy }: any) => {
    const [selectedTab, setSelectedTab] = useState<string>(LIVE);
    const [demandLimit, setDemandLimit] = useState(true);
    const [instantPower, setInstantPower] = useState(true);
    const [selectedChargers, setSelectedChargers] = useState(['BTC-DC-3PH-0001']);
    const handleType = (tab: any) => {
        if (tab) {
            setSelectedTab(tab.name);
            setGroupBy(tab.value);
        }
    };
    const isBigScreen = useMediaQuery('(max-width:1500px)');
    const options = [
        { label: 'BTC-DC-3PH-0001', value: 'BTC-DC-3PH-0001' },
        { label: 'BTC-DC-3PH-0002', value: 'BTC-DC-3PH-0002' },
        { label: '9IOCAH10-50-1', value: '9IOCAH10-50-1' },
        { label: '9IOCAH10-50-2', value: '9IOCAH10-50-2' },
    ];
    const handleChange = (e, name) => {
        const value = e.target.checked;
        if (name == 'demand') {
            setDemandLimit(value);
        } else {
            setInstantPower(value);
        }
    };
    return (
        <Grid className={styles.bodyContainer}>
            <Grid className={styles.headerContainer}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <TabPanel
                        data-cy='demand-view-tabs'
                        {...{
                            tabs: tabs,
                            selectedTab: selectedTab,
                        }}
                        variant='standard'
                        textColor='primary'
                        handleTab={handleType}
                        from={'demand-view'}
                        sx={{
                            '& .MuiTabs-root, .MuiTabs-scroller': {
                                overflow: 'visible',
                                minHeight: '40px',
                            },
                            '& .MuiTabs-flexContainer': {
                                display: 'flex',
                                flexDirection: 'row',
                                margin: 0,
                                padding: 0,
                                border: '1px solid #D0D5DD',
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                fontWeight: 400,
                                // borderRight:'none'
                            },
                            '& .MuiToggleButtonGroup-root': {
                                padding: '0px',
                                margin: '0px',
                                // borderRight: '1px solid #D0D5DD',
                                // borderRadius: 0
                            },
                            '& .MuiBox-root': {
                                padding: '0px',
                                margin: '0px',
                            },
                            '& .MuiToggleButton-root': {
                                textTransform: 'none',
                                fontSize: isBigScreen ? '14px' : '12px',
                                padding: isBigScreen ? '7.5px 12px' : '10px 16px',
                                height: isBigScreen ? '33px' : '44px',
                                boxShadow: '0px 1px 2px 0px #1018280D',
                                fontWeight: 400,
                                borderRadius: '0px',
                                border: 'none',
                                lineHeight: '24px',
                                fontFamily: 'Roboto',
                                color: '#344054',
                            },
                            '& .Mui-selected.MuiToggleButton-root': {
                                backgroundColor: '#3D3AD3',
                                border: 'none',
                                boxShadow: 'none',
                                borderRadius: 'none',
                                '&:hover': {
                                    backgroundColor: '#3D3AD3', 
                                     opacity:0.8
                                },
                                ...(selectedTab === LIVE && {
                                    borderRadius: '3px 0px 0px 3px',
                                }),
                                ...(selectedTab === YEAR && {
                                    borderRadius: '0px 3px 3px 0px',
                                }),
                            },
                        }}
                        lasttabsx={{
                            borderRight: '0px solid #D0D5DD',
                            borderRadius: 0
                        }}
                    />
                    <p className={styles.dateText}>{selectedTab === LIVE ? '4/8/2024' : (selectedTab === MONTH ? 'March, 2024 - April, 2024' : 'March, 2023 - April, 2024')}</p>
                </div>
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Grid data-cy='charger-select'>
                        <DemandViewSelect {...{
                            options, selected: selectedChargers, setSelected: setSelectedChargers, renderText: 'Charger', multiple: true, sx: {
                                '& .MuiSelect-outlined': {
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: '#3D3AD3',
                                    border: '1px solid #3D3AD3',
                                    padding: '8px',
                                    '@media (max-width: 1440px)': {
                                        fontSize: '12px',
                                        padding: '4px',
                                    },
                                }
                            }
                        }} /> </Grid>
                    <Grid item display={'flex'} gap={'4px'} alignItems={'center'} data-cy='demand-limit'>
                        <Grid item>
                            <EvesCheckbox sx={{ padding: '0px', margin: '0px', width: '16px', height: '16px', color: '' }} checked={demandLimit} onChange={(e) => handleChange(e, 'demand')} />
                        </Grid>
                        <Grid item sx={{ background: '#F79009', width: '10px', height: '10px', borderRadius: '50%' }}>
                        </Grid>
                        <Grid item className={styles.checkboxLabel}>Demand Limit</Grid>
                    </Grid>
                    {selectedTab === LIVE && <Grid item display={'flex'} gap={'4px'} alignItems={'center'} data-cy='instant-power'>
                        <Grid item>
                            <EvesCheckbox sx={{ padding: '0px', margin: '0px', width: '16px', height: '16px', color: '', }} checked={instantPower} onChange={(e) => handleChange(e, 'power')} />
                        </Grid>
                        <Grid item sx={{ background: '#1B1AFF', width: '10px', height: '10px', borderRadius: '50%' }}>
                        </Grid>
                        <Grid item className={styles.checkboxLabel}>Total Instant Power</Grid>
                    </Grid>
                    }
                </div>
            </Grid>
            <Grid width={'100%'}>
                {selectedTab === YEAR && <YearlyGraph demandLimit={demandLimit} />}
                {selectedTab === MONTH && <MonthlyGraph demandLimit={demandLimit} />}
                {selectedTab === LIVE && <LiveGraph {...{ demandLimit, instantPower }} />}
            </Grid>
        </Grid>
    );
};