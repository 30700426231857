import { createSlice } from '@reduxjs/toolkit';
import { IAppContextModel } from './types';

const initialState: IAppContextModel = {
	showLoader: false,
	disableLoader: false,
	showAutoLoader: false,
	notification: {
		showNotification: false,
		message: '',
		type: 'success'
	}
};

export const appContextSlice = createSlice({
	name: 'appContext',
	initialState,
	reducers: {
		showNotification: (state, action) => {
			state.notification = {
				showNotification: true,
				message: action.payload.message,
				type: action.payload.type
			};
		},
		hideNotification: (state) => {
			state.notification.showNotification = false;
		},
		setLoader: (state, action) => {
			state.showLoader = !state.disableLoader && action.payload;
		},
		setAutoLoader: (state, action) => {
			state.showAutoLoader = action.payload;
		},
		setDisableLoader: (state, action) => {
			state.disableLoader = action.payload;
		}
	}
});

export const { showNotification, hideNotification, setLoader, setAutoLoader, setDisableLoader} = appContextSlice.actions;
export default appContextSlice.reducer;