import { format, intervalToDuration } from 'date-fns';
export class Formatter {
	/**
     * Format date as MM/DD/YY
     * @param inputDate Valid date as Date | string
     * @returns string
     */
	static toDateString (inputDate: Date | string): string {
		try {
			const date = new Date(inputDate);
			return format(date, 'MM/dd/yy');
		} catch (err) {
			console.log('Unable to parse date', inputDate, err);
			throw err;
		}
	}

	/**
     * Format date as MM/DD/YY, hh:mm AM
     * @param inputDate Valid date as Date | string
     * @returns string
     */
	static toDateTimeString (inputDate: Date | string): string {
		try {
			const date = new Date(inputDate);
			return format(date, 'MM/dd/yy, hh:mm a');
		} catch (err) {
			console.log('Unable to parse date', inputDate, err);
			throw err;
		}
	}

	/**
     * Format given number as currency string
     * @param inputValue Valid number to convert
     * @returns string
     */
	static toCurrencyString (inputValue: number): string {
		try {
			return '$' + inputValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
		} catch (err) {
			console.log('Invalid currency input value', inputValue, err);
			throw err;
		}
	}

	/**
     * calculate duration
     * @param startDate Valid date as Date | string
	 * @param endDate Valid date as Date | string
     * @returns string
     */
	static duration (startDate: Date | string, endDate: Date | string): string {
		try {
			const sDate = new Date(startDate);
			const eDate = new Date(endDate);
			const result = intervalToDuration({
				start: new Date(sDate.getUTCFullYear(), sDate.getMonth(), sDate.getDay(), sDate.getHours(), sDate.getMinutes(), sDate.getSeconds()),
				end: new Date(eDate.getUTCFullYear(), eDate.getMonth(), eDate.getDay(), eDate.getHours(), eDate.getMinutes(), eDate.getSeconds())
			});
			if(result.hours === 0 && result.minutes === 0)
				return result.seconds + 's';
			if(result.hours === 0)
				return result.minutes + 'm';
			return result.hours + 'h ' + result.minutes + 'm';
		} catch (err) {
			console.log('Unable to calculate duration', startDate, endDate, err);
			throw err;
		}
	}
}