import {
	withContext,
} from '@/common/utils/withContext';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { FormControlLabel,Box } from '@mui/material';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { isSuperAdmin } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

const Notifications = (props: any) => {
	const userForm = props.userForm;
	const isUserSuperAdmin = isSuperAdmin();
	const { t: translate } = useTranslation();

	useEffect(() => {
		props.toggleNotifications(userForm.getValues().notificationsActive);
	}, [userForm.getValues().notificationsActive]);

	if(isUserSuperAdmin) 
		return <>
			<Grid container direction="row">
				<Grid item xs={12}>
					<Controller
						name="notificationsActive"
						control={userForm.control}
						render={({ field }) => (
							<FormControlLabel
								control={
									<EvesCheckbox
										{...field}
										data-cy="user-notifications"
										checked={userForm.watch('notificationsActive')}
										disabled={!props.isEnableAllEnabled}
									/>
								}
								label={`${translate('users.notifications_active')}`}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Box pl={3}>
				<Grid container direction="row">
					<Grid item xs={12}>
						<Controller
							name="notifications.sendCarCatalogSynchronizationFailed"
							control={userForm.control}
							render={({ field }) => (
								<FormControlLabel
									control={
										<EvesCheckbox
											{...field}
											data-cy="user-notifications"
											checked={userForm.watch(
												'notifications.sendCarCatalogSynchronizationFailed'
											)}
											disabled={!props.isNotificationEnabled}
										/>
									}
									label={`${translate('notifications.car_catalog_synchronization_failed')}`}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</Box></>;

	return (
		<>
			{props.showNotification && (
				<>
					<Grid container direction="row">
						<Grid item xs={12}>
							<Controller
								name="notificationsActive"
								control={userForm.control}
								render={({ field }) => (
									<FormControlLabel
										control={
											<EvesCheckbox
												{...field}
												data-cy="user-notifications"
												checked={userForm.watch('notificationsActive')}
												disabled={!props.isEnableAllEnabled}
											/>
										}
										label={`${translate('users.notifications_active')}`}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Box pl={3}>
						<Grid container direction="row">
							<Grid item xs={12}>
								<Controller
									name="notifications.sendSessionStarted"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendSessionStarted'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.session_started')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendOptimalChargeReached"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendOptimalChargeReached'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.optimal_charge_reached')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendEndOfCharge"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch('notifications.sendEndOfCharge')}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.end_of_charge')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendEndOfSession"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch('notifications.sendEndOfSession')}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.end_of_session')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendUserAccountStatusChanged"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendUserAccountStatusChanged'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.user_account_status_changed')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendSessionNotStarted"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendSessionNotStarted'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.session_not_started')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendUserAccountInactivity"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendUserAccountInactivity'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.user_account_inactivity')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendPreparingSessionNotStarted"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendPreparingSessionNotStarted'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.preparing_session_not_started')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendBillingNewInvoice"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendBillingNewInvoice'
													)}
													disabled={!props.isNotificationEnabled}
												/>
											}
											label={`${translate('notifications.billing_new_invoice')}`}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Box>
				</>
			)}
			{props.showAdminNotification && (
				<Box pl={3}>
					<Box sx={{ fontSize: '14px', padding: '6px 0' }}>{`${translate('notifications.admin_only')}`}</Box>
					<Box pl={3}>
						<Grid container direction="row">
						<Grid item xs={12}>
								<Controller
									name="notifications.sendExpiringRFID"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendExpiringRFID'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.rfid_expiring')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendUnknownUserBadged"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendUnknownUserBadged'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.unknown_user_badged')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendChargingStationStatusError"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendChargingStationStatusError'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.charging_station_status_error')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendChargingStationRegistered"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendChargingStationRegistered'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.charging_station_registered')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendOfflineChargingStations"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendOfflineChargingStations'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.charging_stations_offline')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendOcpiPatchStatusError"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendOcpiPatchStatusError'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.ocpi_patch_status_error')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendOicpPatchStatusError"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendOicpPatchStatusError'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.oicp_patch_status_error')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendBillingSynchronizationFailed"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendBillingSynchronizationFailed'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.billing_synchronization_failed')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendBillingPeriodicOperationFailed"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendBillingPeriodicOperationFailed'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.billing_periodic_operation_failed')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendComputeAndApplyChargingProfilesFailed"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendComputeAndApplyChargingProfilesFailed'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.compute_and_apply_charging_profiles_failed')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendEndUserErrorNotification"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendEndUserErrorNotification'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.end_user_error_notification')}`}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="notifications.sendAdminAccountVerificationNotification"
									control={userForm.control}
									render={({ field }) => (
										<FormControlLabel
											control={
												<EvesCheckbox
													{...field}
													data-cy="user-notifications"
													checked={userForm.watch(
														'notifications.sendAdminAccountVerificationNotification'
													)}
													disabled={!props.isAdminNotificationEnabled}
												/>
											}
											label={`${translate('notifications.admin_account_verification')}`}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
		</>
	);
};

export default withContext(Notifications);