import EvesSelect from '@/common/components/atoms/select/select';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { AssetConnectionType } from '@/common/enums/enums';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { AssetFormModel } from '@/modules/integrationSettings/shared/models/integrationSettings';
import { formSchema, requiredFields } from '@/modules/integrationSettings/shared/schemas/assetFormSchema';
import { Grid } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { connect } from 'react-redux';
import Schneider from './schneider';
import Greencom from './greencom/indext';
import Iothink from './iothink';
import Wit from './wit';
import Lacroix from './lacroix';
import { AccountBalanceRounded } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AssetForm = (props: any) => {
	const { t: translate } = useTranslation();
	const assetForm = props.formService as UseFormReturn<AssetFormModel, any>;
	const isSaveEnabled=(assetForm.formState.isValid && assetForm.formState.isDirty);
	const assetConnectionTypes = [
		{ label: translate('settings.asset.types.schneider'), value: AssetConnectionType.SCHNEIDER, },
		{ label: translate('settings.asset.types.greencom'), value: AssetConnectionType.GREENCOM,  },
		{ label: translate('settings.asset.types.iothink'),value: AssetConnectionType.IOTHINK,  },
		{ label: translate('settings.asset.types.wit'),value: AssetConnectionType.WIT,  },
		{ label: translate('settings.asset.types.lacroix'),value: AssetConnectionType.LACROIX,  }
	];
	const assertType=assetForm.watch('type');
	const assetName=assetForm.watch('name');
	
	const onFormSubmit = (formData: AssetFormModel) => {
		if (!formData.id) {
			formData.id = new Date().getTime().toString();
		}
		if (formData.type !== AssetConnectionType.GREENCOM) {
			delete formData.greencomConnection;
		}
		if (formData.type  !== AssetConnectionType.SCHNEIDER) {
			delete formData.schneiderConnection;
		}
		if (formData.type !== AssetConnectionType.IOTHINK) {
			delete formData.iothinkConnection;
		}
		if (formData.type !== AssetConnectionType.WIT) {
			delete formData.witConnection;
		}
		if (formData.type !== AssetConnectionType.LACROIX) {
			delete formData.lacroixConnection;
		}
		props.handleRowData(formData);
		props.onFormClose();
		props.resetAssetForm('');
	};

	const getAssetConnection = () => {
		const content = props.state.rows.find((data: any) => props.state.assetRecordId === data.id);
		assetForm.reset({...content});
	};

	useEffect(() => {
		if(props.state.assetRecordId !== ''){
			getAssetConnection();
		}
	}, []);

	const onHandleClose=()=>{
		props.onFormClose();
		props.resetAssetForm('');
	};

	const setAssetValue = (value: any) => {
		if(value === 'schneider'){
			assetForm.setValue('schneiderConnection.schneiderConnection', true);
			assetForm.setValue('greencomConnection.greencomConnection', false);
			assetForm.setValue('iothinkConnection.iothinkConnection', false);
			assetForm.setValue('lacroixConnection.lacroixConnection', false);
			assetForm.setValue('witConnection.witConnection', false);
		}else if(value === 'greencom') {
			assetForm.setValue('schneiderConnection.schneiderConnection', false);
			assetForm.setValue('greencomConnection.greencomConnection', true);
			assetForm.setValue('iothinkConnection.iothinkConnection', false);
			assetForm.setValue('lacroixConnection.lacroixConnection', false);
			assetForm.setValue('witConnection.witConnection', false);
		}else if(value === 'iothink'){
			assetForm.setValue('schneiderConnection.schneiderConnection', false);
			assetForm.setValue('greencomConnection.greencomConnection', false);
			assetForm.setValue('iothinkConnection.iothinkConnection', true);
			assetForm.setValue('lacroixConnection.lacroixConnection', false);
			assetForm.setValue('witConnection.witConnection', false);
		}else if(value === 'wit'){
			assetForm.setValue('schneiderConnection.schneiderConnection', false);
			assetForm.setValue('greencomConnection.greencomConnection', false);
			assetForm.setValue('iothinkConnection.iothinkConnection', false);
			assetForm.setValue('lacroixConnection.lacroixConnection', false);
			assetForm.setValue('witConnection.witConnection', true);
		}else if(value === 'lacroix'){
			assetForm.setValue('schneiderConnection.schneiderConnection', false);
			assetForm.setValue('greencomConnection.greencomConnection', false);
			assetForm.setValue('iothinkConnection.iothinkConnection', false);
			assetForm.setValue('lacroixConnection.lacroixConnection', true);
			assetForm.setValue('witConnection.witConnection', false);
		}
	};

	useEffect(() => {
		if(assetForm.formState.isDirty && assetForm.formState.isValid)
			assetForm.trigger();
	}, [assetForm.formState.isDirty, assetForm.formState.isValid]);

	const handleNameValidation = () => assetForm.trigger('name');

	return<>
		<EvesModal maxWidth={'900px'} isOpen={props.showAssetForm} 
			onHandleSave={assetForm.handleSubmit(onFormSubmit)} onHandleClose={onHandleClose}
			isSaveDisabled={!(isSaveEnabled)}
			title={assetName}
			titleIcon={<AccountBalanceRounded/>} scrollButtons={'auto'} >
			<Grid container rowSpacing={1} columnSpacing={2}>
				<Grid item xs={12}>
					<Controller name="name" control={assetForm.control} render={({ field }) => (
						<EvesTextbox {...field} id="name" label={`${translate('settings.asset.connection.name')}`} autoFocus={true}
							fullWidth disabled={props.isDisabled}
							required={requiredFields['name']} variant="standard"
							error={!!assetForm.formState?.errors.name}
							helperText={`${translate(assetForm.formState?.errors.name?.message || ' ').replace(/{{length}}/g, `'${100}'`)}`}
							onChange={(e: any) => { field.onChange(e); handleNameValidation(); }}
						/>
					)} />
				</Grid>
				<Grid item xs={12}>
					<Controller name="description" control={assetForm.control} render={({ field }) => (
						<EvesTextbox {...field} id="description" label={`${translate('settings.asset.connection.description')}`}
							fullWidth disabled={props.isDisabled}
							required={requiredFields['description']} variant="standard"
							error={!!assetForm.formState?.errors.description}
							helperText={`${translate(assetForm.formState?.errors.description?.message || ' ')}`}
						/>
					)} />
				</Grid>
				<Grid item xs={12}>
					<Controller name="url" control={assetForm.control} render={({ field }) => (
						<EvesTextbox {...field} id="url" label={`${translate('settings.asset.connection.base_url')}`}
							fullWidth disabled={props.isDisabled}
							required={requiredFields['url']} variant="standard"
							error={!!assetForm.formState?.errors.url}
							helperText={`${translate(assetForm.formState?.errors.url?.message || ' ')}`}
						/>
					)} />
				</Grid>
				<Grid item xs={6}>
					<Controller name="type" control={assetForm.control}
						render={({ field }) => (
							<EvesSelect data-cy="type-option" label={`${translate('settings.asset.connection.type')} *`}  options={assetConnectionTypes} 
								{...field}
								onChange={(event:any) => {field.onChange(event); setAssetValue(event.target.value);}}
								disabled ={props.isDisabled} error={!!assetForm.formState?.errors.type}
								helperText={`${translate(assetForm.formState?.errors.type?.message || ' ')}`}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Controller name="refreshIntervalMins" control={assetForm.control} render={({ field }) => (
						<EvesTextbox {...field} id="refreshIntervalMins" label={`${translate('settings.asset.connection.refreshIntervalMins')}`}
							fullWidth disabled={props.isDisabled} type='number' variant="standard"
							error={!!assetForm.formState?.errors.refreshIntervalMins}
							helperText={`${translate(assetForm.formState?.errors.refreshIntervalMins?.message || ' ')}`}
						/>
					)} />
				</Grid>
				{assertType=='schneider' && <Schneider assetForm={assetForm} requiredFields={requiredFields} />}
				{assertType=='greencom' && <Greencom assetForm={assetForm} requiredFields={requiredFields} />}
				{assertType=='iothink' && <Iothink assetForm={assetForm} requiredFields={requiredFields} />}
				{assertType=='wit' && <Wit assetForm={assetForm} requiredFields={requiredFields} />}
				{assertType=='lacroix' && <Lacroix assetForm={assetForm} requiredFields={requiredFields} />}
			</Grid>
		</EvesModal>
	</>;
};

const mapStateToProps = (state: any) => ({
	userInfo: state.userContext.userInfo
});

export default connect(mapStateToProps)(withContext( withUseFormHook(AssetForm, {
	schema: formSchema,
	defaultValues: { ...new AssetFormModel() }
})
));
