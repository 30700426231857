import React from 'react';
import styles from './styles.module.scss';
import { Slider } from '@mui/material';
import potentail from '../../../../assets/img/sessions/potential.svg';
interface PotentialCurtailementInterface {
    totev: string;
    availableCurtailement: string;
    percentage: string;
    handleChangeSlider?:any;
}
const customStyle = {
    color: '#EAECF0',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: '#2469F3',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '1.5px solid #2469F3',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        '@media (max-width: 1400px)': {
            height: 20,
            width: 20,
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'center',
        background: 'unset',
        color: '#101828',
        padding: 0,
        width: 32,
        height: 32,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-135deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -10%) rotate(135deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(225deg)',
        },
        '@media (max-width: 1400px)': {
            fontSize: 14,
            lineHeight: '20px',
            width: 24,
            height: 24,
        },
    },
};
const PotentialCurtailment = (props: PotentialCurtailementInterface) => {
    return (
        <div className={styles.commonCardContainer}>
            <div className={styles.potentialCardHeaderContainer}>
                <img src={potentail} className={styles.commonCardImage} />
                <h2 className={styles.commonCardHeaderText}>Potential Curtailment</h2>
            </div>
            <div className={styles.potentialDescContainer}>
                <div className={styles.potentialTextAndNumberContainer}>
                    <h2 className={styles.potentialText}>Total EV</h2>
                    <h1 className={styles.potentialNumberText}>{props.totev}</h1>
                </div>
                <div className={styles.potentialTextAndNumberContainer}>
                    <h2 className={styles.potentialText}>Available Curtailment (kW)</h2>
                    <h1 className={styles.potentialNumberText}>{props.availableCurtailement}</h1>
                </div>
            </div>
            <div style={{ marginRight: '10px' }}>
                <Slider
                    defaultValue={0}
                    aria-label="medium"
                    valueLabelDisplay="on"
                    valueLabelFormat={value => <div>{value}%</div>}
                    sx={customStyle}
                    onChange={props.handleChangeSlider}
                />
            </div>
        </div>
    );
};

export default PotentialCurtailment;