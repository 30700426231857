import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  getLocales,
  withContext,
  withUseFormHook,
} from '@/common/utils/withContext';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import {
  DefaultPageSize,
  HTTPError,
  StatusCodes,
} from '@/common/constants/constants';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { useMutation, useQuery } from 'react-query';
import { NotifyType } from '@/common/utils/notificationService';
import { connect } from 'react-redux';
import { USER_STATUSES } from '@/modules/profile/shared/models/user';
import {
  canListPaymentMethods,
  getAvailableRoles,
  handleHttpError,
  isActive,
  isSuperAdmin,
} from '@/common/utils/utils';
import { TenantComponents, UserRole, UserStatus } from '@/common/enums/enums';
import {
  createUser,
  getUserInformation,
  updateUser,
} from '@/services/userProfile';
import {
  Email,
  Link,
  LocationOn,
  Lock,
  MoreHoriz,
  PaymentOutlined,
} from '@mui/icons-material';
import { formSchema } from '@/modules/user/shared/schemas/userSchema';
import DefaultAvatar from '@/assets/img/user-default.png';
import { IUserFormPropsModel, UserFormModel } from '../../models/user';
import Notifications from '@/modules/shared/components/user/notifications';
import Address from '@/modules/shared/components/user/address';
import Security from '@/modules/shared/components/user/security';
import Connections from '@/modules/shared/components/user/connections';
import Miscs from '@/modules/shared/components/user/miscs';
import PaymentMethods from '@/modules/shared/components/user/paymentMethods';
import UserProfile from '@/modules/shared/components/user/userProfile';
import { useTranslation } from 'react-i18next';

const UserForm = (props: IUserFormPropsModel) => {
  const { t: translate } = useTranslation();
  const userForm = props.formService as UseFormReturn<UserFormModel, any>;
  const userName = userForm.watch('name');
  const userFirstName = userForm.watch('firstName');
  const isUserSuperAdmin = isSuperAdmin();
  const isBillingComponentActive = isActive(TenantComponents.BILLING);
  const isUserCanListPaymentMethods: any = canListPaymentMethods();
  const isFormValid = userForm.formState.isValid && userForm.formState.isDirty;

  const [state, setStateData] = useState({
    selectedTab: '1',
    limit: DefaultPageSize,
    canUpdate: false,
    isAccountLookupModalOpen: false,
    userData: {},
    avatar: DefaultAvatar,
    isTechnicalDisabled: false,
    isFreeAccessDisabled: false,
    userRoleOptions: [],
    notificationsActive: true,
    isAdminNotificationEnabled: false,
    isNotificationEnabled: true,
    isEnableAllEnabled: false,
    userRole: '',
    securityState: true,
    miscsState: true,
    canUserListPaymentMethods: true,
  } as any);

  const notify: NotifyType = {
    message: '',
    type: 'success',
  };

  const convertObject = (userOptions: any) => {
    const options: any[] = [];
    for (const pair of userOptions) {
      options.push({ label: pair.value, key: pair.key, value: pair.value });
    }
    return options;
  };

  const statusOptions = convertObject(USER_STATUSES).map((option) => ({
    ...option,
    label: translate(option.label || ' '),
  }));

  const userLocales: any = getLocales();
  const languageOptions = convertObject(userLocales);

  const getRole = (userRoleOptions: any[], role: string) => {
    const userRole: any = userRoleOptions.find(
      (userRole) => userRole.key === role
    );
    return userRole.value;
  };

  const getKey = (options: any[], value: string) => {
    const key: any = options.find((data) => data.value === value)?.key;
    return key;
  };

  const getStatus = (statusKey: string) => {
    const statusValue = statusOptions.find(
      (status) => status.key == statusKey
    ).value;
    return statusValue;
  };

  const getRoleKey = (userRoleOptions: any[], value: string) => {
    const userRole: any = userRoleOptions.find(
      (userRole) => userRole.value === value
    );
    return userRole.key;
  };

  const getLanguage = (locale: string) => {
    const userLanguage: any = languageOptions.find(
      (userLanguage) => userLanguage.key === locale
    );
    return userLanguage.value;
  };

  const notificationControls: any[] = [
    'sendSessionStarted',
    'sendOptimalChargeReached',
    'sendEndOfCharge',
    'sendEndOfSession',
    'sendUserAccountStatusChanged',
    'sendSessionNotStarted',
    'sendUserAccountInactivity',
    'sendBillingNewInvoice',
    'sendPreparingSessionNotStarted',
  ];

  const adminNotificationControls: any[] = [
    'sendUnknownUserBadged',
    'sendUnknownUserBadged',
    'sendChargingStationStatusError',
    'sendOfflineChargingStations',
    'sendOcpiPatchStatusError',
    'sendOicpPatchStatusError',
    'sendBillingSynchronizationFailed',
    'sendBillingPeriodicOperationFailed',
    'sendComputeAndApplyChargingProfilesFailed',
    'sendEndUserErrorNotification',
    'sendChargingStationRegistered',
    'sendAdminAccountVerificationNotification',
  ];

  const loadUser = (userData: any) => {
    const userFormData: UserFormModel = new UserFormModel();
    if (userData.id) {
      userFormData.id = userData.id;
    }
    userFormData.issuer = userData.issuer;
    if (userData.name) {
      userFormData.name = userData.name.toUpperCase();
    }
    if (userData.firstName) {
      userFormData.firstName = userData.firstName;
    }
    if (userData.email) {
      userFormData.email = userData.email;
      userFormData.originalEmail = userData.email;
    }
    if (userData.phone) {
      userFormData.phone = userData.phone;
    }
    if (userData.mobile) {
      userFormData.mobile = userData.mobile;
    }
    if (userData.status) {
      const status = getStatus(userData.status);
      userFormData.status = status;
    }
    if (userData.role) {
      const userRoles: any[] = getAvailableRoles(userData.role);
      const userRoleOptions: any[] = convertObject(userRoles);
      setStateData((currentData: any) => {
        return {
          ...currentData,
          userRoleOptions,
        };
      });
      userFormData.role = getRole(userRoleOptions, userData.role);
      if (userData.role === UserRole.ADMIN) {
        setStateData((currentData: any) => {
          return {
            ...currentData,
            userRole: userData.role,
            showAdminNotification: true,
            showNotification: true,
            isNotificationEnabled: true,
            isAdminNotificationEnabled: true,
            isEnableAllEnabled: true,
          };
        });
      } else {
        setStateData((currentData: any) => {
          return {
            ...currentData,
            showAdminNotification: false,
            showNotification: true,
            isNotificationEnabled: true,
            isAdminNotificationEnabled: false,
            isEnableAllEnabled: true,
          };
        });
      }
    }
    if (userData.locale) {
      userFormData.language = getLanguage(userData.locale);
    }
    if (userData.plateID) {
      userFormData.plateID = userData.plateID;
    }
    if (userData.notificationsActive) {
      enableAllNotifications(userData.notificationsActive);
      userFormData.notificationsActive = userData.notificationsActive;
    } else {
      enableAllNotifications(userData.notificationsActive);
      userFormData.notificationsActive = userData.notificationsActive;
    }
    if (userData.projectFields.includes('technical')) {
      if (userData.technical) {
        userFormData.technical = userData.technical;
      }
    } else {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isTechnicalDisabled: true,
        };
      });
    }
    if (userData.projectFields.includes('freeAccess')) {
      if (userData.freeAccess) {
        userFormData.freeAccess = userData.freeAccess;
      }
    } else {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isFreeAccessDisabled: true,
        };
      });
    }
    if (userData.iNumber) {
      userFormData.iNumber = userData.iNumber;
    }
    if (userData.costCenter) {
      userFormData.costCenter = userData.costCenter;
    }
    //Address
    userFormData.address = userData.address;
    //Notifications
    userFormData.passwords = { password: '', repeatPassword: '' };
    userFormData.notifications = userData.notifications;
    return userFormData;
  };

  const { refetch: fetchUserInformation } = useQuery(
    'User',
    () => {
      return getUserInformation(props.userId);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if (data) {
          const userData = data;
          const userFormData: any = loadUser(userData);
          userForm.reset({ ...userFormData });
        }
      },
    }
  );

  const enableNotifications = (
    notificationControl: string,
    enabled: boolean
  ) => {
    if (notificationControl === 'adminNotificationControls') {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isAdminNotificationEnabled: enabled,
        };
      });
    } else if (notificationControl === 'notificationControls') {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isNotificationEnabled: enabled,
        };
      });
    }
  };

  const enableAllNotifications = (enabled: boolean) => {
    enableNotifications('notificationControls', enabled);
    enableNotifications('adminNotificationControls', enabled);
  };

  const activateNotifications = (enabled: boolean) => {
    userForm.setValue('notificationsActive', enabled);
    setStateData((currentData: any) => {
      return {
        ...currentData,
        notificationsActive: enabled,
        isEnableAllEnabled: enabled,
      };
    });
  };

  const setNotificationsValue = (
    notificationControls: any[],
    enabled: boolean
  ) => {
    const formData: any = {};
    for (const notificationControl of notificationControls) {
      formData[notificationControl] = enabled;
    }
    const notificationData: any = {
      ...userForm.getValues().notifications,
      ...formData,
    };
    userForm.setValue('notifications', notificationData);
  };

  const setAllNotificationsValue = (enabled: boolean) => {
    setNotificationsValue(adminNotificationControls, enabled);
    setNotificationsValue(notificationControls, enabled);
  };

  const showAllNotifications = (enabled: boolean) => {
    showNotifications('notificationControls', enabled);
    showNotifications('adminNotificationControls', enabled);
  };

  const showNotifications = (notificationControl: string, enabled: boolean) => {
    if (notificationControl === 'adminNotificationControls') {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          showAdminNotification: enabled,
        };
      });
    } else if (notificationControl === 'notificationControls') {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          showNotification: enabled,
        };
      });
    }
  };

  const toggleNotifications = (checked: boolean) => {
    enableAllNotifications(checked);
  };

  const roleChanged = (role: string) => {
    switch (role) {
      case UserRole.DEMO:
        setAllNotificationsValue(false);
        activateNotifications(false);
        enableAllNotifications(false);
        showNotifications('adminNotificationControls', false);
        break;
      case UserRole.BASIC:
        activateNotifications(true);
        showNotifications('notificationControls', true);
        enableNotifications('notificationControls', true);
        setNotificationsValue(notificationControls, true);
        enableNotifications('adminNotificationControls', false);
        showNotifications('adminNotificationControls', false);
        setNotificationsValue(adminNotificationControls, false);
        break;
      case UserRole.ADMIN:
        activateNotifications(true);
        enableAllNotifications(true);
        setAllNotificationsValue(true);
        showAllNotifications(true);
        break;
    }
  };

  const changeRole = (role: string) => {
    const key: string = getRoleKey(state.userRoleOptions, role);
    roleChanged(key);
  };

  useEffect(() => {
    if (props.userId) {
      fetchUserInformation();
    } else {
      const userRoles: any[] = getAvailableRoles(isUserSuperAdmin ? 'S' : 'B');
      const userRoleOptions: any[] = convertObject(userRoles);
      setStateData((currentData: any) => {
        return {
          ...currentData,
          userRoleOptions,
        };
      });
      const userModel = new UserFormModel();
      userModel.language = 'English';
      userModel.role = isUserSuperAdmin ? 'Super Admin' : 'Basic';
      const status = getStatus(UserStatus.ACTIVE);
      userModel.status = status;
      userModel.notifications.sendPreparingSessionNotStarted = false;
      userForm.reset({ ...userModel });
      roleChanged(UserRole.BASIC);
    }
  }, []);

  const setSecurityState = (recordState: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        securityState: recordState,
      };
    });
  };

  const setMiscsState = (recordState: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        miscsState: recordState,
      };
    });
  };

  const isUserId = props.userId ? true : false;

  const tabsConfig = [
    {
      name: `User - ${userName} ${userFirstName}`,
      icon: <AccountBoxOutlinedIcon />,
      component: (
        <UserProfile
          userForm={userForm}
          onChangeRole={changeRole}
          languageOptions={languageOptions}
          userRoleOptions={state.userRoleOptions}
          statusOptions={statusOptions}
          isTechnicalDisabled={state.isTechnicalDisabled}
          isFreeAccessDisabled={state.isFreeAccessDisabled}
          userId={props.userId}
          statusRequired={props.statusRequired}
        />
      ),
      visible: true,
    },
    {
      name: `${translate('general.notifications')}`,
      icon: <Email />,
      component: (
        <Notifications
          userForm={userForm}
          toggleNotifications={toggleNotifications}
          notificationsActive={state.notificationsActive}
          showNotification={state.showNotification}
          showAdminNotification={state.showAdminNotification}
          isAdminNotificationEnabled={state.isAdminNotificationEnabled}
          isNotificationEnabled={state.isNotificationEnabled}
          isEnableAllEnabled={state.isEnableAllEnabled}
        />
      ),
      visible: true,
    },
    {
      name: `${translate('general.address')}`,
      icon: <LocationOn />,
      component: <Address userForm={userForm} />,
      visible: true,
    },
    {
      name: `${translate('users.security')}`,
      icon: <Lock />,
      component: (
        <Security userForm={userForm} setSecurityState={setSecurityState} />
      ),
      visible: true,
    },
    {
      name: `${translate('general.connectors')}`,
      icon: <Link />,
      component: <Connections userForm={userForm} />,
      visible: !isUserSuperAdmin,
    },
    {
      name: `${translate('users.miscs')}`,
      icon: <MoreHoriz />,
      component: <Miscs userForm={userForm} setMiscsState={setMiscsState} />,
      visible: true,
    },
    {
      name: `${translate('users.tabs.billing')}`,
      icon: <PaymentOutlined />,
      component: <PaymentMethods userForm={userForm} />,
      visible:
        isUserId && isBillingComponentActive && isUserCanListPaymentMethods,
    },
  ];

  const { mutate: addUser } = useMutation(
    (userFormData) => createUser(userFormData),
    {
      onSuccess: (data: any) => {
        if (data.status == 'Success') {
          props.onFormClose();
          props.resetUserForm('');
          notify.message = `${translate('users.create_success').replace(
            /{{userFullName}}/g,
            `'${userName} ${userFirstName}'`
          )}`;
          props.notificationService?.notify(notify);
          props.fetchAllUsers();
        } else {
          switch (data.status) {
            // Email already exists
            case HTTPError.USER_EMAIL_ALREADY_EXIST_ERROR:
              notify.message = `${translate(
                'authentication.email_already_exists'
              )}`;
              notify.type = 'error';
              props.notificationService?.notify(notify);
              break;
            // User deleted
            case StatusCodes.NOT_FOUND:
              notify.message = `${translate('users.create_error')}`;
              notify.type = 'error';
              props.notificationService?.notify(notify);
              break;
            default: {
              const errorNotify = handleHttpError(
                data,
                `${translate('users.create_error')}`
              );
              props.notificationService?.notify(errorNotify);
            }
          }
        }
      },
    }
  );

  const { mutate: editUser } = useMutation(
    (userFormData) => updateUser(userFormData),
    {
      onSuccess: (data: any) => {
        if (data.status == 'Success') {
          props.onFormClose();
          props.resetUserForm('');
          notify.message = `${translate('users.update_success').replace(
            /{{userFullName}}/g,
            `'${userName} ${userFirstName}'`
          )}`;
          props.notificationService?.notify(notify);
          props.fetchAllUsers();
        } else {
          switch (data.status) {
            // Email already exists
            case HTTPError.USER_EMAIL_ALREADY_EXIST_ERROR:
              notify.message = `${translate(
                'authentication.email_already_exists'
              )}`;
              notify.type = 'error';
              props.notificationService?.notify(notify);
              break;
            // User deleted
            case StatusCodes.NOT_FOUND:
              notify.message = `${translate('users.create_error')}`;
              notify.type = 'error';
              props.notificationService?.notify(notify);
              break;
            default: {
              const errorNotify = handleHttpError(
                data,
                `${translate('users.create_error')}`
              );
              props.notificationService?.notify(errorNotify);
            }
          }
        }
      },
    }
  );

  const onFormSubmit = (formData: UserFormModel) => {
    const userFormData: any = { ...formData };
    userFormData.locale = getKey(languageOptions, userFormData.language);
    userFormData.status = getKey(statusOptions, userFormData.status);
    userFormData.role = getKey(state.userRoleOptions, userFormData.role);
    if (userFormData.passwords.password) {
      userFormData.password = userFormData.passwords.password;
      delete userFormData.passwords;
    } else delete userFormData.password;
    if (!userFormData.image) {
      userFormData.image = null;
    }

    if (props.userId) {
      editUser(userFormData);
    } else {
      if (!userFormData.locale) {
        userFormData.locale = 'en_US';
      }
      if (!userFormData.language) {
        delete userFormData.language;
      }
      if (!userFormData.role) {
        userFormData.role = 'B';
      }
      if (!userFormData.status) {
        userFormData.status = 'A';
      }
      userFormData.passwords = formData.passwords;
      addUser(userFormData);
    }
  };

  const onHandelClose = () => {
    if (!!Object.keys(userForm.formState?.dirtyFields).length === true) {
      //based on the formValidity confirmation Dialogtype changes
      if (userForm.formState.isValid) {
        props.dialogService?.showConfirm(
          {
            confirmType: 'DIRTY_CHANGE_CLOSE',
          },
          (result) => {
            if (result === 'SAVE') {
              userForm.handleSubmit(onFormSubmit)();
            } else if (result === 'CLOSE') {
              props.onFormClose();
              props.resetUserForm('');
            }
          }
        );
      } else {
        props.dialogService?.showConfirm(
          {
            confirmType: 'INVALID_CHANGE_CLOSE',
          },
          (result: string) => {
            if (result === 'CLOSE') {
              props.onFormClose();
              props.resetUserForm('');
            }
          }
        );
      }
    } else {
      props.onFormClose();
      props.resetUserForm('');
    }
  };

  return (
    <>
      <EvesModal
        maxWidth={'1200px'}
        isOpen={props.showUserForm}
        onHandleSave={userForm.handleSubmit(onFormSubmit)}
        onHandleClose={onHandelClose}
        isSaveDisabled={!isFormValid}
        tabsConfig={tabsConfig}
        modalType='tabs'
        isSaveHidden={false}
        data-cy='userFormModal'
        scrollButtons={'auto'}
      ></EvesModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(UserForm, {
      schema: formSchema,
      defaultValues: { ...new UserFormModel() },
    })
  )
);
