export class LoginFormModel {
	constructor() {
		this.userName = '';
		this.password = '';
		this.acceptAgreement = false;
	}
	userName!: string;
	password!: string ;
	acceptAgreement!: boolean;
}

// model to register an user
export class RegisterFormModel {
	constructor() {
		this.name = '';
		this.firstName = '';
		this.email = '';
		this.mobile = '';
		this.password = '';
		this.repeatPassword = '';
		this.captcha = '';
		this.acceptAgreement = false;
	}
	name!: string;
	firstName!: string;
	email!: string;
	mobile!: string;
	password!: string;
	repeatPassword!: string;
	captcha!: string;
	acceptAgreement!: boolean;
}

// model to reset password
export class ResetPasswordFormModel {
	constructor() {
		this.email = '';
		this.captcha = '';
		this.acceptAgreement = false;
	}

	email!: string;
	captcha!: string;
	acceptAgreement!: boolean;
}

// model to define new password
export class DefinePasswordFormModel {
	constructor() {
		this.password = '';
		this.repeatPassword = '';
		this.resetPasswordHash = '';
	}

	password!: string;
	repeatPassword!: string;
	resetPasswordHash!: string;
}