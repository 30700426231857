import { Box } from '@mui/material';
// import { PgLft, PgRgt, RightDis, LeftdisPg } from '@/icons';
import { TablePagination } from '@mui/material';
import './pagination.scss';
interface IEvesPagination {
    count?: number;
    rowsPerPage?: number;
    page?: number;
    onPageChange?: any;
    onRowsPerPageChange?: any;
    isFilterDrawerOpen?: any;
    popupwidth?: boolean;
    borderRadius?: boolean;
}

// Main Pagination Component
const EVESPagination = (props: IEvesPagination) => {
    const { count = 0, rowsPerPage = 50, page = 1, onPageChange, onRowsPerPageChange } = props;
    return (
        <Box className={'paginationContainer'} >
            <TablePagination
                {...{
                    count: count,
                    page: page,
                    onPageChange: onPageChange,
                    rowsPerPage: rowsPerPage,
                    onRowsPerPageChange: onRowsPerPageChange,
                    showLastButton: true,
                    showFirstButton: true,
                }}
                data-cy="record_count_view"
                className={'paginationStyles'}
            />
        </Box>
    );
};

export default EVESPagination;