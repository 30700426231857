import { EvesButton } from '@/common/components/atoms/button/button';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { NotifyType } from '@/common/utils/notificationService';
import { withContext } from '@/common/utils/withContext';
import { updateChargingStationUrl } from '@/services/chargingStations';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

const FirmwareUpdate = (props: any) => {
  const [inputValue, setInputValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const [errorProp, setErrorProp] = useState(false);
  const getFormValues = props.chargingStationForm.getValues();
  const [disable, setDisable] = useState(true);
  const urlString = props.chargingStationForm.watch('url');
  const { t: translate } = useTranslation();

  const onUrlUpdate = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('chargers.update_firmware_title')}`,
        description: `${translate('chargers.update_firmware_confirm').replace(
          /{{chargeBoxID}}/g,
          `'${props.chargingStationId}'`
        )}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          editChargingStationUrl(props.chargingStationId, urlString);
        }
      }
    );
  };

  const { mutate: editChargingStationUrl } = useMutation(
    () => updateChargingStationUrl(props.chargingStationId, urlString),
    {
      onSuccess: (data: any) => {
        const notify: NotifyType = {
          message: '',
          type: 'success',
        };
        if (data.status === 'Success') {
          notify.message = `${translate(
            'chargers.update_firmware_success'
          ).replace(/{{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`;
          notify.type = 'success';
          props.notificationService?.notify(notify);
        } else {
          notify.message = `${translate('chargers.update_firmware_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        }
      },
    }
  );

  const handleInputChange = (e) => {
    if (e.target.value) {
      setDisable(false);
      setErrorText('');
      setErrorProp(false);
    } else {
      setErrorText(`${translate('general.mandatory_field')}`);
      setInputValue(e.target.value);
      setDisable(true);
      setErrorProp(true);
    }
    setInputValue(e.target.value);
  };

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems={'center'}
        style={{ gap: 12, alignItems: 'center' }}
      >
        <Grid item xs={12}></Grid>
      </Grid>
      <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
        <EvesButton
          type='button'
          disabled={disable}
          onClick={onUrlUpdate}
          data-cy='firmwareUpdate-button'
          startIcon={<SystemUpdateAltOutlinedIcon />}
          sx={{ marginTop: '10px' }}
        >
          {translate('chargers.button_update_firmware')}
        </EvesButton>
        <EvesTextbox
          id='url'
          label={`${translate('chargers.charger_url')} *`}
          variant='standard'
          fullWidth
          value={inputValue}
          disabled={!getFormValues.canUpdateFirmware}
          onChange={handleInputChange}
          error={errorProp}
          helperText={errorText}
          onBlur={handleInputChange}
        />
      </Stack>
    </>
  );
};

export default withContext(FirmwareUpdate);
