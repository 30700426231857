import { findRequiredFields } from '@/common/utils/validationHelper';
import  * as schema from 'yup';
import { ElectricVehicleModel } from '../models/electricVehicle';

export const formSchema = schema.object<Partial<Record<keyof ElectricVehicleModel, schema.AnySchema>>>({
	evCatalog: schema.string().required('general.mandatory_field'),	
	converter: schema.string().required('general.mandatory_field'),
	vin:schema.string().required('general.mandatory_field').matches(/^[A-Z0-9]{17}$/,'cars.invalid_vin'),
	licensePlate:schema.string().required('general.mandatory_field').matches(/^[A-Z0-9-]*$/, 'users.invalid_plate_id'),
	user:schema.string()
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);