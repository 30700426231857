import { EvesButton } from '@/common/components/atoms/button/button';
import { Card } from '@/common/components/molecules/card/card';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { Business, Info, Save, Sync, Launch } from '@mui/icons-material';
import { connect } from 'react-redux';
import {
   IRefundingFormPropsModel,
   RefundingFormModel,
} from '../../shared/models/integrationSettings';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
   getRefundSettings,
   updateRefundingContent,
} from '@/services/integrationSettings';
import { Controller, UseFormReturn } from 'react-hook-form';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid, IconButton, InputAdornment, Stack } from '@mui/material';
import { NotifyType } from '@/common/utils/notificationService';
import {
   formSchema,
   requiredFields,
} from '../../shared/schemas/refundingFormSchema';
import {
   RefundSettingsType,
   RestResponse,
   TenantComponents,
} from '@/common/enums/enums';
import { StatusCodes } from '@/common/constants/constants';
import { synchronizeRefundedTransactions } from '@/services/session';
import { isActive } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

const Refunding = (props: IRefundingFormPropsModel) => {
   const { t: translate } = useTranslation();
   const refundingForm = props.formService as UseFormReturn<
      RefundingFormModel,
      any
   >;
   const [state, setStateData] = useState({
      id: '',
      identifier: '',
      backupSensitiveData: {},
      canCheckAssetConnection: false,
      canCheckBillingConnection: false,
      canCheckSmartChargingConnection: false,
      canDelete: false,
      canRead: false,
      canSyncRefund: false,
      canUpdate: false,
      sensitiveData: [],
      content: {},
   } as any);

   const notify: NotifyType = {
      message: '',
      type: 'success',
   };

   const isRefuncingComponentActive = isActive(TenantComponents.REFUND);

   const getRefundParams = () => {
      return { Identifier: TenantComponents.REFUND };
   };

   const { refetch: fetchRefundSettings } = useQuery(
      'Refunding',
      () => {
         return getRefundSettings(getRefundParams());
      },
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         enabled: false,
         onSuccess: async (res: any) => {
            if (res) {
               const data={...res?.setting};
               setStateData((currentData: any) => {
                  if(data){
                     return {
                        ...currentData,
                        id: data.id,
                        backupSensitiveData: data.backupSensitiveData,
                        canCheckAssetConnection: data.canCheckAssetConnection,
                        canCheckBillingConnection: data.canCheckBillingConnection,
                        canCheckSmartChargingConnection:
                           data.canCheckSmartChargingConnection,
                        canDelete: data.canDelete,
                        canRead: data.canRead,
                        canSyncRefund: data.canSyncRefund,
                        canUpdate: data.canUpdate,
                        identifier: data.identifier,
                        sensitiveData: data.sensitiveData,
                        content: data.content,
                     };
                  }
                
               });
               refundingForm.reset({
                  ...data.content?.concur,
                  ...data.content?.stripeRefund,
               });
            }
         },
         onError: (error: any) => {
            switch (error.status) {
               case StatusCodes.NOT_FOUND:
                  props.notificationService?.notify({
                     message: `${translate('settings.refund.not_found')}`,
                     type: 'error',
                  });
                  break;
               default:
                  props.notificationService?.notify({
                     message: `${translate(
                        'general.unexpected_error_backend'
                     )}`,
                     type: 'error',
                  });
            }
         },
      }
   );

   const onFormSubmit = () => {
      const refundSettings: any = {
         id: state.id,
      };
      if (state.content.concur) {
         refundSettings.type = RefundSettingsType.CONCUR;
         refundSettings.concur = { ...refundingForm.getValues() };
      } else if (state.content.stripeRefund) {
         refundSettings.type = RefundSettingsType.STRIPE_REFUND;
         refundSettings.stripeRefund = {
            ...refundingForm.getValues(),
         };
      } else {
         return;
      }

      saveRefundSettings(refundSettings);
   };

   const saveRefundSettings = (settings: any) => {
      // Check the type
      if (!settings.type) {
         settings.type = RefundSettingsType.CONCUR;
      }

      // Build setting payload
      const settingsToSave: any = {
         id: settings.id,
         identifier: TenantComponents.REFUND,
         sensitiveData: [],
         content: { ...settings },
      };
      if (settings.type === RefundSettingsType.CONCUR) {
         settingsToSave.sensitiveData = ['content.concur.clientSecret'];
      }

      if (settings.type === RefundSettingsType.STRIPE_REFUND) {
         settingsToSave.sensitiveData = ['content.stripeRefund.secretKey'];
      }
      // Delete IDS
      delete settingsToSave.content.id;
      delete settingsToSave.content.identifier;
      delete settingsToSave.content.sensitiveData;
      // Save
      return save(settingsToSave);
   };

   const save = async (refundSettings: any) => {
      try {
         const response: any = await updateRefundingContent(refundSettings);
         if (response.status === RestResponse.SUCCESS) {
            notify.message = !refundSettings.id
               ? `${translate('settings.refund.create_success')}`
               : `${translate('settings.refund.update_success')}`;
            fetchRefundSettings();
         } else {
            notify.message = !refundSettings.id
               ? `${translate('settings.refund.create_error')}`
               : `${translate('settings.refund.update_error')}`;
            notify.type = 'error';
         }
      } catch (error: any) {
         switch (error.status) {
            case StatusCodes.NOT_FOUND:
               notify.message = `${translate(
                  'settings.refund.setting_do_not_exist'
               )}`;
               notify.type = 'error';
               break;
            default:
               notify.message = !refundSettings.id
                  ? `${translate('settings.refund.create_error')}`
                  : `${translate('settings.refund.update_error')}`;
         }
      }
      props.notificationService?.notify(notify);
   };

   const synchronizeRefund = () => {
      props.dialogService?.showConfirm(
         {
            title: `${translate(
               'settings.refund.synchronize_dialog_refund_title'
            )}`,
            description: `${translate(
               'settings.refund.synchronize_dialog_refund_confirm'
            )}`,
            confirmType: 'YES_NO',
         },
         (result: any) => {
            if (result === 'YES') {
               props.notificationService?.notify({
                  message: `${translate(
                     'settings.refund.synchronize_started'
                  )}`,
                  type: 'info',
               });
               synchronizeRefundedTransactions()
                  .then((synchronizeResponse: any) => {
                     if (synchronizeResponse.status === 'Success') {
                        props.notificationService?.notify({
                           message: `${translate(
                              'settings.refund.synchronize_success'
                           )}`,
                           type: 'success',
                        });
                        fetchRefundSettings();
                     } else {
                        props.notificationService?.notify({
                           message: `${translate(
                              'settings.refund.synchronize_error'
                           )}`,
                           type: 'error',
                        });
                     }
                  })
                  .catch(() => {
                     notify.message = `${translate(
                        'settings.refund.synchronize_error'
                     )}`;
                     notify.type = 'error';
                     props.notificationService?.notify(notify);
                  });
            }
         }
      );
   };

   const redirect = () => {
      window.open(refundingForm.getValues().url, '_blank');
   };

   useEffect(() => {
      fetchRefundSettings();
   }, []);

   if (!isRefuncingComponentActive) {
      const translatedText = `${translate(
         'settings.refund.description'
      )}`.split(',');
      const description = translatedText
         .map((part) => `<p >${part.trim()}</p>`)
         .join('');
      return (
         <div className='information_block'>
            <Card
               icon={<Info />}
               title={`${translate('chargers.connector_info_title')}`}
               isTransparent={true}
            >
               <div className='information'>
                  <p
                     data-cy='roamingHubject-content1'
                     dangerouslySetInnerHTML={{
                        __html: `${description} <a target='__blank' style="text-decoration: underline; color: inherit" href='https://www.concur.com/'>Concur</a>`,
                     }}
                  ></p>
                  <br />
               </div>
               <div>
                  <p></p>
                  <p data-cy='roamingHubject-content2'>{`${translate(
                     'settings.activation_contact_msg'
                  )}`}</p>
               </div>
            </Card>
         </div>
      );
   }

   const stripeFields: any = refundingForm?.formState?.dirtyFields;
   const dirtyFieldsCount = stripeFields ? Object.keys(stripeFields).length : 0;

   return (
      <>
         <Stack direction='row' spacing={2} marginBottom={'40px'}>
            {state?.content?.concur && (
               <EvesButton
                  type='button'
                  disabled={
                     !(
                        refundingForm.formState.isValid &&
                        refundingForm.formState.isDirty
                     )
                  }
                  onClick={onFormSubmit}
                  startIcon={<Save />}
                  color={'error'}
                  variant='contained'
                  data-cy='save-btn'
               >
                  {`${translate('general.save')}`}
               </EvesButton>
            )}
            {/* {state?.content?.stripeRefund && (
               <EvesButton
                  type='button'
                  disabled={!(dirtyFieldsCount === 3)}
                  onClick={onFormSubmit}
                  startIcon={<Save />}
                  color={'error'}
                  variant='contained'
                  data-cy='save-btn'
               >
                  {`${translate('general.save')}`}
               </EvesButton>
            )} */}
            <EvesButton
               type='button'
               disabled={refundingForm.formState.isDirty}
               onClick={synchronizeRefund}
               startIcon={<Sync />}
               variant='contained'
               data-cy='synchronize-btn'
            >
               {`${translate('general.synchronize')}`}
            </EvesButton>
         </Stack>
         {state.content?.concur && (
            <Card
               icon={<Business />}
               title={`${translate('settings.refund.concur.title')}`}
               isTransparent={true}
               sx={{ marginBottom: '40px' }}
            >
               <>
                  <Grid
                     container
                     rowSpacing={1}
                     columnSpacing={2}
                     data-cy={'refunding-content-concur'}
                  >
                     <Grid item xs={6}>
                        <Controller
                           name='authenticationUrl'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='authenticationUrl'
                                 label={`${translate(
                                    'settings.refund.concur.authentication_url'
                                 )}`}
                                 autoFocus={true}
                                 data-cy='refunding-authenticationUrl'
                                 required={requiredFields['authenticationUrl']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors
                                       .authenticationUrl
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors
                                       .authenticationUrl?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='apiUrl'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='apiUrl'
                                 label={`${translate(
                                    'settings.refund.concur.api_url'
                                 )}`}
                                 required={requiredFields['apiUrl']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 data-cy='apiUrl'
                                 error={
                                    !!refundingForm.formState?.errors.apiUrl
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors.apiUrl
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='clientId'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='clientId'
                                 label={`${translate(
                                    'settings.refund.concur.client_id'
                                 )}`}
                                 data-cy='clientId'
                                 required={requiredFields['clientId']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors.clientId
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors.clientId
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='clientSecret'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='clientSecret'
                                 label={`${translate(
                                    'settings.refund.concur.client_secret'
                                 )}`}
                                 data-cy='clientSecret'
                                 type='password'
                                 required={requiredFields['clientSecret']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors
                                       .clientSecret
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors.clientSecret
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='paymentTypeId'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='paymentTypeId'
                                 label={`${translate(
                                    'settings.refund.concur.payment_type_id'
                                 )}`}
                                 data-cy='user-paymentTypeId'
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 required={requiredFields['paymentTypeId']}
                                 error={
                                    !!refundingForm.formState?.errors
                                       .paymentTypeId
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors
                                       .paymentTypeId?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='expenseTypeCode'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='expenseTypeCode'
                                 label={`${translate(
                                    'settings.refund.concur.expense_type_code'
                                 )}`}
                                 data-cy='user-expenseTypeCode'
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 required={requiredFields['expenseTypeCode']}
                                 error={
                                    !!refundingForm.formState?.errors
                                       .expenseTypeCode
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors
                                       .expenseTypeCode?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='policyId'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='policyId'
                                 label={`${translate(
                                    'settings.refund.concur.policy_id'
                                 )}`}
                                 data-cy='user-policyId'
                                 required={requiredFields['policyId']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors.policyId
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors.policyId
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='reportName'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='reportName'
                                 label={`${translate(
                                    'settings.refund.concur.report_name'
                                 )}`}
                                 data-cy='user-reportName'
                                 required={requiredFields['reportName']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors.reportName
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors.reportName
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <Controller
                           name='appUrl'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='appUrl'
                                 label={`${translate(
                                    'settings.refund.concur.app_url'
                                 )}`}
                                 data-cy='user-appUrl'
                                 required={requiredFields['appUrl']}
                                 fullWidth
                                 disabled={state.isDisabled}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors.appUrl
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors.appUrl
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                  </Grid>
               </>
            </Card>
         )}
         {/* {state.content?.stripeRefund && (
            <Card
               icon={<Business />}
               title={`${translate('settings.billing.stripe.title')}`}
               isTransparent={true}
               sx={{ marginBottom: '40px' }}
               data-cy='refunding-content-stripeRefund'
            >
               <>
                  <Grid container rowSpacing={1} columnSpacing={3}>
                     <Grid item xs={6}>
                        <Controller
                           name='url'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='authenticationUrl'
                                 label={`${translate(
                                    'settings.billing.stripe.url'
                                 )}`}
                                 data-cy='stripeRefundUrl'
                                 fullWidth
                                 disabled={state?.isTransactionBillingActivated}
                                 variant='standard'
                                 error={!!refundingForm.formState?.errors?.url}
                                 InputProps={{
                                    endAdornment: (
                                       <>
                                          <InputAdornment
                                             position='end'
                                             style={{ cursor: 'pointer' }}
                                          >
                                             <IconButton>
                                                <Launch
                                                   onClick={() => redirect()}
                                                   sx={{
                                                      color: 'black',
                                                      height: '15px',
                                                   }}
                                                />
                                             </IconButton>
                                          </InputAdornment>
                                       </>
                                    ),
                                 }}
                                 helperText={`${translate(
                                    refundingForm.formState?.errors?.url
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}></Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='publicKey'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='apiUrl'
                                 label={`${translate(
                                    'settings.billing.stripe.public_key'
                                 )}`}
                                 fullWidth
                                 disabled={state?.isTransactionBillingActivated}
                                 variant='standard'
                                 data-cy='publicKey'
                                 error={
                                    !!refundingForm.formState?.errors?.publicKey
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors?.publicKey
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item xs={6}></Grid>
                     <Grid item xs={6}>
                        <Controller
                           name='secretKey'
                           control={refundingForm.control}
                           render={({ field }) => (
                              <EvesTextbox
                                 {...field}
                                 id='clientId'
                                 label={`${translate(
                                    'settings.billing.stripe.secret_key'
                                 )}`}
                                 data-cy='secretKey'
                                 type='password'
                                 fullWidth
                                 disabled={state?.isTransactionBillingActivated}
                                 variant='standard'
                                 error={
                                    !!refundingForm.formState?.errors?.secretKey
                                 }
                                 helperText={`${translate(
                                    refundingForm.formState?.errors?.secretKey
                                       ?.message || ' '
                                 )}`}
                              />
                           )}
                        />
                     </Grid>
                  </Grid>
               </>
            </Card>
         )} */}
      </>
   );
};

const mapStateToProps = (state: any) => ({
   userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
   withContext(
      withUseFormHook(Refunding, {
         schema: formSchema,
         defaultValues: { ...new RefundingFormModel() },
      })
   )
);
