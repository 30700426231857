import { AddressFormModel } from '@/common/models/model';
import { IContextPropsModel, IFormPropsModel } from '@/common/utils/withContext';
import { CoordinatesModel } from '@/modules/chargingStation/shared/models/chargingStationForm';
import { UseFormReturn } from 'react-hook-form';

class Notifications {
	sendSessionStarted = true;
	sendOptimalChargeReached = true;
	sendCarCatalogSynchronizationFailed = true;
	sendEndOfCharge = true;
	sendEndOfSession = true;
	sendUserAccountStatusChanged = true;
	sendUnknownUserBadged = false;
	sendChargingStationStatusError = false;
	sendChargingStationRegistered = false;
	sendOfflineChargingStations = false;
	sendOcpiPatchStatusError = false;
	sendOicpPatchStatusError = false;
	sendPreparingSessionNotStarted = false;
	sendBillingSynchronizationFailed = false;
	sendBillingPeriodicOperationFailed = false;
	sendSessionNotStarted = true;
	sendUserAccountInactivity = true;
	sendComputeAndApplyChargingProfilesFailed = false;
	sendEndUserErrorNotification = false;
	sendBillingNewInvoice = true;
	sendAdminAccountVerificationNotification = false;
	sendExpiringRFID=false;
}

export class PasswordFormModel {
	password: string = '';
	repeatPassword: string = '';
}

export class UserFormModel {
	id: string = '';
	issuer:boolean =true;
	name:string = '';
	firstName:string = '';
	email:string = '';
	phone:string = '';
	mobile:string = '';
	plateID:string = '';
	status:string = '';
	role:string = '';
	locale: string = '';
	technical:boolean = false;
	costCenter:string = '';
	image:string = '';
	iNumber:string = '';
	password?: string = '';
	passwords: PasswordFormModel;
	freeAccess:boolean = false;
	originalEmail: string = '';
	language:string = '';
	notificationsActive: boolean = false;
	notifications:Notifications;
	address: AddressFormModel;
	siteArea:string = '';
	constructor() {
		this.address = { ...new AddressFormModel() };
		this.notifications = { ... new Notifications() };
		this.passwords={...new PasswordFormModel()};
	}
}
export interface IUserFormPropsModel extends IContextPropsModel, IFormPropsModel<UserFormModel> {
	userId:string;
	showUserForm: boolean;
	onFormClose: () => void;
	resetUserForm:(value:string)=>void;
	fetchAllUsers:()=>void;
	statusRequired?: boolean;
	canCreate?:boolean;
	userForm?: UseFormReturn<UserFormModel, any>;
}

export 	interface IUserListStateModal {
	canCreate: boolean;
	canExport: boolean;
	canImport: boolean;
	isResetBtnDisabled: boolean;
	limit: number;
	sortFields: string;
	search: string;
	projectFields: string[],
	role: string;
	status: string;
	issuer: boolean | null;
	siteID: string;
	visualTagID: string;
	technical: boolean | null;
	freeAccess: boolean | null;
	rows: Record<string, any>[];
	totalRowsCount: number;
	defaultIssuer: boolean;
	RFIDFilterValue: string;
	siteFilterValue: string;
	siteName:string;
	issuerName:string;
	isSiteLookupModalOpen: boolean;
	userId: string,
	showUserForm: boolean
	showRFIDFilterModal:boolean;
	isUserImportModalOpen: boolean;
	isAssignToSiteModal: boolean;
	userName: string;
	firstName: string;
	technicalList:any;
	accessModeList:any;
	page:number;
}

export class AssetFormModel {
	name:string = '';
    siteArea:string = '';
	siteAreaID:string = '';
	assetType:string = '';
	staticValueWatt:number = 0;
	fluctuationPercent:number =0;
	variationThresholdPercent:number =0;
	excludeFromSmartCharging:boolean=false;
	image:string= '';
	coordinates?: CoordinatesModel;
	constructor () {
		this.coordinates = { ...new CoordinatesModel() };
	}
	usesPushAPI:boolean=false;
	meterID:string='';
	connectionID:string='';
	dynamicAsset:boolean=false;
	Date=new Date().toISOString();

}