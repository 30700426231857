import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SmartCarConnection = (props: any) => {
	const { t: translate } = useTranslation();
	
	return <Grid container rowSpacing={1} columnSpacing={2} sx={{ marginTop:'8px'}}>
		<Grid item xs={6}>
			<Controller name="smartCarConnection.authenticationUrl" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="authenticationUrl" label={`${translate('settings.car_connector.connection.authentication_url')} *`}
					data-cy="refunding-authenticationUrl"
					fullWidths
					variant="standard" 
					error={!!props.carConnectorForm.formState?.errors.smartCarConnection?.authenticationUrl}
					helperText={`${translate(props.carConnectorForm.formState?.errors.smartCarConnection?.authenticationUrl?.message || ' ')}`}
				/>
			)} />
		</Grid>
		<Grid item xs={6}>	
			<Controller name="smartCarConnection.apiUrl" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="apiUrl" label={`${translate('settings.car_connector.connection.api_url')} *`}
					fullWidth 
					// required={props.requiredFields['smartCarConnection.apiUrl']}
					variant="standard"
					error={!!props.carConnectorForm.formState?.errors?.smartCarConnection?.apiUrl}
					helperText={`${translate(props.carConnectorForm.formState?.errors?.smartCarConnection?.apiUrl?.message || ' ')}`}
				/>
			)} />
		</Grid>	
		<Grid item xs={6}>
			<Controller name="smartCarConnection.clientId" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="clientId" label={`${translate('settings.car_connector.connection.client_id')} *`}
					data-cy="clientId"
					fullWidth
					// required={props.requiredFields['smartCarConnection.clientId']}
					variant="standard"
					autoComplete="new-password"
					error={!!props.carConnectorForm.formState?.errors.smartCarConnection?.clientId}
					helperText={`${translate(props.carConnectorForm.formState?.errors.smartCarConnection?.clientId?.message || ' ')}`}
				/>
			)} />
		</Grid>
		<Grid item xs={6}>
			<Controller name="smartCarConnection.clientSecret" control={props.carConnectorForm.control} render={({ field }) => (
				<EvesTextbox {...field} id="clientSecret" label={`${translate('settings.car_connector.connection.client_secret')} *`}
					data-cy="clientSecret"
					type="password"
					fullWidth
					// required={props.requiredFields['smartCarConnection.clientSecret']}
					variant="standard"
					autoComplete="new-password"
					error={!!props.carConnectorForm.formState?.errors.smartCarConnection?.clientSecret}
					helperText={`${translate(props.carConnectorForm.formState?.errors.smartCarConnection?.clientSecret?.message || ' ')}`}
				/>
			)} />
		</Grid>
	</Grid>;
};

export default SmartCarConnection;