import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountCircle,
  Email,
  LockOutlined,
  Smartphone,
} from '@mui/icons-material';
import { showNotification } from '@/store/app/reducer';
import store from '@/store';
import { setUserContext } from '@/store/auth/reducer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Local imports
import img from '@/assets/img/theme/no-image.png';
import { formSchema } from '../../shared/schemas/registerFormSchema';
import { RegisterFormModel } from '../../shared/models/models';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { EvesButton } from '@/common/components/atoms/button/button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getTenantLogoBySubdomain, registerUser } from '@/services/auth';
import { Logo } from '@/common/components/atoms/logo/logo';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import {
  blobToBase64String,
  getSubdomain,
  parseAccessToken,
} from '@/common/utils/utils';
import Captcha from '../captcha/captcha';
import Config from '@/common/constants/config';

// style imports
import styles from './signUp.module.scss';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    isPasswordVisible: false,
    isRepeatPasswordVisible: false,
    isRepeatPasswordError: '',
    tenantLogo: img,
  });

  const { control, formState, watch, getValues, setValue, trigger } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: new RegisterFormModel(),
    mode: 'onTouched',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subdomain = getSubdomain();

  const { refetch: fetchTenantLogo } = useQuery(
    'tenantLogo',
    () => {
      if (subdomain) {
        return getTenantLogoBySubdomain(subdomain);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if(data){
          if (data?.status >= 400 && data?.status < 500) {
            setStateData((currentData: any) => {
              return {
                ...currentData,
                tenantLogo: img,
              };
            });
          } else if (data) {
            const base64String = await blobToBase64String(data);
            setStateData((currentData) => {
              return {
                ...currentData,
                tenantLogo: base64String,
              };
            });
          }
        }
       
      },
    }
  );

  useEffect(() => {
    fetchTenantLogo();
  }, []);

  // password visibility toggle
  const handleClickShowPassword = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isPasswordVisible: !currentData.isPasswordVisible,
      };
    });
  };

  const handleClickShowRepeatPassword = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isRepeatPasswordVisible: !currentData.isRepeatPasswordVisible,
      };
    });
  };

  // grab input values and register user
  const onSignUp = () => {
    const registerForm = getValues();

    // to keep the user email intact in input after user registers succesfully
    const userEmail = registerForm.email;

    registerUser(
      registerForm.name,
      registerForm.firstName,
      registerForm.email,
      registerForm.mobile,
      registerForm.password,
      registerForm.repeatPassword
      
    ).then((res: any) => {
      if (res.status === 'Success') {
        // when registration is sucessful
        if (subdomain === '') {
          // super user success
          store.dispatch(
            showNotification({
              showNotification: true,
              message: `${translate(
                'authentication.register_super_user_success'
              )}`,
              type: 'success',
            })
          );
        } else {
          // normal user success
          store.dispatch(
            showNotification({
              showNotification: true,
              message: `${translate('authentication.register_user_success')}`,
              type: 'success',
            })
          );
        }

        // if registration success, re-direct to login screen, email-input true
        navigate('/login');
        setValue('email', userEmail);
      } else {
        // handle expected errors below
        store.dispatch(
          showNotification({
            showNotification: true,
            message: `${translate('general.unexpected_error_backend')}`,
            type: 'error',
          })
        );
      }

      if (res.status === 404 || res.status === 500) {
        store.dispatch(
          showNotification({
            showNotification: true,
            message: `${translate('general.unexpected_error_backend')}`,
            type: 'error',
          })
        );
        return;
      }

      if (res.status === 510) {
        store.dispatch(
          showNotification({
            showNotification: true,
            message: `${translate('general.email_already_exists')}`,
            type: 'error',
          })
        );
        return;
      }

      // parse the token
      const userInfo = parseAccessToken(res.token);

      // authenticate user by return payload
      dispatch(
        setUserContext({
          isAuthenticated: true,
          userInfo,
        })
      );

      // save user info & token in browser storage
      localStorage.setItem('access_token', res.token);
      localStorage.setItem('user_info', JSON.stringify(userInfo));
    });
  };

  const { errors, isValid } = formState;
  const isAgreementAccepted = watch('acceptAgreement');

  const canSubmitForm = isValid && isAgreementAccepted;

  // capturing token from captcha component and setting it to registerForm.captcha
  const onCapResolve = (capToken: string) => {
    setValue('captcha', capToken);
  };

  const capKey = Config.user.captchaSiteKey;

  const validatePasswords = () => {
    trigger('password');
    if (formState.touchedFields['repeatPassword']) trigger('repeatPassword');
  };

  const validateRepeatPasswordErrorMessage = () => {
    if (
      errors.password &&
      getValues().password === getValues().repeatPassword
    ) {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          repeatPasswordErrorMessage: '',
        };
      });
    }
  };

  useEffect(() => {
    const password = getValues().password;
    const repeatPassword = getValues().repeatPassword;
    let isRepeatPasswordError: any;
    if (errors.repeatPassword) {
      isRepeatPasswordError = true;
    } else if (
      getValues().password === getValues().repeatPassword &&
      !errors.password
    ) {
      isRepeatPasswordError = false;
    }
    if (formState.touchedFields['repeatPassword']) {
      if (
        (password === repeatPassword && errors?.password) ||
        password !== repeatPassword
      )
        isRepeatPasswordError = true;
    }
    setStateData((currentData: any) => {
      return {
        ...currentData,
        isRepeatPasswordError,
      };
    });
  }, [errors?.repeatPassword, errors?.password]);

  return (
      <div className={styles.register}>
        <div className={styles.registerCard}>
          <div className={styles.registerCardHeader}>
            <Logo src={state.tenantLogo} alt='' />
          </div>
          <h4 className={styles.registerTitle}>{`${translate(
            'authentication.register'
          )}`}</h4>
          <form id='register-form' data-cy='register-form-main'>
            <div>
              <Controller
                name='name'
                control={control}
                key='name'
                render={({ field }) => (
                  <div className={styles.registerFormControl}>
                    <AccountCircle className={styles.signup_icons} />
                    <EvesTextbox
                      {...field}
                      id='name'
                      label={`${translate('users.name')}`}
                      type='text'
                      fullWidth
                      required={true}
                      variant='standard'
                      error={!!errors?.name}
                      helperText={`${translate(errors?.name?.message || ' ')}`}
                      autoFocus
                    />
                  </div>
                )}
              />
            </div>
            <p>&nbsp;</p>
            <div className={styles.registerFormRow}>
              <Controller
                name='firstName'
                control={control}
                key='firstName'
                render={({ field }) => (
                  <div className={styles.registerFormControl}>
                    <AccountCircle className={styles.registerIconInvisible} />
                    <EvesTextbox
                      {...field}
                      id='firstName'
                      label={`${translate('users.first_name')}`}
                      type='text'
                      fullWidth
                      required={true}
                      variant='standard'
                      error={!!errors?.firstName}
                      helperText={`${translate(
                        errors?.firstName?.message || ' '
                      )}`}
                    />
                  </div>
                )}
              />
            </div>

            <div className={styles.registerFormRow}>
              <Controller
                name='email'
                control={control}
                key='email'
                render={({ field }) => (
                  <div className={styles.registerFormControl}>
                    <Email className={styles.signup_icons} />
                    <EvesTextbox
                      {...field}
                      id='email'
                      label={`${translate('users.email')}`}
                      type='text'
                      fullWidth
                      required={true}
                      variant='standard'
                      autoComplete='new-password'
                      error={!!errors?.email}
                      helperText={`${translate(errors?.email?.message || ' ')}`}
                    />
                  </div>
                )}
              />
            </div>
            <div className={styles.registerFormRow}>
              <Controller
                name='mobile'
                control={control}
                key='mobile'
                render={({ field }) => (
                  <div className={styles.registerFormControl}>
                    <Smartphone className={styles.signup_icons} />
                    <EvesTextbox
                      {...field}
                      id='mobile'
                      label={`${translate('users.mobile')}`}
                      type='text'
                      fullWidth
                      required={true}
                      variant='standard'
                      error={!!errors?.mobile}
                      helperText={`${translate(
                        errors?.mobile?.message || ' '
                      )}`}
                    />
                  </div>
                )}
              />
            </div>

            <div className={styles.registerFormRow}>
              <Controller
                name='password'
                control={control}
                key='password'
                render={({ field }) => (
                  <div className={styles.registerFormControl}>
                    <LockOutlined className={styles.signup_icons} />
                    <EvesTextbox
                      {...field}
                      id='password'
                      label={`${translate('authentication.password')}`}
                      type={state.isPasswordVisible ? 'text' : 'password'}
                      fullWidth
                      onChange={(event: any) => {
                        field.onChange(event);
                        validatePasswords();
                      }}
                      required={true}
                      variant='standard'
                      autoComplete='new-password'
                      error={!!errors?.password}
                      helperText={`${translate(
                        errors?.password?.message || ' '
                      )}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            <IconButton onClick={handleClickShowPassword}>
                              {!state.isPasswordVisible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <p>&nbsp;</p>
            <div className={styles.registerFormRow}>
              <Controller
                name='repeatPassword'
                control={control}
                key='repeatPassword'
                render={({ field }) => (
                  <div className={styles.registerFormControl}>
                    <LockOutlined className={styles.registerIconInvisible} />
                    <EvesTextbox
                      {...field}
                      id='repeatPassword'
                      label={`${translate('authentication.repeat_password')}`}
                      type={state.isRepeatPasswordVisible ? 'text' : 'password'}
                      fullWidth
                      onChange={(event: any) => {
                        field.onChange(event);
                        trigger('repeatPassword');
                        validateRepeatPasswordErrorMessage();
                      }}
                      required={true}
                      variant='standard'
                      error={state.isRepeatPasswordError}
                      helperText={`${translate(
                        errors?.repeatPassword?.message || ' '
                      )}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            <IconButton onClick={handleClickShowRepeatPassword}>
                              {!state.isRepeatPasswordVisible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div className={styles.recaptcha_text}>
              {`${translate('general.captcha_text_1')}`}{' '}
              <a href='https://policies.google.com/privacy'>{`${translate(
                'general.captcha_text_2'
              )}`}</a>{' '}
              {`${translate('general.captcha_text_3')}`}{' '}
              <a href='https://policies.google.com/terms'>{`${translate(
                'general.captcha_text_4'
              )}`}</a>
              {`${translate('general.captcha_text_5')}`}
            </div>
            <br></br>
            <div className={styles.registerAgreement}>
              <Controller
                name='acceptAgreement'
                control={control}
                render={({ field }) => (
                  <div data-cy='check-agree'>
                    <FormControlLabel
                      className={styles.registerAgreementLbl}
                      control={<EvesCheckbox {...field} size='small' />}
                      label={
                        <Typography>
                          {`${translate('authentication.accept')}`}{' '}
                          <span style={{ textDecoration: 'underLine' }}>
                            {`${translate('authentication.eula')}`}
                          </span>
                        </Typography>
                      }
                    />
                  </div>
                )}
              />
            </div>
            <div className='hide-cap'>
              <Captcha onCapResolve={onCapResolve} />
            </div>
            <div className={styles.registerCenterRegBtn}>
              <EvesButton
                data-cy='sign-up'
                size='medium'
                onClick={onSignUp}
                disabled={!canSubmitForm}
                id='btnLogin'
                variant='default'
              >
                {`${translate('authentication.sign_up')}`}
              </EvesButton>
            </div>
          </form>
        </div>
      </div>
  );
};

export default SignUp;
