import { EvesButton } from '@/common/components/atoms/button/button';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { DefaultPageSize, StatusCodes } from '@/common/constants/constants';
import { TenantComponents, UserRole } from '@/common/enums/enums';
import { columnsType } from '@/common/models/model';
import { NotifyType } from '@/common/utils/notificationService';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import {
  Delete,
  Edit,
  OpenInNew,
  Refresh,
  Add,
  Save,
  Info,
} from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Card } from '@/common/components/molecules/card/card';
import {
  getSettingsList,
  saveAnalyticsList,
} from '@/services/integrationSettings';
import AnalyticsLink from './components/analytics-link/analyticsLink';
import { handleHttpError, isActive } from '@/common/utils/utils';
import SacSettings from './components/sac/sacSettings';
import { UseFormReturn } from 'react-hook-form';
import { SacSettingModel } from '../../shared/models/integrationSettings';
import { connect } from 'react-redux';
import { formSchema } from '../../shared/schemas/sacSettingsSchema';
import { getAllTimeZones } from '@/common/utils/timeZoneList';
import { useTranslation } from 'react-i18next';

const Analytics = (props: any) => {
  const { t: translate } = useTranslation();
  const sacSettingFields = props.formService as UseFormReturn<
    SacSettingModel,
    any
  >;
  const dataTableRef = useRef<any>();
  const isError = Object.keys(sacSettingFields.formState.errors)?.length == 0;
  const getSacFields = sacSettingFields.getValues();
  const [state, setStateData] = useState({
    limit: DefaultPageSize,
    page: 0,
    sortDescending: false,
    sortFields: '',
    rows: [],
    totalRowsCount: 0,
    selectedPriceId: '',
    canCreate: false,
    projectFields: [],
    showAnalyticsOpen: false,
    saveDisabled: true,
    settingId: '',
    analyticsId: '',
    timezoneListOptions: [],
  } as any);
  const notify: NotifyType = {
    message: '',
    type: 'success',
  };
  const columns: columnsType[] = [
    {
      id: 'actions',
      label: translate('general.actions'),
      minWidth: 20,
      textAlign: 'center',
      maxWidth: 90,
      projectField: 'action',
    },
    {
      id: 'name',
      label: translate('analytics.link.name'),
      minWidth: 80,
      textAlign: 'left',
      maxWidth: 120,
      projectField: 'name',
    },
    {
      id: 'description',
      label: translate('analytics.link.description'),
      minWidth: 200,
      textAlign: 'left',
      maxWidth: 200,
    },
    {
      id: 'role',
      label: translate('analytics.link.role'),
      minWidth: 70,
      textAlign: 'left',
      maxWidth: 120,
    },
    {
      id: 'url',
      label: translate('analytics.link.url'),
      minWidth: 90,
      textAlign: 'left',
      maxWidth: 120,
    },
  ];

  const isAnalyticsComponentActive = isActive(TenantComponents.ANALYTICS);

  const onOpenUrl = (record: any) => {
    window.open(record.url);
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () => translate('general.edit'),
      icon: () => Edit,
      iconColor: 'primary',
      onClick: (record: any) => onEditAnalyticsForm(record),
    },
    {
      title: () => translate('general.open'),
      icon: () => OpenInNew,
      iconColor: 'primary',
      onClick: (record: any) => onOpenUrl(record),
    },
    {
      title: () => translate('general.delete'),
      icon: () => Delete,
      iconColor: 'error',
      onClick: (record: any) => onDeleteItem(record),
    },
  ];

  const loadAssetList = () => {
    const params: any = {
      Identifier: TenantComponents.ANALYTICS,
      Limit: state.limit,
      SortFields: state.sortFields,
      Page: state.page + 1,
    };
    return getSettingsList(params);
  };

  const resetAnalyticsLink = (val: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        analyticsId: val,
      };
    });
  };

  const { refetch: fetchAnalyticsList } = useQuery(
    'AnalyticsList',
    () => {
      return loadAssetList();
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res: any) => {
        if(res){
          const data = res.setting;
        setStateData((currentData) => {
          return {
            ...currentData,
            rows:
              data && data.content && data.content.links
                ? data.content.links
                : [],
            totalRowsCount: res.count,
            settingId: data?.id,
          };
        });
        sacSettingFields.reset({
          mainUrl: data?.content?.sac.mainUrl,
          timezone: data?.content?.sac.timezone,
        });
        }
      },
    }
  );

  useEffect(() => {
    fetchAnalyticsList();
    const timezoneList = getAllTimeZones();
    const timezoneListOptions = timezoneList.map((item) => ({
      label: item,
      value: item,
    }));
    setStateData((currentData: any) => {
      return {
        ...currentData,
        timezoneListOptions: timezoneListOptions,
      };
    });
  }, [state.sortFields, state.page, state.limit]);
  const RoleCell = (row: any) => {
    const roleType: string = row.role;
    let roleTypeMessage: string;
    switch (roleType) {
      case UserRole.ADMIN:
        roleTypeMessage = 'Admin';
        break;
      case UserRole.DEMO:
        roleTypeMessage = 'Demo';
        break;
      case UserRole.ALL_ROLES:
        roleTypeMessage = 'All Roles';
        break;
      case UserRole.ALL_DEFAULT_ROLES:
        roleTypeMessage = 'All Roles';
        break;
      default:
        roleTypeMessage = '';
    }
    return <p>{roleTypeMessage}</p>;
  };

  const onAnalyticsOpen = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showAnalyticsOpen: true,
      };
    });
  };

  const onDialogClose = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showAnalyticsOpen: false,
      };
    });
  };

  const handleRowData = (data: any) => {
    const index = state.rows.findIndex((item) => item.id === data.id);
    if (index === -1) {
      state.rows.push(data);
    } else {
      state.rows[index] = data;
    }
    state.rows.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    const links: any = [];
    for (let index = 0; index < state.rows.length; index++) {
      const link: any = state.rows[index];
      link.id = index.toString();
      links.push(link);
    }
    setStateData((currentData) => {
      return {
        ...currentData,
        totalRowsCount: state.rows?.length,
        saveDisabled: sacSettingFields.formState.isValid ? false : true,
        rows: links,
      };
    });
  };

  const onSaveClick = () => {
    saveAnalytics(state.rows);
  };

  const saveAnalytics = (settings: any) => {
    const settingsToSave: any = {
      id: state.assetId,
      identifier: TenantComponents.ANALYTICS,
      sensitiveData: [],
      content: {
        type: 'sac',
        links: settings,
        sac: {
          mainUrl: getSacFields?.mainUrl,
          timezone: getSacFields?.timezone,
        },
      },
    };

    saveAnalyticsList(state.settingId, settingsToSave).then(
      (res: any) => {
        if (res.status === 'Success') {
          notify.message = `${translate('settings.analytics.update_success')}`;
          notify.type = 'success';
          fetchAnalyticsList();
        } else {
          notify.message = `${translate('settings.analytics.update_error')}`;
          notify.type = 'error';
        }
        props.notificationService?.notify(notify);
      },
      (error) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            notify.message = `${translate(
              'settings.analytics.setting_do_not_exist'
            )}`;
            notify.type = 'error';
            break;
          default:
            handleHttpError(error, `${translate(' unexpected_error_backend')}`);
        }
      }
    );
    setStateData((currentData) => {
      return {
        ...currentData,
        saveDisabled: true,
      };
    });
  };

  const onEditAnalyticsForm = (record: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showAnalyticsOpen: true,
        analyticsId: record.id,
      };
    });
  };

  const onDeleteItem = (record: any) => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('settings.asset.connection.delete_title')}`,
        description: `${translate(
          'settings.asset.connection.delete_confirm'
        ).replace(/{{assetConnectionName}}/, `'${record.name}'`)}`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          setStateData((currentData: any) => {
            const updatedItems = state.rows.filter(
              (item) => item.id !== record.id
            );
            return {
              ...currentData,
              rows: updatedItems,
              totalRowsCount: updatedItems.length,
              saveDisabled: sacSettingFields.formState.isValid ? false : true,
            };
          });
        }
      }
    );
  };

  if (!isAnalyticsComponentActive) {
    const translatedText = `${translate(
      'settings.analytics.description'
    )}`.split(',');
    const description = translatedText
      .map(
        (part, i) =>
          `${part.trim()} ${i !== translatedText.length - 1 ? '<br />' : ''}`
      )
      .join('');
    return (
      <div className='information_block'>
        <Card
          icon={<Info />}
          title={`${translate('settings.information')}`}
          isTransparent={true}
        >
          <div className='information'>
            <p
              data-cy='roamingHubject-content1'
              dangerouslySetInnerHTML={{
                __html: `${description} <a target='__blank' style='text-decoration: underline; color: inherit' href='https://www.sap.com/products/technology-platform/cloud-analytics.html' onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='underline';">Analytics Cloud</a>`,
              }}
            ></p>
          </div>
          <div>
            <p></p>
            <p data-cy='roamingHubject-content2'>
              {`${translate('settings.activation_contact_msg')}`}{' '}
            </p>
          </div>
        </Card>
      </div>
    );
  }
  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };
  return (
    <>
      <Stack direction='row' marginBottom={'40px'}>
        <EvesButton
          startIcon={<Save style={{ fontSize: '22.4px' }} />}
          data-cy='analytics-save-btn'
          color='error'
          onClick={onSaveClick}
          style={{ padding: '8px 24px' }}
          disabled={
            (!(
              sacSettingFields.formState.isValid &&
              sacSettingFields.formState.isDirty
            ) &&
              state.saveDisabled) ||
            !isError
          }
        >{`${translate('general.save')}`}</EvesButton>
      </Stack>
      <SacSettings
        sacSettingFields={sacSettingFields}
        timezoneListOptions={state.timezoneListOptions}
      />
      <Card
        icon={<OpenInNew />}
        title={`${translate('analytics.links')}`}
        isTransparent={true}
        sx={{ marginBottom: '30px' }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          marginBottom={'10px'}
        >
          <EvesButton
            variant='contained'
            startIcon={<Add />}
            onClick={onAnalyticsOpen}
            data-cy='create-btn'
          >{`${translate('general.create')}`}</EvesButton>
          <Tooltip
            title={`${translate('Refresh')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <EvesButton
              onClick={fetchAnalyticsList}
              sx={{
                backgroundColor: '#3D3AD3',
                ':hover': { backgroundColor: '#3D3AD3' },
              }}
            >
              <Refresh />
            </EvesButton>
          </Tooltip>
        </Stack>
        <DataTable
          className='asset-table'
          ref={dataTableRef}
          rows={state.rows}
          height={{ height: 'calc(70vh - 328px)' }}
          columns={columns}
          totalRowsCount={state.totalRowsCount}
          actionsMenu={primaryActions}
          defaultSortField={state.sortFields}
          data-cy='asset-table'
          count={state.totalRowsCount}
          limit={state.limit}
          page={state.page}
          tablePagination={true}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        >
          <RoleCell slot='role' />
        </DataTable>
        {state.showAnalyticsOpen && (
          <AnalyticsLink
            showAnalyticsOpen={state.showAnalyticsOpen}
            onDialogClose={onDialogClose}
            handleRowData={handleRowData}
            state={state}
            resetAnalyticsLink={resetAnalyticsLink}
          />
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(Analytics, {
      schema: formSchema,
      defaultValues: { ...new SacSettingModel() },
    })
  )
);
