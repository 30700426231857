import React from 'react';
import styles from './logo.module.scss';

export function Logo(props: any) {
	return (
		<div className={styles.logo}>
			<img className={styles.logo__img} src={props.src} alt={props.alt} />
		</div>			
	);
}
