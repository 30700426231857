import { IMenuItem } from '@/common/models/menuItem';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './../../organisms/sidebar/sidebar';
import styles from './main.module.scss';
import { useMediaQuery, useTheme } from '@mui/material';

export const Main = (props: { menuItems: IMenuItem[] }) => {
   const [isSideBarOpen, setIsSideBarOpen] = useState(true);
   const theme = useTheme();
   const selfToggle = useMediaQuery(theme.breakpoints.down(990));
   const sideBarOpenToggle = () => {
      setIsSideBarOpen(!isSideBarOpen);
   };

   useEffect(() => {
      if (selfToggle) {
         setIsSideBarOpen(false);
      } else {
         setIsSideBarOpen(true);
      }
   }, [selfToggle]);

   return (
      <div className={styles.main}>
         <Sidebar
            menuItems={props.menuItems}
            onToggle={sideBarOpenToggle}
            isOpen={isSideBarOpen}
         />
         {/* <div className={styles.mainBody}> */}
         <div
            id='main_body_id'
            className={`${styles.mainBody} ${
               isSideBarOpen ? styles.mainBodyShrink : ''
            }`}
         >
            <Outlet />
         </div>
      </div>
   );
};
