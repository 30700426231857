export function IconNoConnector(props: any) {
  const height = props.height || '22px';
  const width = props.width || '22px';
  const color = props.color || '#fff';
  return (
    <>
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 1280.000000 1280.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          transform='translate(0.000000,1280.000000) scale(0.100000,-0.100000)'
          fill={color}
          stroke='none'
        >
          <path
            d='M6145 12790 c-38 -5 -144 -13 -235 -20 -790 -56 -1574 -267 -2299
			-621 -524 -255 -951 -537 -1401 -923 -135 -116 -520 -501 -636 -636 -386 -450
			-668 -877 -923 -1401 -353 -724 -565 -1509 -621 -2294 -6 -88 -15 -201 -21
			-252 -12 -117 -12 -369 0 -486 6 -51 15 -164 21 -252 56 -785 268 -1570 621
			-2294 255 -524 537 -951 923 -1401 116 -135 501 -520 636 -636 450 -386 877
			-668 1401 -923 724 -353 1509 -565 2294 -621 88 -6 201 -15 252 -21 117 -12
			369 -12 486 0 51 6 164 15 252 21 785 56 1570 268 2294 621 524 255 951 537
			1401 923 135 116 520 501 636 636 386 450 668 877 923 1401 353 724 565 1509
			621 2294 6 88 15 201 21 252 12 117 12 369 0 486 -6 51 -15 164 -21 252 -56
			786 -269 1571 -621 2294 -255 524 -537 951 -923 1401 -116 135 -501 520 -636
			636 -448 385 -875 666 -1395 920 -729 355 -1501 565 -2295 624 -80 6 -192 15
			-250 20 -120 11 -402 11 -505 0z m655 -1001 c948 -65 1907 -405 2688 -953 188
			-132 332 -247 332 -265 0 -21 -7570 -7591 -7592 -7591 -17 0 -170 196 -300
			385 -523 762 -846 1686 -918 2630 -14 180 -14 630 0 810 125 1627 964 3095
			2305 4032 844 590 1857 929 2875 962 233 7 401 5 610 -10z m3892 -2106 c637
			-842 1000 -1778 1093 -2820 18 -199 21 -665 5 -868 -125 -1627 -964 -3095
			-2305 -4032 -705 -493 -1528 -811 -2387 -922 -240 -32 -412 -42 -698 -42 -146
			0 -328 5 -405 11 -863 66 -1682 331 -2419 782 -247 151 -596 407 -596 437 0
			21 7570 7591 7592 7591 9 0 60 -57 120 -137z'
          />
        </g>
      </svg>
    </>
  );
}
