/* eslint-disable no-debugger */
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Schneider = (props: any) => {
	const { t: translate } = useTranslation();
	return (
		<>
			<Grid item xs={6}>	
				<Controller name="schneiderConnection.user" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="user" label={`${translate('settings.asset.connection.user')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['schneiderConnection.user']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.schneiderConnection?.user}
						helperText={`${translate(props.assetForm.formState?.errors.schneiderConnection?.user?.message || ' ')}`}
					/>
				)} />
			</Grid>	
			<Grid item xs={6}>	
				<Controller name="schneiderConnection.password" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="password" label={`${translate('settings.asset.connection.password')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['schneiderConnection.password']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.schneiderConnection?.password}
						helperText={`${translate(props.assetForm.formState?.errors.schneiderConnection?.password?.message || ' ')}`}
					/>
				)} />
			</Grid>			</>
	);
};

export default Schneider;