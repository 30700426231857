import EvesSelect from '@/common/components/atoms/select/select';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { CarConnectorConnectionType } from '@/common/enums/enums';
import { withContext } from '@/common/utils/withContext';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import Mercedes from './mercedes';
import Tronity from './tronity';
import { useTranslation } from 'react-i18next';
import TargaConnection from './targaTelematics';
import SmartCarConnection from './smartCar';

const CarConnectorForm = (props: any) => {
  const { t: translate } = useTranslation();
  const carConnectorForm = props.carConnectorForm;
  const type = carConnectorForm.watch('type');
  const types = [
    {
      value: CarConnectorConnectionType.MERCEDES,
      label: `${translate('settings.car_connector.types.mercedes')}`,
    },
    {
      value: CarConnectorConnectionType.TRONITY,
      label: `${translate('settings.car_connector.types.tronity')}`,
    },
    {
      value: CarConnectorConnectionType.TARGA,
      label: `${translate('settings.car_connector.types.targa')}`,
    },
    {
      value: CarConnectorConnectionType.SMART,
      label: `${translate('settings.car_connector.types.smartCar')}`,
    },
  ];

  const setConnectionValue = (value: any) => {
    if (value === 'mercedes') {
      carConnectorForm.setValue('mercedesConnection.mercedesConnection', true);
      carConnectorForm.setValue('tronityConnection.tronityConnection', false);
      carConnectorForm.setValue('targaTelematicsConnection.targaTelematicsConnection', false);
      carConnectorForm.setValue('smartCarConnection.smartCarConnection', false);
    } else if (value === 'targaTelematics') {
      carConnectorForm.setValue('mercedesConnection.mercedesConnection', false);
      carConnectorForm.setValue('tronityConnection.tronityConnection', false);
      carConnectorForm.setValue('targaTelematicsConnection.targaTelematicsConnection', true);
      carConnectorForm.setValue('smartCarConnection.smartCarConnection', false);
    } else if (value === 'smartCar') {
      carConnectorForm.setValue('mercedesConnection.mercedesConnection', false);
      carConnectorForm.setValue('tronityConnection.tronityConnection', false);
      carConnectorForm.setValue('targaTelematicsConnection.targaTelematicsConnection', false);
      carConnectorForm.setValue('smartCarConnection.smartCarConnection', true);
    } else {
      carConnectorForm.setValue('mercedesConnection.mercedesConnection', false);
      carConnectorForm.setValue('tronityConnection.tronityConnection', true);
      carConnectorForm.setValue('targaTelematicsConnection.targaTelematicsConnection', false);
      carConnectorForm.setValue('smartCarConnection.smartCarConnection', false);
    }
  };

  const handleNameValidation = () => carConnectorForm.trigger('name');

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={carConnectorForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id="name"
                label={`${translate(
                  'settings.car_connector.connection.name'
                )} *`}
                autoFocus={true}
                fullWidth
                disabled={props.isDisabled}
                variant="standard"
                error={!!carConnectorForm.formState?.errors.name}
                helperText={`${translate(
                  carConnectorForm.formState?.errors.name?.message || ' '
                ).replace(/{{length}}/g, `'${100}'`)}`}
                onChange={(e: any) => {
                  field.onChange(e);
                  handleNameValidation();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={carConnectorForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id="car-connector-description"
                label={`${translate(
                  'settings.car_connector.connection.description'
                )} *`}
                data-cy="User-filter"
                type="text"
                variant="standard"
                error={!!carConnectorForm.formState?.errors.description}
                helperText={`${translate(
                  carConnectorForm.formState?.errors.description?.message || ' '
                )}`}
                onChange={(event: any) => {
                  field.onChange(event);
                  carConnectorForm.trigger('description');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="type"
            control={carConnectorForm.control}
            render={({ field }) => (
              <EvesSelect
                label={`${translate(
                  'settings.car_connector.connection.type'
                )} *`}
                options={types}
                {...field}
                error={!!carConnectorForm.formState?.errors?.type}
                helperText={`${translate(
                  carConnectorForm.formState?.errors.type?.message || ' '
                )}`}
                onChange={(event: any) => {
                  field.onChange(event);
                  (value: any) => carConnectorForm.setValue('type', value);
                  setConnectionValue(event.target.value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {(type === 'mercedes' && (
        <Mercedes carConnectorForm={carConnectorForm} />
      )) ||
        (type === 'tronity' && (
          <Tronity carConnectorForm={carConnectorForm} />
        )) ||
        (type === 'targaTelematics' && (
          <TargaConnection carConnectorForm={carConnectorForm} />
        )) ||
        (type === 'smartCar' && <SmartCarConnection carConnectorForm={carConnectorForm} />)}
    </>
  );
};

export default withContext(CarConnectorForm);
