import { lazy, Suspense } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import '@/assets/scss/main.scss';
import ProtectedRoute from './ProtectedRoute';
import { Main } from '@/common/components/templates/main/main';
import { MenuItems } from '@/common/constants/menuConfig';
import PermissionRoleRoute from './PermissionRoleRoute';
import { Loader } from '@/common/components/atoms/loader/loader';
import UserInError from '../user/components/inError';
import TransactionsInProgress from '../sessions/components/inProgress';
import TrasactionsHistory from '../sessions/components/history';
import TrasactionsInError from '../sessions/components/inError';
import Consumption from '../statistics/components/consumption';
import Refunding from '../integrationSettings/components/refunding';
import Pricing from '../integrationSettings/components/pricing';
import Billing from '../integrationSettings/components/billing';
import SignUp from '../auth/components/signUp/signUp';
import SmartCharging from '../integrationSettings/components/smartCharging';
import ForgotPassword from '../auth/components/forgotPassword/forgotPasswordV2';
import CarConnector from '../integrationSettings/components/carConnector';
import Asset from '../integrationSettings/components/asset';
import Analytics from '../integrationSettings/components/analytics';
import DefinePassword from '../auth/components/definePassword/definePassword';
import VerifyEmail from '../auth/components/verifyEmail';
import EnergyStorage from '../energyStorage';
import ElectricVehicles from '../car/components/electricVehicles';
import { useSelector } from 'react-redux';
import { IUserContextModel } from '@/store/auth/types';
import Gridify from '../gridify';
import GridifyInProgress from '../gridify/components/inProgress';
import DemandInHistory from '../gridify/components/demandView';
import PowerManagement from '../gridify/components/powerManagement';
import SigninV2 from '../auth/components/signin/signinV2';
import ChargerConfiguration from '../gridify/components/chargerConfiguration';
import AccountOnBoarding from '../auth/components/account-boarding';

const Dashboard = lazy(() => import('../dashboard'));
const DashboardContent = lazy(
  () => import('../dashboard/components/dashboard')
);
const Fleet = lazy(() => import('../dashboard/components/fleet'));
const Telematics = lazy(
  () => import('../dashboard/components/telematics/components/telematics/index')
);
const Organization = lazy(() => import('../organization'));
const Car = lazy(() => import('../car'));
const User = lazy(() => import('../user'));
const Logs = lazy(() => import('../logs'));
const RfidCard = lazy(() => import('../rfidCard'));
const RFIDCardList = lazy(() => import('../rfidCard/components/rfidCard'));
const ExpiringSoonList = lazy(
  () => import('../rfidCard/components/expiringSoon')
);
const Sessions = lazy(() => import('../sessions'));
const Profile = lazy(() => import('../profile'));
const Tenant = lazy(() => import('../tenant'));
const ChargingStation = lazy(() => import('../chargingStation'));
const Assets = lazy(() => import('../assets'));
const EnergyStoragesList = lazy(
  () => import('../energyStorage/components/energyStorages')
);
const CompanyList = lazy(() => import('../organization/components/company'));
const SiteList = lazy(() => import('../organization/components/site'));
const SiteAreaList = lazy(() => import('../organization/components/siteArea'));
const Catalog = lazy(() => import('../car/components/catalog'));
const UserList = lazy(() => import('../user/components/userList'));
const ChargingStationList = lazy(
  () => import('../chargingStation/components/chargingStation')
);
const ChargingPlan = lazy(
  () => import('../chargingStation/components/chargingPlan')
);
const OnBoardNewStation = lazy(
  () => import('../chargingStation/components/onBoardStation')
);
const ChargingStationInError = lazy(
  () => import('../chargingStation/components/inError')
);
const NewChargingStationList = lazy(
  () => import('../chargingStation/components/newchargingStation')
);
const AssetInError = lazy(() => import('../assets/components/inError'));
const AssetInAsset = lazy(() => import('../assets/components/chargingStation'));

const TechnicalSetting = lazy(() => import('../technicalSetting'));
const CryptoSetting = lazy(
  () => import('../technicalSetting/components/cryptoSetting')
);
const UserSetting = lazy(
  () => import('../technicalSetting/components/userSetting')
);
const TechnicalOrganization = lazy(
  () => import('../technicalSetting/components/organization')
);

const IntegrationSetting = lazy(() => import('../integrationSettings'));
const IntegrationRoaming = lazy(
  () => import('../integrationSettings/components/roaming')
);
const RoamingHubject = lazy(
  () => import('../integrationSettings/components/roamingHubject')
);

const ChargingStationTemplate = lazy(
  () => import('../chargingStationTemplate')
);
const ChargingStationTemplateList = lazy(
  () => import('../chargingStationTemplate/components/chargingStationTemplate')
);

const Invoice = lazy(() => import('../invoice'));
const InvoiceList = lazy(() => import('../invoice/components/invoices/index'));
const TransactionList = lazy(
  () => import('../invoice/components/transactions/index')
);
const TrasactionsRefund = lazy(
  () => import('../invoice/components/refund/index')
);

const TransferList = lazy(
  () => import('../invoice/components/transfers/index')
);



const Statistics = lazy(() => import('../statistics'));
const Usage = lazy(() => import('../statistics/components/usage'));
const Inactivity = lazy(() => import('../statistics/components/inactivity'));
const StatisticSessions = lazy(
  () => import('../statistics/components/sessions')
);
const StatisticPricing = lazy(() => import('../statistics/components/pricing'));

function App() {
  const isSuperAdmin = (role = false) => {
    const authContext: IUserContextModel = useSelector(
      (state: any) => state.userContext
    );
    return role
      ? authContext.userInfo?.role
      : authContext.userInfo?.scopes?.includes(`${'Car'}:${'List'}`);
  };

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<Main menuItems={MenuItems} />}>
            <Route
              element={
                <Navigate
                  to={isSuperAdmin(true) == 'S' ? '/tenants' : '/invoices'}
                  replace
                />
              }
              path='/'
            ></Route>
            <Route element={<PermissionRoleRoute />}>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Dashboard />
                  </Suspense>
                }
                path='/dashboard'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <DashboardContent />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Telematics />
                    </Suspense>
                  }
                  path='telematics'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Fleet />
                    </Suspense>
                  }
                  path='fleet'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Organization />
                  </Suspense>
                }
                path='/organizations'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <CompanyList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <CompanyList />
                    </Suspense>
                  }
                  path='companies'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <SiteList />
                    </Suspense>
                  }
                  path='sites'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <SiteAreaList />
                    </Suspense>
                  }
                  path='site-areas'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Car />
                  </Suspense>
                }
                path='/cars'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      {isSuperAdmin() === true ? (
                        <ElectricVehicles />
                      ) : (
                        <Catalog />
                      )}
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ElectricVehicles />
                    </Suspense>
                  }
                  path='electric-vehicles'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Catalog />
                    </Suspense>
                  }
                  path='catalogs'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <User />
                  </Suspense>
                }
                path='/users'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserList />
                    </Suspense>
                  }
                  path='user-lists'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserInError />
                    </Suspense>
                  }
                  path='user-in-error'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Logs />
                  </Suspense>
                }
                path='logs'
              ></Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <RfidCard />
                  </Suspense>
                }
                path='tags'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <RFIDCardList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <RFIDCardList />
                    </Suspense>
                  }
                  path='tags-list'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ExpiringSoonList />
                    </Suspense>
                  }
                  path='expiring-tags-list'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Sessions />
                  </Suspense>
                }
                path='transactions'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionsInProgress />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionsInProgress />
                    </Suspense>
                  }
                  path='/transactions/in-progress'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsHistory />
                    </Suspense>
                  }
                  path='/transactions/history'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsInError />
                    </Suspense>
                  }
                  path='/transactions/in-error'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Gridify />
                  </Suspense>
                }
                path='gridify'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <DemandInHistory />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <GridifyInProgress />
                    </Suspense>
                  }
                  path='/gridify/in-progress'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <DemandInHistory />
                    </Suspense>
                  }
                  path='/gridify/demand-view'
                ></Route>
                 <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <PowerManagement />
                    </Suspense>
                  }
                  path='/gridify/power-management'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargerConfiguration />
                    </Suspense>
                  }
                  path='/gridify/charger-configuration'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsHistory />
                    </Suspense>
                  }
                  path='/gridify/history'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsInError />
                    </Suspense>
                  }
                  path='/gridify/in-error'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Invoice />
                  </Suspense>
                }
                path='invoices'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionList />
                    </Suspense>
                  }
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <InvoiceList />
                    </Suspense>
                  }
                  path='/invoices/invoice-lists'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransferList />
                    </Suspense>
                  }
                  path='/invoices/transfers'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsRefund />
                    </Suspense>
                  }
                  path='/invoices/refund'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionList />
                    </Suspense>
                  }
                  path='/invoices/in-transactions'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Profile />
                  </Suspense>
                }
                path='/profile'
              ></Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Tenant />
                  </Suspense>
                }
                path='tenants'
              ></Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <ChargingStation />
                  </Suspense>
                }
                path='/charging-stations'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <NewChargingStationList />
                    </Suspense>
                  }
                  path='new-charging-stations-list'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationList />
                    </Suspense>
                  }
                  path='charging-stations-list'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingPlan />
                    </Suspense>
                  }
                  path='charging-plans'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationInError />
                    </Suspense>
                  }
                  path='charging-in-error'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <OnBoardNewStation />
                    </Suspense>
                  }
                  path='onboard-new-station'
                ></Route>
              </Route>

              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Assets />
                  </Suspense>
                }
                path='/assets'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <AssetInAsset />
                    </Suspense>
                  }
                ></Route>

                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <AssetInError />
                    </Suspense>
                  }
                  path='assets-in-error'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <EnergyStorage />
                  </Suspense>
                }
                path='/energy-storage'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <EnergyStoragesList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationList />
                    </Suspense>
                  }
                  path='charging-stations-list'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingPlan />
                    </Suspense>
                  }
                  path='charging-plans'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationInError />
                    </Suspense>
                  }
                  path='charging-in-error'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <OnBoardNewStation />
                    </Suspense>
                  }
                  path='onboard-new-station'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Car />
                  </Suspense>
                }
                path='/cars'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Catalog />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Catalog />
                    </Suspense>
                  }
                  path='catalogs'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <User />
                  </Suspense>
                }
                path='/users'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserList />
                    </Suspense>
                  }
                  path='user-lists'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserInError />
                    </Suspense>
                  }
                  path='user-in-error'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Logs />
                  </Suspense>
                }
                path='logs'
              ></Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <RfidCard />
                  </Suspense>
                }
                path='tags'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <RFIDCardList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <RFIDCardList />
                    </Suspense>
                  }
                  path='tags-list'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Sessions />
                  </Suspense>
                }
                path='transactions'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionsInProgress />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionsInProgress />
                    </Suspense>
                  }
                  path='/transactions/in-progress'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsHistory />
                    </Suspense>
                  }
                  path='/transactions/history'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsInError />
                    </Suspense>
                  }
                  path='/transactions/in-error'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Invoice />
                  </Suspense>
                }
                path='invoices'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <InvoiceList />
                    </Suspense>
                  }
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <InvoiceList />
                    </Suspense>
                  }
                  path='/invoices/invoice-lists'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransferList />
                    </Suspense>
                  }
                  path='/invoices/transfers'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TransactionList />
                    </Suspense>
                  }
                  path='/invoices/in-transactions'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TrasactionsRefund />
                    </Suspense>
                  }
                  path='/invoices/refund'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Profile />
                  </Suspense>
                }
                path='/profile'
              ></Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Tenant />
                  </Suspense>
                }
                path='tenants'
              ></Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <ChargingStation />
                  </Suspense>
                }
                path='/charging-stations'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationList />
                    </Suspense>
                  }
                  path='charging-stations-list'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingPlan />
                    </Suspense>
                  }
                  path='charging-plans'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationInError />
                    </Suspense>
                  }
                  path='charging-in-error'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <OnBoardNewStation />
                    </Suspense>
                  }
                  path='onboard-new-station'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <Statistics />
                  </Suspense>
                }
                path='statistics'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Consumption />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Consumption />
                    </Suspense>
                  }
                  path='consumption'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Usage />
                    </Suspense>
                  }
                  path='usage'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Inactivity />
                    </Suspense>
                  }
                  path='inactivity'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <StatisticSessions />
                    </Suspense>
                  }
                  path='transactions'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <StatisticPricing />
                    </Suspense>
                  }
                  path='pricing'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <IntegrationSetting />
                  </Suspense>
                }
                path='integration-settings'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <IntegrationRoaming />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <IntegrationRoaming />
                    </Suspense>
                  }
                  path='roaming'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <RoamingHubject />
                    </Suspense>
                  }
                  path='roaming-hubject'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Refunding />
                    </Suspense>
                  }
                  path='refunding'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Pricing />
                    </Suspense>
                  }
                  path='pricing'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Billing />
                    </Suspense>
                  }
                  path='billing'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Analytics />
                    </Suspense>
                  }
                  path='analytics'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <SmartCharging />
                    </Suspense>
                  }
                  path='smartCharging'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <Asset />
                    </Suspense>
                  }
                  path='asset'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <CarConnector />
                    </Suspense>
                  }
                  path='carConnector'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <TechnicalSetting />
                  </Suspense>
                }
                path='technical-settings'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <TechnicalOrganization />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <CryptoSetting />
                    </Suspense>
                  }
                  path='crypto-settings'
                ></Route>
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <UserSetting />
                    </Suspense>
                  }
                  path='user-settings'
                ></Route>
              </Route>
              <Route
                element={
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <ChargingStationTemplate />
                  </Suspense>
                }
                path='/charging-station-templates'
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationTemplateList />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <Suspense fallback={<Loader showLoader={true} />}>
                      <ChargingStationTemplateList />
                    </Suspense>
                  }
                  path='/charging-station-templates-list'
                ></Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route element={<SigninV2 />} path='/login'></Route>
        <Route element={<SignUp />} path='/register'></Route>
        <Route element={<ForgotPassword />} path='/reset-password'></Route>
        <Route element={<DefinePassword />} path='/define-password'></Route>
        <Route element={<VerifyEmail />} path='/verify-email'></Route>
        <Route element={<AccountOnBoarding />} path='/auth/account-onboarding'></Route>

      </Routes>
    </Router>
  );
}

export default App;
