import { httpClient } from './httpClient';
import { CatalogModel, ElectricVehicleModel } from './models/cars';
import { IResponseModel } from './models/responseModel';

export const getElectricVehicles = (params: any) => {
	return httpClient.get<IResponseModel<ElectricVehicleModel>>(
		'/api/cars',
		params
	);
};

export const getElectricVehicleById = (electricVehicleId: string) => {
	return httpClient.get<IResponseModel<ElectricVehicleModel>>(
		`/api/cars/${electricVehicleId}`);
};

export const getCatalog = (params: any) => {
	return httpClient.get<IResponseModel<CatalogModel>>(
		'/api/car-catalogs',
		params
	);
};

export const getCatalogById = (catalogId: string) => {
	return httpClient.get<IResponseModel<CatalogModel>>(
		`/api/car-catalogs/${catalogId}`);
};

export const getImagesCatalogById = (catalogId: string) => {
	return httpClient.get<IResponseModel<CatalogModel>>(
		`/api/car-catalogs/${catalogId}/images?Limit=2`);
};

export const addElectricVehicle = (electricVehicle: any) => {
	return httpClient.post('/api/cars', electricVehicle);
};

export const updateElectricVehicle = (formModel: any, id: any,) => {
	return httpClient.put('/api/cars/' + id, formModel);
};

export const deleteElectricVehicle = (electricVehicleId: any) => {
	return httpClient.delete('/api/cars/'+electricVehicleId);
};

export const syncAllCars = () => {
	return httpClient.put('/api/car-catalogs/action/synchronize',{});
};