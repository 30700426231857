import { UseFormReturn } from 'react-hook-form';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { connect } from 'react-redux';
import { Business } from '@mui/icons-material';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { formSchema } from '@/modules/user/shared/schemas/paymentFormSchema';
import AddConnectdAccount from './addConnectdAccount';
import { ConnectedAccountModel, IConnectedAccountFormPropsModel } from '@/modules/integrationSettings/shared/models/integrationSettings';
import { useMutation } from 'react-query';
import { NotifyType } from '@/common/utils/notificationService';
import { createBillingAccount } from '@/services/integrationSettings';
import { useTranslation } from 'react-i18next';

const connnectedAccountForm = (props: IConnectedAccountFormPropsModel) => {
	const { t: translate } = useTranslation();
	const connectedAccountForm: any = props.formService as UseFormReturn<ConnectedAccountModel, any>;
	const superAdmin = props.superAdmin;
	const tabsConfig = [
		{
			name: `${translate('accounts.list.add_account')}`,
			icon: <Business />,
			component: <AddConnectdAccount connectedAccountForm={connectedAccountForm} superAdmin={superAdmin} tenantId={props?.tenantId}/>,
			visible: true
		},
	];
	const { mutate: saveConnectedUser } = useMutation(
		(billingAccount: any) => createBillingAccount(billingAccount),
		{
			onSuccess: (data: any) => {
				const notify: NotifyType = {
					message: '',
					type: 'success',
				};
				if (data.status === 'Success') {
					notify.message = `${translate('accounts.message.create_success')}`;
					notify.type = 'success';
					props.notificationService?.notify(notify);
					props.onFormClose();
					props.fetchAllConnectedAccounts();
				} else {
					notify.message = `${translate('accounts.message.create_error')}`;
					notify.type = 'error';
					props.notificationService?.notify(notify);
					props.onFormClose();
					props.fetchAllConnectedAccounts();
				}
			},
		}
	);

	const onFormSubmit = (formData: any) => {
		const billingAccount = {
			id: formData.id,
			companyName: formData.companyName,
			businessOwnerID: formData.userID
		};
		saveConnectedUser(billingAccount);
	};

	const onHandleClose = () => {
		if (connectedAccountForm.formState.isDirty === true) {
			//based on the formValidity confirmation Dialogtype changes
			if (connectedAccountForm.formState.isValid) {
				props.dialogService?.showConfirm(
					{
						confirmType: 'DIRTY_CHANGE_CLOSE',
					},
					(result) => {
						if (result === 'SAVE') {
							connectedAccountForm.handleSubmit(onFormSubmit)();
						} else if (result === 'CLOSE') {
							props.onFormClose();
							props.resetConnectedAccountForm('');
						}
					}
				);
			} else {
				props.dialogService?.showConfirm(
					{
						confirmType: 'INVALID_CHANGE_CLOSE',
					},
					(result: string) => {
						if (result === 'CLOSE') {
							props.onFormClose();
							props.resetConnectedAccountForm('');
						}
					}
				);
			}
		} else {
			props.onFormClose();
			props.resetConnectedAccountForm('');
		}
	};
	const isSaveEnable= connectedAccountForm.formState.isValid;
	return (
		<>
			<EvesModal maxHeight={'275px'} maxWidth={'900px'}
				isOpen={props.showConnectedAccountForm}
				onHandleSave={superAdmin ? ()=>props.handleAdminConnectedAccount(connectedAccountForm) : connectedAccountForm.handleSubmit(onFormSubmit)}
				onHandleClose={onHandleClose}
				isSaveDisabled={!(isSaveEnable)}
				tabsConfig={tabsConfig}
				modalType='tabs'
				isSaveHidden={false}
			>
			</EvesModal >
		</>
	);
};

const mapStateToProps = (state: any) => ({
	userInfo: state.userContext.userInfo
});

export default connect(mapStateToProps)(withContext(withUseFormHook(connnectedAccountForm, {
	schema: formSchema,
	defaultValues: { ...new ConnectedAccountModel() }
})
));