export class AssetModel {
	id:string='';
	name: string = '';
	description:string='';
	type:string='';
	refreshIntervalMins:number=1;
	schneiderConnection?:any={user:'',password:''};
	witConnection?:any={authenticationUrl:'',clientId:'',clientSecret:'',user:'',password:''};
	greencomConnection?:any={clientId:'',clientSecret:''};
	lacroixConnection?:any={user:'',password:''};
	iothinkConnection?:any={user:'',password:''};
   
	constructor (input: AssetModel) {
		Object.assign(this, input);
	}
}