export function IconType1CcsNew(props: any) {
  const height = props.height || '20px';
  const width = props.width || '20px';
  const color = props.color || '#ffffff';

  return (
    <>
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        height={height}
        width={width}
        viewBox='0 0 208.000000 284.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          transform='translate(0.000000,284.000000) scale(0.100000,-0.100000)'
          fill={color}
          stroke='none'
        >
          <path
            d='M873 2825 c-456 -83 -789 -457 -810 -910 -9 -205 37 -390 143 -567
			92 -154 281 -316 447 -384 59 -24 67 -31 67 -54 0 -27 -2 -27 -74 -32 -176
			-11 -326 -140 -372 -317 -46 -182 52 -389 225 -474 l66 -32 485 0 485 0 57 27
			c78 36 132 81 175 146 143 216 68 494 -164 609 -65 32 -86 37 -164 41 -88 4
			-89 5 -89 31 0 23 9 29 73 56 154 63 343 221 434 362 80 125 132 270 153 426
			13 94 1 242 -30 363 -55 215 -211 434 -400 561 -198 133 -472 191 -707 148z
			m362 -190 c33 -9 107 -39 165 -67 92 -45 116 -63 195 -142 165 -167 237 -340
			237 -571 1 -232 -112 -469 -290 -610 -85 -68 -240 -141 -341 -159 -427 -80
			-811 155 -932 569 -19 67 -23 102 -23 215 0 120 3 144 28 220 73 225 209 384
			414 485 65 32 177 69 237 79 57 9 250 -3 310 -19z m288 -1895 c247 -92 265
			-426 30 -542 l-68 -33 -430 0 c-425 0 -431 0 -487 23 -108 44 -180 151 -180
			271 -1 102 40 183 123 244 73 54 88 56 541 56 394 1 420 0 471 -19z'
          />
          <path
            d='M880 2474 c-218 -57 -399 -240 -455 -459 -22 -86 -19 -224 6 -315 59
			-216 243 -394 459 -444 83 -20 207 -20 290 0 221 52 408 236 465 459 18 71 21
			208 5 285 -15 71 -79 203 -126 260 -87 104 -232 194 -352 219 -73 15 -226 13
			-292 -5z m-6 -161 c58 -41 86 -94 86 -166 0 -119 -84 -207 -200 -207 -218 0
			-280 304 -80 395 54 24 143 14 194 -22z m523 12 c139 -69 153 -259 25 -349
			-81 -58 -196 -43 -266 34 -71 79 -73 187 -3 267 65 74 156 92 244 48z m-697
			-514 c94 -50 98 -196 7 -251 -101 -62 -236 21 -224 137 11 106 123 165 217
			114z m808 -1 c52 -32 74 -76 69 -139 -3 -49 -8 -58 -46 -94 -41 -38 -44 -39
			-102 -35 -63 5 -97 26 -125 80 -64 122 85 260 204 188z m-373 -154 c21 -11 52
			-40 69 -63 25 -36 30 -53 30 -106 1 -54 -4 -71 -29 -112 -84 -136 -289 -125
			-356 20 -24 53 -24 126 0 174 24 46 82 99 119 107 15 3 32 7 37 9 21 8 92 -8
			130 -29z'
          />
          <path
            d='M710 2190 c-41 -41 -10 -110 50 -110 37 0 70 31 70 65 0 34 -33 65
			-70 65 -17 0 -39 -9 -50 -20z'
          />
          <path
            d='M1257 2192 c-22 -25 -21 -75 1 -95 42 -39 115 -8 115 48 0 57 -79 88
			-116 47z'
          />
          <path
            d='M586 1718 c-21 -30 -20 -51 3 -72 27 -25 72 -17 90 15 13 24 13 29
			-2 53 -22 34 -69 36 -91 4z'
          />
          <path
            d='M1397 1722 c-35 -38 -10 -92 42 -92 15 0 32 5 39 12 17 17 15 64 -4
			82 -21 21 -57 20 -77 -2z'
          />
          <path
            d='M997 1539 c-29 -17 -39 -56 -22 -88 19 -38 60 -48 94 -23 70 52 3
			155 -72 111z'
          />
          <path
            d='M659 727 c-186 -52 -253 -283 -125 -434 69 -82 211 -108 314 -57 163
			81 195 308 60 430 -69 62 -162 85 -249 61z m118 -118 c106 -40 130 -168 46
			-246 -31 -30 -41 -33 -93 -33 -52 0 -62 3 -93 33 -81 76 -62 198 38 243 37 16
			65 17 102 3z'
          />
          <path
            d='M1329 727 c-65 -19 -109 -47 -146 -95 -79 -104 -68 -256 25 -350 51
			-50 105 -72 182 -72 108 0 191 50 238 145 100 198 -87 431 -299 372z m127
			-128 c31 -15 48 -32 63 -63 25 -50 26 -75 7 -120 -22 -52 -73 -86 -132 -86
			-154 0 -201 199 -64 271 45 24 74 24 126 -2z'
          />
        </g>
      </svg>
    </>
  );
}
