import EvesSelect from '@/common/components/atoms/select/select';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { UserRole } from '@/common/enums/enums';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { AnalyticsLinkModel } from '@/modules/integrationSettings/shared/models/integrationSettings';
import { AnalyticsSchemaConstants, formSchema, requiredFields } from '@/modules/integrationSettings/shared/schemas/analyticsLink';
import { InsertLink, OpenInNew } from '@mui/icons-material';
import { Grid, InputAdornment, } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const AnalyticsLink = (props: any) => {
	const { t: translate } = useTranslation();
	const analyticsLinkForm = props.formService as UseFormReturn<AnalyticsLinkModel, any>;
	const isSaveEnabled=(analyticsLinkForm.formState.isValid && analyticsLinkForm.formState.isDirty);
	const roleTypes = [
		{ label: translate('users.role_admin'),value: UserRole.ADMIN},
		{ label: translate('users.role_demo'), value: UserRole.DEMO }, 
		{ label: translate('users.role_mult_all'),value: '' },];
	const analyticsName=analyticsLinkForm.watch('name');
	const isFormDirty: boolean = analyticsLinkForm.formState.isDirty || false;
	const [disableUrl,setDisableUrl]=useState(true);

	const onFormSubmit = (formData: AnalyticsLinkModel) => {
		if(formData.role==' '){
			formData.role='';
		}
		props.handleRowData(formData);
		props.onDialogClose();
		props.resetAnalyticsLink('');
	};

	const getAnalyticsContent = () => {
		const content = props.state.rows.find((data: any) => props.state.analyticsId === data.id);
		setDisableUrl(false);
		analyticsLinkForm.reset({...content});
	};

	useEffect(() => {
		if(props.state.analyticsId !== ''){
			getAnalyticsContent();
		}
	}, []);

	const onHandleClose = () => {
		if (isFormDirty==true) {
			if (analyticsLinkForm.formState.isValid) {
				props.dialogService?.showConfirm(
					{
						confirmType: 'DIRTY_CHANGE_CLOSE',
					},
					(result) => {
						if (result === 'SAVE') {
							analyticsLinkForm.handleSubmit(onFormSubmit)();
						} else if (result === 'CLOSE') {
							props.onDialogClose();
							props.resetAnalyticsLink('');
						}
					}
				);
			} else {
				props.dialogService?.showConfirm(
					{
						confirmType: 'INVALID_CHANGE_CLOSE',
					},
					(result: string) => {
						if (result === 'CLOSE') {
							props.onDialogClose();
							props.resetAnalyticsLink('');
						}
					}
				);
			}
		} else {
			props.onDialogClose();
			props.resetAnalyticsLink('');
		}
	};

	const onUrlChange=(event:any)=>{
		const regex = /^(ftp|http|https)?:\/\/[^ "]*$/;
		const inputValue = event.target.value;
		if (regex.test(inputValue)) {
			setDisableUrl(false);
		} else {
			setDisableUrl(true);
		}
	};

	const onOpenUrl=()=>{
		const mainUrl=analyticsLinkForm.watch('url');
		window.open(mainUrl);
	};

	return (
		<>
			<EvesModal maxHeight={'275px'} maxWidth={'900px'} isOpen={props.showAnalyticsOpen} 
				onHandleSave={analyticsLinkForm.handleSubmit(onFormSubmit)}  onHandleClose={onHandleClose}
				isSaveDisabled={!(isSaveEnabled)}
				title={analyticsName} titleIcon={<InsertLink/>} scrollButtons={'auto'} >
				<Grid container rowSpacing={1} columnSpacing={2}>
					<Grid item xs={4}>
						<Controller name="name" control={analyticsLinkForm.control} render={({ field }) => (
							<EvesTextbox {...field} id="name" label={`${translate('analytics.link.name')}`} fullWidth variant="standard" autoFocus={true}
								required={requiredFields['name']}  error={!!analyticsLinkForm.formState?.errors.name}
								helperText={`${translate(analyticsLinkForm.formState?.errors.name?.message?.toString() || ' ').replace(/{{length}}/g, `'${AnalyticsSchemaConstants.nameMaxLength}'`)}`}
							/> )} />
					</Grid>
					<Grid item xs={8}>
						<Controller name="description" control={analyticsLinkForm.control} render={({ field }) => (
							<EvesTextbox {...field} id="description" label={`${translate('analytics.link.description')}`}
								fullWidth disabled={props.isDisabled} variant="standard"
								required={requiredFields['description']}  error={!!analyticsLinkForm.formState?.errors.description}
								helperText={`${translate(analyticsLinkForm.formState?.errors.description?.message?.toString() || ' ')}`}
							/> )} />
					</Grid>
					<Grid item xs={4}>
						<Controller name="role" control={analyticsLinkForm.control}
							render={({ field }) => (
								<EvesSelect 
									{...field}	
									data-cy="type-option" 
									label={`${translate('analytics.link.role')}`}  
									options={roleTypes} 
									displayEmpty={true}
									shrink={true}
									onChange={(event:any) => {field.onChange(event);}}
								/> )} />
					</Grid>
					<Grid item xs={12}>
						<Controller name="url" control={analyticsLinkForm.control} render={({ field }) => (
							<EvesTextbox {...field} id="url" label={`${translate('analytics.link.url')}`}
								fullWidth   variant="standard" required={requiredFields['url']}
								InputProps={{ endAdornment: (
									<InputAdornment position="end" style={{  cursor: disableUrl ? 'default' : 'pointer'  }}>
										<OpenInNew   sx={{ color: disableUrl ? 'gray' : 'black', height: '15px',}}  onClick={disableUrl? undefined :onOpenUrl} />
									</InputAdornment> ), }} 
								onChange={(event:any) => {
									field.onChange(event);
									analyticsLinkForm.trigger('url'); 
									onUrlChange(event);}}
								error={!!analyticsLinkForm.formState?.errors.url}
								helperText={`${translate(analyticsLinkForm.formState?.errors.url?.message?.toString() || ' ')}`}
							/> )} />
					</Grid>
				</Grid>
			</EvesModal>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	userInfo: state.userContext.userInfo
});

export default connect(mapStateToProps)(withContext( withUseFormHook(AnalyticsLink, {
	schema: formSchema,
	defaultValues: { ...new AnalyticsLinkModel() }
})
));

