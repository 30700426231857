import styles from './toggleButton.module.scss';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { forwardRef } from 'react';

export interface IToggleButton {
  value?: any;
  onChange?: any;
  disabled?: any;
  options?: any;
  defaultValue?: any;
  fullWidth?: any;
  size?: any;
  isSwitch?: any;
  exclusive?: boolean;
}

// eslint-disable-next-line react/display-name
export const EvesToggleButton = forwardRef((props: IToggleButton) => {
  props = { ...props };
  const isSwitch = props.isSwitch;
  delete props.isSwitch;
  return (
    <>
      <ToggleButtonGroup
        {...props}
        className={`${styles.toggleButton} ${
          isSwitch ? styles.toggleButton_Switch : ''
        }`}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        exclusive={props.exclusive}
      >
        {props.options.map((option: any, index: any) => (
          <ToggleButton
            key={index}
            style={{ textTransform: 'none' }}
            className={styles.toggleButtonItem}
            value={option.value}
            aria-label={option.label}
            data-cy={`${option.label}-btn`}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
});
