import styles from './styles.module.scss';

interface CommonCardInterface {
   img: any;
   headerText: string;
   footerText: string;
   historyTab?: boolean;
}

const CommonCard = (props: CommonCardInterface) => {
   return (
      <section
         className={`${styles.commonCardContainer} ${
            props.historyTab ? styles.historyCardContainer : ''
         }`}
      >
         <div className={styles.commonCardHeaderContainer}>
            <img src={props.img} className={styles.commonCardImage} />
            <h2 className={styles.commonCardHeaderText}>{props.headerText}</h2>
         </div>
         <div className={styles.commonCardFooterText}>{props.footerText}</div>
      </section>
   );
};

export default CommonCard;
