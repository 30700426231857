import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import logo from '@/assets/img/EvesLogo.png';
import { Nav } from '../../molecules/nav/nav';
import { IMenuItem } from '@/common/models/menuItem';
import { getTenantLogoBySubdomain } from '@/services/auth';
import { blobToBase64String, getSubdomain } from '@/common/utils/utils';
import styles from './sidebar.module.scss';

export default function Sidebar(props: {
  menuItems: IMenuItem[];
  onToggle: any;
  isOpen: any;
}) {
  const [state, setStateData] = useState({
    isVisible: false,
    tenantLogo: logo,
  });
  const subdomain = getSubdomain();

  const { refetch: fetchTenantLogo } = useQuery(
    'tenantLogo',
    () => {
      if (subdomain) return getTenantLogoBySubdomain(subdomain);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if(data){
          if (data.status >= 400 && data.status < 500) {
            setStateData((currentData) => {
              return {
                ...currentData,
                tenantLogo: logo,
              };
            });
          } else if (data) {
            const base64String = await blobToBase64String(data);
            setStateData((currentData) => {
              return {
                ...currentData,
                tenantLogo: base64String,
              };
            });
          }
        }
       
      },
    }
  );
  useEffect(() => {
    if (subdomain) fetchTenantLogo();
  }, []);

  return (
    <div
      data-cy='side-bar'
      className={`${styles.sidebar} ${props.isOpen ? styles.sidebarOpen : ''}`}
    >
      <div
        className={`${styles.sidebarTop} ${
          props.isOpen ? styles.sidebarTopOpen : ''
        }`}
      >
        {/* <div className={styles.sidebarHamburger} data-cy='manu-icon'>
					<IconButton color="primary" onClick={props.onToggle}><MenuIcon/></IconButton>
				</div> */}
        <div
          data-cy='logo'
          className={`${styles.sidebarLogo} ${
            props.isOpen ? styles.sidebarLogoOpen : ''
          }`}
        >
          <img
            src={state.tenantLogo}
            alt='logo'
            className={`${styles.sidebarLogoImg} ${
              props.isOpen ? styles.sidebarLogoImgOpen : ''
            }`}
          />
        </div>
      </div>
      <div className={styles.sidebar_scroll}>
        <div className={styles.sidebarBottom}>
          {/* <UserMenu isSideBarOpen={props.isOpen}></UserMenu> */}
          <Nav
            isSideBarOpen={props.isOpen}
            menuItems={props.menuItems}
            isPrimary={true}
          ></Nav>
        </div>
      </div>
    </div>
  );
}
