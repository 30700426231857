export function IconType2(props: any) {
  const height = props.height || '20px';
  const width = props.width || '20px';

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 45 45'
      >
        <g
          transform='matrix(.2626 0 0 .24592 -3.5 -22.7)'
          fill='none'
          stroke='currentColor'
        >
          <ellipse cx='120.3' cy='215' rx='13.3' ry='13.1' strokeWidth='9.2' />
          <ellipse ry='13.1' rx='13.3' cy='215' cx='77.7' strokeWidth='9.2' />
          <ellipse cx='57.6' cy='179.5' rx='13.3' ry='13.1' strokeWidth='9.2' />
          <ellipse ry='13.1' rx='13.3' cy='179.5' cx='99' strokeWidth='9.2' />
          <ellipse
            cx='139.7'
            cy='179.5'
            rx='13.3'
            ry='13.1'
            strokeWidth='9.2'
          />
          <ellipse ry='10.1' rx='9.7' cy='150.7' cx='120' strokeWidth='9.2' />
          <ellipse cx='79.2' cy='150.7' rx='9.7' ry='10.1' strokeWidth='9.2' />
          <path
            d='M57 120.9h85.2s9 6 12.7 10.3a82 82 0 0 1 9.9 14.4 77.2 77.2 0 0 1 10 31.7c.5 7-.2 16.4-1.8 23.4a74.6 74.6 0 0 1-15.7 29.8 93.9 93.9 0 0 1-27.6 21.6c-5.6 2.6-18.2 5.7-18.2 5.7s-17 2.3-30.7-2.5c-5.4-2-9-2.6-15-5.7-7.6-4-15-9-20.7-15.3A89.8 89.8 0 0 1 26.9 204c-3-8.8-4.1-18.5-3.8-27.9.3-7.1 2-14.2 4.4-20.9 2.1-5.7 5.7-11.1 8.8-15.8 3-4.7 8.4-9.7 12-12.7 3.5-3 8.7-5.7 8.7-5.7z'
            strokeWidth='16'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </>
  );
}
