import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { DefaultPageSize } from '@/common/constants/constants';
import { connect } from 'react-redux';
import { Payment } from '@mui/icons-material';
import CreatePaymentMethodForm from './createPaymentMethodForm';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { setupPaymentMethod } from '@/services/userProfile';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import {
  IPaymentFormPropsModel,
  PaymentMethodFormModel,
} from '@/modules/profile/shared/models/user';
import { formSchema } from '@/modules/user/shared/schemas/paymentFormSchema';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

const PaymentForm = (props: IPaymentFormPropsModel) => {
  const paymentForm = props.formService as UseFormReturn<
    PaymentMethodFormModel,
    any
  >;
  const isFormDirty: boolean = paymentForm.formState.isDirty || false;
  const [state, setStateData] = useState({
    selectedTab: '1',
    limit: DefaultPageSize,
    paymentState: false,
    canUpdate: false,
    cardState: {},
  } as any);

  const changePaymentState = (paymentState: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        paymentState,
      };
    });
  };

  const stripe: any = useStripe();
  const { t: translate } = useTranslation();

  const tabsConfig = [
    {
      name: `${translate('settings.billing.payment_methods_create_title')}`,
      icon: <Payment />,
      component: (
        <CreatePaymentMethodForm
          changePaymentState={changePaymentState}
          userId={props.userId}
          paymentForm={paymentForm}
        />
      ),
      visible: true,
    },
  ];

  const attachPaymentMethod = async (operationResult) => {
    const response: any = await setupPaymentMethod({
      setupIntentId: operationResult.setupIntent?.id,
      paymentMethodId: operationResult.setupIntent?.payment_method,
      userID: props.userId,
    });
    return response;
  };

  const createSetupIntent = async () => {
    const response: any = await setupPaymentMethod({
      userID: props.userId,
    });
    return response?.internalData;
  };

  const confirmSetupIntent = async (setupIntent, cardElement) => {
    const result: { error?: StripeError; setupIntent?: SetupIntent } =
      await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: cardElement,
        },
      });
    return result;
  };

  const createPaymentMethod = async (cardElement: any) => {
    const setupIntent = await createSetupIntent();
    const confirmationResult = await confirmSetupIntent(
      setupIntent,
      cardElement
    );
    if (confirmationResult.error) {
      return confirmationResult;
    }
    return attachPaymentMethod(confirmationResult);
  };

  const doCreatePaymentMethod = async (cardElement) => {
    const operationResult: any = await createPaymentMethod(cardElement);
    if (operationResult.error) {
      if (operationResult.error.code === 'card_declined') {
        setStateData((currentData: any) => {
          return {
            ...currentData,
            cardState: {
              ...currentData.cardState,
              isCardNumberValid: false,
            },
          };
        });
        props.notificationService?.notify({
          message: `${translate(
            'settings.billing.payment_methods_create_error_card_declined'
          )}`,
          type: 'error',
        });
        setStateData((currentData: any) => {
          return {
            ...currentData,
            cardState: {
              ...currentData.cardState,
              cardNumberError: 'Card declined',
            },
          };
        });
      } else {
        props.notificationService?.notify({
          message: `${translate(
            'settings.billing.payment_methods_create_error'
          )}`,
          type: 'error',
        });
      }
    } else {
      props.notificationService?.notify({
        message: `${translate(
          'settings.billing.payment_methods_create_success'
        ).replace(/{{last4}}/g, `${operationResult.internalData.card.last4}`)}`,
        type: 'success',
      });
      props.onFormClose();
      props.fetchAllPaymentMethods();
    }
  };

  const onFormSubmit = (formData: any) => {
    doCreatePaymentMethod(paymentForm.getValues().cardNumber);
  };

  const onHandleClose = () => {
    if (isFormDirty === true) {
      //based on the formValidity confirmation Dialogtype changes
      if (paymentForm.formState.isValid) {
        props.dialogService?.showConfirm(
          {
            confirmType: 'DIRTY_CHANGE_CLOSE',
          },
          (result) => {
            if (result === 'SAVE') {
              paymentForm.handleSubmit(onFormSubmit)();
            } else if (result === 'CLOSE') {
              props.onFormClose();
              props.resetPaymentForm('');
            }
          }
        );
      } else {
        props.dialogService?.showConfirm(
          {
            confirmType: 'INVALID_CHANGE_CLOSE',
          },
          (result: string) => {
            if (result === 'CLOSE') {
              props.onFormClose();
              props.resetPaymentForm('');
            }
          }
        );
      }
    } else {
      props.onFormClose();
      props.resetPaymentForm('');
    }
  };

  return (
    <>
      <EvesModal
        maxHeight={'550px'}
        maxWidth={'500px'}
        isOpen={props.showCompanyForm}
        onHandleSave={onFormSubmit}
        onHandleClose={onHandleClose}
        isSaveDisabled={
          !(state.paymentState && paymentForm.getValues().acceptConditions)
        }
        tabsConfig={tabsConfig}
        modalType='tabs'
        isSaveHidden={false}
      ></EvesModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(PaymentForm, {
      schema: formSchema,
      defaultValues: { ...new PaymentMethodFormModel() },
    })
  )
);
