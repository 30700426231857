import { Controller, UseFormReturn } from 'react-hook-form';
import { PriceFormModel } from '@/modules/organization/shared/models/price';
import { FormControlLabel, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { IUserContextModel } from '@/store/auth/types';
import { DEFAULT_CURRENCY_CODE } from '@/common/constants/constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesSwitch } from '@/common/components/atoms/switch/switch';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { useTranslation } from 'react-i18next';

interface IDimensionPropsModel {
	priceForm: UseFormReturn<PriceFormModel, any>
}

export default function Dimension(props: IDimensionPropsModel) {
	const { t: translate} = useTranslation();

	const { control, formState, watch,setValue } = props.priceForm;
	const { errors } = formState;
    
	const flatFeeActive = watch('dimensions.flatFee.active');
	const energyActive = watch('dimensions.energy.active');
	const energyStepSizeEnabled = watch('dimensions.energy.stepSizeEnabled') && energyActive;
	const chargingActive = watch('dimensions.chargingTime.active');
	const chargingStepSizeEnabled = watch('dimensions.chargingTime.stepSizeEnabled') && chargingActive;
	const parkingActive = watch('dimensions.parkingTime.active');
	const parkingStepSizeEnabled = watch('dimensions.parkingTime.stepSizeEnabled') && parkingActive;

	const userContext: IUserContextModel = useSelector((state: any) => state.userContext);
	const currency = userContext.userInfo.currency;
	const currencySymbol = getSymbolFromCurrency(currency || DEFAULT_CURRENCY_CODE);

	return (
		<>
			<Grid container direction="row" sx={{ mb: 2 }}>
				<Grid item sm={3}>
					<Controller name="dimensions.flatFee.active" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesSwitch {...field} checked={field.value} id="flatFeeActive" />} label={translate('settings.pricing.flat_fee')} />
					)} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.flatFee.price" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="flatFeePrice" label={currencySymbol + '/session'}
							fullWidth required={flatFeeActive} variant="standard" disabled={!flatFeeActive}
							error={flatFeeActive && !!errors?.dimensions?.flatFee?.price}
							helperText={flatFeeActive && `${translate(errors?.dimensions?.flatFee?.price?.message || ' ')}`}
						/>
					)} />
				</Grid>
			</Grid>

			<Grid container direction="row" sx={{ mb: 2 }}>
				<Grid item sm={3}>
					<Controller name="dimensions.energy.active" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesSwitch {...field} checked={field.value} id="energyActive"   onChange={(e) => {
                            field.onChange(e.target.checked);
                            if (e.target.checked) {
                                setValue('dimensions.chargingTime.active', false);
                            }
                        }} />} label={translate('settings.pricing.energy')} />
					)} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.energy.price" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="energyPrice" label={currencySymbol + '/kWh'}
							fullWidth required={energyActive} variant="standard" disabled={!energyActive}
							error={energyActive && !!errors?.dimensions?.energy?.price}
							helperText={energyActive && `${translate(errors?.dimensions?.energy?.price?.message || ' ')}`}
						/>
					)} />
				</Grid>
				<Grid item sm={3}>
					<Controller name="dimensions.energy.stepSizeEnabled" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesCheckbox {...field} checked={field.value} id="energyStepSizeActive" disabled={!energyActive} />}
							label={translate('settings.pricing.step_size')} /> )} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.energy.stepSize" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="energyStepSize" label={translate('settings.pricing.energy_step_unit')}
							fullWidth required={energyStepSizeEnabled} variant="standard" disabled={!energyStepSizeEnabled}
							error={energyStepSizeEnabled && !!errors?.dimensions?.energy?.stepSize}
							helperText={energyStepSizeEnabled && `${translate(errors?.dimensions?.energy?.stepSize?.message || ' ')}`}
						/>
					)} />
				</Grid>
			</Grid>

			<Grid container direction="row" sx={{ mb: 2 }}>
				<Grid item sm={3}>
					<Controller name="dimensions.chargingTime.active" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesSwitch {...field} checked={field.value}   onChange={(e) => {
                            field.onChange(e.target.checked);
                            if (e.target.checked) {
                                setValue('dimensions.energy.active', false);
                            }
                        }}  id="chargingActive" />} label={translate('settings.pricing.charging_time')} />
					)} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.chargingTime.price" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="chargingPrice" label={currencySymbol + '/hour'}
							fullWidth required={chargingActive} variant="standard" disabled={!chargingActive}
							error={chargingActive && !!errors?.dimensions?.chargingTime?.price}
							helperText={chargingActive && `${translate(errors?.dimensions?.chargingTime?.price?.message || ' ')}`}
						/>
					)} />
				</Grid>
				<Grid item sm={3} >
					<Controller name="dimensions.chargingTime.stepSizeEnabled" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesCheckbox {...field} checked={field.value} id="chargingStepSizeActive" disabled={!chargingActive} />} label={translate('settings.pricing.step_size')} />
					)} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.chargingTime.stepSize" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="chargingStepSize" label={translate('settings.pricing.time_step_unit')}
							fullWidth required={chargingStepSizeEnabled} variant="standard" disabled={!chargingStepSizeEnabled}
							error={chargingStepSizeEnabled && !!errors?.dimensions?.chargingTime?.stepSize}
							helperText={chargingStepSizeEnabled &&  `${translate(errors?.dimensions?.chargingTime?.stepSize?.message || ' ')}`}
						/>
					)} />
				</Grid>
			</Grid>

			<Grid container direction="row" sx={{ mb: 2 }}>
				<Grid item sm={3}>
					<Controller name="dimensions.parkingTime.active" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesSwitch {...field} checked={field.value} id="parkingActive" />} label={translate('settings.pricing.parking_time')} />
					)} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.parkingTime.price" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="parkingPrice" label={currencySymbol + '/hour'}
							fullWidth required={parkingActive} variant="standard" disabled={!parkingActive}
							error={parkingActive && !!errors?.dimensions?.parkingTime?.price}
							helperText={parkingActive &&  `${translate(errors?.dimensions?.parkingTime?.price?.message || ' ')}`}
						/>
					)} />
				</Grid>
				<Grid item sm={3} >
					<Controller name="dimensions.parkingTime.stepSizeEnabled" control={control} render={({ field }) => (
						<FormControlLabel sx={{ pt: 2 }} control={<EvesCheckbox {...field} checked={field.value} id="parkingStepSizeActive" disabled={!parkingActive} />} label={translate('settings.pricing.step_size')} />
					)} />
				</Grid>
				<Grid item sm={3} sx={{ pr: 10 }}>
					<Controller name="dimensions.parkingTime.stepSize" control={control} render={({ field }) => (
						<EvesTextbox {...field} id="parkingStepSize" label={translate('settings.pricing.time_step_unit')}
							fullWidth required={parkingStepSizeEnabled} variant="standard" disabled={!parkingStepSizeEnabled}
							error={parkingStepSizeEnabled && !!errors?.dimensions?.parkingTime?.stepSize}
							helperText={parkingStepSizeEnabled && `${translate(errors?.dimensions?.parkingTime?.stepSize?.message || ' ')}`}
						/>
					)} />
				</Grid>
			</Grid>
		</>
	);
}