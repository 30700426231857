export const findRequiredFields = (prefix: string, fields: any) => {
	return Object.keys(fields).reduce((prev: any, current: string) => {
		if (fields[current].tests?.findIndex(({ name }: any) => name === 'required') >= 0) {
			prev[prefix ? prefix + '.' + current : current] = true;
		}
		if (fields[current].fields) {
			const nested = findRequiredFields(current, fields[current].fields);
			prev = { ...prev,  ...nested };
		}
		return prev;
	}, {});
};