import { FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem } from '@mui/material';
import { RefObject, useState } from 'react';
import { SiteAreaFormModel } from '@/modules/organization/shared/models/siteArea';
import { requiredFields } from '@/modules/organization/shared/schemas/siteAreaFormSchema';
import { Controller, UseFormReturn } from 'react-hook-form';
import React from 'react';
import AddressComponent from '@/common/components/addressComponent';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import LookupComponent from '@/common/components/lookupComponent';
import { LookupEntityType } from '@/common/enums/enums';
import { Close, Edit } from '@mui/icons-material';
import { FlipCard } from '@/common/components/molecules/flipCard/flipCard';
import { NotifyType } from '@/common/utils/notificationService';
import { EvesButton } from '@/common/components/atoms/button/button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import copy from 'copy-to-clipboard';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useTranslation } from 'react-i18next';
import Config from '@/common/constants/config';

interface ISiteAreaPropsModel extends IContextPropsModel {
	siteAreaForm: UseFormReturn<SiteAreaFormModel, any>
	pageLimit: any;
	loadRegistrationToken?: () => void;
	onConnectToCharging: () => void;
	isDisabled: boolean;
}

const SiteArea = (props: ISiteAreaPropsModel) => {
	const [isOpenSiteLookupModal, setOpenSiteLookupModal] = useState(false);
	const [isOpenSubStationLookupModal, setOpenSubStationLookupModal] = useState(false);
	const [isOpenFeederLookupModal, setOpenFeederLookupModal] = useState(false);
	const [isOpenTransformerLookupModal, setOpenTransformerLookupModal] = useState(false);
	const [isOpenParentSiteAreaModal, setOpenParentSiteAreaModal] = useState(false);
	const siteAreaForm = props.siteAreaForm;
	const inputOpenFileRef: RefObject<HTMLInputElement> = React.createRef();
	const logo = siteAreaForm.watch('image');
	const name = siteAreaForm.watch('name');
	const siteName = siteAreaForm.watch('site');
	const siteAreaId = props.siteAreaForm.watch('id');
	const registrationToken = siteAreaForm.watch('registrationToken');
	const [openOcppList, setOpenOcppList] = useState(null);
	const parentSiteArea = props.siteAreaForm.watch('parentSiteArea');
	const siteParams = [{ key: 'Issuer', value: true }, { key: 'SiteAdmin', value: true }];
	const parentParams = [{ key: 'Issuer', value: true }, { key: 'SiteID', value: props?.siteAreaForm?.watch('siteID') },
	{ key: 'ExcludeSiteAreaID', value: props.siteAreaForm.watch('id') }, { key: 'WithSite', value: true }, { key: 'Limit', value: props.pageLimit }];
	const subStationParams = [{ key: 'Issuer', value: true }, { key: 'Limit', value: props.pageLimit }];
	const feederParams = [{ key: 'Issuer', value: true }, { key: 'SubStationID', value: props?.siteAreaForm?.watch('subStationID') }, { key: 'Limit', value: props.pageLimit }];
	const transformerParams = [{ key: 'Issuer', value: true },{ key: 'SubStationID', value: props?.siteAreaForm?.watch('subStationID') }, { key: 'FeederID', value: props?.siteAreaForm?.watch('feederID') }, { key: 'Limit', value: props.pageLimit }];
	const { t: translate } = useTranslation();
	const maxPictureKb: number = Config.siteArea.maxPictureKb;

	const onFileSelected = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			const notify: NotifyType = {
				message: '',
				type: 'success'
			};
			if (event.target.files[0].size > (maxPictureKb * 1024)) {
				notify.message = `${translate('site_areas.image_size_error').replace(/{{maxPictureKb}}/, maxPictureKb.toString())}`;
				notify.type = 'error';
				props.notificationService?.notify(notify);
				return;
			}
			else {
				const reader = new FileReader();
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = () => {
					props.siteAreaForm?.setValue('image', reader.result as unknown as string, { shouldDirty: true });
				};
			}
		}
	};

	const onFileBrowseClick = () => {
		if (!name) {
			siteAreaForm.setValue('name', '', { shouldTouch: true, shouldValidate: true });
		}
		if (!siteName) {
			siteAreaForm.setValue('site', '', { shouldTouch: true, shouldValidate: true });
		}
		inputOpenFileRef?.current?.click();
	};

	const onCloseParentLookupModal = () => {
		setOpenParentSiteAreaModal(false);
	};

	const onClickSiteLookUpModal = () => {
		!(props.isDisabled) ? setOpenSiteLookupModal(true) : '';
	};

	const onCloseSubStationLookUpModal = () => {
		setOpenSubStationLookupModal(false);
	};

	const onClickFeederLookUpModal = () => {
		!(props.isDisabled) ? setOpenFeederLookupModal(true) : '';
	};

	const onCloseFeederLookUpModal = () => {
		setOpenFeederLookupModal(false);
	};

	const onClickTransformerLookUpModal = () => {
		!(props.isDisabled) ? setOpenTransformerLookupModal(true) : '';
	};

	const onCloseTransformerLookUpModal = () => {
		setOpenTransformerLookupModal(false);
	};

	const onClickSubStationLookUpModal = () => {
		!(props.isDisabled) ? setOpenSubStationLookupModal(true) : '';
	};

	const onCloseSiteLookUpModal = () => {
		setOpenSiteLookupModal(false);
	};

	const onClickParentSiteLookupModal = (event: any) => {
		!(props.isDisabled) && event.target.tagName === 'INPUT' ? setOpenParentSiteAreaModal(true) : '';
	};

	const editParentSite = () => {
		!(props.isDisabled) ? setOpenParentSiteAreaModal(true) : '';
	};

	const onSiteSelect = (site: any) => {
		const getFormValues = props.siteAreaForm.getValues();
		if (site[0].name !== getFormValues.site) {
			props.siteAreaForm.setValue('parentSiteArea', '');
			props.siteAreaForm.setValue('parentSiteAreaID', '');
		}
		props.siteAreaForm.setValue('site', site[0].name, { shouldValidate: true, shouldDirty: true });
		props.siteAreaForm.setValue('siteID', site[0].id);
		props.siteAreaForm.setValue('public', site[0].public);
		props.siteAreaForm.trigger('site');
		setOpenSiteLookupModal(false);
	};

	const onParentSiteAreaSelect = (parentSite: any) => {
		props.siteAreaForm.setValue('parentSiteArea', parentSite[0].name, { shouldDirty: true });
		props.siteAreaForm.setValue('parentSiteAreaID', parentSite[0].id);
		props.siteAreaForm.setValue('site', parentSite[0].site.name, { shouldValidate: true, shouldDirty: true });
		props.siteAreaForm.setValue('siteID', parentSite[0].site.id);
		props.siteAreaForm.setValue('public', parentSite[0].site.public);
		setOpenParentSiteAreaModal(false);
	};

	const onSubstationIdSelect = (subStation: any) => {
		const getFormValues = props.siteAreaForm.getValues();
		if (subStation[0].name !== getFormValues.subStation) {
			props.siteAreaForm.setValue('feeder', '');
			props.siteAreaForm.setValue('feederID', '',{ shouldDirty: true,shouldValidate: true });
			props.siteAreaForm.setValue('transformer', '');
			props.siteAreaForm.setValue('transformerID', '',{ shouldDirty: true,shouldValidate: true });
		}
		props.siteAreaForm.setValue('subStation', subStation[0].name);
		props.siteAreaForm.setValue('subStationID', subStation[0].name,{ shouldDirty: true,shouldValidate: true });
		setOpenSubStationLookupModal(false);
	};

	const onFeederIdSelect = (feeder: any) => {
		const getFormValues = props.siteAreaForm.getValues();
		if (feeder[0].name !== getFormValues.feeder) {
			props.siteAreaForm.setValue('transformer', '');
			props.siteAreaForm.setValue('transformerID', '');
		}
		props.siteAreaForm.setValue('feeder', feeder[0].name);
		props.siteAreaForm.setValue('feederID', feeder[0].name,{ shouldDirty: true,shouldValidate: true });
		setOpenFeederLookupModal(false);
	};

	const onTransformerIdSelect = (transformer: any) => {
		props.siteAreaForm.setValue('transformer', transformer[0].name);
		props.siteAreaForm.setValue('transformerID', transformer[0].name,{ shouldDirty: true,shouldValidate: true });
		setOpenTransformerLookupModal(false);
	};

	const removeParentSite = () => {
		props.siteAreaForm.setValue('parentSiteArea', '', { shouldDirty: true });
		props.siteAreaForm.setValue('parentSiteAreaID', '');
	};

	const onConnectChargingClick = (event: any) => {
		setOpenOcppList(event.currentTarget);
	};

	const onConnectChargingClose = () => {
		setOpenOcppList(null);
	};

	const copyUrl = (url: any) => {
		copy(url);
		props.notificationService?.notify({
			message: `${translate('chargers.connections.url_copied')} `,
			type: 'info',
		});
		setOpenOcppList(null);
	};
	return (
		<>
			{isOpenSiteLookupModal && <LookupComponent title={`${translate('site_areas.assign_site')} `}
				showLookupModal={isOpenSiteLookupModal} onModalClose={onCloseSiteLookUpModal}
				selectionMode='single' defaultSortColumn='name' entityType={LookupEntityType.SITE}
				onSelect={onSiteSelect} params={siteParams} />}
			{isOpenParentSiteAreaModal && (<LookupComponent title={`${translate('site_areas.select_parent_site_area')}`}
				showLookupModal={isOpenParentSiteAreaModal} onModalClose={onCloseParentLookupModal}
				selectionMode='single' defaultSortColumn='name' entityType={LookupEntityType.SITEAREA}
				onSelect={onParentSiteAreaSelect} params={parentParams} />)}

			{isOpenSubStationLookupModal && (<LookupComponent title={`${translate('site_areas.select_substation_id')}`}
				showLookupModal={isOpenSubStationLookupModal} onModalClose={onCloseSubStationLookUpModal}
				selectionMode='single' defaultSortColumn='name' entityType={LookupEntityType.SUBSTATIONID}
				onSelect={onSubstationIdSelect} params={subStationParams} />)}

			{isOpenFeederLookupModal && (<LookupComponent title={`${translate('site_areas.select_feeder_id')}`}
				showLookupModal={isOpenFeederLookupModal} onModalClose={onCloseFeederLookUpModal}
				selectionMode='single' defaultSortColumn='name' entityType={LookupEntityType.FEEDERID}
				onSelect={onFeederIdSelect} params={feederParams} />)}

			{isOpenTransformerLookupModal && (<LookupComponent title={`${translate('site_areas.select_transformer_id')}`}
				showLookupModal={isOpenTransformerLookupModal} onModalClose={onCloseTransformerLookUpModal}
				selectionMode='single' defaultSortColumn='name' entityType={LookupEntityType.TRANSFORMERID}
				onSelect={onTransformerIdSelect} params={transformerParams} />)}

			<Grid container rowSpacing={1} columnSpacing={2}>
				<Grid item xs={5}>
					<FlipCard logo={logo} inputOpenFileRef={inputOpenFileRef}
						onFileSelected={onFileSelected} onFileBrowseClick={onFileBrowseClick}
						onFileRemoveClick={() => siteAreaForm.setValue('image', null, { shouldDirty: true })}
						isUpdate={!(props.isDisabled)} />
				</Grid>
				<Grid item xs={7}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Controller name="name" control={siteAreaForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="name" label={`${translate('site_areas.name')}`}
									fullWidth disabled={props.isDisabled} required={requiredFields['name']}
									variant="standard" error={!!siteAreaForm.formState?.errors.name}
									helperText={`${translate(siteAreaForm.formState?.errors.name?.message || ' ')}`}
								/>)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="site" control={siteAreaForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="site" label={`${translate('sites.site')}`}
									fullWidth onClick={onClickSiteLookUpModal} disabled={props.isDisabled}
									required={requiredFields['site']} variant="standard"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" style={{ cursor: 'pointer' }}>
												<IconButton disabled={props.isDisabled}><Edit sx={{ color: 'black', height: '15px' }} /></IconButton>
											</InputAdornment>
										), readOnly: true
									}}
									error={!!siteAreaForm.formState?.errors.site}
									helperText={`${translate(siteAreaForm.formState?.errors.name?.message || ' ')}`}
								/>
							)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="parentSiteArea" control={siteAreaForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="parentSiteArea" label={`${translate('site_areas.parent_site_area')}`}
									onClick={onClickParentSiteLookupModal}
									disabled={props.isDisabled}
									InputProps={{
										endAdornment: (
											<>
												<InputAdornment position="end" style={{ cursor: 'pointer' }}>
													<IconButton disabled={props.isDisabled}><Edit onClick={() => editParentSite()} sx={{ color: 'black', height: '15px' }} /></IconButton>
												</InputAdornment>
												{parentSiteArea ? <InputAdornment position='end' style={{ cursor: 'pointer' }}><IconButton onClick={() => removeParentSite()}><Close sx={{ color: 'black', height: '15px' }} /></IconButton></InputAdornment> : null}
											</>
										),
										readOnly: true,
									}}
									variant="standard"
								/>)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="subStationID" control={siteAreaForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="subStationID" label={`${translate('site_areas.sub_station_id')}`}
									fullWidth onClick={onClickSubStationLookUpModal} disabled={props.isDisabled}
									required={requiredFields['subStationID']} variant="standard"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" style={{ cursor: 'pointer' }}>
												<IconButton disabled={props.isDisabled}><Edit sx={{ color: 'black', height: '15px' }} /></IconButton>
											</InputAdornment>
										), readOnly: true
									}}
									error={!!siteAreaForm.formState?.errors.subStationID}
									helperText={`${translate(siteAreaForm.formState?.errors.subStationID?.message || ' ')}`}
								/>
							)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="feederID" control={siteAreaForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="feederID" label={`${translate('site_areas.feeder_id')}`}
									fullWidth onClick={onClickFeederLookUpModal} disabled={props.isDisabled}
									required={requiredFields['feederID']} variant="standard"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" style={{ cursor: 'pointer' }}>
												<IconButton disabled={props.isDisabled}><Edit sx={{ color: 'black', height: '15px' }} /></IconButton>
											</InputAdornment>
										), readOnly: true
									}}
									error={!!siteAreaForm.formState?.errors.feederID}
									helperText={`${translate(siteAreaForm.formState?.errors.feederID?.message || ' ')}`}
								/>
							)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="transformerID" control={siteAreaForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="transformerID" label={`${translate('site_areas.transformer_id')}`}
									fullWidth onClick={onClickTransformerLookUpModal} disabled={props.isDisabled}
									required={requiredFields['transformerID']} variant="standard"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" style={{ cursor: 'pointer' }}>
												<IconButton disabled={props.isDisabled}><Edit sx={{ color: 'black', height: '15px' }} /></IconButton>
											</InputAdornment>
										), readOnly: true
									}}
									error={!!siteAreaForm.formState?.errors.transformerID}
									helperText={`${translate(siteAreaForm.formState?.errors.transformerID?.message || ' ')}`}
								/>
							)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="accessControl" control={props.siteAreaForm.control} render={({ field }) => (
								<FormControlLabel control={<EvesCheckbox {...field} checked={props.siteAreaForm.watch('accessControl')} disabled={props.isDisabled} />}
									label={`${translate('site_areas.enable_access_control')}`} />)} />
						</Grid>
						{(siteAreaId && registrationToken?.id) &&
							<Grid item xs={12}>
								<EvesButton variant="contained" aria-controls="simple-menu" aria-haspopup="true"
									onClick={onConnectChargingClick} > <FileCopyIcon />{`${translate('chargers.connections.copy_url_tooltip')}`} </EvesButton>
								<Menu keepMounted anchorEl={openOcppList} onClose={onConnectChargingClose}
									open={Boolean(openOcppList)}  >
									<MenuItem onClick={() => copyUrl(registrationToken?.ocpp15SOAPSecureUrl)}>{`${translate('chargers.connections.ocpp_15_soap_secure')}`}</MenuItem>
									<MenuItem onClick={() => copyUrl(registrationToken?.ocpp16SOAPSecureUrl)}>{`${translate('chargers.connections.ocpp_16_soap_secure')}`}</MenuItem>
									<MenuItem onClick={() => copyUrl(registrationToken?.ocpp16JSONSecureUrl)}>{`${translate('chargers.connections.ocpp_16_json_secure')}`}</MenuItem>
								</Menu>
							</Grid>
						}
						{(siteAreaId && !registrationToken) && <Grid item xs={12}>
							{!(props.isDisabled) && <EvesButton variant="contained" onClick={() => props.onConnectToCharging()}><AttachmentIcon />{`${translate('chargers.connections.generate_connection_url')}`}</EvesButton>}
						</Grid>
						}
					</Grid>
				</Grid>
				<AddressComponent label={`${translate('site_areas.title')}`} name={name} isDisabled={props.isDisabled} requiredFields={requiredFields} hostForm={siteAreaForm as any} />
			</Grid>
		</>
	);
};

export default withContext(SiteArea);
