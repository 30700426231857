export function IconType1(props: any) {
  const height = props.height || '20px';
  const width = props.width || '20px';
  const color = props.color || '#ffffff';
  return (
    <>
      <svg
        height={height}
        width={width}
        version='1.1'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 100 108'
      >
        <path
          fill={color}
          enableBackground={'new 0 0 100 108'}
          d='M28,58c0,1.1,0.9,2,2,2s2-0.9,2-2s-0.9-2-2-2S28,56.9,28,58z M47,70c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3S47,68.3,47,70z
	 M69,58c0,1.1,0.9,2,2,2s2-0.9,2-2s-0.9-2-2-2S69,56.9,69,58z M62,36c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3S62,34.3,62,36z M33,36
	c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3S33,34.3,33,36z M17,50c0,18.2,14.8,33,33,33s33-14.8,33-33S68.2,17,50,17S17,31.8,17,50z
	 M22,58c0-4.4,3.6-8,8-8s8,3.6,8,8s-3.6,8-8,8S22,62.4,22,58z M39,70c0-6.1,4.9-11,11-11s11,4.9,11,11s-4.9,11-11,11S39,76.1,39,70z
	 M63,58c0-4.4,3.6-8,8-8s8,3.6,8,8s-3.6,8-8,8S63,62.4,63,58z M54,36c0-6.1,4.9-11,11-11s11,4.9,11,11s-4.9,11-11,11S54,42.1,54,36z
	 M25,36c0-6.1,4.9-11,11-11s11,4.9,11,11s-4.9,11-11,11S25,42.1,25,36z M0,50c0,22,14.2,40.7,34,47.4v2.6c0,4.4,3.6,8,8,8h17
	c4.4,0,8-3.6,8-8v-3c19.2-7,33-25.4,33-47c0-27.6-22.4-50-50-50S0,22.4,0,50z M6,50C6,25.7,25.7,6,50,6s44,19.7,44,44
	c0,20.5-14,37.7-33,42.6V97c0,2.8-2.2,5-5,5H45c-2.8,0-5-2.2-5-5v-4.1C20.5,88.3,6,70.9,6,50z'
        />
      </svg>
    </>
  );
}
