import { TenantComponents } from '@/common/enums/enums';
import { httpClient } from './httpClient';
import {
   ActionsResponse,
   IRefundSettingsResponseModal,
   ITransactionsHistoryParams,
   ITransactionsHistoryResponseModal,
   ITransactionsInErrorParams,
   ITransactionsInErrorResponseModal,
   ITransactionsInProgressParams,
   ITransactionsInProgressResponseModal,
   ITransactionsRefundParams,
   ITransactionsRefundResponseModal,
   SessionReportModel,
} from './models/session';
import * as XLSX from 'xlsx';


export const getTransactionsInProgress = (
   params: ITransactionsInProgressParams
) => {
   return httpClient.get<ITransactionsInProgressResponseModal>(
      '/api/transactions/status/active',
      params
   );
};

export const getInProgressDashboard = (
   params: ITransactionsInProgressParams
) => {
   return httpClient.get<ITransactionsInProgressResponseModal>(
      '/api/tsdashboard-sessions-in-progress',
      params
   );
};

export const getInhistoryDashboard = (
   params: ITransactionsInProgressParams
) => {
   return httpClient.get<ITransactionsInProgressResponseModal>(
      '/api/tsdashboard-sessions-history',
      params
   );
};

export const getPotentialCurtailment = (
   params: ITransactionsInProgressParams
) => {
   return httpClient.get<ITransactionsInProgressResponseModal>(
      '/api/tsdashboard-sessions-potential-curtailment',
      params
   );
};

export const getTransactionsHistory = (params: ITransactionsHistoryParams) => {
   return httpClient.get<ITransactionsHistoryResponseModal>(
      '/api/transactions/status/completed',
      params
   );
};

export const getTransactionsInError = (params: ITransactionsInErrorParams) => {
   return httpClient.get<ITransactionsInErrorResponseModal>(
      '/api/transactions/status/in-error',
      params
   );
};

export const getTransactionsRefund = (params: ITransactionsRefundParams) => {
   return httpClient.get<ITransactionsRefundResponseModal>(
      '/api/transactions/status/refund',
      params
   );
};

export const getRefundSettings = () => {
   const params = { Identifier: TenantComponents.REFUND };
   return httpClient.get<IRefundSettingsResponseModal>('/api/settings', params);
};

export const exportSessions = (params: ITransactionsHistoryParams) => {
   return httpClient.get<ITransactionsHistoryResponseModal>(
      '/api/transactions/action/export',
      params
   );
};

export const refundTransactions = (transactionIds: any) => {
   return httpClient.post('/api/transactions/action/refund', transactionIds);
};

export const synchronizeRefundedTransactions = () => {
   return httpClient.post('/api/transactions/status/refund/synchronize', {});
};

export const exportRefundSession = (params: ITransactionsRefundParams) => {
   return httpClient.get<ITransactionsRefundResponseModal>(
      '/api/transactions/action/export',
      params
   );
};

export const getSessionReports = (params: any) => {
   return httpClient.get<SessionReportModel>(
      '/api/transactions/status/refund/reports?',
      params
   );
};

export const deleteTransactions = (transactionsIDs: number[]) => {
   return httpClient.deleteV2<ActionsResponse>('/api/transactions', {
      headers: {},
      body: { transactionsIDs },
   });
};

export const exportSessionChart = (id: string) => {
   return httpClient
      .get(`/api/transactions/${id}/action/export`)
      .then((response: any) => {
         const blob = new Blob([response], { type: 'text/csv' });

         // Creating an object for downloading url
         const url = window.URL.createObjectURL(blob);

         // Creating an anchor(a) tag of HTML
         const a = document.createElement('a');

         // Passing the blob downloading url
         a.setAttribute('href', url);

         // Setting the anchor tag attribute for downloading
         // and passing the download file name
         a.setAttribute('download', 'exported-session.csv');

         // Performing a download with click
         a.click();
      });
};

export const exportSessionExcel = (id: string) => {
   return httpClient
      .get(`/api/transactions/${id}/action/export`)
      .then((response: any) => {
         const arrayOfArrayCsv = response.split('\n').map((row: string) => {
            return row.split(',');
         });

         const wb = XLSX.utils.book_new();
         const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
         XLSX.utils.book_append_sheet(wb, newWs);
         XLSX.writeFile(wb, 'exported-session.xlsx');
      });
};
