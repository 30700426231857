import { findRequiredFields } from '@/common/utils/validationHelper';
import * as schema from 'yup';
import { ConnectedAccountModel } from '@/modules/integrationSettings/shared/models/integrationSettings';

export const formSchema = schema.object<
  Partial<Record<keyof ConnectedAccountModel, schema.AnySchema>>
>({
  companyName: schema.string().required('Required field'),
  user: schema.string().required('Required field'),
});

export const requiredFields = findRequiredFields(
  '',
  formSchema.describe().fields
);
