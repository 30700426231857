import * as schema from 'yup';
import { RegisterFormModel } from '../models/models';

/* eslint-disable */
export const formSchema = schema.object<
	Partial<Record<keyof RegisterFormModel, schema.AnySchema>>
>({
	name: schema.string().required('general.mandatory_field'),
	firstName: schema.string().required('general.mandatory_field'),
	mobile: schema
		.string()
		.required('general.mandatory_field')
		.matches(
			/(^\+?([0-9] ?){9,14}[0-9]$)|^$/,
			'users.invalid_phone_number'
		),
	email: schema
		.string()
		.required('general.mandatory_field')
		.matches(
			/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
			'authentication.invalid_email'
		),
	password: schema
		.string()
		.required('general.mandatory_field')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/]{8,}|^$/,
			'authentication.password_rule'
		),
	repeatPassword: schema
		.string()
		.oneOf([schema.ref('password')], 'authentication.password_not_equal')
		.required('general.mandatory_field'),
});