import { EvesModal } from '@/common/components/molecules/modal/modal';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { Build, EvStation, Info } from '@mui/icons-material';
import { connect } from 'react-redux';
import ChargingStation from './chargingStation';
import { UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getChargingStationById,
  getOCPPParameters,
  updateChargingStationForm,
} from '@/services/chargingStations';
import {
  ChargingStationFormModel,
  CoordinatesModel,
  IEditChargingStationFormPropsModel,
} from '@/modules/chargingStation/shared/models/chargingStationForm';
import OCPPParameters from './ocppParams';
import { formSchema } from '@/modules/chargingStation/shared/shemas/chargingStationSchema';
import {
  phaseAssignmentToGridMapSinglePhased,
  phaseAssignmentToGridMapThreePhased,
  StatusCodes,
} from '@/common/constants/constants';
import { NotifyType } from '@/common/utils/notificationService';
import { OCPPProtocol } from '@/common/enums/enums';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import FirmwareUpdate from './firmwareUpdate';
import Properties from './properties';
import dayjs from 'dayjs';
import { handleHttpError, containsGPSCoordinates } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

const EditChargingStation = (props: IEditChargingStationFormPropsModel) => {
  const [state, setStateData] = useState({
    isForceInactive: false,
    isPublic: false,
    isManualConfiguration: false,
    isExcludeFromSmartCharging: false,
    isSiteAreaSmartCharging: false,
    isMasterSlave: false,
    isChargingStationURL: false,
    isMaximumPower: false,
    rows: [],
    originalData: [],
    siteName: '',
    siteAreaName: '',
    showUpdate: false,
    canUpdateFirmware: false,
    canGetOCPPParams: false,
    canUpdateOCPPParams: false,
  } as any);

  const chargingStationForm = props.formService as UseFormReturn<
    ChargingStationFormModel,
    any
  >;
  const isFormDirty = chargingStationForm.formState.isDirty;
  const isSaveEnabled =
    Object.keys(chargingStationForm.formState.errors)?.length == 0 &&
    isFormDirty;
  let phaseAssignmentToGridMap: any = phaseAssignmentToGridMapThreePhased;
  const { t: translate } = useTranslation();

  const notify: NotifyType = {
    message: '',
    type: 'success',
  };

  const { refetch: fetchOCPPParameters } = useQuery(
    ['res', props.chargingStationId],
    () => {
      return getOCPPParameters(props.chargingStationId);
    },
    {
      enabled: false,
      onSuccess: async (data: any) => {
        const rows = data.result
          ? data.result.map((result: any, index: number) => {
              return { ...result, id: index + 1 };
            })
          : [];
        setStateData((currentData) => {
          return {
            ...currentData,
            rows: [{ id: 0, key: '', value: '' }, ...rows] as any,
            originalData: [{ id: 0, key: '', value: '' }, ...rows] as any,
            canUpdate: data?.canUpdate,
          };
        });
      },
    }
  );

  const tabsConfig = [
    {
      name: `${props.chargingStationId}`,
      icon: <EvStation />,
      component: (
        <ChargingStation
          canUpdateChargingStation={props.canUpdateChargingStation}
          chargingStationForm={chargingStationForm}
          state={state}
          limit={props.limit}
          fetchAllChargingStations={props.fetchAllChargingStations}
        />
      ),
      visible: true,
    },
    {
      name: `${translate('chargers.properties_title')}`,
      icon: <Info />,
      component: (
        <Properties
          chargingStationForm={chargingStationForm}
          chargingStationId={props.chargingStationId}
          data={state.rows}
        />
      ),
      visible: state.showUpdate,
    },
    {
      name: `${translate('chargers.ocpp_parameters_title')}`,
      icon: <Build />,
      visible: state.canGetOCPPParams,
      component: (
        <OCPPParameters
          canUpdateOCPPParams={state.canUpdateOCPPParams}
          canGetOCPPParams={state.canGetOCPPParams}
          state={state}
          chargingStationId={props.chargingStationId}
          fetchOCPPParameters={fetchOCPPParameters}
        />
      ),
    },
    {
      name: `${translate('chargers.firmware_update_title')}`,
      icon: <SystemUpdateAltOutlinedIcon />,
      visible: state.canUpdateFirmware,
      component: (
        <FirmwareUpdate
          chargingStationId={props.chargingStationId}
          chargingStationForm={chargingStationForm}
        />
      ),
    },
  ];

  useEffect(() => {
    if (props.chargingStationId) {
      getChargingStation();
      fetchOCPPParameters();
    }
  }, []);

  const { refetch: getChargingStation } = useQuery(
    ['data', props?.chargingStationId],
    () => {
      const chargingStationParams = {
        WithSite: true,
        WithSiteArea: true,
      };
      return getChargingStationById(
        props?.chargingStationId,
        chargingStationParams
      );
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        const coordinates: CoordinatesModel = {};
        if (!data?.coordinates || data?.coordinates?.length === 0) {
          coordinates.longitude = '';
          coordinates.latitude = '';
        } else {
          const isValidCoordinates = containsGPSCoordinates(data?.coordinates);
          if (isValidCoordinates) {
            coordinates.longitude = data?.coordinates[0];
            coordinates.latitude = data?.coordinates[1];
          } else {
            coordinates.longitude = '';
            coordinates.latitude = '';
          }
        }
        const connectors = data?.connectors;
        const maxPower: any[] = [];
        const maxPowerAmps: any[] = [];
        let connectorsData: any = [];
        if (connectors?.length>0) {
          connectorsData = connectors.map((item, index) => {
            connectors[index].amperagePerPhase = ((connectors[index]
              ?.amperage as number) /
              connectors[index]?.numberOfConnectedPhase) as number;
            connectors[index].numberOfConnectedPhase =
              connectors[index].currentType == 'DC'
                ? 3
                : !connectors[index].numberOfConnectedPhase
                ? 1
                : connectors[index].numberOfConnectedPhase;
            maxPower.push(item.power === null ? 0 : item.power);
            maxPowerAmps.push(connectors[index].amperage);
            if (connectors[index].numberOfConnectedPhase === 1) {
              phaseAssignmentToGridMap = phaseAssignmentToGridMapSinglePhased;
            } else {
              phaseAssignmentToGridMap = phaseAssignmentToGridMapThreePhased;
            }
            if (connectors[index].phaseAssignmentToGrid) {
              connectors[index].phaseAssignmentToGrid =
                phaseAssignmentToGridMap[
                  phaseAssignmentToGridMap.findIndex(
                    (res) =>
                      res.value?.csPhaseL1 ===
                      connectors[index].phaseAssignmentToGrid?.csPhaseL1
                  )
                ]?.value;
            }
            if (!connectors[index]?.voltage) {
              connectors[index].voltage = 230;
            }
            return {
              ...item,
              power: item?.power ? item?.power :0,
              currentType:item?.currentType ? item?.currentType : '',
            };
          });
        }

        const totalMaxPower = maxPower?.reduce(
          (accumulator, currentValue) => accumulator + currentValue,0
        );
        const toalMaxPowerAmps = maxPowerAmps?.reduce(
          (accumulator, currentValue) => accumulator + currentValue,0
        );
        setStateData((currentData: any) => {
          return {
            ...currentData,
            siteName: data?.site?.name,
            siteAreaName: data?.siteArea?.name,
            isSiteAreaSmartCharging: data?.siteArea?.smartCharging,
            showUpdate: data?.canUpdate,
            canUpdateFirmware: data?.canUpdateFirmware,
            canGetOCPPParams: data?.canGetOCPPParams,
            issuer: data?.issuer,
            canUpdateOCPPParams: data?.canUpdateOCPPParams,
          };
        });
        let excludeFromSmartCharging = false;
        if (!data?.capabilities?.supportChargingProfiles) {
          excludeFromSmartCharging = true;
          setStateData((currentData) => {
            return {
              ...currentData,
              isExcludeFromSmartCharging: true,
            };
          });
        } else {
          excludeFromSmartCharging = data?.excludeFromSmartCharging;
        }
        if (!data?.site?.public) {
          setStateData((currentData) => {
            return {
              ...currentData,
              isPublic: true,
            };
          });
        }

        // URL not editable in case OCPP v1.6 or above
        if (data?.ocppProtocol === OCPPProtocol.JSON) {
          setStateData((currentData) => {
            return {
              ...currentData,
              isChargingStationURL: true,
            };
          });
        }
        if (data?.chargePoints && !data.manualConfiguration) {
          setStateData((currentData) => {
            return {
              ...currentData,
              isMaximumPower: true,
            };
          });
        }
        chargingStationForm.reset({
          siteArea: data?.siteArea?.name,
          siteAreaID: data?.siteAreaID,
          tariffID: data?.tariffID,
          public: data?.public,
          maximumAmps: toalMaxPowerAmps,
          // maximumPower: totalMaxPower,
          maximumPower: data?.maximumPower,
          masterSlave: data?.masterSlave,
          manualConfiguration: data?.manualConfiguration,
          issuer: data?.issuer,
          id: data?.id,
          excludeFromSmartCharging: excludeFromSmartCharging,
          forceInactive: data?.forceInactive,
          coordinates: coordinates,
          chargingStationURL: data?.chargingStationURL
            ? data?.chargingStationURL
            : '',
          connectors: connectorsData,
          chargePoints: data?.chargePoints,
          capabilities: data?.capabilities,
          canUpdateFirmware: data?.canUpdateFirmware,
          canGetConnectorQRCode: data?.canGetConnectorQRCode,
          ocppProtocol: data?.ocppProtocol,
          ocppVersion: data?.ocppVersion,
          firmwareVersion: data?.firmwareVersion,
          endpoint: data?.endpoint,
          currentIPAddress: data?.currentIPAddress,
          chargePointVendor: data?.chargePointVendor,
          chargePointModel: data?.chargePointModel,
          chargeBoxSerialNumber: data?.chargeBoxSerialNumber,
          lastReboot: dayjs(data?.lastReboot).format('M/D/YY, h:mm A'),
          lastSeen: dayjs(data?.lastSeen).format('M/D/YY, h:mm A'),
          createdOn: dayjs(data?.createdOn).format('M/D/YY, h:mm A'),
          site: data?.site?.name,
          chargerStatus:data?.chargerStatus,
          checkStatus:data?.chargerStatus
        });
        chargingStationForm.trigger('siteArea');
      },
    }
  );

  const onHandelClose = () => {
    if (
      !!Object.keys(chargingStationForm.formState?.dirtyFields).length === true
    ) {
      //based on the formValidity confirmation Dialogtype changes
      if (isSaveEnabled) {
        props.dialogService?.showConfirm(
          {
            confirmType: 'DIRTY_CHANGE_CLOSE',
          },
          (result) => {
            if (result === 'SAVE') {
              chargingStationForm.handleSubmit(onFormSubmit)();
            } else if (result === 'CLOSE') {
              props.onFormClose();
            }
          }
        );
      } else {
        props.dialogService?.showConfirm(
          {
            confirmType: 'INVALID_CHANGE_CLOSE',
          },
          (result: string) => {
            if (result === 'CLOSE') {
              props.onFormClose();
            }
          }
        );
      }
    } else {
      props.onFormClose();
    }
  };

  const onFormSubmit = (formData: ChargingStationFormModel) => {
    const dataToSend: any = { ...formData };
    if (
      dataToSend.coordinates.length > 0 &&
      dataToSend.coordinates.latitude === ''
    ) {
      dataToSend.coordinates = [];
    } else {
      dataToSend.coordinates = [
        dataToSend.coordinates.longitude,
        dataToSend.coordinates.latitude,
      ];
    }
    editChargingStationForm(dataToSend);
  };

  const { mutate: editChargingStationForm } = useMutation(
    (chargingStationFormData) =>
      updateChargingStationForm(
        props.chargingStationId,
        chargingStationFormData
      ),
    {
      onSuccess: (data: any) => {
        if (data.status === 'Success') {
          props.onFormClose();
          notify.message = `${translate(
            'chargers.change_config_success'
          ).replace(/{{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`;
          notify.type = 'success';
          props.notificationService?.notify(notify);
          props.fetchAllChargingStations();
        } else if (data.status === StatusCodes.NOT_FOUND) {
          notify.message = `${translate('chargers.change_config_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else if (
          data.status ===
          StatusCodes.THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA
        ) {
          notify.message = `${translate('chargers.change_config_phase_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else if (data.status === StatusCodes.CHARGE_POINT_NOT_VALID) {
          notify.message = `${translate(
            'chargers.charge_point_connectors_error'
          )}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else if (data.status === StatusCodes.FEATURE_NOT_SUPPORTED_ERROR) {
          notify.message = `${translate('chargers.update_public_cs_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else {
          notify.message = `${translate('chargers.change_config_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        }
      },
      onError: (error) => {
        const errorNotify = handleHttpError(
          error,
          `${translate('chargers.change_config_error')}`
        );
        props.notificationService?.notify(errorNotify);
      },
    }
  );

  return (
    <EvesModal
      maxWidth={'1200px'}
      data-cy='chargingStation-tabs'
      isOpen={props.editChargingStationForm}
      onHandleClose={onHandelClose}
      onHandleSave={chargingStationForm.handleSubmit(onFormSubmit)}
      tabsConfig={tabsConfig}
      modalType='tabs'
      isSaveDisabled={!isSaveEnabled}
      isSaveHidden={!props.canUpdateChargingStation}
    ></EvesModal>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(EditChargingStation, {
      schema: formSchema,
      defaultValues: { ...new ChargingStationFormModel() },
    })
  )
);
