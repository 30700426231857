import { Controller, UseFormReturn } from 'react-hook-form';
import { PriceFormModel } from '@/modules/organization/shared/models/price';
import { FormControlLabel, Grid, TextField } from '@mui/material';
import { EvesToggleButton } from '@/common/components/molecules/toggleButton/toggleButton';
import { EvesSwitch } from '@/common/components/atoms/switch/switch';
import { useTranslation } from 'react-i18next';
interface IRestrictionPropsModel {
  priceForm: UseFormReturn<PriceFormModel, any>;
}

export default function Restriction(props: IRestrictionPropsModel) {
  const { t: translate } = useTranslation();
  const { control, formState, watch } = props.priceForm;
  const { errors } = formState;

  const handleOnDaysOfWeekChange = (event: any, selectedDays: any) => {
    props.priceForm.setValue('restrictions.daysOfWeek', selectedDays, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };
  const daysOfWeekEnabled = watch('restrictions.daysOfWeekEnabled');
  const minDurationActive = watch('restrictions.minDurationSecsEnabled');
  const maxDurationActive = watch('restrictions.maxDurationSecsEnabled');
  const minEnergyActive = watch('restrictions.minEnergyKWhEnabled');
  const maxEnergyActive = watch('restrictions.maxEnergyKWhEnabled');
  const timeRangeEnabled = watch('restrictions.timeRangeEnabled');

  const options = [
    { label: translate('week_days.mon'), value: 1 },
    { label: translate('week_days.tue'), value: 2 },
    { label: translate('week_days.wed'), value: 3 },
    { label: translate('week_days.thu'), value: 4 },
    { label: translate('week_days.fri'), value: 5 },
    { label: translate('week_days.sat'), value: 6 },
    { label: translate('week_days.sun'), value: 7 },
  ];

  return (
    <>
      <Grid container direction='row' sx={{ mb: 2 }}>
        <Grid item sm={3}>
          <Controller
            name='restrictions.minDurationSecsEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pt: 2 }}
                control={
                  <EvesSwitch
                    {...field}
                    checked={field.value}
                    id='minDuration'
                  />
                }
                label={`${translate(
                  'settings.pricing.restriction_min_duration'
                )}`}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.maxDurationSecs');
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} sx={{ pr: 10 }}>
          <Controller
            name='restrictions.minDurationSecs'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.maxDurationSecs');
                }}
                id='minDurationSec'
                label={`${translate('settings.pricing.time_step_unit')}`}
                fullWidth
                required={minDurationActive}
                variant='standard'
                disabled={!minDurationActive}
                error={
                  minDurationActive && !!errors?.restrictions?.minDurationSecs
                }
                helperText={
                  minDurationActive &&
                  `${translate(
                    errors?.restrictions?.minDurationSecs?.message || ' '
                  )}`
                }
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='restrictions.maxDurationSecsEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pt: 2 }}
                control={
                  <EvesSwitch
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      props.priceForm.trigger('restrictions.minDurationSecs');
                    }}
                    checked={field.value}
                    id='maxDuration'
                  />
                }
                label={`${translate(
                  'settings.pricing.restriction_max_duration'
                )}`}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} sx={{ pr: 10 }}>
          <Controller
            name='restrictions.maxDurationSecs'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.minDurationSecs');
                }}
                id='maxDurationSec'
                label={`${translate('settings.pricing.time_step_unit')}`}
                fullWidth
                required={maxDurationActive}
                variant='standard'
                disabled={!maxDurationActive}
                error={
                  maxDurationActive && !!errors?.restrictions?.maxDurationSecs
                }
                helperText={
                  maxDurationActive &&
                  `${translate(
                    errors?.restrictions?.maxDurationSecs?.message || ' '
                  )}`
                }
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Energy */}
      <Grid container direction='row' sx={{ mb: 2 }}>
        <Grid item sm={3}>
          <Controller
            name='restrictions.minEnergyKWhEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pt: 2 }}
                control={
                  <EvesSwitch
                    {...field}
                    checked={field.value}
                    id='minEnergy'
                    onChange={(e) => {
                      field.onChange(e);
                      props.priceForm.trigger('restrictions.maxEnergyKWh');
                    }}
                  />
                }
                label={`${translate(
                  'settings.pricing.restriction_min_energy'
                )}`}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} sx={{ pr: 10 }}>
          <Controller
            name='restrictions.minEnergyKWh'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.maxEnergyKWh');
                }}
                id='minEnergyKWh'
                label={`${translate(
                  'settings.pricing.restriction_max_energy_unit'
                )}`}
                fullWidth
                required={minEnergyActive}
                variant='standard'
                disabled={!minEnergyActive}
                error={minEnergyActive && !!errors?.restrictions?.minEnergyKWh}
                helperText={
                  minEnergyActive &&
                  `${translate(
                    errors?.restrictions?.minEnergyKWh?.message || ' '
                  )}`
                }
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='restrictions.maxEnergyKWhEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pt: 2 }}
                control={
                  <EvesSwitch
                    {...field}
                    checked={field.value}
                    id='maxEnergy'
                    onChange={(e) => {
                      field.onChange(e);
                      props.priceForm.trigger('restrictions.minEnergyKWh');
                    }}
                  />
                }
                label={`${translate(
                  'settings.pricing.restriction_max_energy'
                )}`}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} sx={{ pr: 10 }}>
          <Controller
            name='restrictions.maxEnergyKWh'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.minEnergyKWh');
                }}
                id='maxEnergyKWh'
                label={`${translate(
                  'settings.pricing.restriction_max_energy_unit'
                )}`}
                fullWidth
                required={maxEnergyActive}
                variant='standard'
                disabled={!maxEnergyActive}
                error={maxEnergyActive && !!errors?.restrictions?.maxEnergyKWh}
                helperText={
                  maxEnergyActive &&
                  `${translate(
                    errors?.restrictions?.maxEnergyKWh?.message || ' '
                  )}`
                }
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Time Range */}
      <Grid container direction='row' sx={{ mb: 2 }}>
        <Grid item sm={3}>
          <Controller
            name='restrictions.timeRangeEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pt: 2 }}
                control={
                  <EvesSwitch
                    {...field}
                    checked={field.value}
                    id='timeRangeEnabled'
                  />
                }
                label={`${translate(
                  'settings.pricing.restriction_time_range'
                )}`}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} sx={{ pr: 10 }}>
          <Controller
            name='restrictions.timeFrom'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.timeTo');
                }}
                id='timeFrom'
                label={`${translate(
                  'settings.pricing.restriction_start_time'
                )}`}
                type='time'
                InputLabelProps={{ shrink: true }}
                fullWidth
                required={timeRangeEnabled}
                variant='standard'
                disabled={!timeRangeEnabled}
                error={timeRangeEnabled && !!errors?.restrictions?.timeFrom}
                helperText={
                  timeRangeEnabled &&
                  `${translate(errors?.restrictions?.timeFrom?.message || ' ')}`
                }
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='restrictions.timeTo'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  props.priceForm.trigger('restrictions.timeFrom');
                }}
                id='timeTo'
                label={`${translate('settings.pricing.restriction_end_time')}`}
                type='time'
                InputLabelProps={{ shrink: true }}
                fullWidth
                inputProps={{ step: 300 }}
                sx={{ width: 150 }}
                required={timeRangeEnabled}
                variant='standard'
                disabled={!timeRangeEnabled}
                error={timeRangeEnabled && !!errors?.restrictions?.timeTo}
                helperText={
                  timeRangeEnabled &&
                  `${translate(errors?.restrictions?.timeTo?.message || ' ')}`
                }
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Days of week */}
      <Grid container direction='row'>
        <Grid item sm={3}>
          <Controller
            name='restrictions.daysOfWeekEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pt: 2 }}
                control={
                  <EvesSwitch
                    {...field}
                    checked={field.value}
                    id='daysOfWeekEnabled'
                  />
                }
                label={`${translate(
                  'settings.pricing.restriction_days_of_week'
                )}`}
              />
            )}
          />
        </Grid>
        <Grid item sm={7} sx={{ pt: 2 }}>
          <Controller
            name='restrictions.daysOfWeek'
            control={control}
            render={({ field }) => (
              <EvesToggleButton
                {...field}
                onChange={handleOnDaysOfWeekChange}
                disabled={!daysOfWeekEnabled}
                options={options}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
