import { httpClient } from './httpClient';

export const getChargingStationSnapshot = (params?: any) => {
   
   return httpClient.get(
      '/api/dashboard-charging-stations-summary',
      params ?? {}
   );
};

export const getGetConnectorSnapshot = (params?: any) => {
   return httpClient.get(
      '/api/dashboard-connectors-summary?Limit=50',
      params ?? {}
   );
};

export const getTotalSitesSummary = () => {
   return httpClient.get('/api/csdashboard-total-sites');
};

export const getDashboardSessions = (params?:any) => {
  
   return httpClient.get('/api/dashboard-sessions-summary',params);
};

export const getCapacityPowerSnapshot = (params: any) => {
   return httpClient.get(
      '/api/csdashboard-totalcapacity-and-instantpower-summary',
      params ?? {}
   );
};

export const getDashboardTotalSessions = (groupBy?: any,SiteID?:any) => {
   const params: { [param: string]: string } = {};
   params['GroupBy'] = groupBy;
   params['SiteID'] = SiteID?.SiteID || '';
   return httpClient.get('/api/dashboard-total-sessions', params);
};

export const GetEnergyUsageTotals = (groupBy?: any,SiteID?:any) => {
   const params: { [param: string]: string } = {};
   params['GroupBy'] = groupBy;
   params['SiteID'] = SiteID;
   return httpClient.get('/api/dashboard-total-energy', params);
};

export const GetRevenueTotals = (groupBy?: any,SiteID?:any) => {
   const params: { [param: string]: string } = {};
   params['GroupBy'] = groupBy;
   params['SiteID'] = SiteID;

   return httpClient.get('/api/dashboard-total-revenue', params);
};

export const GetErrorsData = (params:any) => {
   return httpClient.get('/api/dashboard-errors',params);
};

export const getTelematicProfile = () => {
   return httpClient.get('/api/telematic-chargeprofile-summary');
};

export const getTelematicRange = () => {
   return httpClient.get('/api/telematic-chargerrange-summary');
};
export const getTelematicSoc = () => {
   return httpClient.get('/api/telematic-soc-summary');
};
export const getTelematicVehical = () => {
   return httpClient.get('/api/telematic-vehicle-summary');
};
