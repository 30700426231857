/* eslint-disable @typescript-eslint/no-empty-function */
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import React from 'react';
import styles from './dataTable.module.scss';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import EVESPagination from '@/common/components/molecules/pagination/pagination';
export const HeirachyTable = (props: any) => {
    const {
        columns,
        rows,
        defaultSelect,
        border,
        tab,
        count,
        limit,
        onPageChange,
  onRowsPerPageChange,
  page
    } = props;
    const { t: translate } = useTranslation();
    const childrenArray = React.Children?.toArray((props as any)?.children);
    const childrens: any =
        childrenArray?.length &&
        childrenArray?.reduce(
            (prev, next: any) => ({ ...prev, [next.props.slot]: next }),
            {}
        );

    const getBackGroundColor = (row: any) => {
        if (tab === 'Load Balancing') {
            const keys = Object.keys(row);
            if (keys.includes('siteAreaName')) {
                return '#1890FF1A';
            } else if (keys.includes('chargerName')) {
                return '#fafafa !important';
            }
        }
    };

    const handleGetBody = ({ rIdx, row, border }: any) => {
        const childrenData = row?.siteAreaChildren?.length > 0 ? [...row.childrens, ...row.siteAreaChildren] : row.childrens;
        return (
            <>
                <TableRow
                    tabIndex={-1}
                    key={`row-${rIdx}${row?.id}`}
                    className={styles?.datatableRow}
                    data-cy='data-row'
                    sx={{
                        background: getBackGroundColor(row)
                    }}
                >
                    {columns?.map((column, columnIndex) => {

                        return childrens[column.id] ? (
                            <TableCell
                                key={`${column.id}-${columnIndex}`}
                                style={{
                                    minWidth: column.minWidth,
                                    textAlign: (column.textAlign as any) ?? 'left',
                                    padding: '0',
                                    paddingLeft: '5px',
                                    maxWidth: column.maxWidth,
                                    // whiteSpace: 'pre-wrap',
                                    ...getBodyBorderStyles({ columnIndex })
                                }}
                                className={styles.datatableRes}
                                data-cy={`test-${column.id}`}
                            >
                                {React.cloneElement(childrens[column.id], {
                                    ...row,
                                    rowIndex: rIdx,
                                })}
                            </TableCell>
                        ) : (
                            <TableCell
                                title={(row[column.id] || '-')?.toString()}
                                key={`${column.id}-${columnIndex}`}
                                data-cy={`test-${column.id}`}
                                style={{
                                    minWidth: column.minWidth,
                                    textAlign: (column.textAlign as any) ?? 'left',
                                    maxWidth: column.maxWidth,
                                    ...getBodyBorderStyles({ columnIndex })
                                }}
                            >
                                {row[column.id]}
                            </TableCell>
                        );
                    })}
                </TableRow>
                {childrenData?.length > 0 && <>
                    {childrenData?.map((itemc) => {
                        return (
                            handleGetBody({ row: { ...itemc }, rIdx: row.id, border })
                        );
                    })}
                </>}
            </>
        );
    };

    const getHeaderBorderStyles = ({ columnIndex }: any) => {
        switch (props.page || props.tab) {
            case 'Demand View':
                return {
                    borderTop: columnIndex > 3 && columnIndex !== 8 && columnIndex < 12 ? '1px solid #4318FF' : undefined,
                    borderLeft: columnIndex === 8 || columnIndex === 4 ? '1px solid #4318FF' : undefined,
                    borderRight: columnIndex === 11 || columnIndex === 8 ? '1px solid #4318FF' : undefined,
                };
            case 'Load Balancing':
                return {
                    borderTop: columnIndex > 2 && columnIndex !== 6 && columnIndex !== 9 && columnIndex !== 11 ? '1px solid #4318FF' : undefined,
                    borderLeft: columnIndex === 6 || columnIndex === 3 || columnIndex === 10 ? '1px solid #4318FF' : undefined,
                    borderRight: columnIndex === 11 || columnIndex === 6 || columnIndex === 8 || columnIndex === 10 || columnIndex === 14 ? '1px solid #4318FF' : undefined,
                };
        }
    };

    const getBodyBorderStyles = ({ columnIndex }: any) => {
        switch (props.page || props.tab) {
            case 'Demand View':
                return {
                    borderLeft: columnIndex === 8 || columnIndex === 4 ? '1px solid #4318FF' : undefined,
                    borderRight: columnIndex === 11 || columnIndex === 8 ? '1px solid #4318FF' : undefined,
                };
            case 'Load Balancing':
                return {
                    borderLeft: columnIndex === 6 || columnIndex === 3 || columnIndex === 10 ? '1px solid #4318FF' : undefined,
                    borderRight: columnIndex === 11 || columnIndex === 6 || columnIndex === 8 || columnIndex === 10 || columnIndex === 14 ? '1px solid #4318FF' : undefined,
                };
        }
    };
    return (
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <TableContainer
                sx={{
                    ...(props.tablePagination && {
                        height: props.height ? props.height : 'calc(100vh - 240px)',
                        '@media (max-width: 1720px)': {
                            height: 'calc(100vh - 225px)'
                        },
                        '@media (max-width: 1600px)': {
                            height: 'calc(100vh - 185px)'
                        },
                        '@media (max-width: 1440px)': {
                            height: 'calc(100vh - 175px)'
                        },
                        '@media (max-width: 1280px)': {
                            height: 'calc(100vh - 140px)'
                        },

                    })
                }}
            >
                <Table
                    className={`${styles.datatable} ${props.className}`}
                    stickyHeader={true}
                    data-cy={'demand-view-table'}
                    aria-label='sticky table'
                    size='small'
                >
                    <TableHead
                        className={
                            props?.priceModule
                                ? styles.datatablePriceHeader
                                : styles.datatableHeader
                        }
                        data-cy='table-head'
                        sx={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#40404014',
                            zIndex: 1,
                        }}
                    >
                        {columns.map((column, columnIndex) => {
                            return (
                                <TableCell
                                    key={`${column.id}${column.label}${+new Date()}`}
                                    style={{
                                        minWidth: column.minWidth,
                                        textAlign: (column.textAlign as any) ?? 'left',
                                        backgroundColor:
                                            props['data-cy'] === 'pricing-list'
                                                ? '#f5f5f5'
                                                : '#fafafa',
                                        cursor: column.allowSort ? 'pointer' : 'auto',
                                        maxWidth: column.maxWidth,
                                        width: column.width,
                                        ...getHeaderBorderStyles({ columnIndex })
                                    }}
                                    data-cy={`table-head-${column.id}`}
                                >
                                    {column.allowSort ? (
                                        <div
                                            className={styles.datatableHeaderCell}
                                            data-cy={column.label}
                                        >
                                            <b>{column.label}</b>
                                        </div>
                                    ) : (
                                        <>
                                            {column.tooltip ? <b style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>{column.label}
                                                <Tooltip
                                                    title={column.tooltip}
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: 'common.white',
                                                                color: 'black',
                                                                borderStyle: 'solid',
                                                                borderWidth: '1px',
                                                            },
                                                        },
                                                    }}
                                                    followCursor={false}
                                                    placement={'bottom-start'}
                                                    enterNextDelay={500}
                                                >
                                                    <InfoOutlined style={{ width: '18px' }} />
                                                </Tooltip>
                                            </b> : <b>{column.label}</b>
                                            }
                                        </>
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableHead>
                    <TableBody className={defaultSelect ? `${styles.datatableBody2}  ${styles.datatableBody}` : styles.datatableBody} data-cy='tableContents' sx={{
                    }}>
                        {rows?.map((row: any, rIdx: number) => {
                            return (
                                <>
                                    {handleGetBody({ rIdx, row: { ...row }, border })}
                                </>
                            );
                        })}
                        {props.rows?.length === 0 ? (
                            <TableRow
                                className={styles.datatableEmptyMessage}
                                data-cy='no_record_text_field'
                            >
                                <TableCell
                                    className={styles.datatableEmptyMessageCell}
                                    data-cy='no-records'
                                    colSpan={12}
                                >
                                    {`${translate('general.no_record_found')}`}
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </TableContainer>

            {props.tablePagination && <EVESPagination
                {...{
                    onPageChange,
            onRowsPerPageChange,
            count,
            page,
            rowsPerPage: limit,
                }}
            />}
        </Grid>
    );
};
