import {
  Business,
  DirectionsCar,
  EvStation,
  List,
  People,
  History,
  Badge,
  SettingsApplications,
  AccountBalance,
  Assignment,
  Settings,
  Assessment,
  InsertChart,
  ViewWeek,
  GridView
  
} from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { IMenuItem } from '../models/menuItem';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { TenantComponents } from '../enums/enums';

export const MenuItems: IMenuItem[] = [
  {
    path: '/dashboard',
    name: 'general.menu.dashboard',
    icon: InsertChart,
  },
  {
    path: '/tenants',
    name: 'general.menu.tenant_sa',
    icon: AccountBalance,
  },
  {
    path: '/charging-stations',
    name: 'general.menu.charging_stations',
    icon: EvStation,
  },
  {
    path: '/energy-storage',
    name: 'general.menu.energy_storages',
    icon: ViewWeek,
    component: TenantComponents.ASSET,
  },
  {
    path: '/transactions',
    name: 'general.menu.transactions',
    icon: History,
  },
  {
    path: '/gridify',
    name: 'general.menu.gridify',
    icon: GridView,
    isVisibleWithoutPermission: false,
    component: TenantComponents.GRIDIFY,
  },
  {
    path: '/invoices',
    name: 'Financials',
    icon: ReceiptIcon,
    isVisibleWithoutPermission: false,
    component: TenantComponents.BILLING,
  },
  {
    path: '/cars',
    name: 'general.menu.cars',
    icon: DirectionsCar,
    isVisibleWithoutPermission: false,
    component: TenantComponents.CAR,
  },
  {
    path: '/users',
    name: 'general.menu.users',
    icon: People,
  },
  {
    path: '/tags',
    name: 'general.menu.tags',
    icon: Badge,
  },
  {
    path: '/assets',
    name: 'Assets',
    icon: AccountBalanceIcon,
    component: TenantComponents.ASSET,
  },
  {
    path: '/organizations',
    name: 'general.menu.organisations',
    icon: Business,
    component: TenantComponents.ORGANIZATION,
  },
  {
    path: '/statistics',
    name: 'general.menu.statistics',
    icon: Assessment,
    component: TenantComponents.STATISTICS,
  },
  {
    path: '/integration-settings',
    name: 'general.menu.integration_settings',
    icon: Settings,
  },
  {
    path: '/technical-settings',
    name: 'general.menu.technical_settings',
    icon: SettingsApplications,
  },
  {
    path: '/charging-station-templates',
    name: 'Charging Station Templates',
    icon: Assignment,
  },
  {
    path: '/logs',
    name: 'general.menu.logs',
    icon: List,
  },
];
