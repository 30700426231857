import { useEffect } from 'react';
import { Money, FormatListNumberedRtl, Rule } from '@mui/icons-material';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { DimensionFormModel, IPriceFormPropsModel, PriceFormModel, RestrictionFormModel, StaticRestrictionFormModel } from '@/modules/organization/shared/models/price';
import { UseFormReturn } from 'react-hook-form';
import Pricing from './pricing';
import Dimension from './dimensions';
import Restriction from './restriction';
import { createPricingDefinition, getPriceDataById, updatePricingDefinition } from '@/services/organizations';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { formSchema } from '../../schemas/priceFormSchema';

const PriceForm = (props: IPriceFormPropsModel) => {
    const { t: translate } = useTranslation();
    const priceForm = props.formService as UseFormReturn<PriceFormModel, any>;
    const isFormDirty: boolean = priceForm.formState.isDirty || false;
    const isSaveEnabled = (priceForm.formState.isValid && isFormDirty);

    const onHandelClose = () => {
        if (isFormDirty === true) {
            if (priceForm.formState.isValid) {
                props.dialogService?.showConfirm(
                    {
                        confirmType: 'DIRTY_CHANGE_CLOSE',
                    },
                    (result: any) => {
                        if (result === 'SAVE') {
                            priceForm.handleSubmit(handleSubmit)();
                            props.handleClose(false);
                            props.resetPriceform('');
                        } else if (result === 'CLOSE') {
                            props.handleClose(false);
                            props.resetPriceform('');
                        }
                    }
                );
            }
            else {
                props.dialogService?.showConfirm(
                    {
                        confirmType: 'INVALID_CHANGE_CLOSE',
                    },
                    (result: string) => {
                        if (result === 'CLOSE') {
                            props.handleClose(false);
                            props.resetPriceform('');
                        }
                    }
                );
            }
        } else {
            props.handleClose(false);
            props.resetPriceform('');
        }
    };
    useEffect(() => {
        getPrice();
    }, []);
    const getPrice = async () => {
        if (props.selectedPriceId) {
            getPriceData();
        }
    };
    const { refetch: getPriceData } = useQuery(['data', props.selectedPriceId], () => {
        const payload = { 'WithEntityInformation': true };
        return getPriceDataById(props.selectedPriceId, payload);
    }, {
        enabled: false,
        onSuccess: (res: any) => {
            priceForm.reset({
                name: res.name,
                description: res.description,
                entityID: res.entityID,
                entityType: res.entityType,
                dimensions: DimensionFormModel.restoreModel(res.dimensions),
                restrictions: RestrictionFormModel.restoreModel(res.restrictions),
                staticRestrictions: StaticRestrictionFormModel.restoreModel(res.staticRestrictions)
            });
        }
    });

    const handleSubmit = (payload: PriceFormModel) => {
        //JIRA: [EVES-1720] Updated entityID and entityType dynamically
        payload.entityID = priceForm?.getValues()?.entityID || props.entityID;
        payload.entityType = priceForm?.getValues()?.entityType || props.entityType;
        const formDataToSend = PriceFormModel.buildModel(payload);
        if (props.selectedPriceId) {
            updatePricingDefinition(props.selectedPriceId, { ...formDataToSend, id: props.selectedPriceId }).then((res: any) => {
                if (res.status === 'Success') {
                    props.notificationService?.notify({
                        message: `${translate('settings.pricing.pricing_definition_update_success')}`,
                        type: 'success',
                    });
                    props.handleClose(false);
                    props.resetPriceform('');
                }
                else {
                    props.notificationService?.notify({
                        message: `${translate('settings.pricing.pricing_definition_update_error')}`,
                        type: 'error',
                    });
                }
            });
        } else {
            createPricingDefinition(formDataToSend).then((res: any) => {
                if (res.status === 'Success') {
                    props.notificationService?.notify({
                        message: `${translate('settings.pricing.pricing_definition_creation_success')}`,
                        type: 'success',
                    });
                    props.handleClose(false);
                    props.resetPriceform('');
                }
                else {
                    props.notificationService?.notify({
                        message: `${translate('settings.pricing.pricing_definition_creation_error')}`,
                        type: 'error',
                    });
                }
            });
        }
    };

    const tabsConfig = [
        {
            name: props.siteName ? 'Pricing - ' + props.siteName : 'Pricing - BUILDING MANAGEMENT ',
            icon: < Money />,
            component: <Pricing priceForm={priceForm} />,
            value: '1',
            visible: true
        },
        {
            name: `${translate('settings.pricing.pricing_dimensions_title')}`,
            icon: <FormatListNumberedRtl />,
            component: <Dimension priceForm={priceForm} />,
            value: '2',
            visible: true
        },
        {
            name: `${translate('settings.pricing.restrictions_title')}`,
            icon: <Rule />,
            component: <Restriction priceForm={priceForm} />,
            value: '3',
            visible: true
        }
    ];

    return (
        <div>
            <EvesModal isOpen={props.show} maxHeight={'400px'} maxWidth={'1200px'}
                isSaveDisabled={!isSaveEnabled}
                onHandleSave={priceForm.handleSubmit(handleSubmit)}
                onHandleClose={onHandelClose}
                modalType='tabs'
                tabsConfig={tabsConfig}
            >
            </EvesModal>
        </div>
    );
};

export default withContext(
    withUseFormHook(PriceForm, {
        schema: formSchema,
        defaultValues: { ...new PriceFormModel() }
    })
);