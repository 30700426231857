import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { Content } from '@/common/components/templates/content/content';
import { DefaultPageSize, filterSelector } from '@/common/constants/constants';
import {
  Filters,
  LookupEntityType,
  TenantComponents,
  TransactionStatisticsType,
} from '@/common/enums/enums';
import {
  canListCars,
  canListChargingStations,
  canListInvoicesBilling,
  canListTags,
  canListUsers,
  canUpdateCar,
  isActive,
  isAdmin,
  isBasic,
  isSiteAdminAcess,
  isSiteOwnerAcess,
} from '@/common/utils/utils';
import { TransactionsHistoryModal } from '@/services/models/session';
import { Close, OpenInNew, RemoveRedEye } from '@mui/icons-material';
import { Grid, InputAdornment, Stack } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import InActivityCell from '../../shared/components/inActiveCell';
import { sessionStatsMapping } from '../../shared/sessions';
import {
  exportSessions,
  getInhistoryDashboard,
  getTransactionsHistory,
} from '@/services/session';
import { ITransactionsHistoryStateModal } from '../../shared/models/history';
import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import LookupComponent from '@/common/components/lookupComponent';
import { CellStatus } from '@/common/components/organisms/table/dataTableCells';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { OWNER_FILTER_ITEMS as Owner } from '@/common/constants/constants';
import OwnerFilter from '@/common/components/ownerFilter';
import FilterSelector from '@/common/components/filterSelector';
import { SearchBox } from '@/common/components/searchBox';
import { useLocation } from 'react-router-dom';
import { columnsType } from '@/common/models/model';
import ConsumptionChart from '@/common/components/molecules/consumptionChart/chart';
import DatetimeRangePicker from '@/common/components/atoms/datetimeRangePicker/datetimeRangePicker';
import { getUserInformation } from '@/services/userProfile';
import { useTranslation } from 'react-i18next';
import CommonCard from '../../shared/components/common-card';
import session from '../../../../assets/img/sessions/active-sessions.svg';
import duration from '../../../../assets/img/sessions/duration.svg';
import inactive from '../../../../assets/img/sessions/inactivity.svg';
import consumption from '../../../../assets/img/sessions/consumption.svg';
import financials from '../../../../assets/img/sessions/financials.svg';
import TransactionDetails from '@/common/components/templates/transactionDetails';
import saveAs from 'file-saver';
import { Loader } from '@/common/components/atoms/loader/loader';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';
import { autoRefresh } from '@/common/utils/autoRefreshConfig';
const TrasactionsHistory = (props: IContextPropsModel) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const firstParam = location?.search?.split('&')[0];
  const parameterCharger =
    firstParam !== '' ? firstParam?.slice(1).split('=') : [];
  const searchParams = new URLSearchParams(location.search);
  const [userId, setUserId] = useState(null);
  const [visualID, setVisualID] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  startDate.setSeconds(0);
  const endDate = new Date();
  endDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);
  const subStationIdLookupRef: any = useRef(null);
  const transformerIdLookupRef: any = useRef(null);
  const feederIdLookupRef: any = useRef(null);
  const isBasicUser = isBasic()
  const siteAdminAccess = isSiteAdminAcess()
  const siteOwnerAccess = isSiteOwnerAcess()
  const [state, setStateData] = useState({
    connectorID: null,
    defaultIssuer: true,
    isResetBtnDisabled: true,
    issuer: true,
    isTransactionDetailModalOpen: false,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    rows: [],
    stats: [],
    search: '',
    selectedChargingStationID: '',
    selectedConnectorID: -1,
    selectedTransactionId: -1,
    selectedRecords: [],
    siteID: '',
    siteAreaID: '',
    sortFields: '-timestamp',
    totalRowsCount: 0,
    userID: userId ? userId : '',
    visualTagID: visualID ? visualID : '',
    isChargingStationLookupModalOpen: false,
    chargingStationName:
      parameterCharger?.length > 0 ? parameterCharger[1] : '',
    chargingStationId: parameterCharger?.length > 0 ? parameterCharger[1] : '',
    inactivityStatus: [],
    userName: '',
    showRFIDFilterModal: false,
    showUserLookUpModal: false,
    RFIDFilterValue: '',
    issuerName: '',
    companyName: '',
    siteName: '',
    siteAreaName: '',
    canExport: false,
    canListSiteAreas: false,
    canListSites: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    withSiteArea: true,
    withSite: true,
    withCompany: true,
    withTag: true,
    withCar: true,
    withUser: true,
    statistics: '',
    subStationID: '',
    subStationName: '',
    feederID: '',
    feederName: '',
    transformerID: '',
    transformerName: '',
    isSubStationIdLookupModalOpen: false,
    isFeederIdLookupModalOpen: false,
    isTransformerIdLookupModalOpen: false,
    onlyRecordCount: true,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  } as ITransactionsHistoryStateModal);
  const isOrganizationComponentActive = isActive(TenantComponents.ORGANIZATION);
  const isUserAdmin = isAdmin();
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const isUserCanListUsers = canListUsers();
  const isUserCanListCars = canListCars();
  const isUserCanUpdateCar = canUpdateCar();
  const isUserCanListTags = canListTags();
  const isUserCanListChargingStations = canListChargingStations();
  const isUserCanListInvoicesBilling = canListInvoicesBilling();
  const defaultSortColumn = '-timestamp';
  const chargingStationLookupRef: any = useRef(null);
  const UserLookupRef: any = useRef(null);
  const RFIDLookupRef: any = useRef(null);
  const siteLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  const searchBoxRef = useRef<any>();
  const dataTableRef = useRef<any>();
  const consumptionChartRef = useRef<any>();
  const [leftPosition, setLeftPosition] = useState<number>(0);

  let isOnClickSiteFilterClear = false;
  let isOnClickSiteAreaFilterClear = false;
  const { refetch: fetchTransactionsHistory } = useQuery(
    'TransactionHistory',
    () => {
      return getTransactionsHistory(getTransactionHistoryParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;
          const canExport = data.canExport;
          const transactionsData = data.result.map(
            (d: any) => new TransactionsHistoryModal(d)
          );
          setStateData((currentData) => {
            return {
              ...currentData,
              rows: transactionsData,
              // totalRowsCount: data.count,
              // stats: data.stats,
              canListSites,
              canListSiteAreas,
              canExport,
              projectFields: data.projectFields && [...data.projectFields],
            };
          });
          consumptionChartRef?.current?.refreshChart();
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const { refetch: fetchHistoryDashboard } = useQuery(
    'HistoryDashboard',
    () => {
      return getInhistoryDashboard(getTransactionHistoryParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          setStateData((currentData) => {
            return {
              ...currentData,
              stats: data.data,
            };
          });
          consumptionChartRef?.current?.refreshChart();
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const { refetch: fetchTransactionsHistoryCount } = useQuery(
    'TransactionHistoryCount',
    () => {
      const getTransactionParameters = getTransactionHistoryParams(true);
      return getTransactionsHistory(getTransactionParameters);
    },
    {
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          setStateData((currentData) => {
            return {
              ...currentData,
              totalRowsCount: data.count,
            };
          });
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const getTransactionHistoryParams = (count = false): any => {
    const firstParam = location?.search?.split('&')[0];
    const parameterCharger = firstParam?.slice(1).split('=');
    const TransactionHistoryGetParams: any = {
      Limit: state.limit,
      Page: state.page + 1,
      SortFields: state.sortFields,
      Issuer: state.issuer,
      ChargingStationID: location.search.includes('?ChargingStationID')
        ? parameterCharger[1]
        : state.chargingStationId,
    };
    TransactionHistoryGetParams.StartDateTime = state.startDate;
    TransactionHistoryGetParams.EndDateTime = state.endDate;

    if (location?.search?.includes('?ChargingStationID')) {
      setDisplayLoader(true);
    }

    if (isOrganizationComponentActive) {
      TransactionHistoryGetParams.WithSite = true;
      TransactionHistoryGetParams.WithCompany = true;
      TransactionHistoryGetParams.WithSiteArea = true;
      TransactionHistoryGetParams.WithTag = true;
      TransactionHistoryGetParams.WithUser = true;
      TransactionHistoryGetParams.WithCar = true;
      TransactionHistoryGetParams.Statistics =
        TransactionStatisticsType.HISTORY;
    }
    if (state.search !== '') {
      TransactionHistoryGetParams['Search'] = state.search;
    }
    if (count) {
      TransactionHistoryGetParams.OnlyRecordCount = true;
    }
    if (state.issuer !== null) {
      TransactionHistoryGetParams['Issuer'] = state.issuer;
    }
    if (state.defaultIssuer) {
      TransactionHistoryGetParams['Issuer'] = true;
    } else {
      if (state.issuerName === Owner.CurrentOrgAPI) {
        TransactionHistoryGetParams['Issuer'] = true;
      }
      if (state.issuerName === Owner.ExternalOrgAPI) {
        TransactionHistoryGetParams['Issuer'] = false;
      }
    }
    if (state.siteID !== '') {
      TransactionHistoryGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      TransactionHistoryGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      TransactionHistoryGetParams['UserID'] = state.userID;
    }
    if (state.connectorID !== -1) {
      TransactionHistoryGetParams['ConnectorID'] = state.connectorID;
    }
    if (state.subStationID !== '') {
      TransactionHistoryGetParams['SubStationID'] = state.subStationID;
    }
    if (state.feederID !== '') {
      TransactionHistoryGetParams['FeederID'] = state.feederID;
    }
    if (state.transformerID !== '') {
      TransactionHistoryGetParams['TransformerID'] = state.transformerID;
    }
    if (state.inactivityStatus.length > 0) {
      let inactivityStatus = state.inactivityStatus[0];
      for (let i = 1; i < state.inactivityStatus.length; i++)
        inactivityStatus += '|' + state.inactivityStatus[i];
      TransactionHistoryGetParams['InactivityStatus'] = inactivityStatus;
    }
    const visualTagID: any = searchParams.get('VisualTagID');
    if (state.visualTagID !== '') {
      TransactionHistoryGetParams['VisualTagID'] = state.visualTagID;
    }
    if (!visualID && visualTagID) {
      setVisualID(visualTagID);
      setStateData((currentData) => {
        return {
          ...currentData,
          visualTagID: visualTagID,
          RFIDFilterValue: visualTagID,
          isResetBtnDisabled: false,
        };
      });
      TransactionHistoryGetParams['VisualTagID'] = visualTagID;
    }

    const paramUserId: any = searchParams.get('UserID');
    if (!userId && paramUserId) {
      setUserId(paramUserId);
      getUserInformation(paramUserId).then((res: any) => {
        setStateData((currentData) => {
          return {
            ...currentData,
            userID: paramUserId,
            userName: res.firstName + ' ' + res.name,
            isResetBtnDisabled: false,
          };
        });
      });
      TransactionHistoryGetParams['UserID'] = paramUserId;
    }
    if (state.inactivityStatus.length > 0) {
      let inactivityStatus = state.inactivityStatus[0];
      for (let i = 1; i < state.inactivityStatus.length; i++)
        inactivityStatus += '|' + state.inactivityStatus[i];
      TransactionHistoryGetParams['InactivityStatus'] = inactivityStatus;
    }
    return TransactionHistoryGetParams;
  };

  const ChargingStationCell = (row: any) => {
    if (!row.chargeBoxID)
      return <div style={{ textAlign: 'center' }}>{'-'}</div>;
    return <>{row.chargeBoxID}</>;
  };

  const columns: columnsType[] = [
    {
      id: 'actions',
      label: `${translate('general.actions')}`,
      minWidth: 90,
      textAlign: 'center',
      maxWidth: 170,
      allowSort: false,
      projectField: 'action',
    },
    {
      id: 'id',
      label: `${translate('transactions.sessionId')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'id',
      visible: isUserAdmin,
    },
    {
      id: 'timestampDateFormated',
      label: `${translate('transactions.started_at')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 180,
      allowSort: true,
      sortKey: 'timestamp',
      projectField: 'timestamp',
    },
    {
      id: 'EndDateFormated',
      label: `${translate('transactions.end_date')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 180,
      allowSort: true,
      sortKey: 'stop.timestamp',
      projectField: 'stop.timestamp',
    },
    {
      id: 'getReason',
      label: `${translate('transactions.stop_reason')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'stop.reason',
    },
    {
      id: 'chargeBoxID',
      label: `${translate('transactions.charging_station')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 200,
      allowSort: true,
      sortKey: 'chargeBoxID',
      projectField: 'chargeBoxID',
    },
    {
      id: 'getConnectorLetter',
      label: `${translate('chargers.connector')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'connectorId',
    },
    {
      id: 'getStopTotalDuration',
      label: `${translate('transactions.duration')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'stop.totalDurationSecs',
    },
    {
      id: 'getStopTotalInactivitySecs',
      label: `${translate('transactions.inactivity')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'stop.totalInactivitySecs',
    },
    {
      id: 'getStopTotalConsumptionWh',
      label: `${translate('transactions.consumption')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'stop.totalConsumptionWh',
    },
    {
      id: 'getStopStateOfCharge',
      label: `${translate('transactions.state_of_charge')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'stateOfCharge',
    },
    {
      id: 'getStopRoundedPrice',
      label: `${translate('transactions.price')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'stop.roundedPrice',
      visible: isActive(TenantComponents.PRICING),
    },
    {
      id: 'companyName',
      label: `${translate('companies.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'company.name',
      visible: isActive(TenantComponents.ORGANIZATION),
    },
    {
      id: 'siteName',
      label: `${translate('sites.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'site.name',
      visible: isActive(TenantComponents.ORGANIZATION),
    },
    {
      id: 'siteAreaName',
      label: `${translate('site_areas.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'siteArea.name',
      visible: isActive(TenantComponents.ORGANIZATION),
    },
    {
      id: 'userFullName',
      label: `${translate('transactions.user')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 190,
      allowSort: false,
      projectField: 'user',
      visible: isUserCanListUsers,
    },
    {
      id: 'tagID',
      label: `${translate('tags.id')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'tagID',
      visible: isUserCanListUsers,
    },
    // {
    //   id: 'tagVisualID',
    //   label: `${translate('tags.visual_id')}`,
    //   minWidth: 60,
    //   textAlign: 'left',
    //   maxWidth: 150,
    //   allowSort: false,
    //   projectField: 'tag.visualID',
    //   visible: isUserCanListUsers,
    // },
    // {
    //   id: 'tagDescription',
    //   label: `${translate('general.description')}`,
    //   minWidth: 60,
    //   textAlign: 'left',
    //   maxWidth: 150,
    //   allowSort: false,
    //   projectField: 'tag.description',
    //   visible: isUserCanListUsers,
    // },
    {
      id: 'getCarCatalog',
      label: `${translate('car.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: true,
      sortKey: 'carCatalog',
      projectField: 'carCatalog',
      visible: isActive(TenantComponents.CAR) && isUserCanListCars,
    },
    {
      id: 'carLicensePlate',
      label: `${translate('cars.license_plate')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: true,
      sortKey: 'car.licensePlate',
      projectField: 'car.licensePlate',
      visible: isActive(TenantComponents.CAR) && isUserCanUpdateCar,
    },
    // {
    //   id: 'getInvoiceNumber',
    //   label: `${translate('invoices.number')}`,
    //   minWidth: 60,
    //   textAlign: 'left',
    //   maxWidth: 150,
    //   allowSort: false,
    //   projectField: 'billingData.stop.invoiceNumber',
    //   visible:
    //     isActive(TenantComponents.BILLING) && isUserCanListInvoicesBilling,
    // },
    // {
    //   id: 'siteAreaFeederId',
    //   label: `${translate('Feeder')}`,
    //   minWidth: 60,
    //   textAlign: 'center',
    //   maxWidth: 150,
    //   allowSort: true,
    //   sortKey: 'siteArea.feederID',
    //   projectField: 'siteArea.feederID',
    //   visible: true,
    // },
    // {
    //   id: 'siteAreaTransformerID',
    //   label: `${translate('Transformer')}`,
    //   minWidth: 60,
    //   textAlign: 'center',
    //   maxWidth: 150,
    //   allowSort: true,
    //   sortKey: 'siteArea.transformerID',
    //   projectField: 'siteArea.transformerID',
    //   visible: true,
    // },
    {
      id: 'siteAreaSubStationID',
      label: `${translate('Substation')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: true,
      sortKey: 'siteArea.subStationID',
      projectField: 'siteArea.subStationID',
      visible: true,
    },
  ];
  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };

  const onClickTransactionDetailModal = (record: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedConnectorID: record.connectorId,
        selectedTransactionId: record.id,
        selectedChargingStationID: record.chargeBoxID,
        isTransactionDetailModalOpen: true,
      };
    });
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate("general.tooltips.view")}`,
      icon: () => RemoveRedEye,
      iconColor: "primary",
      onClick: onClickTransactionDetailModal,
      visible: () => isUserAdmin, // EVES-1935
      "data-cy": "transactions-view-action",
    },
  ]

  const secondaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('logs.redirect')}`,
      icon: () => OpenInNew,
      iconColor: 'primary',
      onClick: (record: any) => {
        window.open(
          `#/logs?ChargingStationID=${record.chargeBoxID}&StartDateTime=${record.timestamp}&EndDateTime=${record.stop?.timestamp}&LogLevel=I`,
          '_blank'
        );
      },
      visible: (record) => record?.issure || isUserAdmin,
      'data-cy': 'navigate-to-logs',
    },
    {
      title: () =>
        `${translate('chargers.smart_charging.charging_plans.redirect')}`,
      icon: () => OpenInNew,
      iconColor: 'primary',
      onClick: (record: any) => {
        window.open(
          `#/charging-stations/charging-plans?ChargingStationID=${record.chargeBoxID}&TransactionID=${record.id}`,
          '_blank'
        );
      },
      visible: (record) => record?.issure || isUserAdmin,
      'data-cy': 'navigate-to-chargingplans',
    },
  ];

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const onClickresetFilters = () => {
    chargingStationLookupRef?.current?.setFocus();
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: '',
        isResetBtnDisabled: true,
        visualTagID: '',
        siteID: '',
        siteAreaID: '',
        userID: '',
        chargingStationId: '',
        isOnClickSiteFilterClear: true,
        chargingStationName: '',
        connectorID: '',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        siteName: '',
        siteAreaName: '',
        RFIDFilterValue: '',
        userName: '',
        defaultIssuer: true,
        subStationID: '',
        subStationName: '',
        feederID: '',
        feederName: '',
        transformerID: '',
        transformerName: '',
      };
    });
  };

  const onOwnerSelect = (selectedIssuerInfo: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isResetBtnDisabled:
          currentData.search === '' &&
          (selectedIssuerInfo.defaultIssuer ||
            selectedIssuerInfo.issuerName === Owner.CurrentOrgAPI) &&
          !state.siteAreaName &&
          !state.siteName &&
          !state.companyName,
        issuerName: selectedIssuerInfo.issuerName,
        defaultIssuer: selectedIssuerInfo.defaultIssuer,
        issuer: selectedIssuerInfo.issuer
          ? selectedIssuerInfo.issuer
          : state.issuer,
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === '' &&
          currentData.siteName === '' &&
          currentData.siteAreaName === '' &&
          currentData.chargingStationId === '' &&
          (currentData.defaultIssuer ||
            currentData.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const setSiteFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSite = () => {
    isOnClickSiteFilterClear = true;
    siteLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteID: '',
        siteName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteAreaID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };
  // NOTE: for future purpose
  const exportSession = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('transactions.dialog.export.title')}`,
        description: `${translate('transactions.dialog.export.confirm')}`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          const historyParams: any = {
            Issuer: state.issuer,
            StartDateTime: state.startDate,
            EndDateTime: state.endDate,
            WithCompany: state.withCompany,
            WithSite: state.withSite,
            WithSiteArea: state.withSiteArea,
            WithTag: state.withTag,
            WithUser: state.withUser,
            WithCar: state.withCar,
            Statistics: TransactionStatisticsType.HISTORY,
          };
          exportSessions(historyParams).then((res: any) => {
            const blob = new Blob([res]);
            saveAs(blob, 'exported-transactions.csv');
          });
        }
      }
    );
  };

  useEffect(() => {
    fetchTransactionsHistory();
    fetchTransactionsHistoryCount();
    fetchHistoryDashboard();
  }, [
    state.limit,
    state.page,
    state.sortFields,
    state.chargingStationId,
    state.userID,
    state.visualTagID,
    state.issuerName,
    state.siteID,
    state.siteAreaID,
    state.inactivityStatus,
    state.search,
    state.connectorID,
    state.startDate,
    state.endDate,
    state.subStationID,
    state.feederID,
    state.transformerID,
    location,
  ]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        chargingStationID: '',
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const setUserFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: true,
      };
    });
  };

  const onClearSelectedUser = (event: any) => {
    event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        userID: '',
        userName: '',
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const getFormatedRFIDCard = (tag: any) => {
    if (tag.length > 1) {
      return `${tag[0].visualID} (+ ${tag.length - 1})`;
    } else {
      return `${tag[0].visualID}`;
    }
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const setRFIDFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: true,
      };
    });
  };

  const onRFIDFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: false,
      };
    });
  };

  const onClearSelectedRFID = (event: any) => {
    event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: '',
        RFIDFilterValue: '',
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onRFIDCardSelected = (selectedTag: any) => {
    const sortedObj = Object.values(selectedTag).sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
    const selectedItems = sortedObj
      ?.map((sessionTag: any) => sessionTag.visualID)
      .join('|');
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: selectedItems,
        RFIDFilterValue: getFormatedRFIDCard(sortedObj),
        showRFIDFilterModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onSelectedRow = (data: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedRecords: data,
      };
    });
  };

  const StatusCell = (row: any) => {
    return (
      <CellStatus
        label={
          row.active
            ? `${translate('tags.activated')}`
            : `${translate('tags.deactivated')}`
        }
        type={row.active ? 'Available' : 'Faulted'}
      />
    );
  };

  const onInactivitySelect = (selectedItems: string[]) => {
    const updatedItems: any = selectedItems.map((item) => {
      return filterSelector[Filters.INACTIVITY].value[item];
    });
    setStateData((currentData) => {
      return {
        ...currentData,
        isResetBtnDisabled: false,
        inactivityStatus: updatedItems,
      };
    });
    if (selectedItems.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            !state.search &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.chargingStationId &&
            (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
            (state.connectorID == -1 || !state.connectorID) &&
            (state.startDate.toString() == startDate.toISOString() ||
              state.endDate.toString() == endDate.toISOString()) &&
            !state.visualTagID &&
            !state.userID,
        };
      });
    }
  };

  const onConnectorSelect = (connectorID: string[]) => {
    const filter: number[] = [];
    connectorID.forEach((item: string) => {
      filter.push(item[0].charCodeAt(0) - 64);
    });
    setStateData((currentData) => {
      return {
        ...currentData,
        connectorID: filter.join('|'),
        isResetBtnDisabled: false,
      };
    });
    if (connectorID.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            !state.search &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.chargingStationId &&
            (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
            (state.startDate.toString() == startDate.toISOString() ||
              state.endDate.toString() == endDate.toISOString()) &&
            !state.visualTagID &&
            !state.userID &&
            state.inactivityStatus.length == 0,
        };
      });
    }
  };

  const onDateRangeChange = (e: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        startDate: e[0].toISOString(),
        endDate: e[1].toISOString(),
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSubStationIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSubStationIdLookupModalOpen: false };
    });
  };
  const onSubStationIdSelect = (substations: any) => {
    const subStationName =
      substations?.length > 1
        ? `${substations[0].name} (+${substations?.length - 1})`
        : substations?.length && substations[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSubStationIdLookupModalOpen: false,
        subStationID: substations
          .map((subStation: any) => subStation.name)
          .join('|'),
        subStationName: subStationName,
        isResetBtnDisabled: false,
      };
    });
  };
  const setFeederIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isFeederIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };
  const resetFeederIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isFeederIdLookupModalOpen: false };
    });
  };
  const onFeederIdSelect = (feeders: any) => {
    const feederName =
      feeders?.length > 1
        ? `${feeders[0].name} (+${feeders?.length - 1})`
        : feeders?.length && feeders[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isFeederIdLookupModalOpen: false,
        feederID: feeders.map((feeder: any) => feeder.name).join('|'),
        feederName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };
  const onClearSelectedFeederId = () => {
    isOnClickSiteFilterClear = true;
    feederIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        feederID: '',
        feederName: '',
        isFeederIdLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };
  const setTransformerIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isTransformerIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };
  const resetTransformerIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isTransformerIdLookupModalOpen: false };
    });
  };
  const onTransformerIdSelect = (transformer: any) => {
    const feederName =
      transformer?.length > 1
        ? `${transformer[0].name} (+${transformer?.length - 1})`
        : transformer?.length && transformer[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransformerIdLookupModalOpen: false,
        transformerID: transformer.map((trans: any) => trans.name).join('|'),
        transformerName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedTransformer = () => {
    isOnClickSiteFilterClear = true;
    transformerIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        transformerID: '',
        transformerName: '',
        isTransformerIdLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSubStationIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isSubStationIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSubStation = () => {
    isOnClickSiteFilterClear = true;
    subStationIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        subStationID: '',
        subStationName: '',
        isSubStationLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };
  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };

  //Mapping Stats value for InProgess
  const stats = sessionStatsMapping(state.stats);
  const FeederFilter = state.subStationID ? false : true;
  const TransformFilter = state.feederID ? false : true;
  return (
    <>
      {<Loader showLoader={displayLoader} />}
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate("users.select_users")}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode="multiple"
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: "Issuer", value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn="name"
          data-cy="session-select-user-lookup"
        />
      )}

      {state.showRFIDFilterModal && (
        <LookupComponent
          data-cy="RFIDLookUpModal"
          title={`${translate("tags.select_tags")}`}
          showLookupModal={state.showRFIDFilterModal}
          onModalClose={onRFIDFilterModalClose}
          selectionMode="multiple"
          entityType={LookupEntityType.SESSION_RFID_TAG}
          tableChildren={<StatusCell slot="statusCustomValue" />}
          params={
            state.userID
              ? [
                  { key: "Issuer", value: true },
                  { key: "WithUser", value: true },
                  { key: "UserID", value: state.userID },
                ]
              : [
                  { key: "Issuer", value: true },
                  { key: "WithUser", value: true },
                ]
          }
          onSelect={onRFIDCardSelected}
          defaultSortColumn="id"
        />
      )}

      <Content
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item xs={10} lg={9} sm={6} md={8} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    <Grid
                      data-cy="owner-dropdown-filter"
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2.2}
                    >
                      <OwnerFilter
                        defaultIssuer={state.defaultIssuer}
                        onChange={onOwnerSelect}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                      <Stack>
                        <DatetimeRangePicker
                          isDefaultRangeShortcut={true}
                          defaultStartDate={startDate}
                          defaultEndDate={endDate}
                          startDate={state.startDate}
                          endDate={state.endDate}
                          cleanable={false}
                          onDateRangeChange={onDateRangeChange}
                        />
                      </Stack>
                    </Grid>
                    <Grid
                      data-cy="owner-dropdown-filter"
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2.2}
                    >
                      <FilterSelector
                        filterOption={Filters.INACTIVITY}
                        defaultIssuer={state.defaultIssuer}
                        onChange={onInactivitySelect}
                        isResetBtnDisabled={state.isResetBtnDisabled}
                      />
                    </Grid>
                    {isOrganizationComponentActive && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                        <EvesTextbox
                          value={state.siteName}
                          id="history-standard-search"
                          label={`${translate("general.search_site")}`}
                          data-cy="select-site"
                          type="text"
                          variant="standard"
                          onClick={setSiteFilter}
                          onChange={onFilterApplied}
                          ref={siteLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSite}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isOrganizationComponentActive && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                        <EvesTextbox
                          value={state.siteAreaName}
                          id="inProgress-standard-search"
                          label={`${translate("site_areas.titles")}`}
                          data-cy="select-site-area"
                          type="text"
                          variant="standard"
                          onClick={setSiteAreaFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={siteAreaLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSiteArea}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListChargingStations && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                        <EvesTextbox
                          value={state.chargingStationName}
                          style={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                          id="history-charging-station-lookup"
                          label={`${translate("chargers.chargers")}`}
                          type="text"
                          variant="standard"
                          data-cy="history-charging-station-lookup"
                          onClick={setChargingStationFilter}
                          onChange={onFilterApplied}
                          ref={chargingStationLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedChargingStation}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListChargingStations && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                        <FilterSelector
                          filterOption={Filters.CONNECTORS}
                          defaultIssuer={state.defaultIssuer}
                          onChange={onConnectorSelect}
                          isResetBtnDisabled={state.isResetBtnDisabled}
                          data-cy="connector-filter"
                        />
                      </Grid>
                    )}
                    {isUserCanListUsers && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                        <EvesTextbox
                          value={state.userName}
                          id="User-standard-search"
                          label={`${translate("sites.users")}`}
                          data-cy="User-filter"
                          type="text"
                          variant="standard"
                          onClick={setUserFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={UserLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedUser}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListTags && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.2}>
                        <EvesTextbox
                          value={state.RFIDFilterValue}
                          id="RFID-standard-search"
                          label={`${translate("users.tags")}`}
                          data-cy="RFID-filter"
                          type="text"
                          variant="standard"
                          onClick={setRFIDFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={RFIDLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedRFID}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}

                    <Grid
                      data-cy="search-field"
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={9}
                      xl={2.2}
                    >
                      <SearchBox
                        ref={searchBoxRef}
                        onChange={onFilterApplied}
                        onRemoveClick={fetchTransactionsHistory}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item gap={"10px"} mt={1} xs={2} sm={6} md={4} lg={3} xl={3}>
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    multiple: true,
                    intervalsAndFunctions: [
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: fetchTransactionsHistory,
                      },
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: fetchHistoryDashboard,
                      },
                    ],
                    onClickExport: exportSession,
                    isExport: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={"12px"} xs={12} my={"12px"}>
              <Grid item xs={3}>
                <CommonCard
                  img={session}
                  historyTab={true}
                  headerText="Total Sessions"
                  footerText={stats?.session}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCard
                  img={duration}
                  historyTab={true}
                  headerText="Duration"
                  footerText={stats?.totalDurationSecs}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCard
                  img={inactive}
                  historyTab={true}
                  headerText="Inactivity"
                  footerText={stats?.totalInactivitySecs}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCard
                  img={consumption}
                  historyTab={true}
                  headerText="Consumption"
                  footerText={stats?.consumption}
                />
              </Grid>
              {/* <Grid item xs={2.4}>
                <CommonCard
                  img={financials}
                  historyTab={true}
                  headerText='Financials'
                  footerText={stats?.price}
                />
              </Grid> */}
            </Grid>
          </Grid>
        }
        contentBody={
          <DataTable
            ref={dataTableRef}
            className="transaction-history-table"
            rows={state.rows}
            columns={columns}
            totalRowsCount={state.totalRowsCount}
            actionsMenu={primaryActions}
            contextActionsMenu={secondaryActions}
            onChange={onTableChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            onRowSelectionChange={onSelectedRow}
            defaultSortField={defaultSortColumn}
            defaultSortDescending={true}
            projectFields={state.projectFields}
            count={state.totalRowsCount}
            limit={state.limit}
            page={state.page}
            tablePagination={true}
            data-cy="transactions-history-table"
            componentName="Session"
            setLeftPosition={setLeftPosition}
          >
            <ChargingStationCell slot="chargeBoxID" />
            {/* EVES-1935 */}
            {isUserAdmin && (
              <ConsumptionChart //3insys.atlassian.net/browse/EVES-1790
                ref={consumptionChartRef}
                slot="row-detail"
                popupOpen={state.isTransactionDetailModalOpen}
                leftPosition={leftPosition}
              />
            )}

            <InActivityCell slot="getStopTotalInactivitySecs" />
          </DataTable>
        }
      ></Content>
      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy="sessions-charging-plan-lookup"
          title={`${translate("chargers.select_id")}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                  { key: "Issuer", value: state.issuer },
                  { key: "SiteAreaID", value: state.siteAreaID },
                ]
              : [{ key: "Issuer", value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn="id"
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate("sites.select_sites")}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.SITE}
          params={[{ key: "Issuer", value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn="name"
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate("site_areas.select_site_areas")}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: "Issuer", value: null },
            { key: "SiteID", value: state.siteID || null },
            { key: "WithSite", value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn="name"
        />
      )}
      {state.isTransactionDetailModalOpen && (
        <TransactionDetails
          transactionId={state.selectedTransactionId}
          chargingStationId={state.selectedChargingStationID}
          connectorId={state.selectedConnectorID}
          onClose={onTransactionDetailModalClose}
          withStopedBy={true}
          popupOpen={state.isTransactionDetailModalOpen}
        />
      )}
      {state.isSubStationIdLookupModalOpen && (
        <LookupComponent
          title={`${translate("site_areas.select_substation_id")}`}
          showLookupModal={state.isSubStationIdLookupModalOpen}
          onModalClose={resetSubStationIdFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.SUBSTATIONID}
          params={[
            { key: "Issuer", value: state.issuer },
            { key: "SubStationID", value: state.subStationID || null },
            { key: "WithSite", value: true },
          ]}
          onSelect={onSubStationIdSelect}
          defaultSortColumn="name"
        />
      )}
      {state.isFeederIdLookupModalOpen && (
        <LookupComponent
          title={`${translate("site_areas.select_feeder_id")}`}
          showLookupModal={state.isFeederIdLookupModalOpen}
          onModalClose={resetFeederIdFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.FEEDERID}
          params={[
            { key: "Issuer", value: state.issuer },
            { key: "FeederID", value: state.feederID || null },
            { key: "WithSite", value: true },
            { key: "SubStationID", value: state.subStationID || null },
          ]}
          onSelect={onFeederIdSelect}
          defaultSortColumn="name"
        />
      )}
      {state.isTransformerIdLookupModalOpen && (
        <LookupComponent
          title={`${translate("site_areas.select_transformer_id")}`}
          showLookupModal={state.isTransformerIdLookupModalOpen}
          onModalClose={resetTransformerIdFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.TRANSFORMERID}
          params={[
            { key: "Issuer", value: state.issuer },
            { key: "TransformerID", value: state.transformerID || null },
            { key: "WithSite", value: true },
            { key: "FeederID", value: state.feederID || null },
            { key: "SubStationID", value: state.subStationID || null },
            { key: "FeederID", value: state.feederID || null },
          ]}
          onSelect={onTransformerIdSelect}
          defaultSortColumn="name"
        />
      )}
    </>
  )
};

export default withContext(TrasactionsHistory);
