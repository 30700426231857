import { Box, MenuItem, Menu, Typography, Icon } from '@mui/material';
import DefaultAvatar from '@/assets/img/user-default.png';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserContext } from '@/store/auth/reducer';
import { PortraitOutlined, LockOutlined } from '@mui/icons-material';
import styles from './userProfile.module.scss';
import { IMenuItem } from '@/common/models/menuItem';
import { IUserContextModel } from '@/store/auth/types';
import { getUserImage, logoutUser } from '@/services/auth';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { blobToBase64String } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const UserMenuHeader = () => {
  const [state, setStateData] = useState({
    avatar: DefaultAvatar,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { t: translate, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MAX_DISPLAY_CHARECTER: number = 15;
  const authContext: IUserContextModel = useSelector(
    (state: any) => state.userContext
  );

  const { refetch: fetchUserImage } = useQuery(
    'user-image',
    () => {
      return getUserImage(authContext.userInfo.userId);
    },
    {
      enabled: false,
      onSuccess: async (data: any) => {
        if (data) {
          const base64String = await blobToBase64String(data);
          setStateData((currentData) => {
            return {
              ...currentData,
              avatar: base64String || DefaultAvatar,
            };
          });
        }
      },
    }
  );
  const handleDisplayName = (name: string, maxCharacter: number) => {
    if (name.length > maxCharacter)
      return name.substring(0, maxCharacter) + '...';
    return name;
  };

  const userInfoContext: IUserContextModel = useSelector(
    (state: any) => state.userContext
  );
  const userLanguage = userInfoContext.userInfo.language;

  useEffect(() => {
    if (authContext.userInfo.userId) {
      fetchUserImage();
    }
    i18n.changeLanguage(userLanguage);
  }, []);

  const onSignOutClick = () => {
    logoutUser();
    dispatch(resetUserContext());
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_info');
  };

  const onProfileHandler = () => {
    navigate('/profile');
  };

  const menuItems: IMenuItem[] = [
    {
      path: '/profile',
      name: `${translate('users.profile')}`,
      icon: PortraitOutlined,
      onClick: onProfileHandler,
    },
    {
      path: '/login',
      name: `${translate('authentication.sign_out')}`,
      icon: LockOutlined,
      onClick: onSignOutClick,
      isVisibleWithoutPermission: true,
    },
  ];
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';

  return (
    <div data-cy='expand-icon'>
      <div
        aria-label='account of current user'
        aria-controls={menuId}
        aria-haspopup='true'
        className={`${styles.userAccount} ${
          anchorEl !== null ? styles.userAccountActive : ''
        }`}
        onClick={handleProfileMenuOpen}
      >
        <div className={styles.userProfilePhoto}>
          <img
            className={styles.userProfileIcon}
            src={state.avatar}
            alt='icon'
          />
        </div>
        <Typography component={'span'} className={styles.userProfileRole}>
          {handleDisplayName(
            authContext.userInfo.firstName + '',
            MAX_DISPLAY_CHARECTER
          )}
        </Typography>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            borderRadius: '0px',
            background: '#FFFFFF',
            marginTop: '0px',
          },
        }}
      >
        <Box data-cy='user-dropdown-controls'>
          {menuItems?.map((menuitem, index) => (
            <MenuItem
              key={index}
              onClick={menuitem.onClick}
              className={styles.profileList}
            >
              <Icon
                component={menuitem.icon}
                className={styles.profileIcon}
              ></Icon>
              <p className={styles.userProfileList}>{menuitem?.name}</p>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </div>
  );
};
