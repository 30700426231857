import { forwardRef, useImperativeHandle, useRef } from 'react';
import { TextField } from '@mui/material';
// import styles from './textbox.module.scss';

// eslint-disable-next-line react/display-name
export const LoginInput = forwardRef((props: any, ref: any) => {
  props = { ...props };
  const inputRef: any = useRef(null);

  const isFilter = props.isFilter;
  delete props.isFilter;

  useImperativeHandle(ref, () => ({
    setFocus: () => {
      inputRef?.current?.focus();
    },
  }));
  const outlineComponent = {
    '& .MuiOutlinedInput-root:hover fieldset': {
      borderColor: '#ced4da',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid #ced4da',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
  };
  return (
    <>
      <TextField
        // className={`${styles.textbox} ${isFilter ? styles.textboxFilter : ''}`}
        sx={{
          width: '328px',
          input: {
            '&::placeholder': {
              textOverflow: 'ellipsis !important',
              color: '#8B849B',
              fontSize: '16px',
            },
          },
          '& .MuiOutlinedInput-root:hover fieldset': {
            borderColor: '#ced4da',
          },
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            outline: 'none',
            boxShadow: 'none',
            border: '1px solid #D5D4DC',
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-root': {
            display: 'flex',
            padding: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            alignSelf: 'stretch',
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-input': {
            padding: 0,
          },
        }}
        id={props.id}
        label={props.label}
        type={props.type}
        fullWidth
        required={props.isRequired}
        variant={props.variant}
        error={props.error}
        helperText={props.helperText}
        InputProps={props.InputProps}
        inputRef={inputRef}
        autoComplete='off'
        {...props}
      />
    </>
  );
});
