import { configureStore } from '@reduxjs/toolkit';
import userContextReducer, { setUserContext } from './auth/reducer';
import appContextReducer from './app/reducer';
import { IUserProfileModel } from './auth/types';

const store = configureStore({
	reducer: {
		userContext: userContextReducer,
		appContext: appContextReducer
	}
});

const restoreDataFromLocal = () => {
	const userInfo = localStorage.getItem('user_info');
	const accessToken = localStorage.getItem('access_token');
	if (userInfo) {
		const parsed: IUserProfileModel = JSON.parse(userInfo);
		store.dispatch(setUserContext({
			isAuthenticated: accessToken ? true : false,
			userInfo: parsed
		}));
	}
};

restoreDataFromLocal();

export default store;