import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { withContext } from '@/common/utils/withContext';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Miscs = (props: any) => {
	const { t: translate } = useTranslation();
	const [state, setStateData] = useState({ 
		costCenterError: false,
		costCenterErrorText: ''
	} as any);

	const userForm = props.userForm;

	const verifyCostCenter = (e: any) => {
		userForm.setValue('costCenter', e.target.value,{shouldValidate: true,shouldDirty:true });
		userForm.trigger('costCenter');
	};

	useEffect(() => {
		props.setMiscsState(!state.costCenterError);
	}, [state.costCenterError]);
  
	return  <Grid container rowSpacing={1} columnSpacing={2}>
		<Grid item xs={6}>
			<Controller name="iNumber" key="iNumber" control={userForm.control} render={({ field }) => (
				<div>
					<EvesTextbox {...field} id="iNumber" label={`${translate('users.inumber')}`}
						fullWidth
						variant="standard"
					/>
				</div>
			)} />
		</Grid>
		<Grid item xs={6}>
			<Controller name="costCenter" key="costCenter" control={userForm.control} render={({ field }) => (
				<div>
					<EvesTextbox {...field} id="costCenter" label={`${translate('users.cost_center')}`}
						fullWidth
						variant="standard"
						onChange={verifyCostCenter}
						error={userForm.formState?.errors.costCenter}
						helperText={`${translate(userForm.formState?.errors.costCenter?.message || ' ')}`}
					/>
				</div>
			)} />
		</Grid>
	</Grid>;
};

export default withContext(Miscs);