import { EvesButton } from '@/common/components/atoms/button/button';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { downloadChargingStationQrCode } from '@/services/chargingStations';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GenerateQRCode =(props:any)=>{
	const { t: translate } = useTranslation();
	const title=props.connectorId==1?`${props.chargingStationId} - Connector A`:`${props.chargingStationId} - Connector B`;
	const connectorType=props.connectorId==1 ?  'Connector A' :'Connector B';
	const connectorName=props.connectorId==1 ?  'a' :'b';
	
	const downloadQRCode=()=>{
		const downloadQrParams:any={};
		downloadQrParams.ChargingStationID=props.chargingStationId;
		downloadQrParams.ConnectorID=props.connectorId;
    
		downloadChargingStationQrCode(downloadQrParams).then((res: any) => {
			const blob = new Blob([res], { type: 'application/pdf' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = props.chargingStationId+'-'+connectorName+ '-qr-codes';
			link.click();
		});
	};

	return(<>
		<EvesModal maxHeight={'480px'} minHeight={'430px'} maxWidth={'960px'}
			isOpen={props.isOpen} 
			title={title}
			onHandleClose={props.onClose}
			isSaveDisabled={true}
			isSaveHidden={true}
			data-cy='chargingStationQRCodeModal'
		>
			<center>
				<img src={props.qrCodeImg} alt='id' style={{minHeight:'260px', minWidth:'260px'}}/>
				<p>{props.chargingStationId}/ {connectorType}</p>
			</center>
			<Stack direction='row' spacing={1}  justifyContent="flex-end" marginTop={'30px'}>
				<EvesButton onClick={downloadQRCode}>{translate('general.download')}</EvesButton>
				<EvesButton onClick={props.onClose} variant="outlined">{translate('general.cancel')}</EvesButton>
			</Stack>
		</EvesModal> 
	</>
	);

};

export default GenerateQRCode;