import React from 'react';
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Badge } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { StyledTabpanel } from './styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export interface Tab {
  name: string;
  id: number;
  active?: boolean;
}

interface ITabpanelProps {
  children?: any;
  labelKey?: string;
  itemKey?: string;
  activeTab?: Array<any>;
  setActiveTab?: (item: Tab) => void;
  onClick?: () => void;
  handleTab?: (item: Tab) => void;
  sx?: any;
  textColor: 'primary';
  variant: 'scrollable' | 'standard';
  tabs?: Array<Tab>;
  defaultVal?: any;
  selectedTab?: string;
  xsm?: any;
  lasttabsx?: any;
  from?: any;
}

const TabPanel = forwardRef(
  ({
    textColor = 'primary',
    variant = 'standard',
    handleTab = () => undefined,
    sx,
    tabs = [],
    defaultVal = tabs[0].name,
    selectedTab,
    xsm = false,
    from,
    ...other
  }: ITabpanelProps) => {
    const theme = useTheme();
    const [alignment, setAlignment] = React.useState(defaultVal);

    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      alignment: string
    ) => {
      if (alignment != null) {
        setAlignment(alignment);
      }
    };

    React.useEffect(() => {
      setAlignment(selectedTab);
    }, [selectedTab]);

    return (
      <StyledTabpanel
        ownerState={{ textColor, variant }}
        sx={{
          ...sx,
        }}
        theme={theme}
        {...other}
        defaultChecked
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            height: variant === 'scrollable' ? 224 : 'max-content',
          }}
        >
          <Tabs orientation='vertical' variant={variant}>
            {tabs?.map((item: any, index) => {
              return (
                <>
                  {item?.badgeContent ? (
                    <>
                      {xsm ? (
                        <Box sx={{ pr: 4 }}>
                          {' '}
                          <Badge
                            badgeContent={item?.badgeContent}
                            sx={{
                              ...item.styles,
                            }}
                          ></Badge>
                          <ToggleButtonGroup
                            sx={{ pl: 4 }}
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            onClick={() => handleTab(item)}
                            aria-label={item.value}
                          >
                            <ToggleButton
                              value={item.name}
                              sx={{ textTransform: 'none' }}
                            >
                              <span style={{ marginTop: '-2px' }}>
                                {item.name}
                              </span>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      ) : (
                        <Badge badgeContent={item?.badgeContent}>
                          <Box sx={{ pr: 4 }}>
                            <ToggleButtonGroup
                              sx={{ pl: 4 }}
                              value={alignment}
                              exclusive
                              onChange={handleChange}
                              onClick={() => handleTab(item)}
                              aria-label={item.value}
                            >
                              <ToggleButton
                                value={item.name}
                                sx={{ textTransform: 'none' }}
                              >
                                {item.name}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Badge>
                      )}
                    </>
                  ) : (
                    <Box sx={{ pr: 4 }}>
                      <ToggleButtonGroup
                        sx={{
                          pl: 4,
                          ...(index !== (from == 'demand-view' ? 2 : 3) && {
                            borderRight: '1px solid #D0D5DD',
                            borderRadius: 0,
                          }),
                        }}
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        onClick={() => handleTab(item)}
                        aria-label={item.value}
                      >
                        <ToggleButton
                          value={item.name}
                          sx={{ textTransform: 'none' }}
                        >
                          {item.name}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  )}
                </>
              );
            })}
          </Tabs>
        </Box>
      </StyledTabpanel>
    );
  }
);

TabPanel.displayName = 'TabPanel';

export default TabPanel;
