import { EvesModal } from '@/common/components/molecules/modal/modal';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { BluetoothDrive } from '@mui/icons-material';
import { UseFormReturn } from 'react-hook-form';
import {
   CarConnectorModel,
   ICarConnectorPropsModel,
} from '@/modules/integrationSettings/shared/models/integrationSettings';
import { connect } from 'react-redux';
import { formSchema } from '@/modules/integrationSettings/shared/schemas/carConnectorFormSchema';
import CarConnectorForm from './carConnectorForm';
import { useEffect } from 'react';

const Connections = (props: ICarConnectorPropsModel) => {
   const carConnectorForm: any = props.formService as UseFormReturn<
      CarConnectorModel,
      any
   >;
   const titleName = carConnectorForm.watch('name');
   const isValid = carConnectorForm.formState.isValid;
   const isDirty = carConnectorForm.formState.isDirty;

   const getCarConnection = () => {
      const connection =
         props.carConnectionSettings.carConnector.connections.find(
            (data: any) => props.carConnectionId === data.id
         );
      const mercedesCon = connection.type === 'mercedes' ? true : false;
      const tronityCon = connection.type === 'tronity' ? true : false;
      const targaCon = connection.type === 'targaTelematics' ? true : false;
      const smartCarCon = connection.type === 'smartCar' ? true : false;
      carConnectorForm.reset({
         ...connection,
         mercedesConnection: {
            ...connection.mercedesConnection,
            mercedesConnection: mercedesCon,
         },
         tronityConnection: {
            ...connection.tronityConnection,
            tronityConnection: tronityCon,
         },
         targaTelematicsConnection: {
            ...connection.targaTelematicsConnection,
            targaTelematicsConnection: targaCon,
         },
         smartCarConnection: {
            ...connection.smartCarConnection,
            smartCarConnection: smartCarCon,
         },
      });
   };

   useEffect(() => {
      if (props.carConnectionId !== '') {
         getCarConnection();
      }
   }, []);

   const tabsConfig = [
      {
         name: titleName,
         icon: <BluetoothDrive />,
         component: <CarConnectorForm carConnectorForm={carConnectorForm} />,
         visible: true,
      },
   ];

   const save = (carConnectorConnection: any) => {
      if (!carConnectorConnection.id) {
         carConnectorConnection.id = new Date().getTime().toString();
      }
      const carConnectionSettings: any = { ...props.carConnectionSettings };
      if (props.carConnectionId !== '') {
         const index = carConnectionSettings.carConnector.connections.findIndex(
            (item) => item.id === carConnectorConnection.id
         );
         if (index === -1) {
            carConnectionSettings.carConnector.connections.push(
               carConnectorConnection
            );
         } else {
            carConnectionSettings.carConnector.connections[index] =
               carConnectorConnection;
         }
         carConnectionSettings.carConnector.connections.sort((a, b) =>
            a.name.localeCompare(b.name)
         );
         props.updateData(carConnectionSettings);
      } else {
         carConnectionSettings.carConnector.connections.push(
            carConnectorConnection
         );
         carConnectionSettings.carConnector.connections.sort((a, b) =>
            a.name.localeCompare(b.name)
         );
         props.updateData(carConnectionSettings);
      }
      props.onFormClose();
      props.resetCarConnectorForm('');
   };

   const onFormSubmit = (formData: any) => {
      const carConnectorConnection = { ...formData };
      save(carConnectorConnection);
   };

   const onHandleClose = () => {
      if (carConnectorForm.formState.isDirty === true) {
         //based on the formValidity confirmation Dialogtype changes
         if (carConnectorForm.formState.isValid) {
            props.dialogService?.showConfirm(
               {
                  confirmType: 'DIRTY_CHANGE_CLOSE',
               },
               (result) => {
                  if (result === 'SAVE') {
                     carConnectorForm.handleSubmit(onFormSubmit)();
                  } else if (result === 'CLOSE') {
                     props.onFormClose();
                     props.resetCarConnectorForm('');
                  }
               }
            );
         } else {
            props.dialogService?.showConfirm(
               {
                  confirmType: 'INVALID_CHANGE_CLOSE',
               },
               (result: string) => {
                  if (result === 'CLOSE') {
                     props.onFormClose();
                     props.resetCarConnectorForm('');
                  }
               }
            );
         }
      } else {
         props.onFormClose();
         props.resetCarConnectorForm('');
      }
   };

   return (
      <EvesModal
         maxHeight={'370px'}
         maxWidth={'900px'}
         isOpen={props.showCarConnectorForm}
         onHandleSave={carConnectorForm.handleSubmit(onFormSubmit)}
         onHandleClose={onHandleClose}
         isSaveDisabled={
            props.carConnectionId !== '' ? !(isValid && isDirty) : !isValid
         }
         tabsConfig={tabsConfig}
         modalType='tabs'
         isSaveHidden={false}
      ></EvesModal>
   );
};

const mapStateToProps = (state: any) => ({
   userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
   withContext(
      withUseFormHook(Connections, {
         schema: formSchema,
         defaultValues: { ...new CarConnectorModel() },
      })
   )
);
