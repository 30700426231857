import { SmartChargingSettingsType, TenantComponents } from '@/common/enums/enums';
import { IContextPropsModel, IFormPropsModel } from '@/common/utils/withContext';
import { UseFormReturn } from 'react-hook-form';

export class RefundingFormModel {
	apiUrl: string = '';
	appUrl: string = '';
	authenticationUrl: string = '';
	clientId: string = '';
	clientSecret: string = '';
	expenseTypeCode: string = '';
	paymentTypeId: string = '';
	policyId: string = '';
	reportName: string = '';
	//stripeRefund: StripeFormModel = {...new StripeFormModel()};
	url: string = '';
	secretKey: string = '';
	publicKey: string = '';
}

export class StripeFormModel {
	url: string = '';
	secretKey: string = '';
	publicKey: string = '';
}

export class BillingFormModel {
	immediateBillingAllowed: boolean = false;
	periodicBillingAllowed: boolean = false;
	taxID: string = '';
	platformFeeTaxID: string = '';
}

export class BillingModel {
	stripe: StripeFormModel = { ...new StripeFormModel() };
	billing: BillingFormModel = { ...new BillingFormModel() };
}

export interface IBillingFormPropsModel extends IContextPropsModel, IFormPropsModel<BillingModel> {
	billingForm?: UseFormReturn<BillingModel, any>;
}

export class SimplePricingModel {
	price: string = '';
	currency: string = '';
}

export interface IRefundingFormPropsModel extends IContextPropsModel, IFormPropsModel<RefundingFormModel> {
	refundingForm?: UseFormReturn<RefundingFormModel, any>;
	stripeForm?: UseFormReturn<StripeFormModel, any>;
}

export interface ISmartChargingFormPropsModel extends IContextPropsModel, IFormPropsModel<SmartChargingFormModal> {
	smartChargingForm?: UseFormReturn<SmartChargingFormModal, any>;
}

export interface IPricingFormPropsModel extends IContextPropsModel, IFormPropsModel<SimplePricingModel> {
	pricingForm?: UseFormReturn<SimplePricingModel, any>;
}

export class ConnectedAccountModel {
	id: any = null;
	user: string = '';
	userID: string = '';
	companyName: string = '';
}

export interface IConnectedAccountFormPropsModel extends IContextPropsModel, IFormPropsModel<ConnectedAccountModel> {
	connectdAccountForm?: UseFormReturn<ConnectedAccountModel, any>;
	showConnectedAccountForm: boolean;
	superAdmin?: boolean;
	handleAdminConnectedAccount?: any;
	onFormClose: () => void;
	resetConnectedAccountForm: (value: string) => void;
	fetchAllConnectedAccounts: () => void;
	canCreate: boolean;
	billingData: any;
	tenantId?:string;
}

export class InterOperabilityModel {
	id: string = '';
	name: string = '';
	role: string = '';
	baseUrl: string = '';
	countryCode: string = '';
	partyId: string = '';
	localToken: string = '';
	token: string = '';
	backgroundPatchJob: boolean = false;
	lastPatchJobOn: Date = new Date();
	lastPatchJobResult?: any;
}

export interface IInterOperabilityFormPropsModel extends IContextPropsModel, IFormPropsModel<InterOperabilityModel> {
	interOperabilityForm?: UseFormReturn<InterOperabilityModel, any>;
	showInterOperabilityForm: boolean;
	onFormClose: () => void;
	resetInterOperabilityForm: (value: string) => void;
	fetchAllInterOperability: () => void;
	canCreate: boolean;
}

export class CarConnectorModel {
	id: string = '';
	description: string = '';
	name: string = '';
	type: string = '';
	mercedesConnection?: MercedesCredentials;
	tronityConnection?: TronityCredentials;
	targaTelematicsConnection?: TargaCredentials;
	smartCarConnection?: SmartCarCredentials;
}

export class TronityCredentials {
	tronityConnection = false;
	apiUrl: string = '';
	clientId: string = '';
	clientSecret: string = '';
}

export class MercedesCredentials {
	mercedesConnection = false;
	authenticationUrl: string = '';
	apiUrl: string = '';
	clientId: string = '';
	clientSecret: string = '';
}

export class TargaCredentials {
	targaTelematicsConnection = false;
	authenticationUrl: string = '';
	apiUrl: string = '';
	clientId: string = '';
	clientSecret: string = '';
}

export class SmartCarCredentials {
	smartCarConnection = false;
	authenticationUrl: string = '';
	apiUrl: string = '';
	clientId: string = '';
	clientSecret: string = '';
}

export interface IMercedesPropsModel extends IContextPropsModel, IFormPropsModel<MercedesCredentials> {
	mercedesForm?: UseFormReturn<MercedesCredentials, any>;
}

export interface ITronityPropsModel extends IContextPropsModel, IFormPropsModel<TronityCredentials> {
	tronityForm?: UseFormReturn<TronityCredentials, any>;
}

export interface ITargaTelematicsPropsModel extends IContextPropsModel, IFormPropsModel<TargaCredentials> {
	targaTelematicsForm?: UseFormReturn<TargaCredentials, any>;
}

export interface ISmartCarPropsModel extends IContextPropsModel, IFormPropsModel<SmartCarCredentials> {
	smartCarForm?: UseFormReturn<SmartCarCredentials, any>;
}

export interface ICarConnectorPropsModel extends IContextPropsModel, IFormPropsModel<CarConnectorModel> {
	connectdAccountForm?: UseFormReturn<CarConnectorModel, any>;
	showCarConnectorForm: boolean;
	onFormClose: () => void;
	resetCarConnectorForm: (value: string) => void;
	fetchAllCarConnectors: () => void;
	canCreate: boolean;
	carConnectionId: string;
	carConnectionSettings: any;
	updateData: any;
	rowIndex: number;
}

export class SmartChargingFormModal {
	optimizerUrl: string = '';
	user: string = '';
	password: string = '';
	stickyLimitation: boolean = false;
	limitBufferDC: number = 0;
	limitBufferAC: number = 0;
}

interface SapSmartChargingSetting {
	optimizerUrl: string;
	user: string;
	password: string;
	stickyLimitation: boolean;
	limitBufferDC: number;
	limitBufferAC: number;
}

export interface SmartChargingSettings {
	identifier: TenantComponents.SMART_CHARGING;
	type?: SmartChargingSettingsType;
	sapSmartCharging?: SapSmartChargingSetting;
}

export class AssetFormModel {
	id: string = '';
	name: string = '';
	description: string = '';
	url: string = '';
	type: string = '';
	refreshIntervalMins: number = 1;
	schneiderConnection?: schneiderConnectionModel;
	greencomConnection?: greencomConnectionModel;
	iothinkConnection?: iothinkConnectionModel;
	lacroixConnection?: lacroixConnectionModel;
	witConnection?: witConnectionModel;
}

export class schneiderConnectionModel {
	schneiderConnection: boolean = false;
	user?: string = '';
	password?: string = '';
}
export class greencomConnectionModel {
	greencomConnection: boolean = false;
	clientId?: string = '';
	clientSecret?: string = '';
}
export class iothinkConnectionModel {
	iothinkConnection: boolean = false;
	user?: string = '';
	password?: string = '';
}
export class lacroixConnectionModel {
	lacroixConnection: boolean = false;
	user?: string = '';
	password?: string = '';
}
export class witConnectionModel {
	witConnection: boolean = false;
	authenticationUrl?: string = '';
	clientId?: string = '';
	clientSecret?: string = '';
	user?: string = '';
	password?: string = '';
}

export class AnalyticsLinkModel {
	id: string = '';
	name: string = '';
	description: string = '';
	url: string = '';
	role: string = '';
}

export class SacSettingModel {
	mainUrl?: string = '';
	timezone: string = '';
}

