import { Outlet } from 'react-router-dom';
import styles from './page.module.scss';

export const Page = (props: any) => {
	return (
		<div className={styles.page}>
			{props.header && <header data-cy='header' className={styles.pageHeader}>{props.header}</header>}
			{props.hasChild ?  <div className={`${styles.pageContent} ${props.hasShadow ? styles.pageContentShadow :''}`}>{props.children}</div> : <div className={`${styles.pageBody} ${props.noPageBodyScroll ? styles.pageBodyNoScroll :''}`}>
				<Outlet />
			</div>}
		</div>
	);
};