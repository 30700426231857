import React, { forwardRef } from 'react';
import { Switch } from '@mui/material';
import styles from './switch.module.scss';

// eslint-disable-next-line react/display-name
export const EvesSwitch = forwardRef((props: any, ref: any) => {
	return (
		<>
			<Switch ref={ref} className={styles.switch} {...props} />
		</>
	);
});
