import { hasPermission } from '@/common/utils/utils';
import { Outlet, useLocation } from 'react-router-dom';

// TODO: Need to create separate page for this
export function AccessDenied() {
	return (
		<div>Access Denied</div>
	);
}

export default function PermissionRoleRoute() {
	const location = useLocation();
	return hasPermission(location.pathname) ? <Outlet /> : <AccessDenied />;
}
