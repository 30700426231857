import { withContext } from '@/common/utils/withContext';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {IconButton, InputAdornment } from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { useTranslation } from 'react-i18next';

const Security = (props: any) => {
	const { t: translate } = useTranslation();
	const [state, setStateData] = useState({
		isPasswordVisible: false,
		isRepeatPasswordVisible: false,
		passwordError: false,
		passwordErrorText: '',
		repeatPasswordError: false,
		isRepeatPasswordHasError: false,
		repeatPasswordErrorText: ''
	} as any);

	const userForm = props.userForm;

	const handleClickShowPassword = () => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				isPasswordVisible: !currentData.isPasswordVisible
			};
		});
	};

	const handleClickShowRepeatPassword = () => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				isRepeatPasswordVisible: !currentData.isRepeatPasswordVisible
			};
		});
	};

	const validatePasswords = () => {
		userForm.trigger('passwords.password');
		userForm.trigger('passwords.repeatPassword');
	};

	useEffect(() => {
		let isRepeatPasswordHasError: boolean = false;
		if(userForm.formState.errors?.passwords?.password) isRepeatPasswordHasError = true;
		setStateData((currentData: any) => {
			return {
				...currentData,
				isRepeatPasswordHasError
			};
		});
	}, [userForm.formState.errors?.passwords?.repeatPassword, userForm.formState.errors?.passwords?.password]);

	return <Grid container rowSpacing={1} columnSpacing={2}>
		<Grid item xs={6}>
			<Controller name="passwords.password" key="password" control={userForm.control} render={({ field }) => (
				<div>
					<EvesTextbox {...field} id="password" label={`${translate('authentication.password')}`}
						type={state.isPasswordVisible ? 'text' : 'password'}
						fullWidth
						autoComplete="new-password"
						onChange={(event:any) => { field.onChange(event); validatePasswords(); }}
						variant="standard"
						error={userForm.formState?.errors.passwords?.password}
						helperText={`${translate(userForm.formState?.errors.passwords?.password?.message || ' ')}`}
						InputProps={{
							endAdornment: <InputAdornment position="start"><IconButton onClick={handleClickShowPassword}>{!state.isPasswordVisible ? <VisibilityIcon /> : <VisibilityOff />}</IconButton></InputAdornment>,
						}}
					/>
				</div>
			)} />
		</Grid>
		<Grid item xs={6}>
			<Controller name="passwords.repeatPassword" key="repeatPassword" control={userForm.control} render={({ field }) => (
				<div>
					<EvesTextbox {...field} id="repeatPassword" label={`${translate('authentication.repeat_password')}`}
						type={state.isRepeatPasswordVisible ? 'text' : 'password'}
						fullWidth
						variant="standard"
						onChange={(event:any) => { field.onChange(event); userForm.trigger('passwords.repeatPassword'); }}
						error={userForm.formState?.errors.passwords?.repeatPassword || state.isRepeatPasswordHasError}
						helperText={`${translate(userForm.formState?.errors.passwords?.repeatPassword?.message || ' ') }`}
						InputProps={{
							endAdornment: <InputAdornment position="start"><IconButton onClick={handleClickShowRepeatPassword}>{!state.isRepeatPasswordVisible ? <VisibilityIcon /> : <VisibilityOff />}</IconButton></InputAdornment>,
						}}
					/>
				</div>
			)} />
		</Grid>
	</Grid>;
};

export default withContext(Security);