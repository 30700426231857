import { forwardRef, useImperativeHandle, useRef } from 'react';
import {TextField} from '@mui/material';
import styles from './textbox.module.scss';

// eslint-disable-next-line react/display-name
export const EvesTextbox = forwardRef((props: any, ref: any) => {
	props = { ...props };
	const inputRef: any = useRef(null);

	const isFilter = props.isFilter;
	delete props.isFilter;

	useImperativeHandle(ref, () => ({
		setFocus: () => {
			inputRef?.current?.focus();
		}
	}));

	return (
		<TextField 
			className={`${styles.textbox} ${isFilter? styles.textboxFilter :''}`} 
			id={props.id} 
			label={props.label}
			type={props.type}
			fullWidth
			required={props.isRequired}
			variant={props.variant}
			error={props.error}
			helperText={props.helperText}
			InputProps={props.InputProps}
			inputRef={inputRef}
			autoComplete="off"
			sx={{
				...props.sx,
				'& .MuiOutlinedInput-root': {
					...(props.sx?.['& .MuiOutlinedInput-root'] || {}),
				},
				'& .MuiInputLabel-root': {
					...(props.sx?.['& .MuiInputLabel-root'] || {}),
				}
			}}
			{...props}
		/>
	);
});
