import * as schema from 'yup';
import { ResetPasswordFormModel } from '../models/models';

export const formSchema = schema.object<
	Partial<Record<keyof ResetPasswordFormModel, schema.AnySchema>>
>({
	email: schema
		.string()
		.required('general.mandatory_field')
		.matches(
			/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
			'authentication.invalid_email'
		),
});
