import React from 'react';
import styles from './styles.module.scss';
import power from '../../../../assets/img/sessions/power.svg';
interface PowerCapacityCardInterface {
  capacity: string;
  power: string;
}
const PowerCapacityCard = (props: PowerCapacityCardInterface) => {
  const powerCapacityRange =
    (parseInt(props.power) / parseInt(props.capacity)) * 100;
  return (
    <div
      className={`${styles.powerCapacityCard} ${
        powerCapacityRange > 90 && styles.borderColorChange
      }`}
    >
      <div className={styles.powerCardContainer}>
        <div className={styles.commonCardHeaderContainer}>
          <img src={power} className={styles.commonCardImage} />
          <h2 className={styles.commonCardHeaderText}>Power</h2>
        </div>
        <div className={styles.commonCardFooterText}>{props.power}</div>
      </div>
      <div className={styles.CapacityCardContainer}>
        <div className={styles.commonCardHeaderContainer}>
          <img src={power} className={styles.commonCardImage} />
          <h2 className={styles.commonCardHeaderText}>Capacity</h2>
        </div>
        <div className={styles.commonCardFooterText}>{props.capacity}</div>
      </div>
    </div>
  );
};

export default PowerCapacityCard;
