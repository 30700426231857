import React from 'react';
import { setLoader, setDisableLoader } from '@/store/app/reducer';
import { IAppContextModel } from '@/store/app/types';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@/common/components/atoms/loader/loader';
import store from '@/store';

export const LoaderContext = createContext({} as any);

export const LoaderContextProvider = ({ children }: any) => {

	const appContext: IAppContextModel = useSelector((state: any) => state.appContext);
	const dispatch = useDispatch();

	const showLoader = (value: boolean) => {
		dispatch(setLoader(value));    
	};

	return (
		
		<LoaderContext.Provider value={{showLoader}}>
			{children}
			<Loader showLoader={appContext.showLoader}/>
		</LoaderContext.Provider>
	);
};

export const disableLoaderForOnce = () => {
	store.dispatch(setDisableLoader(true));
	setTimeout(() => store.dispatch(setDisableLoader(false))); // reset back after a delay
};