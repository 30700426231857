import { columnsType } from '@/common/models/model';
import { HeirachyTable } from '@/modules/gridify/shared/components/heirarchyTable';
import { LinearProgress } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const LoadBalancing = ({ data, defaultSortColumn, onTableChange, state, SiteAreaName, ChargerName,showconnector,showphase,  onPageChange,
  onRowsPerPageChange,page }: any) => {
    const dataTableRef = useRef<any>();
    const { t: translate } = useTranslation();

    const loadcolumns: columnsType[] = [
      {
        id: 'siteAreaName',
        label: `${translate('site_areas.title')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 140 : 100,
        allowSort: false,
      },
      {
        id: 'chargerName',
        label: `${translate('gridify.demand_view.charger_id')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 80 : 70,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      {
        id: 'connectors',
        label: `${translate('gridify.charger_configuration.connectors')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth: showphase ?65 : 60,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      {
        id: 'connectorId',
        label: `${translate('gridify.power_managment.conectorid')}`,
        minWidth: 50,
        textAlign: 'start',
        maxWidth:showphase ?  60 : 50,
        allowSort: false,
        projectField: 'communication',
      },
      {
        id: 'phaseAssignment',
        label: `${translate('gridify.power_managment.phase')}`,
        minWidth: 35,
        textAlign: 'center',
        maxWidth:showphase? 40 : 35,
        allowSort: false,
        projectField: 'phaseAssignment',
      },
  
      {
        id: 'voltage',
        label: `${translate('gridify.power_managment.voltage')}`,
        minWidth: 50,
        textAlign: 'center',
        maxWidth: 60,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      {
        id: 'empty1',
        label: '',
        minWidth: 0,
        textAlign: 'start',
        maxWidth: 0,
      },
      {
        id: 'rating',
        label: `${translate('gridify.power_managment.sitearea_rating')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 60 : 70,
        allowSort: false,
        projectField: 'rating',
      },
      {
        id: 'demand',
        label: `${translate('gridify.power_managment.set_point')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 65:80,
        allowSort: false,
        projectField: 'demand',
      },
      {
        id: 'empty2',
        label: '',
        minWidth: 0,
        textAlign: 'start',
        maxWidth: 0,
      },
      {
        id: 'instantkw',
        label: `${translate('gridify.power_managment.Instant_current')}`,
        minWidth: 60,
        textAlign: 'center',
        tooltip: 'Instant Power vs Installed Capacity',
        maxWidth: showphase ? 120 : 90,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      ...(showphase ? [
        {
          id: 'empty3',
          label: '',
          minWidth: 0,
          textAlign: 'start',
          maxWidth: 0,
        },
        {
          id: 'amperage',
          label: `${translate('R(L1)')}`,
          minWidth: 25,
          textAlign: 'center',
          maxWidth: 38,
          allowSort: false,
          projectField: 'amperage',
        },
        {
          id: 'amperage',
          label: `${translate('S(L2)')}`,
          minWidth: 25,
          textAlign: 'center',
          maxWidth: 38,
          allowSort: false,
          projectField: 'amperage',
        },
        {
          id: 'amperage',
          label: `${translate('T(L3)')}`,
          minWidth: 30,
          textAlign: 'center',
          maxWidth: 38,
          allowSort: false,
          projectField: 'amperage',
        },
      ]
        : []),
    ];

   

    const InstantPowerCell = (row: any) => {
      return Object.keys(row).includes('currentInstantWatts')  ? (
        <>
          <div style={{ margin: '0 16px', width: '83%' }}>
            <div
              className={`d-flex power-bar-text ${
                row.maximumPower === 0 && 'power-bar-text-error'
              }`}
            >
              {(parseFloat(row?.currentInstantWatts || 0))?.toFixed(2)} 
                    &nbsp;/&nbsp;
                    {(parseFloat(row?.amperage || 0))?.toFixed(2)} A
            </div>
            {
              <LinearProgress
                variant='determinate'
                value={(row?.currentInstantWatts||0)/(row?.amperage ||0) || 0}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#1BD4ED',
                    maxWidth: 90,
                  },
                }}
              />
            }
          </div>
        </>
      ) : (
        <></>
      ); 
    };

    const DemandSet = (row:any) => {
      return Object.keys(row).includes('smartCharging') ? (
        <div>
         {row?.smartCharging?.setPointAmp}
        </div>
      ) : (
        <></>
      );
    };

    const Phase = (row:any) => {
      return Object.keys(row).includes('numberOfConnectedPhase') ? (
        <div>
         {row?.numberOfConnectedPhase}
        </div>
      ) : (
        <></>
      );
    };

    
    const Amp = (row:any) => {
      return Object.keys(row).includes('amperage') ? (
        <div>
         {row?.amperage}
        </div>
      ) : (
        <></>
      );
    };
    return (
        <HeirachyTable
            ref={dataTableRef}
            className='transaction-history-table'
            rows={data}
            columns={loadcolumns}
            totalRowsCount={state.totalRowsCount}
            onChange={onTableChange}
            defaultSortField={defaultSortColumn}
            border={true}
            defaultSortDescending={true}
            count={state.totalRowsCount}
            limit={state.limit}
            tab={showconnector ? 'Load Balancing' : ''}
            page={page}
            data-cy='loadbalancing-table'
            customRowClassName='powerManagement'
            tablePagination={true}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            >
            <SiteAreaName slot='siteAreaName' />
            <ChargerName slot='chargerName' />
            <InstantPowerCell slot='instantkw' />
            <DemandSet slot='demand'/>
            <Phase slot='phaseAssignment'/>
            <Amp slot='amperage'/>
        </HeirachyTable>
    );
};