import { Grid } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { GetEnergyUsageTotals } from '@/services/dashboard';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const MonthlyGraph = ({ groupBy, demandLimit }: any) => {
  const [_, setData] = useState<any>({
    totalEnergyConsumerInKWh: 0,
  });

  const [data, setDataC] = useState([
    {
      type: 'bar',
      label: 'Data',
      data: [4000, 3000, 5000, 6000, 9000, 3000, 5000, 6000, 1000, 3500, 2000, 2500, 4000, 3000, 5000, 6000, 9000, 3000, 5000, 6000, 1000, 3500, 2000, 2500],
      borderColor: '#2D9CDB',
      borderWidth: 0,
      fill: true,
      backgroundColor: '#2D9CDB',
      borderRadius: 5,
      barPercentage: groupBy == '_30days' ? 1 : 0.35,
    }
  ]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand (KW)',
          color: 'black',
          padding: 5,
        },
        grid: {
          display: false,
        },
        ticks: {
          // stepSize: 500,
        },
      },
    },
  };

  const labeldata: any = ['29 Mar', '30 Mar', '31 Mar', '01 Apr', '02 Apr', '03 Apr', '04 Apr', '05 Apr', '06 Apr', '07 Apr', '08 Apr', '09 Apr', '10 Apr', '11 Apr', '12 Apr', '13 Apr', '14 Apr', '15 Apr', '16 Apr', '17 Apr', '18 Apr'];

  const chartData: any = {
    labels: labeldata,
    datasets: data,
  };

  useEffect(() => {
    let dataF: any = [...data];
    const dataExist = dataF.filter((item) => item.type === 'line');
    if (demandLimit && dataExist.length == 0) {
      dataF.push({
        type: 'line',
        label: 'Line Dataset',
        borderColor: '#F79009',
        borderWidth: 2,
        backgroundColor: '#F79009',
        borderRadius: 0,
        data: [2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800, 2800],
      });
    } else if (!demandLimit) {
      dataF = dataF.filter((item) => item.type !== 'line');
    }
    setDataC(dataF);
  }, [demandLimit]);

  const { refetch: fetchEnergyUsageData } = useQuery(
    'energyUsageSnapshot',
    () => {
      return GetEnergyUsageTotals(groupBy);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if (data && data.returnCode === 200) {
          setData(data.data);
        }
      },
    }
  );
  useEffect(() => {
    // fetchEnergyUsageData();
  }, [groupBy]);

  return (
    <Grid container sx={{ display: 'flex' }}>
      <Grid
        className={styles.graphContainer}
        data-cy='energy-usage-bargraph'
      >
        <Line data={chartData} options={options} />
        {/* <Bar
          style={{
            padding: '0 0px',
            display: 'flex',
            gap: '12px',
          }}
          data={chartData}
          options={options}
        /> */}
      </Grid>
    </Grid>
  );
};

export default MonthlyGraph;
