import { EvesButton } from '@/common/components/atoms/button/button';
import { Card } from '@/common/components/molecules/card/card';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { Info, InsertLink, Save, Settings } from '@mui/icons-material';
import { FormControlLabel, Grid, Stack } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  ISmartChargingFormPropsModel,
  SmartChargingFormModal,
  SmartChargingSettings,
} from '../../shared/models/integrationSettings';
import { smartChargingFormSchema } from '../../shared/schemas/smartChargingFormSchema';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { StatusCodes } from '@/common/constants/constants';
import { useQuery } from 'react-query';
import {
  RestResponse,
  SmartChargingSettingsType,
  TenantComponents,
} from '@/common/enums/enums';
import {
  checkSmartChargingConnection,
  getRefundSettings,
  updateSetting,
} from '@/services/integrationSettings';
import { NotifyType } from '@/common/utils/notificationService';
import { isActive } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

interface ISmartChargingState {
  id: string;
  sapSmartCharging: any;
}

const SmartCharging = (props: ISmartChargingFormPropsModel) => {
  const userMaxCharError: number = 100;
  const { t: translate } = useTranslation();
  const smartChargingForm = props.formService as UseFormReturn<
    SmartChargingFormModal,
    any
  >;
  const [state, setStateData] = useState({
    id: '',
    sapSmartCharging: {},
  } as ISmartChargingState);
  const isSmartChargingComponentActive: boolean = isActive(
    TenantComponents.SMART_CHARGING
  );
  const smartChargingStyles = {
    cardLegend: {
      fontSize: 'calc(1.275rem + 0.3vw)',
      marginTop: '15px',
      marginBottom: '15px',
    } as CSSProperties,
  };

  const isSmartChargingActive = isActive(TenantComponents.SMART_CHARGING);

  const notify: NotifyType = {
    message: '',
    type: 'success',
  };

  const { refetch: fetchSmartChargingSettings } = useQuery(
    'SmartCharging',
    () => {
      // reused api with 'smartCharging' parameter
      return getRefundSettings({ Identifier: TenantComponents.SMART_CHARGING });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res: any) => {
        if (res) {
          const data = res.setting;
          setStateData((currentData: any) => {
            return {
              ...currentData,
              id: data?.id,
              sapSmartCharging:
                data && data.content && data.content.sapSmartCharging
                  ? data.content.sapSmartCharging
                  : {},
            };
          });
          const sapSmartCharging =
            data && data.content && data.content.sapSmartCharging
              ? data.content.sapSmartCharging
              : {};
          smartChargingForm.reset({ ...sapSmartCharging });
        }
      },
      onError: (error: any) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            props.notificationService?.notify({
              message: `${translate(
                'settings.smart_charging.setting_not_found'
              )}`,
              type: 'error',
            });
            break;
          default:
            props.notificationService?.notify({
              message: `${translate('general.unexpected_error_backend')}`,
              type: 'error',
            });
        }
      },
    }
  );

  const onFormSubmit = () => {
    const content: SmartChargingSettings = {
      sapSmartCharging: smartChargingForm.getValues(),
      type: SmartChargingSettingsType.SAP_SMART_CHARGING,
      identifier: TenantComponents.SMART_CHARGING,
    };
    if (!content.sapSmartCharging) return;

    // Build setting payload
    const settingsToSave: any = {
      id: state.id,
      identifier: TenantComponents.SMART_CHARGING,
      sensitiveData: [] as string[],
      content: { ...content },
    };
    if (content.type === SmartChargingSettingsType.SAP_SMART_CHARGING) {
      settingsToSave.sensitiveData = ['content.sapSmartCharging.password'];
    }
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;

    save(settingsToSave);
  };

  const save = async (settingsToSave: any) => {
    try {
      const response: any = await updateSetting(state.id, settingsToSave);
      if (response.status === RestResponse.SUCCESS) {
        notify.message = !state.id
          ? `${translate('settings.smart_charging.create_success')}`
          : `${translate('settings.smart_charging.update_success')}`;
        notify.type = 'success';
        fetchSmartChargingSettings();
      } else {
        notify.message = !state.id
          ? `${translate('settings.smart_charging.create_error')}`
          : `${translate('settings.smart_charging.update_error')}`;
        notify.type = 'error';
      }
    } catch (error: any) {
      switch (error.status) {
        case StatusCodes.NOT_FOUND:
          notify.message = `${translate(
            'settings.smart_charging.setting_do_not_exist'
          )}`;
          notify.type = 'error';
          break;
        default:
          notify.message = !state.id
            ? `${translate('settings.smart_charging.create_error')}`
            : `${translate('settings.smart_charging.update_error')}`;
          notify.type = 'error';
      }
    }
    props.notificationService?.notify(notify);
  };

  const checkConnection = () => {
    checkSmartChargingConnection()
      .then((response: any) => {
        if (response.status === RestResponse.SUCCESS) {
          notify.message = `${translate(
            'settings.smart_charging.connection_success'
          )}`;
          notify.type = 'success';
        } else {
          notify.message = `${translate(
            'settings.smart_charging.connection_error'
          )}`;
          notify.type = 'error';
        }
        props.notificationService?.notify(notify);
      })
      .catch(() => {
        notify.message = `${translate(
          'settings.smart_charging.connection_error'
        )}`;
        notify.type = 'error';
        props.notificationService?.notify(notify);
      });
  };

  useEffect(() => {
    fetchSmartChargingSettings();
  }, []);

  if (!isSmartChargingActive)
    return (
      <div className='information_block'>
        <Card icon={<Info />} title='Information' isTransparent={true}>
          <div className='information'>
            <p data-cy='roamingHubject-content1'>{`${translate(
              'settings.smart_charging.description'
            )}`}</p>
          </div>
          <div>
            <p></p>
            <p data-cy='roamingHubject-content2'>{`${translate(
              'settings.activation_contact_msg'
            )}`}</p>
          </div>
        </Card>
      </div>
    );

  return (
    <>
      {!isSmartChargingComponentActive && (
        <Grid container mt={1}>
          <Grid item mt={2} xs={12}>
            <Card icon={<Info />} title={'Information'} isTransparent={true}>
              <Grid item xs={12}>
                <div>
                  {`${translate('settings.smart_charging.description')}`}
                </div>
                <br />
                <div>{`${translate('settings.activation_contact_msg')}`}</div>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}

      {isSmartChargingComponentActive && (
        <>
          <Stack direction='row' spacing={2} marginBottom={'40px'}>
            <EvesButton
              type='button'
              startIcon={<Save />}
              color='error'
              variant='contained'
              data-cy='save-btn'
              disabled={
                !(
                  smartChargingForm.formState.isValid &&
                  smartChargingForm.formState.isDirty
                )
              }
              onClick={onFormSubmit}
            >
              {`${translate('general.save')}`}
            </EvesButton>
            <EvesButton
              type='button'
              startIcon={<InsertLink />}
              variant='contained'
              data-cy='check-connection'
              disabled={
                !smartChargingForm.formState.isValid ||
                smartChargingForm.formState.isDirty
              }
              onClick={checkConnection}
            >
              {`${translate('settings.smart_charging.check_connection')}`}
            </EvesButton>
          </Stack>
          <Grid container spacing={2} rowSpacing={1}>
            <Grid item xs={12}>
              <Card
                icon={<Settings />}
                title={`${translate(
                  'settings.smart_charging.sap_smart_charging.title'
                )}`}
                isTransparent={true}
              >
                <legend style={smartChargingStyles.cardLegend}>{`${translate(
                  'settings.smart_charging.sap_smart_charging.settings'
                )}`}</legend>
                <Grid
                  container
                  direction={'row'}
                  columnSpacing={2}
                  rowSpacing={1}
                >
                  <Grid item xs={12}>
                    <Controller
                      name='optimizerUrl'
                      control={smartChargingForm.control}
                      render={({ field }) => (
                        <EvesTextbox
                          {...field}
                          id='optimizerUrl'
                          label={`${translate('smart_charging.optimizerUrl')}`}
                          data-cy='smart-charging-optimizerUrl'
                          fullWidth
                          variant='standard'
                          autoFocus
                          error={
                            smartChargingForm.formState.errors.optimizerUrl
                          }
                          helperText={`${translate(
                            smartChargingForm.formState?.errors.optimizerUrl
                              ?.message || ' '
                          )}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name='user'
                      control={smartChargingForm.control}
                      render={({ field }) => (
                        <EvesTextbox
                          {...field}
                          id='user'
                          label={`${translate(
                            'settings.smart_charging.sap_smart_charging.user'
                          )}`}
                          autoComplete='off'
                          data-cy='smart-charging-user'
                          fullWidth
                          variant='standard'
                          required={true}
                          onChange={(event: any) => {
                            field.onChange(event);
                            smartChargingForm.trigger('user');
                          }}
                          error={!!smartChargingForm.formState?.errors.user}
                          helperText={`${translate(
                            smartChargingForm.formState?.errors.user?.message ||
                              ' '
                          ).replace(/{{length}}/g, `${userMaxCharError}`)}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Controller
                      name='password'
                      control={smartChargingForm.control}
                      render={({ field }) => (
                        <EvesTextbox
                          {...field}
                          id='password'
                          label={`${translate(
                            'settings.smart_charging.sap_smart_charging.password'
                          )}`}
                          type='password'
                          data-cy='smart-charging-password'
                          fullWidth
                          variant='standard'
                          required
                          error={
                            !!smartChargingForm.formState.errors.password
                              ?.message
                          }
                          helperText={`${translate(
                            smartChargingForm.formState?.errors.password
                              ?.message || ' '
                          )}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={12} mt={1}>
                    <Controller
                      name='stickyLimitation'
                      control={smartChargingForm.control}
                      render={({ field }) => (
                        <FormControlLabel
                          label={`${translate(
                            'settings.smart_charging.sap_smart_charging.sticky_limitation'
                          )}`}
                          data-cy='smart-charging-stickyLimitation'
                          control={
                            <EvesCheckbox
                              {...field}
                              checked={smartChargingForm.watch(
                                'stickyLimitation'
                              )}
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={2}
                    ml={2}
                    mt={2}
                  >
                    <Grid item xs={2}>
                      <Controller
                        name='limitBufferDC'
                        control={smartChargingForm.control}
                        render={({ field }) => (
                          <EvesTextbox
                            {...field}
                            id='limitBufferDC'
                            label={`${translate(
                              'settings.smart_charging.sap_smart_charging.limit_buffer_dc'
                            )}`}
                            type='number'
                            disabled={
                              !smartChargingForm.watch('stickyLimitation')
                            }
                            data-cy='smart-charging-limitBufferDC'
                            fullWidth
                            variant='standard'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='limitBufferAC'
                        control={smartChargingForm.control}
                        render={({ field }) => (
                          <EvesTextbox
                            {...field}
                            id='limitBufferAC'
                            label={`${translate(
                              'settings.smart_charging.sap_smart_charging.limit_buffer_ac'
                            )}`}
                            type='number'
                            disabled={
                              !smartChargingForm.watch('stickyLimitation')
                            }
                            data-cy='smart-charging-limitBufferAC'
                            fullWidth
                            variant='standard'
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(SmartCharging, {
      schema: smartChargingFormSchema,
      defaultValues: { ...new SmartChargingFormModal() },
    })
  )
);
