import * as XLSX from 'xlsx';
import dayjs from 'dayjs';


export const exportChartInExcel = (chartData: any, startDate: any[]) => {
  // Convert labels to dayjs objects
  const timestamps: dayjs.Dayjs[] = startDate.map((label) => dayjs(label))
  const endTimestampsArr: dayjs.Dayjs[] = chartData.labels.map((label) =>
    dayjs(label)
  )
  // Create Excel worksheet
  const ws = XLSX.utils.aoa_to_sheet([
    [
      "Started At",
      "Ended At",
      ...chartData.datasets.map((dataset) => dataset.label),
    ],
  ])

  // Populate chartData into worksheet
  timestamps.forEach((timestamp, index: number) => {
    const endTimestamp = endTimestampsArr[index]
    const row = [
      timestamp?.format("YYYY-MM-DD HH:mm:ss"),
      endTimestamp?.format("YYYY-MM-DD HH:mm:ss"),
    ]
    chartData.datasets.forEach((dataset) => {
      row.push(dataset.data[index])
    })

    XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 })
  })

  // Create Excel workbook and write to file
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Data")
  XLSX.writeFile(wb, "exported-session.xlsx", {
    bookType: "xlsx",
    type: "buffer",
  })
}

export const exportChartInCSV = (chartData: any, startDate: any[]) => {
   // Convert labels to dayjs objects
   const timestamps: dayjs.Dayjs[] = startDate.map((label) => dayjs(label))
   const endTimestampsArr: dayjs.Dayjs[] =  chartData.labels.map(label => dayjs(label));

   // Prepare CSV content
   let csvContent = 'Started At,Ended At,' + chartData.datasets.map(dataset => dataset.label).join(',') + '\n';
   timestamps.forEach((timestamp, index) => {
      const endTimestamp = endTimestampsArr[index];
      const row = [timestamp?.format('YYYY-MM-DD HH:mm:ss'), endTimestamp?.format('YYYY-MM-DD HH:mm:ss')];
      chartData.datasets.forEach(dataset => {
         row.push(dataset.data[index]);
      });
      csvContent += row.join(',') + '\n';
   });

   // Write CSV to file
   const blob = new Blob([csvContent], { type: 'text/csv' });
   // Creating an object for downloading url
   const url = window.URL.createObjectURL(blob);
   // Creating an anchor(a) tag of HTML
   const a = document.createElement('a');
   // Passing the blob downloading url
   a.setAttribute('href', url);
   // Setting the anchor tag attribute for downloading
   // and passing the download file name
   a.setAttribute('download', 'exported-session.csv');
   // Performing a download with click
   a.click();
};