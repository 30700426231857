import styles from './content.module.scss';

export const Content = (props: any) => {
   return (
      <section className={styles.content}>
         <header
            className={
               props?.price ? styles.contentTopPrice : styles.contentTop
            }
         >
            {props.contentTop}
         </header>
         <div
            className={
               props?.disableOverflow
                  ? styles.contentBodyCharging
                  : props?.priceModule
                  ? styles.contentBodyPrice
                  : styles.contentBody
            }
         >
            {props.contentBody}
         </div>
      </section>
   );
};
