export const IconDomesticUE = (props: any) => {
  const height = props.height || '20px';
  const width = props.width || '20px';
  const color = props.color || '#ffffff';

  return (
    <>
      <svg
        version='1.1'
        id='Capa_1'
        xmlns='http://www.w3.org/2000/svg'
        height={height}
        width={width}
        x='0px'
        y='0px'
        viewBox='0 0 902.061 902.061'
      >
        <g enableBackground={'new 0 0 902.061 902.061'}>
          <g id='Female_1_'>
            <path
              fill={color}
              id='F'
              d='M896.255,391.912h-56.887V225.896C713.985,25.051,545.646,7.637,493.405,1.832V143.27
						c21.308,13.876,35.408,37.891,35.408,65.213c0,42.96-34.827,77.784-77.783,77.784c-42.959,0-77.784-34.824-77.784-77.784
						c0-27.324,14.099-51.34,35.409-65.213V1.832C356.412,7.637,188.074,25.051,62.692,225.896v166.016H5.805
						c0,0-5.805,21.877-5.805,59.118s5.805,59.118,5.805,59.118h56.887v166.016c125.383,200.845,293.721,218.259,345.963,224.063
						V758.794c-21.311-13.875-35.409-37.893-35.409-65.216c0-42.957,34.825-77.783,77.784-77.783c42.957,0,77.783,34.826,77.783,77.783
						c0,27.323-14.102,51.341-35.408,65.216v141.435c52.241-5.806,220.58-23.22,345.964-224.063V510.148h56.887
						c0,0,5.805-21.877,5.805-59.118S896.255,391.912,896.255,391.912z M206.842,510.536c-32.863,0-59.506-26.641-59.506-59.506
						s26.643-59.506,59.506-59.506c32.865,0,59.505,26.641,59.505,59.506C266.348,483.896,239.707,510.536,206.842,510.536z
						M695.217,510.536c-32.865,0-59.506-26.646-59.506-59.506c0-32.865,26.641-59.506,59.506-59.506s59.506,26.641,59.506,59.506
						C754.721,483.891,728.081,510.536,695.217,510.536z'
            />
          </g>
        </g>
      </svg>
    </>
  );
};
