import * as schema from 'yup';
import { DefinePasswordFormModel } from '../models/models';

/* eslint-disable */
export const formSchema = schema.object<
	Partial<Record<keyof DefinePasswordFormModel, schema.AnySchema>>
>({
	password: schema
		.string()
		.required('general.mandatory_field')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/]{8,}|^$/,
			'authentication.password_rule'
		),
	repeatPassword: schema
		.string()
		.oneOf([schema.ref('password')], 'authentication.password_not_equal')
		.required('general.mandatory_field'),
});