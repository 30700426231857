import { createSlice } from '@reduxjs/toolkit';
import { IUserContextModel } from './types';

const initialState: IUserContextModel = {
	isAuthenticated: false,
	userInfo: {
		firstName: '',
		email: '',
		lastName: '',
		userId: '',
		tenantId: ''
	}
};

export const userContextSlice = createSlice({
	name: 'userContext',
	initialState,
	reducers: {
		setUserContext: (state, action) => {
			state.isAuthenticated = action.payload.isAuthenticated;
			state.userInfo = action.payload.userInfo;
		},
		setAuthStatus: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		resetUserContext: (state) => {
			state.isAuthenticated = false;
			state.userInfo = initialState.userInfo as any;
		}
	}
});

export const { setUserContext, setAuthStatus, resetUserContext } = userContextSlice.actions;
export default userContextSlice.reducer;