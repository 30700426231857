import React, { forwardRef } from 'react';
import { Button as MUIButton } from '@mui/material';
import styles from './button.module.scss';
import actionStyles from './actionbutton.module.scss';

// eslint-disable-next-line react/display-name
export const EvesButton = forwardRef((props: any, ref: any) => {
  return (
    <>
      {!props.isTypeFile && (
        <MUIButton
          ref={ref}
          className={props.isAction ? actionStyles.button : styles.button}
          variant='contained'
          size={props.size}
          onClick={props.onClick}
          disabled={props.disabled}
          {...props}
        >
          {props.children}
        </MUIButton>
      )}
      {props.isTypeFile && (
        <MUIButton
          variant='contained'
          className={props.isAction ? actionStyles.button : styles.button}
          size={props.size}
          disabled={props.disabled}
          data-cy={props['data-cy']}
          component='label'
        >
          {props.children}
          <input
            hidden
            accept='.csv'
            multiple
            type='file'
            onChange={props.onChange}
          />
        </MUIButton>
      )}
    </>
  );
});
