import { IconButton, Icon } from '@mui/material';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreHoriz } from '@mui/icons-material';

export interface ITableActionMenuModel {
	title: (record: Record<string, any> | undefined) => string;
	icon: (record: Record<string, any> | undefined) => any;
	iconColor?: any;
	visible?: (record: Record<string, any> | undefined) => boolean;
	disabled?: (record: Record<string, any> | undefined) => boolean;
	onClick?: (record: Record<string, any> | undefined, event?: any) => void;
	dynamicColor?: boolean,
	'data-cy'?: string;
}

export const TableActionMenu = (props: { menus: ITableActionMenuModel[], record?: Record<string, any>, styles?: any }) => {

	const [isActionMenu, setActionMenu] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(isActionMenu);

	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setActionMenu(event.currentTarget);
	};

	const handleMenuClose = () => {
		setActionMenu(null);
	};

	return (
		<div>
			<IconButton size="small" aria-controls={isMenuOpen ? 'basic-menu' : undefined} className={props.styles.datatableButton} aria-haspopup="true" color="primary"
				aria-expanded={isMenuOpen ? 'true' : undefined} onClick={handleMenuOpen}><MoreHoriz className={props.styles.datatableIcon} /></IconButton>
			<Menu id="basic-menu" anchorEl={isActionMenu} open={isMenuOpen} onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{props.menus.map((action, index) => {
					return !action.visible || (action.visible && action.visible(props.record)) ?
						<MenuItem key={`menu-item-${index}`} disabled={action.disabled && action.disabled(props.record)}
							onClick={(event: any) => {
								if (action?.onClick) {
									action.onClick(props.record, event);
								}
								handleMenuClose();
							}} sx={{ fontSize: '14px' }}
							style={{ minHeight: '48px' }}>
							<Icon color={action.iconColor} data-cy={action['data-cy']} component={action.icon(props.record)} sx={{ marginRight: '10px', fontSize: '20px', color: action.iconColor }} />
							{action.title(props.record)}

						</MenuItem> : <></>;
				}
				)}
			</Menu>
		</div>);
};
