import { findRequiredFields } from '@/common/utils/validationHelper';
import  * as schema from 'yup';
import { CarConnectorModel, MercedesCredentials, SmartCarCredentials, TargaCredentials, TronityCredentials } from '../models/integrationSettings';

export const formSchema = schema.object<Partial<Record<keyof CarConnectorModel, schema.AnySchema>>>({
	name: schema.string().required('general.mandatory_field').max(100, 'general.error_max_length'),
	description:schema.string().required('general.mandatory_field'),
	type : schema.string().required('general.mandatory_field'),
	tronityConnection: schema.object<Partial<Record<keyof TronityCredentials, schema.AnySchema>>>({
		tronityConnection: schema.boolean(),
		clientId: schema.string().when('tronityConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		clientSecret: schema.string().when('tronityConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		apiUrl: schema.string().when('tronityConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		})
	}),
	mercedesConnection: schema.object<Partial<Record<keyof MercedesCredentials, schema.AnySchema>>>({
		mercedesConnection: schema.boolean(),
		clientId: schema.string().when('mercedesConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		clientSecret: schema.string().when('mercedesConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		apiUrl: schema.string().when('mercedesConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		authenticationUrl: schema.string().when('mercedesConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		})
	}),
	targaTelematicsConnection: schema.object<Partial<Record<keyof TargaCredentials, schema.AnySchema>>>({
		targaTelematicsConnection: schema.boolean(),
		clientId: schema.string().when('targaTelematicsConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		clientSecret: schema.string().when('targaTelematicsConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		apiUrl: schema.string().when('targaTelematicsConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		authenticationUrl: schema.string().when('targaTelematicsConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		})
	}),
	smartCarConnection: schema.object<Partial<Record<keyof SmartCarCredentials, schema.AnySchema>>>({
		smartCarConnection: schema.boolean(),
		clientId: schema.string().when('smartCarConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		clientSecret: schema.string().when('smartCarConnection', {
			is: true,
			then: schema.string().required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		apiUrl: schema.string().when('smartCarConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		}),
		authenticationUrl: schema.string().when('smartCarConnection', {
			is: true,
			then: schema.string().matches(/^(ftp|http|https):\/\/[^ "]*$/, 'general.error_url_pattern').required('general.mandatory_field'),
			otherwise: schema.string(),
		})
	})
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);