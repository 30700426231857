import { timeAgo } from '@/common/utils/utils';
import { withContext } from '@/common/utils/withContext';
import { Stack, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Properties = (props: any) => {
  const { t: translate } = useTranslation();
  const chargingStationFormValues = props.chargingStationForm.getValues();
  return (
    <>
      <Stack
        direction='column'
        fontSize={14}
        justifyContent='flex-end'
        spacing={2}
        paddingBottom={'20px'}
      >
        <Typography fontWeight={600} fontSize={'16px'}>
          Boot Notification :
        </Typography>
        {chargingStationFormValues.chargePointVendor && (
          <Stack direction='row' spacing={2} data-cy='Vendor'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargePointVendor')}
            </Box>
            <Box>{chargingStationFormValues.chargePointVendor}</Box>
          </Stack>
        )}
        {chargingStationFormValues.chargePointModel && (
          <Stack direction='row' spacing={2} data-cy='Model'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargePointModel ')}
            </Box>
            <Box>{chargingStationFormValues.chargePointModel}</Box>
          </Stack>
        )}
        {chargingStationFormValues.firmwareVersion && (
          <Stack direction='row' spacing={2} data-cy='Firmware Version'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargers.firmware_version')}
            </Box>
            <Box>{chargingStationFormValues.firmwareVersion}</Box>
          </Stack>
        )}
        {chargingStationFormValues.chargeBoxSerialNumber && (
          <Stack direction='row' spacing={2} data-cy='SerialNo'>
            <Box minWidth={200} fontWeight={700}>
              {translate('chargers.serial_number')}
            </Box>
            <Box>{chargingStationFormValues.chargeBoxSerialNumber}</Box>
          </Stack>
        )}
        <Typography fontWeight={600} fontSize={'16px'}>
          Public/Private URL :
        </Typography>

        {chargingStationFormValues.endpoint && (
          <Stack direction='row' spacing={2} data-cy='Private URL'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargers.private_url')}
            </Box>
            <Box>{chargingStationFormValues.endpoint}</Box>
          </Stack>
        )}

        {chargingStationFormValues.chargingStationURL && (
          <Stack direction='row' spacing={2} data-cy='Public URL'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargers.public_url')}
            </Box>
            <Box>{chargingStationFormValues.chargingStationURL}</Box>
          </Stack>
        )}
        {chargingStationFormValues.currentIPAddress && (
          <Stack direction='row' spacing={2} data-cy='Current IP Route'>
            <Box minWidth={200} fontWeight={700}>
              {translate('chargers.current_ip')} :
            </Box>
            <Box pl={2}>{chargingStationFormValues.currentIPAddress}</Box>
          </Stack>
        )}

        {chargingStationFormValues.lastReboot && (
          <Stack direction='row' spacing={2} data-cy='Last Reboot'>
            <Box minWidth={200} fontWeight={700}>
              {translate('chargers.last_reboot')} :
            </Box>
            <Box pl={2}>{chargingStationFormValues.lastReboot}</Box>
          </Stack>
        )}
        {chargingStationFormValues.createdOn && (
          <Stack direction='row' spacing={2} data-cy='Creation date'>
            <Box minWidth={200} fontWeight={700}>
              {translate('chargers.created_on')} :
            </Box>
            <Box pl={2}>{chargingStationFormValues.createdOn}</Box>
          </Stack>
        )}
        {chargingStationFormValues.capabilities && (
          <Stack direction='row' spacing={2} data-cy='Capabilities'>
            <Box minWidth={200} fontWeight={700}>
              {translate('chargers.capabilities')}
            </Box>
            <Box pl={2}>
              supportChargingProfiles:
              {chargingStationFormValues.capabilities.supportChargingProfiles},
              supportStaticLimitation:
              {chargingStationFormValues.capabilities.supportStaticLimitation}
            </Box>
          </Stack>
        )}

        {chargingStationFormValues.ocppVersion && (
          <Stack direction='row' spacing={2} data-cy='last reboot'>
            <Box minWidth={200} fontWeight={700}>
              {translate('Last Seen')}
            </Box>
            <Box pl={2}>{`${timeAgo(chargingStationFormValues.lastSeen)}`}</Box>
          </Stack>
        )}
        <Typography fontWeight={600} fontSize={'16px'}>
          OCPP Configuration :
        </Typography>
        {chargingStationFormValues.ocppVersion && (
          <Stack direction='row' spacing={2} data-cy='OCPP Version'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargers.ocpp_version')}
            </Box>
            <Box>{chargingStationFormValues.ocppVersion}</Box>
          </Stack>
        )}
        {chargingStationFormValues.ocppProtocol && (
          <Stack direction='row' spacing={2} data-cy='OCPP Protocol'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('chargers.ocpp_protocol')}
            </Box>
            <Box>{chargingStationFormValues.ocppProtocol}</Box>
          </Stack>
        )}
        {chargingStationFormValues.connectors && (
          <Stack direction='row' spacing={2} data-cy='connector length'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('NumberOfConnectors ')}
            </Box>
            <Box>{chargingStationFormValues.connectors?.length}</Box>
          </Stack>
        )}
        {props.data && (
          <Stack direction='row' spacing={2} data-cy='HeartbeatInterval'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('HeartbeatInterval  ')}
            </Box>
            <Box>
              {props.data.filter((item) => {
                if (
                  ['HeartBeatInterval', 'HeartbeatInterval']?.includes(
                    item?.key
                  )
                ) {
                  return item.value;
                }
              }).length > 0
                ? props.data.map((item) => {
                    if (
                      ['HeartBeatInterval', 'HeartbeatInterval']?.includes(
                        item?.key
                      )
                    ) {
                      return item.value;
                    }
                  })
                : '-'}
            </Box>
          </Stack>
        )}
        {props.data && (
          <Stack
            direction='row'
            spacing={2}
            data-cy='MeterValuesSampledInterval'
          >
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('MeterValueSampleInterval')}
            </Box>
            <Box>
              {props.data.map((item) => {
                if (item.key === 'MeterValueSampleInterval') {
                  return item.value;
                }
              })}
            </Box>
          </Stack>
        )}
        <Typography fontWeight={600} fontSize={'16px'}>
          OCPP Feature profiles :
        </Typography>

        {props.data && (
          <Stack direction='row' spacing={2} data-cy='SupportedFeatureProfiles'>
            <Box minWidth={200} fontWeight={700} ml={2}>
              {translate('SupportedFeatureProfiles')}
            </Box>
            <Box>
              {props.data.map((item, index) => {
                if (item.key === 'SupportedFeatureProfiles') {
                  const featureProfiles = item?.value?.split(',') || [];

                  return (
                    <div key={index}>
                      {featureProfiles?.map((feature, featureIndex) => (
                        <span key={featureIndex}>
                          {feature.trim()}
                          {featureIndex < featureProfiles.length - 1 && ','}
                          <br />
                        </span>
                      ))}
                    </div>
                  );
                }

                return null;
              })}
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default withContext(Properties);
