import { Box, Divider } from '@mui/material';
import TransactionHeader from './transactionHeader';
import ConsumptionChartPop from '@/common/components/molecules/consumptionChart/popupChart';
import { useRef } from 'react';

const Transaction = (props: any) => {
  const consumptionPopup = useRef<any>();

  return (
    <div>
      <TransactionHeader
        transaction={props.transaction}
        userImage={props.userImage}
        carCatalogImage={props.carCatalogImage}
        withStopedBy={props.withStopedBy}
      />
      <Divider />
      <Box sx={{ marginTop: '40px' }}>
        {props.transactionId && (
          <ConsumptionChartPop
            ref={consumptionPopup}
            currentTransactionID={props.transactionId}
            popupOpen={props.popupOpen}
          />
        )}
      </Box>
    </div>
  );
};

export default Transaction;
