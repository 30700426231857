import { httpClient } from './httpClient';

export const getTransactionYear = () => {
	return httpClient.get('/api/statistics/transactions/years');
};

export const getChargingStationConsumptionDetails=(params:any)=>{
	return httpClient.get('/api/statistics/charging-stations/consumption',params);
};

export const getUserConsumptionDetails=(params:any)=>{
	return httpClient.get('/api/statistics/users/consumption',params);
};

export const getChargingStationUsageDetails=(params:any)=>{
	return httpClient.get('/api/statistics/charging-stations/usage',params);
};

export const getChargingStationInactivityDetails=(params:any)=>{
	return httpClient.get('/api/statistics/charging-stations/inactivity',params);
};

export const getChargingStationTransactionDetails=(params:any)=>{
	return httpClient.get('/api/statistics/charging-stations/transaction',params);
};

export const getChargingStationPricingDetails=(params:any)=>{
	return httpClient.get('/api/statistics/charging-stations/pricing',params);
};

export const getStatisticsExportDetails=(params:any)=>{
	return httpClient.getImage('/api/statistics/action/export',params);
};

export const getUserUsageDetails=(params:any)=>{
	return httpClient.get('/api/statistics/users/usage',params);
};

export const getUserSessionDetails=(params:any)=>{
	return httpClient.get('/api/statistics/users/transaction',params);
};

export const getUserPricingDetails=(params:any)=>{
	return httpClient.get('/api/statistics/users/pricing',params);
};

export const getUserInactivityDetails=(params:any)=>{
	return httpClient.get('/api/statistics/users/inactivity',params);
};