import { AddressFormModel } from '@/common/models/model';
import {
  IContextPropsModel,
  IFormPropsModel,
} from '@/common/utils/withContext';
import { DefaultPageSize } from '@/common/constants/constants';
import { IUserProfileModel } from '@/store/auth/types';
import { ConnectorsModel } from '@/modules/chargingStation/shared/models/chargingStationForm';

export interface ISiteAreaListStateModel {
  openSiteArea: boolean;
  isSiteAreaFormShown: boolean;
  page: number;
  rowsPerPage: number;
  rows: any[];
  totalRowsCount: number;
  defaultPageSize: number;
  sortDescending: boolean;
  withSiteArea: boolean;
  withParentSiteArea: boolean;
  issuer: boolean;
  limit: number;
  sortFields: string;
  search: any;
  issuerName: string;
  defaultIssuer: boolean;
  isSiteLookupModalOpen: boolean;
  siteId: string;
  siteName: string;
  isResetBtnDisabled: boolean;
  isSiteAreaChargingStationModalOpen: boolean;
  siteAreaId: string;
  siteAreaName: string;
  isAssetsModalOpen: boolean;
  activeRowDetailIndex: number;
  withSite?: boolean;
  canCreate: boolean;
  canAssignChargingStations: boolean;
  canUnassignChargingStations: boolean;
  canUpdateAssets: boolean;
  showSiteAreaForm: boolean;
  projectFields: string[];
  canAssignAssets: boolean;
  canUnassignAssets: boolean;
  feederID?: string;
  transformerID?: string;
  subStationID?: string;
}

export class SiteAreaRegistrationModel {
  id?: string = '';
  description?: string = '';
  createdOn?: Date = new Date();
  expirationDate?: Date = new Date();
  revocationDate?: Date = new Date();
  siteAreaID?: string = '';
  ocpp15SOAPSecureUrl?: string = '';
  ocpp16SOAPSecureUrl?: string = '';
  ocpp16JSONSecureUrl?: string = '';
}
export class RegistrationToken {
  id?: string = '';
  description?: string = '';
  createdOn?: Date = new Date();
  expirationDate?: Date = new Date();
  revocationDate?: Date = new Date();
  siteAreaID?: string = '';
  siteArea?: SiteAreaRegistrationModel;
  ocpp15SOAPSecureUrl?: string = '';
  ocpp16SOAPSecureUrl?: string = '';
  ocpp16JSONSecureUrl?: string = '';
}
export class SiteAreaFormModel {
  name?: string = '';
  site?: string = '';
  parentSiteArea?: string = '';
  subStationID?: string = 'Default';
  subStation?: string = '';
  feederID?: string = 'Default';
  feeder?: string = '';
  transformer?: string = '';
  transformerID?: string = 'Default';
  address?: AddressFormModel;
  id?: string = '';
  image?: any = null;
  issuer?: boolean;
  maximumPower?: number = 0;
  maximumPowerAmpsPerPhase?: number = 0;
  maximumTotalPowerAmps?: number = 0;
  numberOfPhases?: number = 3;
  siteID?: string = '';
  tariffID?: any = null;
  voltage?: number = 230;
  accessControl?: boolean = true;
  registrationToken?: RegistrationToken;
  parentSiteAreaID?: string = '';
  public?: boolean;
  subSiteAreasAction?: string = '';
  amperagePerPhase?: number = 0;
  amperage?: number = 0;
  currentType?: string = '';
  numberOfConnectedPhase?: number = 0;
  type?: string = '';
  power?: number = 0;
  shrink?: boolean = false;
  smartCharging?: smartCharging;
  ampPerPhase?: number = 0;
  totalAmpAllPhases?: number = 0;
  rating?: number = 0;
  phaseAssignment?: any;
  installedChargers?:number;
  totalInstallAmp?:number;
  totalInstallKw?:number;
  constructor() {
    this.address = { ...new AddressFormModel() };
    this.registrationToken = { ...new RegistrationToken() };
    this.smartCharging = { ...new smartCharging() };
  }
}

export class smartCharging {
  enabled?: boolean;
  smartChargingtype?: any;
  setPointkw?: number;
  setPointAmp?: number;
  // JIRA -[EVES-1680] setting type at smart charging object 
  type?:string;
}

export class SiteAreaFormStateModel {
  limit: number = DefaultPageSize;
  selectedTab: string = '';
}

export interface ISiteAreaFormPropsModel
  extends IContextPropsModel,
    IFormPropsModel<SiteAreaFormModel> {
  pageLimit: number;
  onFormClose: () => void;
  openSiteArea: boolean;
  siteAreaId: string;
  resetSiteAreaForm: (val: string) => void;
  limit?: number;
  fetchAllSiteAreas: () => void;
  userInfo: IUserProfileModel;
  canCreate: boolean;
  isPowerLimit?: boolean;
}

export type ISiteAreaListPropsModel = IContextPropsModel;
export interface ISiteAreaChargingStationPropsModel extends IContextPropsModel {
  canAssignChargingStations: boolean;
  canUnassignChargingStations: boolean;
  siteAreaId: string;
  siteAreaName: string;
  showModal: boolean;
  onClose: () => void;
  defaultSortColumn?: string;
}

export interface ISiteAreaChargingStationsStateModel {
  search: string;
  limit: number;
  sortDescending: boolean;
  sortFields: string;
  rows: Record<string, any>[];
  totalRowsCount: number;
  selectedRows: any[];
  defaultSortColumn?: string;
  isChargingStationAddModalOpen: boolean;
  projectFields: string[];
}

export interface ISiteAreaAssetsPropsModel extends IContextPropsModel {
  siteAreaId: string;
  siteAreaName: string;
  showModal: boolean;
  onClose: () => void;
  defaultSortColumn?: string;
  canUpdateAssets: boolean;
  canAssignAssets: boolean;
  canUnassignAssets: boolean;
}

export interface ISiteAreaAssetsStateModel {
  search: string;
  limit: number;
  sortDescending: boolean;
  sortFields: string;
  rows: Record<string, any>[];
  totalRowsCount: number;
  selectedRows: any[];
  defaultSortColumn?: string;
  isAssetAddModalOpen: boolean;
  projectFields: string[];
}

export interface ISiteAreaFormStateModel {
  pageLimit: number;
  selectedTab: string;
  isDisabled: boolean;
  canUpdateSiteAreaLimits?:boolean;
}
