import { Grid, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { EvesButton } from '@/common/components/atoms/button/button';
import { IAppContextModel } from '@/store/app/types';
import { useSelector } from 'react-redux';
import { InlineLoader } from '../../atoms/inlineLoader/inlineLoader';
import { disableLoaderForOnce } from '@/common/utils/loadingService';
import { setAutoLoader } from '@/store/app/reducer';
import store from '@/store';
import { useTranslation } from 'react-i18next';
import styles from './dataTable.module.scss';
import AutoRefreshIcon from '../../../../assets/img/dashboard/RefreshSVG.svg';
import { autoRefresh } from '@/common/utils/autoRefreshConfig';
interface ITableRefreshComponentPropsModel {
  componentName?: string;
  autoRefreshStatus?: boolean;
  intervalsAndFunctions?: { interval: 10000; func: () => void }[];
  hideLabel?: boolean;
  multiple?: boolean;
  onRefresh: (params?: any) => void;
}

let autoRefreshIntervals: any = [];
let autoRefreshInterval;
export default function TableRefreshComponent(
  props: ITableRefreshComponentPropsModel
) {
  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(
    props.autoRefreshStatus
  );

  const appContext: IAppContextModel = useSelector(
    (state: any) => state.appContext
  );

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (autoRefreshEnabled) {
      startAutoRefreshIntervals();
    } else {
      clearAutoRefreshIntervals();
    }
  }, [autoRefreshEnabled]);

  useEffect(() => {
    return () => {
      clearAutoRefreshIntervals();
    };
  }, []);

  const toggleAutoRefresh = () => {
    setAutoRefreshEnabled(!autoRefreshEnabled);
  };

  const startAutoRefreshIntervals = () => {
    if (autoRefreshIntervals?.length === 0 && autoRefreshEnabled) {
      autoRefreshIntervals =
        props?.intervalsAndFunctions &&
        props?.intervalsAndFunctions.map(({ interval, func }) => {
          return window.setInterval(() => {
            disableLoaderForOnce();
            store.dispatch(setAutoLoader(true));
            func();
          }, interval);
        });
    }
  };
  useEffect(() => {
    // Set default auto-refresh interval if not set to multiple
    if (!props.multiple && autoRefreshEnabled) {
      if (autoRefreshEnabled) {
        autoRefreshInterval = window.setInterval(() => {
          disableLoaderForOnce();
          store.dispatch(setAutoLoader(true));
          props.onRefresh();
        }, autoRefresh.DEFAULT) as any;
      }
    }

    return () => {
      clearAutoRefreshIntervals();
    };
  }, [autoRefreshEnabled]);

  const clearAutoRefreshIntervals = () => {
    if (props.multiple) {
      if (autoRefreshIntervals?.length > 0) {
        autoRefreshIntervals.forEach((intervalId) => {
          window.clearInterval(intervalId);
        });
        autoRefreshIntervals = [];
      }
    } else {
      if (autoRefreshInterval !== null) {
        window.clearInterval(autoRefreshInterval);
        autoRefreshInterval = null;
      }
    }
  };

  const onRefreshClick = () => {
    if (!props.multiple) {
      props.onRefresh();
    } else {
      props.intervalsAndFunctions &&
        props.intervalsAndFunctions.forEach(({ func }) => {
          func();
        });
    }
  };

  return (
    <>
      <Grid item className={styles.autoRefresh}>
        <Tooltip
          title={`${translate('general.tooltips.refresh')}`}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'black',
                borderStyle: 'solid',
                borderWidth: '1px',
              },
            },
          }}
          followCursor={false}
          placement={'bottom-start'}
          enterNextDelay={500}
        >
          <Grid item>
            <EvesButton
              isAction={true}
              onClick={onRefreshClick}
              data-cy={`${props.componentName}-manual-refresh`}
              style={{
                background: '#3D3AD3',
                //  height:'40px'
              }}
            >
              {!appContext.showLoader &&
              appContext.showAutoLoader &&
              autoRefreshEnabled ? (
                <InlineLoader showLoader={true} />
              ) : (
                <RefreshIcon></RefreshIcon>
              )}
            </EvesButton>
          </Grid>
        </Tooltip>

        {props.hideLabel && (
          <Tooltip
            title={`${translate('Auto Refresh')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <Grid item>
              <EvesButton
                isAction={true}
                onClick={toggleAutoRefresh}
                data-cy={`${props.componentName}-auto-refresh`}
                style={{
                  background: autoRefreshEnabled ? '#3D3AD3' : '#999',
                  //  height:'40px',
                  '& :hover': {
                    background: autoRefreshEnabled ? '#3D3AD3' : '#999',
                  },
                }}
              >
                <img src={AutoRefreshIcon} />
              </EvesButton>
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </>
  );
}
