import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Iothink = (props: any) => {
	const { t: translate } = useTranslation();
	return ( 
		<>
			<Grid item xs={6}>	
				<Controller name="iothinkConnection.user" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="user" label={`${translate('settings.asset.connection.user')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['iothinkConnection.user']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors?.iothinkConnection?.user}
						helperText={`${translate(props.assetForm.formState?.errors?.iothinkConnection?.user?.message || ' ')}`}
					/>
				)} />
			</Grid>	
			<Grid item xs={6}>	
				<Controller name="iothinkConnection.password" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="password" label={`${translate('settings.asset.connection.password')}`} type='password'
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['iothinkConnection.password']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors?.iothinkConnection?.password}
						helperText={`${translate(props.assetForm.formState?.errors?.iothinkConnection?.password?.message || ' ')}`}
					/>
				)} />
			</Grid>	
		</>
	);
};

export default Iothink;