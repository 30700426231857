import EvesSelect from '@/common/components/atoms/select/select';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { withContext } from '@/common/utils/withContext';
import { Grid, InputAdornment } from '@mui/material';
import { Controller} from 'react-hook-form';
import { Card } from '@/common/components/molecules/card/card';
import { OpenInNew, Settings } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SacSettings = (props: any) => {
	const { t: translate } = useTranslation();
	const { watch }= props.sacSettingFields;
	const url=watch('mainUrl');
	const [disableUrl,setDisableUrl]=useState(false);
	const onOpenUrl=()=>{
		if(props.sacSettingFields.formState.isValid){
			const mainUrl=props.sacSettingFields.watch('mainUrl');
			window.open(mainUrl);
		}
	};

	const onUrlChange=(event:any)=>{
		const regex = /^(ftp|http|https)?:\/\/[^ "]*$|^$/;
		const inputValue = event.target.value;
		if (regex.test(inputValue)) {
			setDisableUrl(false);
		} else {
			setDisableUrl(true);
		}
	};

	return (
		<>
			<Card icon={<Settings/>} title={`${translate('settings.analytics.sac.title')}`} isTransparent={true} sx={{marginBottom:'30px'}}>
				<Grid container direction="row" columnSpacing={2} rowSpacing={3}>
					<Grid item xs={6}>
						<Controller name="mainUrl" control={props.sacSettingFields.control} render={({ field }) => (
							<EvesTextbox {...field} id="mainUrl" label={`${translate('analytics.mainurl')}`}
								fullWidth  variant="standard" 
								data-cy="analytics-cloud-home-page"
								autoFocus={true}
								InputProps={{ endAdornment: (
									<InputAdornment position="end" style={{  cursor: disableUrl || url=='' ? 'default' : 'pointer'  }}>
										<OpenInNew   sx={{ color: disableUrl || url=='' ? 'gray' : 'black', height: '15px',}}  onClick={disableUrl || url==''? undefined : onOpenUrl} />
									</InputAdornment> ), }} 
								onChange={(event:any) => {
									field.onChange(event);
									props.sacSettingFields.trigger('mainUrl'); 
									onUrlChange(event);}}
								error={!!props.sacSettingFields.formState?.errors?.mainUrl}
								helperText={props.sacSettingFields.formState?.errors.mainUrl?.message?.toString()}
							/> )} />
					</Grid>
				</Grid>
				<Grid container direction="row" paddingTop={2}>
					<Grid item xs={3}> 
						<Controller name="timezone" control={props.sacSettingFields.control}
							render={({ field }) => (
								<EvesSelect data-cy="type-option" label={`${translate('analytics.timezone')} *`} options={props.timezoneListOptions}
									{...field}  onChange={(event:any) => {field.onChange(event);}}
								/> )} />
					</Grid>
				</Grid>
			</Card>
		</>
	);
};

export default withContext(SacSettings);