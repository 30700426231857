import EvesSelect from '@/common/components/atoms/select/select';
import { Card } from '@/common/components/molecules/card/card';
import { Business } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Simple = (props: any) => {
	const { t: translate } = useTranslation();
	const pricingForm = props.pricingForm;
	const currencyOptions = [
		{ value: 'AUD', label: 'Australian Dollar'},
		{ value: 'BRL', label: 'Brazilian Real '},
		{ value: 'CAD', label: 'Canadian Dollar'},
		{ value: 'CZK', label: 'Czech Koruna'},
		{ value: 'DKK', label: 'Danish Krone'},
		{ value: 'EUR', label: 'Euro'},
		{ value: 'HKD', label: 'Hong Kong Dollar'},
		{ value: 'HUF', label: 'Hungarian Forint '},
		{ value: 'INR', label: 'Indian Rupee'},
		{ value: 'ILS', label: 'Israeli New Sheqel'},
		{ value: 'JPY', label: 'Japanese Yen'},
		{ value: 'MYR', label: 'Malaysian Ringgit'},
		{ value: 'MXN', label: 'Mexican Peso'},
		{ value: 'NOK', label: 'Norwegian Krone'},
		{ value: 'NZD', label: 'New Zealand Dollar'},
		{ value: 'PHP', label: 'Philippine Peso'},
		{ value: 'PLN', label: 'Polish Zloty'},
		{ value: 'GBP', label: 'Pound Sterling'},
		{ value: 'SGD', label: 'Singapore Dollar'},
		{ value: 'SEK', label: 'Swedish Krona'},
		{ value: 'CHF', label: 'Swiss Franc'},
		{ value: 'TWD', label: 'Taiwan New Dollar'},
		{ value: 'THB', label: 'Thai Baht'},
		{ value: 'TRY', label: 'Turkish Lira'},
		{ value: 'USD', label: 'U.S. Dollar'}
	];
	

	return <Card icon={<Business/>} title={`${translate('settings.pricing.settings.title')}`} isTransparent={true} sx={{marginBottom:'25px', height: '125px'}}>
		<>
			<Grid container rowSpacing={1} columnSpacing={2}>
				<Grid item xs={3}>	
					<Controller
						name="currency"
						control={pricingForm.control}
						render={({ field }) => (
							<EvesSelect
							{...field}
								data-cy="voltage-option"
								label={`${translate('pricing.price_unit_label')} *`}
								options={currencyOptions} 
								onChange={(e:any)=>{props.saveCurrencyData(e.target.value);field.onChange(e);}}
								disabled ={props.isCurrencyCodeReadonly}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</>
	</Card>;
};

export default Simple;