import { FormControlLabel, Grid, IconButton, InputAdornment, RadioGroup, Radio } from '@mui/material';
import { useState } from 'react';
import { requiredFields } from '@/modules/car/shared/schemas/electricVehiclesForm';
import { Controller, UseFormReturn } from 'react-hook-form';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import LookupComponent from '@/common/components/lookupComponent';
import { LookupEntityType } from '@/common/enums/enums';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import EvesSelect from '@/common/components/atoms/select/select';
import noImage from '@/assets/img/theme/no-image.png';
import { ElectricVehicleModel } from '@/modules/car/shared/models/electricVehicle';

interface IElectricVehicleModel extends IContextPropsModel {
	electricVehicleForm: UseFormReturn<ElectricVehicleModel, any>
	pageLimit: any;
	isDisabled: boolean;
}

const EvForm = (props: IElectricVehicleModel) => {
	const [state, setStateData] = useState({
		showUserLookUpModal: false,
		isOpenEvCatalogLookupModal: false,
		converter: '',
		evCatelog: '',
		user: ''
	});
	const electricVehicleForm = props.electricVehicleForm;
	const userInfo = JSON.parse(localStorage.getItem('user_info') || '');
	const userFieldAccess = !userInfo?.rolesACL.includes('basic');
	const poolFieldAccess = !(userInfo?.rolesACL.includes('basic') || userInfo?.rolesACL.includes('siteOwner'));
	const data = props.electricVehicleForm.getValues();
	const catalogParams = [{ key: 'Limit', value: 50 }, { key: 'SortField', value: 'vehicleMake' }];
	const { t: translate } = useTranslation();
	const onClickEvCatalogLookUpModal = () => {
		setStateData((currentDate) => {
			return {
				...currentDate,
				isOpenEvCatalogLookupModal: true,
			};
		});
	};

	const onClickUserLookUpModal = () => {
		setStateData((currentDate) => {
			return {
				...currentDate,
				showUserLookUpModal: true,
			};
		});
	};

	const onCloseEvCatalogLookUpModal = () => {
		setStateData((currentDate) => {
			return {
				...currentDate,
				isOpenEvCatalogLookupModal: false,
			};
		});
	};

	const onUserFilterModalClose = () => {
		setStateData((currentDate) => {
			return {
				...currentDate,
				showUserLookUpModal: false,
			};
		});
	};


	const onEvCatalogSelect = (evCatelog: any) => {
		props.electricVehicleForm.setValue('converter', `${evCatelog[0].chargeStandardPhaseAmp} kW - ${evCatelog[0].chargeStandardPhase} Phase(s) -  ${evCatelog[0].chargeStandardPower} A`, { shouldValidate: true, shouldDirty: true });
		props.electricVehicleForm.setValue('evCatalog', `${evCatelog[0].vehicleMake} ${evCatelog[0].vehicleModel}`, { shouldValidate: true, shouldDirty: true });
		props.electricVehicleForm.setValue('evCatalogId', evCatelog[0].id);
		props.electricVehicleForm.setValue('image', evCatelog[0].image);
		props.electricVehicleForm.setValue('amperagePerPhase', evCatelog[0].chargeStandardPhaseAmp);
		props.electricVehicleForm.setValue('numberOfPhases', evCatelog[0].chargeStandardPhase);
		props.electricVehicleForm.setValue('powerWatts', evCatelog[0].chargeStandardPower);
		props.electricVehicleForm.setValue('converterType', 'S');

		setStateData((currentDate) => {
			return {
				...currentDate,
				isOpenEvCatalogLookupModal: false,
			};
		});
	};


	const onUserSelected = (users: any) => {
		props.electricVehicleForm.setValue('user', `${users[0].firstName} ${users[0].name}`, { shouldValidate: true, shouldDirty: true });
		props.electricVehicleForm.setValue('userId', users[0].id);
		setStateData((currentDate) => {
			return {
				...currentDate,
				showUserLookUpModal: false,
			};
		});
	};

	return (
		<>
			{state.isOpenEvCatalogLookupModal && <LookupComponent title={`${translate('cars.assign_car_catalog')} `}
				showLookupModal={state.isOpenEvCatalogLookupModal} onModalClose={onCloseEvCatalogLookUpModal}
				selectionMode='single' defaultSortColumn='name' entityType={LookupEntityType.EV_CATALOG}
				onSelect={onEvCatalogSelect} params={catalogParams} />}
			{state.showUserLookUpModal && (
				<LookupComponent
					title={`${translate('users.select_users')}`}
					showLookupModal={state.showUserLookUpModal}
					onModalClose={onUserFilterModalClose}
					selectionMode='single'
					entityType={LookupEntityType.SESSION_USER}
					params={[{ key: 'Issuer', value: true }]}
					onSelect={onUserSelected}
					defaultSortColumn='name'
					data-cy='session-select-user-lookup'
				/>
			)}

			<Grid container rowSpacing={1} columnSpacing={2.5}>
				<Grid width={300} height={250} ml={2} mt={2} sx={{
					boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)'
				}}>
					<img src={data.image ? data.image : noImage} crossOrigin='anonymous' width={300} height={250} />
				</Grid>
				<Grid item xs={7}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Controller name="evCatalog" control={electricVehicleForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="evCatalog" label={`${translate('cars.ev_catalog')}`}
									fullWidth onClick={onClickEvCatalogLookUpModal} disabled={props.isDisabled}
									required={requiredFields['evCatalog']} variant="standard"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" style={{ cursor: 'pointer' }}>
												<IconButton disabled={props.isDisabled}><Edit sx={{ color: 'black', height: '15px' }} /></IconButton>
											</InputAdornment>
										), readOnly: true
									}}
									error={!!electricVehicleForm.formState?.errors.evCatalog}
									helperText={`${translate(electricVehicleForm.formState?.errors.evCatalog?.message || ' ')}`}
								/>
							)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="converter" control={electricVehicleForm.control} render={({ field }) => (
								<EvesSelect data-cy="voltage-option" label={`${translate('cars.converter')} *`}
									options={[{ label: data.converter, value: data.converter }]}  {...field}
									onChange={(event: any) => {
										field.onChange(event);
									}} disabled={props.isDisabled} required={requiredFields['converter']}
								/>
							)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller name="vin" control={electricVehicleForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="vin" label={`${translate('cars.vin')}`}
									fullWidth disabled={props.isDisabled} required={requiredFields['vin']}
									variant="standard" error={!!electricVehicleForm.formState?.errors.vin}
									helperText={`${translate(electricVehicleForm.formState?.errors.vin?.message || ' ')}`}
								/>)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="licensePlate" control={electricVehicleForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="name" label={`${translate('cars.license_plate')}`}
									fullWidth disabled={props.isDisabled} required={requiredFields['licensePlate']}
									variant="standard" error={!!electricVehicleForm.formState?.errors.licensePlate}
									helperText={`${translate(electricVehicleForm.formState?.errors.licensePlate?.message || ' ')}`}
								/>)} />
						</Grid>
						{userFieldAccess && <Grid item xs={12}>
							<Controller name="user" control={electricVehicleForm.control} render={({ field }) => (
								<EvesTextbox {...field} id="user" label={`${translate('general.search_user')}`}
									fullWidth onClick={onClickUserLookUpModal} disabled={props.isDisabled}
									variant="standard"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" style={{ cursor: 'pointer' }}>
												<IconButton disabled={props.isDisabled}><Edit sx={{ color: 'black', height: '15px' }} /></IconButton>
											</InputAdornment>
										), readOnly: true
									}}
									error={!!electricVehicleForm.formState?.errors.user}
									helperText={`${translate(electricVehicleForm.formState?.errors.user?.message || ' ')}`}
								/>
							)} />
						</Grid>}
						<Grid item xs={12}>
							<Controller name="defaultEv" control={props.electricVehicleForm.control} render={({ field }) => (
								<FormControlLabel control={<EvesCheckbox {...field} checked={props.electricVehicleForm.watch('defaultEv')} disabled={props.isDisabled} />}
									label={`${translate('cars.defaultEV')}`} />)} />
						</Grid>
						<Grid item xs={12}>
							<Controller name="type" control={props.electricVehicleForm.control} render={({ field }) => (
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									defaultValue="C"
									{...field}
								>
									<FormControlLabel value="C" control={<Radio />} label="Company" />
									<FormControlLabel value="P" control={<Radio />} label="Private" />
									{poolFieldAccess && <FormControlLabel value="PC" control={<Radio />} label="Pool" />}								</RadioGroup>)} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default withContext(EvForm);
