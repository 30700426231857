import { EvesButton } from '@/common/components/atoms/button/button';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import TableRefreshComponent from '@/common/components/organisms/table/refreshComponent';
import { DefaultPageSize } from '@/common/constants/constants';
import {
  IPriceListPropsModel,
  IPriceListStateModel,
} from '@/modules/organization/shared/models/price';
import { PriceModel } from '@/services/models/organization';
import { deletePriceDefinition, getPriceList } from '@/services/organizations';
import {
  Add,
  Delete,
  Edit,
  RemoveRedEye,
  HourglassBottom,
  OfflineBolt,
  Schedule,
  CalendarViewWeek,
  ArrowForwardIos,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import PriceForm from './index';
import { EvesSwitch } from './../../../../common/components/atoms/switch/switch';
import { Content } from '@/common/components/templates/content/content';
import { Card } from '@/common/components/molecules/card/card';
import { columnsType } from '@/common/models/model';
import { useTranslation } from 'react-i18next';
import AllConnectorsIcon from '@/assets/img/connectors/all_connectors.svg';
import Type2Icon from '@/assets/img/connectors/type2.svg';
import Type2ComboCcsIcon from '@/assets/img/connectors/combo-ccs.svg';
import ChademoIcon from '@/assets/img/connectors/chademo.svg';
import Type1Icon from '@/assets/img/connectors/type1.svg';
import Type1CCSIcon from '@/assets/img/connectors/type1-ccs-new.svg';
import NacsIcon from '@/assets/img/connectors/nscc.svg';
import { FormControlLabel } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useLocation } from 'react-router-dom';

export default function PriceList(props: IPriceListPropsModel) {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    limit: DefaultPageSize,
    sortDescending: false,
    sortFields: '',
    rows: [],
    totalRowsCount: 0,
    isPriceAddModalOpen: false,
    selectedPriceId: '',
    canCreate: false,
    projectFields: [],
    siteRows: [],
    tenantRows: [],
    totalSiteRows: 0,
    totalTenantRows: 0,
    page: 0,
  } as IPriceListStateModel);
  const [viewAllEnabled, setViewAllEnabled] = useState(false);
  const location = useLocation();
  const [accordionOpen, setAccordionOpen] = useState(props.entityType);

  const options = [
    { label: translate('week_days.mon'), value: 1, name: 'Mon' },
    { label: translate('week_days.tue'), value: 2, name: 'Tue' },
    { label: translate('week_days.wed'), value: 3, name: 'Wed' },
    { label: translate('week_days.thu'), value: 4, name: 'Thu' },
    { label: translate('week_days.fri'), value: 5, name: 'Fri' },
    { label: translate('week_days.sat'), value: 6, name: 'Sat' },
    { label: translate('week_days.sun'), value: 7, name: 'Sun' },
  ];

  const toggleAutoRefresh = () => {
    setViewAllEnabled(!viewAllEnabled);
  };

  const dataTableRef = useRef<any>();
  const isModal = props.isModal === undefined || props.isModal ? true : false;

  const buildTableColumns = (): columnsType[] => {
    const columns: columnsType[] = [
      {
        id: 'actions',
        label: `${translate('general.actions')}`,
        minWidth: 70,
        textAlign: 'center',
        maxWidth: 90,
        projectField: 'action',
      },
      {
        id: 'name',
        label: `${translate('users.name')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'name',
        textAlign: 'left',
        maxWidth: 220,
        projectField: 'name',
      },
      {
        id: 'restriction',
        label: `${translate('general.restrictions')}`,
        minWidth: 70,
        textAlign: 'center',
        maxWidth: 120,
        projectField: 'staticRestrictions.connectorType',
      },
      {
        id: 'validFrom',
        label: `${translate('settings.pricing.valid_from')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'staticRestrictions.validFrom',
        textAlign: 'left',
        maxWidth: 120,
        projectField: 'staticRestrictions.validFrom',
      },
      {
        id: 'validTo',
        label: `${translate('settings.pricing.valid_to')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'staticRestrictions.validTo',
        textAlign: 'left',
        maxWidth: 120,
        projectField: 'staticRestrictions.validTo',
      },
      {
        id: 'flatFee',
        label: `${translate('settings.pricing.flat_fee')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.flatFee.price',
      },
      {
        id: 'energy',
        label: `${translate('settings.pricing.energy')}`,
        minWidth: 90,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.energy.price',
      },
      {
        id: 'chargingTime',
        label: `${translate('settings.pricing.charging_time')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.chargingTime.price',
      },
      {
        id: 'parkingTime',
        label: `${translate('settings.pricing.parking_time')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.parkingTime.price',
      },
    ];

    if (viewAllEnabled) {
      const newColumns = [
        {
          id: 'entityType',
          label: `${translate('settings.pricing.entity_type')}`,
          minWidth: 80,
          allowSort: true,
          sortKey: 'entityType',
          textAlign: 'left',
          maxWidth: 180,
          projectField: 'entityType',
        },
        {
          id: 'entityName',
          label: `${translate('settings.pricing.entity_name')}`,
          minWidth: 80,
          allowSort: false,
          sortKey: 'entityName',
          textAlign: 'left',
          maxWidth: 180,
          projectField: 'entityName',
        },
      ];
      columns.splice(2, 0, ...newColumns);
    }
    return columns;
  };

  const buildSiteColumns = (): columnsType[] => {
    const columns: columnsType[] = [
      ...(props.entityType === 'Site'
        ? [
            {
              id: 'actions',
              label: `${translate('general.actions')}`,
              minWidth: 70,
              textAlign: 'center',
              maxWidth: 90,
              projectField: 'action',
            },
          ]
        : []),
      {
        id: 'name',
        label: `${translate('users.name')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'name',
        textAlign: 'left',
        maxWidth: 220,
        projectField: 'name',
      },
      {
        id: 'restriction',
        label: `${translate('general.restrictions')}`,
        minWidth: 70,
        textAlign: 'center',
        maxWidth: 120,
        projectField: 'staticRestrictions.connectorType',
      },
      {
        id: 'validFrom',
        label: `${translate('settings.pricing.valid_from')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'staticRestrictions.validFrom',
        textAlign: 'left',
        maxWidth: 120,
        projectField: 'staticRestrictions.validFrom',
      },
      {
        id: 'validTo',
        label: `${translate('settings.pricing.valid_to')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'staticRestrictions.validTo',
        textAlign: 'left',
        maxWidth: 120,
        projectField: 'staticRestrictions.validTo',
      },
      {
        id: 'flatFee',
        label: `${translate('settings.pricing.flat_fee')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.flatFee.price',
      },
      {
        id: 'energy',
        label: `${translate('settings.pricing.energy')}`,
        minWidth: 90,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.energy.price',
      },
      {
        id: 'chargingTime',
        label: `${translate('settings.pricing.charging_time')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.chargingTime.price',
      },
      {
        id: 'parkingTime',
        label: `${translate('settings.pricing.parking_time')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 220,
        projectField: 'dimensions.parkingTime.price',
      },
    ];

    if (viewAllEnabled) {
      const newColumns = [
        {
          id: 'entityType',
          label: `${translate('settings.pricing.entity_type')}`,
          minWidth: 80,
          allowSort: true,
          sortKey: 'entityType',
          textAlign: 'left',
          maxWidth: 180,
          projectField: 'entityType',
        },
        {
          id: 'entityName',
          label: `${translate('settings.pricing.entity_name')}`,
          minWidth: 80,
          allowSort: false,
          sortKey: 'entityName',
          textAlign: 'left',
          maxWidth: 180,
          projectField: 'entityName',
        },
      ];
      columns.splice(2, 0, ...newColumns);
    }
    return columns;
  };

  const buildTenantColumns = (): columnsType[] => {
    const columns: columnsType[] = [
      ...(props.entityType === 'Tenant'
        ? [
            {
              id: 'actions',
              label: `${translate('general.actions')}`,
              minWidth: 70,
              textAlign: 'center',
              maxWidth: 90,
              projectField: 'action',
            },
          ]
        : []),
      {
        id: 'name',
        label: `${translate('users.name')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'name',
        textAlign: 'left',
        maxWidth: 220,
        projectField: 'name',
      },
      {
        id: 'restriction',
        label: `${translate('general.restrictions')}`,
        minWidth: 70,
        textAlign: 'center',
        maxWidth: 120,
        projectField: 'staticRestrictions.connectorType',
      },
      {
        id: 'validFrom',
        label: `${translate('settings.pricing.valid_from')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'staticRestrictions.validFrom',
        textAlign: 'left',
        maxWidth: 120,
        projectField: 'staticRestrictions.validFrom',
      },
      {
        id: 'validTo',
        label: `${translate('settings.pricing.valid_to')}`,
        minWidth: 90,
        allowSort: true,
        sortKey: 'staticRestrictions.validTo',
        textAlign: 'left',
        maxWidth: 120,
        projectField: 'staticRestrictions.validTo',
      },
      {
        id: 'flatFee',
        label: `${translate('settings.pricing.flat_fee')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.flatFee.price',
      },
      {
        id: 'energy',
        label: `${translate('settings.pricing.energy')}`,
        minWidth: 90,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.energy.price',
      },
      {
        id: 'chargingTime',
        label: `${translate('settings.pricing.charging_time')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 150,
        projectField: 'dimensions.chargingTime.price',
      },
      {
        id: 'parkingTime',
        label: `${translate('settings.pricing.parking_time')}`,
        minWidth: 120,
        textAlign: 'left',
        maxWidth: 220,
        projectField: 'dimensions.parkingTime.price',
      },
    ];

    if (viewAllEnabled) {
      const newColumns = [
        {
          id: 'entityType',
          label: `${translate('settings.pricing.entity_type')}`,
          minWidth: 80,
          allowSort: true,
          sortKey: 'entityType',
          textAlign: 'left',
          maxWidth: 180,
          projectField: 'entityType',
        },
        {
          id: 'entityName',
          label: `${translate('settings.pricing.entity_name')}`,
          minWidth: 80,
          allowSort: false,
          sortKey: 'entityName',
          textAlign: 'left',
          maxWidth: 180,
          projectField: 'entityName',
        },
      ];
      columns.splice(2, 0, ...newColumns);
    }
    return columns;
  };

  const { refetch: fetchPriceList } = useQuery(
    '',
    () => {
      return loadPriceList();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if(data){
          const resultData = data?.result.map((res: any) => new PriceModel(res));

          const resultTenantData = resultData.filter((res: any) => {
            if (res.entityType === 'Tenant') {
              return new PriceModel(res);
            }
          });
  
          const resultChargingData = resultData.filter((res: any) => {
            if (res.entityType === 'ChargingStation') {
              return new PriceModel(res);
            }
          });
  
          const resultSiteData = resultData.filter((res: any) => {
            if (res.entityType === 'Site') {
              return new PriceModel(res);
            }
          });
          setStateData((currentData) => {
            return {
              ...currentData,
              rows:
                props.entityType === 'Tenant'  ? viewAllEnabled   ? resultData  : resultTenantData          : resultChargingData,
              totalRowsCount: props.entityType === 'Tenant'  ? viewAllEnabled   ? resultData?.length  : resultTenantData?.length: resultChargingData.length ?? 0 ,
              projectFields: data.projectFields && [...data.projectFields],
              canCreate: data.canCreate,
              siteRows: resultSiteData,
              tenantRows: resultTenantData,
              totalSiteRows: resultSiteData?.length ?? 0,
              totalTenantRows: resultTenantData.length ?? 0,
            };
          });
        }
       
      },
    }
  );

  const { mutate: deletePriceMutate } = useMutation(
    (priceId: string) => deletePriceDefinition(priceId),
    {
      onSuccess: (res: any) => {
        if (res.status === 'Success') {
          props.notificationService?.notify({
            message: `${translate(
              'settings.pricing.pricing_definition_delete_success'
            )}`,
            type: 'success',
          });
          fetchPriceList();
        } else {
          props.notificationService?.notify({
            message: `${translate(
              'settings.pricing.pricing_definition_delete_error'
            )}`,
            type: 'error',
          });
        }
      },
    }
  );

  useEffect(() => {
    if (!state.isPriceAddModalOpen) fetchPriceList();
  }, [
    state.page,
    state.limit,
    state.isPriceAddModalOpen,
    state.sortFields,
    state.sortDescending,
    viewAllEnabled,
  ]);

  const loadPriceList = () => {
    const params: any = {
      Limit: state.limit,
      EntityID: props.siteId,
      SiteID: props.chargingSiteId,
      EntityType: props.entityType,
      Page: state.page + 1,
    };
    const viewAllParams: any = {
      Limit: state.limit,
      WithEntityInformation: true,
      Page: state.page + 1,
    };
    if (state.sortFields !== '') {
      params['SortFields'] = state.sortFields;
    }
    return getPriceList(viewAllEnabled ? viewAllParams : params);
  };

  const onDataTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const onPriceCreate = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isPriceAddModalOpen: true,
      };
    });
  };

  const onPriceFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isPriceAddModalOpen: false,
      };
    });
  };

  const onResetPriceForm = (val: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedPriceId: val,
      };
    });
  };

  const onEditPriceClick = (record: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isPriceAddModalOpen: true,
        selectedPriceId: record.id,
      };
    });
  };

  const onDeletePriceClick = (record: any) => {
    props.dialogService?.showConfirm(
      {
        title: `${translate(
          'settings.pricing.pricing_definition_delete_title'
        )}`,
        description: `${translate(
          'settings.pricing.pricing_definition_delete_confirm'
        )}`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          deletePriceMutate(record.id);
        }
      }
    );
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: (record: any) =>
        record.canUpdate
          ? `${translate('general.edit')}`
          : `${translate('general.tooltips.view')}`,
      icon: (record: any) => (record.canUpdate ? Edit : RemoveRedEye),
      iconColor: 'primary',
      onClick: onEditPriceClick,
    },
    {
      title: () => `${translate('general.delete')}`,
      icon: () => Delete,
      iconColor: 'error',
      onClick: onDeletePriceClick,
      visible: (record) => record?.canDelete,
    },
  ];

  const RestrictionsCell = (row: any) => {
    const connectorTypeOptions = [
      {
        label: `${translate('chargers.connector_type_all')}`,
        value: 'A',
        img: AllConnectorsIcon,
      },
      {
        label: `${translate('chargers.connector_type_type2')}`,
        value: 'T2',
        img: Type2Icon,
      },
      {
        label: `${translate('chargers.connector_type_combo')}`,
        value: 'CCS',
        img: Type2ComboCcsIcon,
      },
      {
        label: `${translate('chargers.connector_type_chademo')}`,
        value: 'C',
        img: ChademoIcon,
      },
      {
        label: `${translate('chargers.connector_type_type1')}`,
        value: 'T1',
        img: Type1Icon,
      },
      {
        label: `${translate('chargers.connector_type_type1ccs')}`,
        value: 'T1CCS',
        img: Type1CCSIcon,
      },
      {
        label: `${translate('chargers.connector_type_nacs')}`,
        value: 'NACS',
        img: NacsIcon,
      },
    ];
    const connectorType = connectorTypeOptions.filter(
      (item) => item.value === row.staticRestrictions?.connectorType
    )[0];
    const daysWeek = row?.restrictions?.daysOfWeek?.map((item) => {
      return options.filter((itemC) => item === itemC?.value)[0]?.name;
    });

    const getTitleData = (min, max, minText, maxText, value) => {
      const minDuration = min;
      const maxDuration = max;

      let title = '';

      if (minDuration !== undefined && maxDuration !== undefined) {
        title = `${minText}: ${
          minDuration / (value === 'kWh' ? 1 : 60)
        } ${value} - ${maxText}: ${
          maxDuration / (value === 'kWh' ? 1 : 60)
        } ${value}`;
      } else if (minDuration !== undefined) {
        title = `${minText}: ${
          minDuration / (value === 'kWh' ? 1 : 60)
        } ${value}`;
      } else if (maxDuration !== undefined) {
        title = `${maxText}: ${
          maxDuration / (value === 'kWh' ? 1 : 60)
        } ${value}`;
      }
      return title;
    };
    const getConnectorTitle = () => {
      let title = '';

      if (
        connectorType?.label !== undefined &&
        row?.staticRestrictions?.connectorPowerkW !== undefined
      ) {
        title = `${connectorType?.label} - Connector Power: ${row?.staticRestrictions?.connectorPowerkW} kW`;
      } else if (connectorType?.label !== undefined) {
        title = `${connectorType?.label}`;
      } else if (row?.staticRestrictions?.connectorPowerkW !== undefined) {
        title = `Connector Power: ${row?.staticRestrictions?.connectorPowerkW} kW`;
      }
      return title;
    };
    const timeTitle = getTitleData(
      row?.restrictions?.minDurationSecs,
      row.restrictions?.maxDurationSecs,
      'Min duration',
      'Max duration',
      'Minutes'
    );
    const energyTitle = getTitleData(
      row?.restrictions?.minEnergyKWh,
      row.restrictions?.maxEnergyKWh,
      'Min Energy',
      'Max Energy',
      'kWh'
    );
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={connectorType?.img || ''}
          width={20}
          title={getConnectorTitle()}
        />
        {row.restrictions?.maxDurationSecs ||
        row?.restrictions?.minDurationSecs ? (
          <div
            title={timeTitle}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <HourglassBottom width={20} />
          </div>
        ) : (
          ''
        )}
        {row.restrictions?.maxEnergyKWh || row?.restrictions?.minEnergyKWh ? (
          <div
            title={energyTitle}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <OfflineBolt width={20} />
          </div>
        ) : (
          ''
        )}
        {row?.restrictions?.timeFrom || row?.restrictions?.timeTo ? (
          <div
            title={`${row?.restrictions?.timeFrom} - ${row?.restrictions?.timeTo}`}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Schedule width={20} />{' '}
          </div>
        ) : (
          ''
        )}
        {row?.restrictions?.daysOfWeek ? (
          <div
            title={daysWeek}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CalendarViewWeek width={20} />{' '}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const DataTableComponent = (
    <DataTable
      className='pricing-table'
      ref={dataTableRef}
      rows={state.rows}
      columns={buildTableColumns()}
      totalRowsCount={state.totalRowsCount}
      actionsMenu={primaryActions}
      onChange={onDataTableChange}
      projectFields={state.projectFields}
      defaultSortField={state.sortFields}
      data-cy='pricing-table'
      maxHeight={'202px'}
      priceModule={true}
    >
      <RestrictionsCell slot='restriction' />
    </DataTable>
  );

  const SiteTableComponent = (
    <DataTable
      className='pricing-table'
      ref={dataTableRef}
      rows={state.siteRows}
      columns={buildSiteColumns()}
      totalRowsCount={state.totalSiteRows}
      actionsMenu={props.entityType === 'Site' ? primaryActions : undefined}
      onChange={onDataTableChange}
      projectFields={state.projectFields}
      defaultSortField={state.sortFields}
      data-cy='pricing-table'
      maxHeight={'202px'}
      priceModule={true}
    >
      <RestrictionsCell slot='restriction' />
    </DataTable>
  );

  const TenantTableComponent = (
    <DataTable
      className='pricing-table'
      ref={dataTableRef}
      rows={state.tenantRows}
      columns={buildTenantColumns()}
      totalRowsCount={state.totalTenantRows}
      actionsMenu={props.entityType === 'Tenant' ? primaryActions : undefined}
      onChange={onDataTableChange}
      projectFields={state.projectFields}
      defaultSortField={state.sortFields}
      data-cy='pricing-table'
      maxHeight={'202px'}
      priceModule={true}
    >
      <RestrictionsCell slot='restriction' />
    </DataTable>
  );
 
  const handleChange = (_, isExpanded) => {
    // if expanded, set to open/expand, close it otherwise
    if (accordionOpen === isExpanded) {
      setAccordionOpen('');
    } else {
      setAccordionOpen(isExpanded);
    }
  };

  return (
    <>
      {(isModal && (
        <EvesModal
          maxWidth={'1100px'}
          isOpen={props.showModal}
          title={translate('settings.pricing.title')}
          titleIcon={<AttachMoneyIcon />}
          onHandleClose={props.onClose}
          isSaveHidden={true}
          priceModule={true}
        >
          <Content
            price={
              location?.pathname === '/charging-stations'
                ? 'ChargingStation'
                : false
            }
            contentTop={
              <Grid
                container
                direction='row'
                sx={{
                  background: '#f5f5f5 !important',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <Grid item xs={6}>
                  <Stack direction='row' spacing={1}>
                    {state.canCreate && (
                      <EvesButton
                        variant='contained'
                        startIcon={<Add />}
                        onClick={onPriceCreate}
                        data-cy='btn-create-price'
                      >
                        {translate('general.create')}
                      </EvesButton>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction='row' spacing={1} justifyContent='flex-end'>
                    <TableRefreshComponent
                      componentName='pricing'
                      onRefresh={fetchPriceList}
                    />
                  </Stack>
                </Grid>
              </Grid>
            }
            contentBody={
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  gap: '20px',
                }}
              >
                {state?.totalRowsCount && state?.rows.length > 0 ? (
                  <Accordion
                    sx={{
                      borderRadius: '4px',
                      background: 'rgba(61, 58, 211, 0.12)',
                      boxShadow: 'none',
                      width: '100%',
                      '& .Mui-expanded': {
                        minHeight: '10px !important',
                        maxHeight: '43px !important',
                        margin: 0,
                      },
                    }}
                    onChange={(e: any) => handleChange(e, 'ChargingStation')}
                    expanded={
                      accordionOpen === 'ChargingStation' ? true : false
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowForwardIos
                          sx={{
                            fill: '#000',
                            width: '16px',
                            height: '9.88px',
                          }}
                        />
                      }
                      sx={{
                        width: '100%',
                        minHeight: '10px',
                        maxHeight: '43px',
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                          {
                            transform: 'rotate(90deg) !important',
                            maxHeight: '20px !important',
                            marginTop: '5px !important',
                          },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          marginTop: '23px !important',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'var(--Gray-900, #242424)',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontWeight: 500,
                        }}
                      >
                        Charging Station Pricing
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: 0,
                      }}
                    >
                      {DataTableComponent}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ''
                )}
                {state.totalSiteRows ? (
                  <Accordion
                    sx={{
                      borderRadius: '4px',
                      background: 'rgba(61, 58, 211, 0.12)',
                      boxShadow: 'none',
                      width: '100%',
                      '& .Mui-expanded': {
                        minHeight: '10px !important',
                        maxHeight: '43px !important',
                        margin: 0,
                      },
                    }}
                    onChange={(e: any) => handleChange(e, 'Site')}
                    expanded={accordionOpen === 'Site' ? true : false}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowForwardIos
                          sx={{
                            fill: '#000',
                            width: '16px',
                            height: '9.88px',
                          }}
                        />
                      }
                      sx={{
                        minHeight: '10px',
                        maxHeight: '43px',
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                          {
                            transform: 'rotate(90deg) !important',
                            maxHeight: '20px',
                            marginTop: '5px !important',
                          },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          marginTop: '23px !important',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'var(--Gray-900, #242424)',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontWeight: 500,
                        }}
                      >
                        Site Pricing
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: 0,
                      }}
                    >
                      {SiteTableComponent}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ''
                )}
                {state.totalTenantRows ? (
                  <Accordion
                    sx={{
                      borderRadius: '4px',
                      background: 'rgba(61, 58, 211, 0.12)',
                      boxShadow: 'none',
                      width: '100%',
                      '& .Mui-expanded': {
                        minHeight: '10px !important',
                        maxHeight: '43px !important',
                        margin: 0,
                      },
                    }}
                    onChange={(e: any) => handleChange(e, 'Tenant')}
                    expanded={accordionOpen === 'Tenant' ? true : false}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowForwardIos
                          sx={{
                            fill: '#000',
                            width: '16px',
                            height: '9.88px',
                            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                              {
                                transform: 'rotate(90deg)',
                                marginTop: '5px !important',
                                maxHeight: '20px',
                              },
                            '& .MuiAccordionSummary-content.Mui-expanded': {
                              marginTop: '23px !important',
                            },
                          }}
                        />
                      }
                      sx={{
                        minHeight: '10px',
                        maxHeight: '43px',
                        '& .MuiAccordionSummary-root': {
                          maxHeight: '43px !important',
                          minHeight: '10px !important',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                          {
                            transform: 'rotate(90deg) !important',
                            maxHeight: '20px',
                          },
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'var(--Gray-900, #242424)',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontWeight: 500,
                        }}
                      >
                        Tenant Pricing
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: 0,
                      }}
                    >
                      {TenantTableComponent}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ''
                )}
              </Grid>
            }
            priceModule={true}
          ></Content>
          {state.isPriceAddModalOpen && (
            <PriceForm
              show={state.isPriceAddModalOpen}
              siteName={props.siteId}
              selectedPriceId={state.selectedPriceId}
              entityID={props.siteId}
              resetPriceform={onResetPriceForm}
              handleClose={onPriceFormClose}
              entityType={props.entityType}
            />
          )}
        </EvesModal>
      )) || (
        <Card
          icon={<AttachMoneyIcon />}
          title={translate('settings.pricing.title')}
          isTransparent={true}
          sx={{ paddingBottom: '0' }}
        >
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              marginBottom={'10px'}
            >
              <Grid>
                {state.canCreate && (
                  <EvesButton
                    variant='contained'
                    startIcon={<Add />}
                    onClick={onPriceCreate}
                    data-cy='btn-create-price'
                  >
                    {translate('general.create')}
                  </EvesButton>
                )}
                <FormControlLabel
                  style={{ marginLeft: '5px' }}
                  control={
                    <EvesSwitch
                      checked={viewAllEnabled}
                      data-cy={'pricing-view-all'}
                      onClick={toggleAutoRefresh}
                    />
                  }
                  label={`${translate('general.view_all')}`}
                />
              </Grid>
              <Grid item xs={6}>
                <Stack direction='row' spacing={1} justifyContent='flex-end'>
                  <TableRefreshComponent
                    componentName='pricing'
                    onRefresh={fetchPriceList}
                  />
                </Stack>
              </Grid>
            </Stack>
            <DataTable
              className='pricing-table'
              ref={dataTableRef}
              rows={state.rows}
              columns={buildTableColumns()}
              totalRowsCount={state.totalRowsCount}
              actionsMenu={primaryActions}
              onChange={onDataTableChange}
              defaultSortField={state.sortFields}
              data-cy='pricing-table'
              height={props.listHeight}
              count={state.totalRowsCount}
              limit={state.limit}
              page={state.page}
              tablePagination={true}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            >
              <RestrictionsCell slot='restriction' />
            </DataTable>
            {state.isPriceAddModalOpen && (
              <PriceForm
                show={state.isPriceAddModalOpen}
                siteName={props.siteName}
                selectedPriceId={state.selectedPriceId}
                entityID={props.siteId}
                resetPriceform={onResetPriceForm}
                handleClose={onPriceFormClose}
                entityType={props.entityType}
              />
            )}
          </>
        </Card>
      )}
    </>
  );
}
