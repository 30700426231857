import { Delete, Edit as EditIcon } from '@mui/icons-material';
import defaultImage from './../../../../assets/img/theme/no-image.png';
import { EvesButton } from './../../atoms/button/button';
import styles from './flipCard.module.scss';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IFlipCard {
  inputOpenFileRef?: any;
  logo?: any;
  onFileSelected?: any;
  onFileBrowseClick?: any;
  onFileRemoveClick?: any;
  height?: any;
  isUpdate?: boolean;
}

export const FlipCard = (props: IFlipCard) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className={styles.flipCard}
      data-cy="image-flipper"
      style={{ height: props.height ? props.height : '' }}
    >
      <div className={props.isUpdate === true ? styles.flipCardInner : ''}>
        <div
          className={styles.flipCardFront}
          style={
            props?.logo
              ? {
                  backgroundImage: `url(${props.logo})`,
                  backgroundOrigin: 'anonymous',
                }
              : {
                  backgroundImage: `url(${defaultImage})`,
                  backgroundOrigin: 'anonymous',
                }
          }
        ></div>
        <div
          id="divId"
          className={styles.flipCardBack}
          style={
            props?.logo
              ? {
                  backgroundImage: `url(${props.logo})`,
                  backgroundOrigin: 'anonymous',
                }
              : {
                  backgroundImage: `url(${defaultImage})`,
                  backgroundOrigin: 'anonymous',
                }
          }
        >
          <input
            className={styles.flipCardBackInput}
            ref={props.inputOpenFileRef}
            accept="image/jpg,image/jpeg,image/png,image/gif"
            type="file"
            onChange={props.onFileSelected}
            data-cy="upload-image"
          />
          <Tooltip
            title={`${translate('Edit Logo Image')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <EvesButton onClick={props.onFileBrowseClick} size="medium">
              <EditIcon fontSize="inherit" />
            </EvesButton>
          </Tooltip>

          <Tooltip
            title={`${translate('Delete Logo Image')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <EvesButton
              sx={{
                background: '#D3302F !important',

                '&:hover': {
                  background: '#D3302F !important',
                },
              }}
              onClick={props.onFileRemoveClick}
              data-cy="create-station"
            >
              <Delete fontSize="inherit" />
            </EvesButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
