import { getSubdomain } from '@/common/utils/utils';
import { httpClient } from './httpClient';

export const loginUser = (userName: string, password: string) => {
	const subDomain = getSubdomain();
	return httpClient.post('/auth/signin', {
		acceptEula: true,
		email: userName,
		password: password,
		tenant: subDomain
	});
};

// To register an user
export const registerUser = (
	firstName: string,
	name: string,
	email: string,
	mobile: any,
	password: string,
	repeatPassword: string,
	captcha?: string,
) => {
	const subDomain = getSubdomain();

	return httpClient.post('/auth/signon', {
		acceptEula: true,
		firstName,
		name,
		email,
		mobile,
		password,
		repeatPassword,
		tenant: subDomain,
		captcha,
	});
};

// To reset password
export const resetPassword = (email: string, captcha: string) => {
	const subDomain = getSubdomain();

	return httpClient.post('auth/password/reset', {
		email,
		tenant: subDomain,
		captcha
	});
};

export const logoutUser = () => {
	return httpClient.get('/auth/signout');
};

export const getUserImage = (id: string) => {
	return httpClient.getImage<any>(`/api/users/${id}/image`);
};

export const getTenantLogoBySubdomain = (tenantSubDomain: string) => {
	return httpClient.getImage<any>(`/util/tenants/logo?Subdomain=${tenantSubDomain}`);
};

// reset-forgot password
export const resetUserPassword = (
	password: string,
	repeatPassword: string,
	hash: string
) => {
	const subdomain = getSubdomain();

	return httpClient.post('/auth/password/reset', {
		tenant: subdomain,
		password,
		repeatPassword,
		hash,
	});
};

export const verifyUserEmail = (data: any)=> {
	data['Tenant'] = getSubdomain();
	return httpClient.get('/auth/mail/check', data);
};

export const resendVerificationEmail = (user: any)=> {
	user['Tenant'] = getSubdomain();
	return httpClient.post('/auth/mail/resend', user);
};
