import { IUserContextModel } from '@/store/auth/types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	const token = localStorage.getItem('access_token');
	return (authContext.isAuthenticated && token) ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
