export function IconType2ComboCcs(props: any) {
  const height = props.height || '20px';
  const width = props.width || '20px';

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 63.5 63.5'
      >
        <g
          transform='matrix(.10333 0 0 .09988 -6.7 -17.6)'
          fill='none'
          stroke='currentColor'
        >
          <ellipse cx='425.6' cy='446.2' rx='33.5' ry='32.7' strokeWidth='23' />
          <ellipse ry='32.7' rx='33.5' cy='446.2' cx='318.4' strokeWidth='23' />
          <ellipse cx='268' cy='357.9' rx='33.5' ry='32.7' strokeWidth='23' />
          <ellipse ry='32.7' rx='33.5' cy='357.9' cx='372' strokeWidth='23' />
          <ellipse cx='474.5' cy='357.9' rx='33.5' ry='32.7' strokeWidth='23' />
          <ellipse ry='25.2' rx='24.4' cy='286.2' cx='424.9' strokeWidth='23' />
          <ellipse cx='322.4' cy='286.2' rx='24.4' ry='25.2' strokeWidth='23' />
          <path
            d='M266.4 212.1h214.4s22.5 15.2 32 25.6a203.8 203.8 0 0 1 24.8 35.9 191 191 0 0 1 15.7 34.7c4.4 13.1 8.4 26.9 9.5 44.1 1.1 17.2-.5 40.8-4.7 58.3a184.5 184.5 0 0 1-39.4 74.1 235 235 0 0 1-31.6 30c-11.6 9-23.8 17.2-37.8 23.6-14 6.5-45.7 14.2-45.7 14.2s-43 5.8-77.3-6.3c-13.6-4.8-22.5-6.4-37.8-14.2-19.1-9.7-37.5-22-52-37.8a223 223 0 0 1-45.7-75.7c-7.8-22-10.4-46-9.5-69.3.6-17.8 4.8-35.5 11-52 5.3-14.2 14.3-27.7 22.1-39.5 7.6-11.5 21-24.1 30-31.5 9-7.4 22-14.2 22-14.2z'
            strokeWidth='40'
            strokeLinejoin='round'
          />
          <ellipse cx='285.3' cy='676.4' rx='52.6' ry='53.4' strokeWidth='23' />
          <ellipse ry='53.4' rx='52.6' cy='674.8' cx='457.2' strokeWidth='23' />
          <rect
            width='388.9'
            height='191.9'
            x='176.8'
            y='581.2'
            ry='95.9'
            strokeWidth='30.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M291.6 560.5l160.8 1.6' strokeWidth='23' />
        </g>
      </svg>
    </>
  );
}
