import React from 'react';
import { hideNotification, showNotification } from '@/store/app/reducer';
import { IAppContextModel } from '@/store/app/types';
import { AlertColor } from '@mui/material';
import { Context, createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from '@/common/components/molecules/notification/notification';

export const NotificationContext = createContext({} as any);

export type NotifyType = { 
    message: string, 
    type: AlertColor
};

export type DoNotifyType = (data: NotifyType) => void;

export const NotificationContextProvider = ({ children }: any) => {

	const appContext: IAppContextModel = useSelector((state: any) => state.appContext);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(hideNotification());
	};

	const notify = (data: any) => {
		dispatch(showNotification({
			message: data.message,
			type: data.type
		}));
	};

	return (
		<NotificationContext.Provider value={{ notify } as { notify: DoNotifyType }} data-cy="notification-alert">
			{children}
			{/* <Snackbar
				open={appContext.notification.showNotification}
				autoHideDuration={3000000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={handleClose}
			>
				<Alert className="alert" severity={appContext.notification.type as any} onClose={handleClose} 
					iconMapping={{
						success: <NotificationsIcon fontSize="inherit" />,
					}}>
					{appContext.notification.message}
				</Alert>
			</Snackbar> */}
			<Notification 
				type={appContext.notification.type}
				message={appContext.notification.message}
				isOpen={appContext.notification.showNotification}
				onClose={handleClose}
				data_cy={'notification-alert'}
			/>
		</NotificationContext.Provider>
	);
};

export default function useNotification() {
	return useContext(NotificationContext as Context<{ notify: DoNotifyType }>);
}