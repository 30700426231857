import { Grid,TextField} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SiteAreaFormModel } from '../../../../shared/models/siteArea';
import { useTranslation } from 'react-i18next';
import { siteAreaTariffLength } from '@/modules/organization/shared/schemas/siteAreaFormSchema';

interface IRoamingPropsModel {
    siteAreaForm: UseFormReturn<SiteAreaFormModel, any>
		isDisabled:boolean;
 }
export default function Roaming (props: IRoamingPropsModel) {
	const { watch }= props.siteAreaForm;
	const publicSite=watch('public');
	const { t: translate} = useTranslation();
	const siteAreaForm=props.siteAreaForm;

	return(
		<>
			<Grid container direction="row" spacing={2}>
				<Grid item xs={5}>
					{publicSite===true? null:<h5 style={{ color: '#d32f2f', fontWeight:'normal', fontSize:'16px' }}>{`${translate('sites.private_limitation')}`}</h5>}
					<Controller name="tariffID" control={props.siteAreaForm.control} render={({ field }) => (
						<TextField {...field}  id="tariffID" label={`${translate('ocpi.tariff_id')}`}
							error={!!props?.siteAreaForm?.formState?.errors.tariffID}
							helperText={`${translate(siteAreaForm.formState?.errors.tariffID?.message?.toString() || '').replace(/{{length}}/g, `'${siteAreaTariffLength.maxLength}'`)}`}
							fullWidth variant="standard" disabled={!publicSite || props.isDisabled}/>  )} />
				</Grid>
			</Grid>
		</>
	); 
}