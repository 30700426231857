import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Greencom = (props: any) => {
	const { t: translate } = useTranslation();
	return (
		<>
			<Grid item xs={6}>	
				<Controller name="greencomConnection.clientId" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="clientId" label={`${translate('settings.asset.connection.client_id')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['greencomConnection.clientId']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.greencomConnection?.clientId}
						helperText={`${translate(props.assetForm.formState?.errors.greencomConnection?.clientId?.message || ' ')}`}
					/>
				)} />
			</Grid>	
			<Grid item xs={6}>	
				<Controller name="greencomConnection.clientSecret" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="clientSecret" label={`${translate('settings.asset.connection.client_secret')}`}
						fullWidth disabled={props.isDisabled} type='password'
						required={props.requiredFields['greencomConnection.clientSecret']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.greencomConnection?.clientSecret}
						helperText={`${translate(props.assetForm.formState?.errors.greencomConnection?.clientSecret?.message || ' ')}`}
					/>
				)} />
			</Grid>	
		</>
	);
};

export default Greencom;