import { Grid, Box, useMediaQuery } from '@mui/material';
import {
  ILookupListStateModel,
  ILookupPropsModel,
  IParamModel,
} from '../models/model';
import { useEffect, useRef, useState } from 'react';
import { DefaultPageSize } from '../constants/constants';
import DataTable from './organisms/table/dataTable';
import { useQuery } from 'react-query';
import { LookupEntityConfig } from '../constants/lookupTableConfigs';
import { EvesModal } from './molecules/modal/modal';
import { SearchBox } from './searchBox';
import { useTranslation } from 'react-i18next';

export default function LookupComponent(props: ILookupPropsModel) {
  const { t: translate } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width:1600px)');

  const [state, setStateData] = useState({
    search: '',
    limit: DefaultPageSize,
    page: 0,
    sortDescending: false,
    sortFields: props.defaultSortColumn ? props.defaultSortColumn : '',
    data: [],
    totalRowsCount: 0,
    selectedRows: [],
    projectFields: [],
  } as ILookupListStateModel);

  const searchBoxRef = useRef<any>();

  const { columnConfig, fetchDataFn, mapToModel } =
    LookupEntityConfig[props.entityType];
  const translatedColumnConfig = columnConfig.map((cf) => ({
    ...cf,
    label: translate(cf.label),
  }));

  const { refetch: fetchLookupData } = useQuery(
    props.entityType,
    () => {
      return fetchDataWithParams();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        const resultData = data?.result.map((res: any) => new mapToModel(res));
        const selectedData = resultData?.map((item: any) => {
          return item;
        });
        const checkedData = selectedData
          ? selectedData.filter((obj) => {
              for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                  if (obj?.id === props?.preSelectedData?.siteAreaID) {
                    return true;
                  }
                }
              }
              return false;
            })
          : [];

        setStateData((currentData) => {
          return {
            ...currentData,
            data: resultData,
            // selectedRows: checkedData,
            totalRowsCount: data?.count,
            projectFields: data?.projectFields && [...data.projectFields],
          };
        });
      },
    }
  );
  useEffect(() => {
    fetchLookupData();
  }, [
    state.search,
    state.sortDescending,
    state.sortFields,
    state.limit,
    state.page,
  ]);

  const fetchDataWithParams: any = () => {
    const params: any = {
      Limit: state.limit,
      Search: state.search,
      Page: state.page + 1,
    };
    if (state.sortFields) {
      params.SortFields = state.sortFields;
    }
    if(props.isConnected){
      params.Status = 'A';
    }
    if (
      props.entityType.toString() === 'feederid' ||
      props.entityType.toString() === 'transformerId'
    ) {
      params['SubStationID'] = '';
    }
    if (props.entityType.toString() === 'transformerId') {
      params['FeederID'] = '';
    }

    props.params?.forEach((param: IParamModel) => {
      params[param.key] = param.value;
    });

    return fetchDataFn(params);
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
      };
    });
  };

  const onDataTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
        limit: params.limit,
      };
    });
  };

  const onRowSelected = (selectedRows: any[]) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedRows: selectedRows,
      };
    });
  };

  const onLookupDataSelect = () => {
    props.onSelect && props.onSelect(state.selectedRows);
  };
  return (
    <EvesModal
      maxWidth={!isSmallScreen ? '900px' : '700px'}
      isOpen={props.showLookupModal}
      title={props.title}
      lookup={true}
      onHandleSave={onLookupDataSelect}
      onHandleClose={props.onModalClose}
      isSaveDisabled={!state.selectedRows?.length}
      isSelection={true}
      data-cy={props['data-cy']}
    >
      {!props.showSearch && (
        <Grid container direction='row'>
          <Grid item xs={12}>
            <SearchBox
              ref={searchBoxRef}
              onChange={onFilterApplied}
              data-cy='lookup-search'
              onRemoveClick={fetchLookupData}
            />
          </Grid>
        </Grid>
      )}
      <Box component='div' sx={{ pt: 2 }} data-cy='lookup-table-container'>
        <DataTable
          selectionMode={props.selectionMode}
          rows={state.data}
          columns={translatedColumnConfig}
          totalRowsCount={state.totalRowsCount}
          onChange={onDataTableChange}
          onRowSelectionChange={onRowSelected}
          projectFields={state.projectFields}
          data-cy='lookup-table'
          selectedRows={state.selectedRows}
          defaultSortField={state.sortFields}
          count={state.totalRowsCount}
          limit={state.limit}
          page={state.page}
          tablePagination={true}
        >
          {props.tableChildren}
        </DataTable>
      </Box>
    </EvesModal>
  );
}
