import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { DefaultPageSize } from '@/common/constants/constants';
import { withContext } from '@/common/utils/withContext';
import {
	IInErrorAssignSitePropsModel,
	IInErrorAssignSiteStateModel,
} from '@/modules/user/shared/models/inError';
import { assignSite, getUserSites, unAssignSite } from '@/services/users';
import { setSiteAdmin, setSiteOwner } from '@/services/organizations';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { Content } from '@/common/components/templates/content/content';
import DataTable from '@/common/components/organisms/table/dataTable';
import { EvesButton } from '@/common/components/atoms/button/button';
import { SearchBox } from '@/common/components/searchBox';
import { Grid, Stack } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import LookupComponent from '@/common/components/lookupComponent';
import { LookupEntityType } from '@/common/enums/enums';
import { disableLoaderForOnce } from '@/common/utils/loadingService';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { UserSitesModel } from '@/services/models/user';
import { getUserInformation } from '@/services/userProfile';
import { IUserContextModel } from '@/store/auth/types';
import { useSelector } from 'react-redux';
import { columnsType } from '@/common/models/model';
import { canAssignUsersSites, canCreateSiteArea, canUnassignUsersSites } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

function UserAssignSite(props: IInErrorAssignSitePropsModel) {
	const [state, setStateData] = useState({
		search: '',
		limit: DefaultPageSize,
		sortDescending: false,
		sortFields: props.defaultSortColumn ? props.defaultSortColumn : 'site.name',
		rows: [],
		totalRowsCount: 0,
		isSelectSiteModalOpen: false,
		siteAdmin: false,
		isSiteAdmin: false,
		isSiteOwner: false,
		siteAdminUserId: '',
		siteOwner: false,
		defaultPageSize: DefaultPageSize,
		selectedRows: [],
		page: 0,
		rowsPerPage: 1,
		defaultSortColumn: 'site.name',
		selectedUserRole: '',
		userId: '',
		projectFields:[],
	} as IInErrorAssignSiteStateModel);

	const dataTableRef = useRef<any>();
	const searchBoxRef = useRef<any>();
	const isUserCanCreateSiteArea = canCreateSiteArea();
	const isUserCanAssignUsersSites = canAssignUsersSites();
	const isUserCanUnassignUsersSites = canUnassignUsersSites();
	const { t: translate } = useTranslation();

	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	
	const loggedUserID =  authContext.userInfo.id;

	const columns: columnsType[] = [
		{
			id: 'name',
			label: `${translate('users.name')}`,
			minWidth: 90,
			allowSort: true,
			sortKey: 'site.name',
			maxWidth: 240,
			projectField: 'site.name',
		},
		{
			id: 'city',
			label: `${translate('general.city')}`,
			minWidth: 60,
			allowSort: false,
			sortKey: 'city',
			maxWidth: 120,
			projectField: 'site.address.city',
		},
		{
			id: 'country',
			label: `${translate('general.country')}`,
			minWidth: 150,
			allowSort: false,
			sortKey: 'country',
			maxWidth: 90,
			projectField: 'site.address.country',
		},
		{
			id: 'siteAdmin',
			label:`${translate('sites.admin_role')}`,
			minWidth: 30,
			allowSort: false,
			sortKey: 'siteAdmin',
			maxWidth: 60,
			textAlign: 'center',
			projectField: 'siteAdmin',
		},
		{
			id: 'siteOwner',
			label: `${translate('sites.owner_role')}`,
			minWidth: 30,
			allowSort: false,
			sortKey: 'siteOwner',
			maxWidth: 60,
			textAlign: 'center',
			projectField: 'siteOwner',
			visible: isUserCanCreateSiteArea,
		},
	];

	const { refetch: fetchSites } = useQuery(
		'UserSites',
		() => {
			return getSites();
		},
		{
			enabled: false,
			onSuccess: (data) => {
			if(data){
				const resultData = data.result.map((res: any) => new UserSitesModel(res));
				setStateData((currentData) => {
					return {
						...currentData,
						rows: resultData,
						totalRowsCount: data.count,
						projectFields: data.projectFields && [...data.projectFields],
						selectedRows: state.selectedRows,
					};
				});
			}
			},
		}
	);

	const { refetch: fetchUsers } = useQuery(
		'UsersDetails',
		() => {
			return getUserInformation(props.userId);
		},
		{
			enabled: false,
			onSuccess: (data:any) => {
				setStateData((currentData) => {
					return {
						...currentData,
						selectedUserRole: data.role,
					};
				});
			},
		}
	);

	const { mutate: assignSiteMutate } = useMutation(
		(siteIdsModel) => assignSite(siteIdsModel, props.userId),
		{
			onSuccess: (res: any) => {
				if (res.status === 'Success') {
					props.notificationService?.notify({
						message: `${translate('users.update_sites_success')}`,
						type: 'success',
					});
					setStateData((currentData) => {
						return {
							...currentData,
							isSelectSiteModalOpen: false,
						};
					});
				} else {
					props.notificationService?.notify({
						message:
						`${translate('sites.update_users_error')}`,
						type: 'error',
					});
				}
			},
		}
	);

	const { mutate: unassignUserMutate } = useMutation(
		(assignSiteIdsModel) => unAssignSite(assignSiteIdsModel, props.userId),
		{
			onSuccess: (res: any) => {
				if (res.status === 'Success') {
					dataTableRef?.current?.resetSelectedRows();
					setStateData((currentData) => {
						return {
							...currentData,
							selectedRows: [],
						};
					});
					props.notificationService?.notify({
						message: `${translate('users.remove_sites_success')}`,
						type: 'success',
					});
					fetchSites();
				} else {
					props.notificationService?.notify({
						message:`${translate('users.remove_sites_error')}`,
						type: 'error',
					});
				}
			},
		}
	);

	useEffect(() => {
		if (!state.isSelectSiteModalOpen) {
			fetchSites();
			fetchUsers();
		}
	}, [
		state.limit,
		state.isSelectSiteModalOpen,
		state.sortFields,
		state.search,
		state.sortDescending,
		state.isSiteAdmin,
		state.siteOwner,
	]);

	const getSites = () => {
		const editSiteParams: any = {
			UserID: props.userId,
			Limit: state.limit,
			SortFields: state.sortFields,
		};

		if (state.search !== '') {
			editSiteParams['Search'] = state.search;
		}
		return getUserSites(editSiteParams);
	};

	const onAssignAddSite = () => {
		setStateData((currentData) => {
			return {
				...currentData,
				isSelectSiteModalOpen: true,
			};
		});
	};

	const onAssignSelectSite = (sites: any) => {
		const siteIds = sites.map((asset: any) => asset.id);
		const siteIDsModel: any = {
			siteIDs: siteIds,
		};
		assignSiteMutate(siteIDsModel);
	};

	const onAssignRemoveSitePopup = () => {
		props.dialogService?.showConfirm(
			{
				title: `${translate('users.remove_sites_title')}`,
				description:
						`${translate('users.remove_sites_confirm')}`,
				confirmType: 'YES_NO',
			},
			(result: any) => {
				if (result === 'YES') {
					if (state.selectedRows.length > 0) {
						const siteIDs = state.selectedRows.map((row: any) => row.id);
						const assignSiteIdsModel: any = {
							siteIDs: siteIDs,
						};
						unassignUserMutate(assignSiteIdsModel);
					}
				}
			}
		);
	};

	const onRowSelectionChange = (selectedRows: any[]) => {
		setStateData((currentData) => {
			return {
				...currentData,
				selectedRows: selectedRows,
			};
		});
	};

	const onSelectSiteModalClose = () => {
		setStateData((currentData) => {
			return {
				...currentData,
				isSelectSiteModalOpen: false,
			};
		});
	};

	const onFilterApplied = (searchText: string) => {
		setStateData((currentData) => {
			return {
				...currentData,
				search: searchText,
			};
		});
	};

	const onDataTableChange = (params: any) => {
		setStateData((currentData) => {
			return {
				...currentData,
				sortFields: params.sortFields,
				limit: params.limit,
			};
		});
	};

	const onOwnerChange = async (record: any, event: any) => {
		const siteOwner = event.target.checked;
		const siteOwnerModel = {
			siteOwner: siteOwner ? true : false,
			userID: record.userID,
		};

		state.rows.map((userData) => {
			if (userData.site.id === record.site.id) {
				disableLoaderForOnce();
				userData.siteOwner = !userData.siteOwner;
			}
		});

		setSiteOwner(siteOwnerModel, record.site.id).then((res: any) => {
			disableLoaderForOnce();
			if (res.status === 'Success') {
				props.notificationService?.notify({
					message: `The user is ${siteOwnerModel.siteOwner ? 'now' : 'no more'
					} owner of the site '${record.site.name}'`,
					type: 'success',
				});

			} else {
				props.notificationService?.notify({
					message: `${translate('sites.update_error')}`,
					type: 'error',
				});
			}
		});
	};

	const onAdminChange = (record: any, event: any) => {
		const siteAdmin = event.target.checked;
		const siteAdminModel = {
			siteAdmin: siteAdmin ? true : false,
			userID: record.userID,
		};

		state.rows.map((userData) => {
			if (userData.site.id === record.site.id) {
				disableLoaderForOnce();
				userData.siteAdmin = !userData.siteAdmin;
			}
		});

		setSiteAdmin(siteAdminModel, record.site.id).then((res: any) => {
			disableLoaderForOnce();
			if (res.status === 'Success') {
				props.notificationService?.notify({
					message: `The user is ${siteAdmin ? 'now' : 'no nore'} administrator on the site '${record.site.name}'`,
					type: 'success',
				});
			} else {
				props.notificationService?.notify({
					message: `${translate('sites.update_error')}`,
					type: 'error',
				});
			}
		});
	};

	const SiteOwnerCheckBox = (row: any) => {
		return (
			<EvesCheckbox
				slot='siteOwner'
				color='primary'
				checked={row.siteOwner}
				onChange={(e: any) => { onOwnerChange && onOwnerChange(row, e); }}
				name='checkbox-buttons'
				data-cy={row.siteOwner ? 'siteOwner-radio-true' : 'siteOwner-radio'}
			/>
		);
	};

	const SiteAdminCheckBox = (row: any) => {
		return (
			<EvesCheckbox
				slot='siteAdmin'
				color='primary'
				checked={(row.siteAdmin) ||state.selectedUserRole === 'A'}
				disabled={
					loggedUserID === state.userId || state.selectedUserRole !== 'B'
				}
				onChange={(e: any) => {
					onAdminChange && onAdminChange(row, e);
				}}
				name='checkbox-buttons'
				data-cy='siteAdmin-checkbox'
			/>
		);
	};

	return (
		<EvesModal
			maxWidth={'1100px'}
			isOpen={props.showModal}
			title={`SITE ASSIGNED TO '${props.firstName} ${props.userName}'`}
			onHandleClose={props.onClose}
			isSaveDisabled={!state.selectedRows.length}
			isSaveHidden={true}
			data-cy='site-assign-modal'
		>
			<Content
				contentTop={
					<Grid container direction='row' spacing={2}>
						<Grid item xs={6}>
							<Stack direction='row' spacing={1}>
								{ isUserCanAssignUsersSites &&
									<EvesButton
										startIcon={<Add />}
										onClick={onAssignAddSite}
										data-cy='site-editUser-add-btn'
									>
										{translate('general.add')}
									</EvesButton>
								}
								{ isUserCanUnassignUsersSites &&
									<EvesButton color="error"
										disabled={state.selectedRows.length > 0 ? false : true}
										startIcon={<Remove />}
										onClick={onAssignRemoveSitePopup}
										data-cy='site-editUser-remove-btn'
									>
										{translate('general.remove')}
									</EvesButton>
								}
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<SearchBox
								ref={searchBoxRef}
								onChange={onFilterApplied}
								onRemoveClick={fetchSites}
								data-cy='site-editUser-search-textbox'
							/>
						</Grid>
					</Grid>
				}
				contentBody={
					<DataTable
						ref={dataTableRef}
						selectionMode={'multiple'}
						rows={state.rows}
						columns={columns}
						totalRowsCount={state.totalRowsCount}
						onChange={onDataTableChange}
						height={{ height: 'calc(100vh - 325px)' }}
						selectedRows={state.selectedRows}
						defaultSortField={state.defaultSortColumn}
						onRowSelectionChange={onRowSelectionChange}
						projectFields={state.projectFields}
						onOwnerChange={onOwnerChange}
						onAdminChange={onAdminChange}
						data-cy='assign-site-table'
					>
						<SiteOwnerCheckBox slot='siteOwner' />
						<SiteAdminCheckBox slot='siteAdmin' />
					</DataTable>
				}
			></Content>
			{state.isSelectSiteModalOpen && (
				<LookupComponent
					title={translate('sites.select_sites')}
					showLookupModal={state.isSelectSiteModalOpen}
					onModalClose={onSelectSiteModalClose}
					selectionMode='multiple'
					entityType={LookupEntityType.SELECT_USERSITE}
					params={[
						{ key: 'Issuer', value: true },
						{ key: 'ExcludeSitesOfUserID', value: props.userId },
					]}
					onSelect={onAssignSelectSite}
					data-cy='in-error-assign-lookup'
				/>
			)}
		</EvesModal>
	);
}

export default withContext(UserAssignSite);
