import React, { forwardRef } from 'react';
import { Checkbox as MUICheckbox } from '@mui/material';
import styles from './checkbox.module.scss';

// eslint-disable-next-line react/display-name
export const EvesCheckbox = forwardRef((props: any, ref: any) => {
  return (
    <MUICheckbox
      className={styles.checkbox}
      onChange={props.onChange}
      checked={props.checked}
      size={props.size}
      inputRef={ref}
      {...props}
    >
      {props.children}
    </MUICheckbox>
  );
});
