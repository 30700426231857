export const autoRefresh = {
  // give time in milliseconds
  dashboard: {
   SESSION_SUMMARY:10000,
   CHARGING_STATION:300000,
   CONNECTORS:300000,
   TOTAL_SESSIONS:10000,
   TELEMATICS_API_CALL_INTERVAL:300000,
  },
  chargingStation: {
   GET_SITES: 3600000,
   CHARGING_STATION:10000,
   TOTAL_CAPACITY:10000,
   CONNECTORS:300000,
  },
  sessions: {
   IN_PROGRESS:10000,
   IN_HISTORY:60000,
  },
  rfid: {
   SUMMARY:300000,
  },
  DEFAULT: 10000, // 10 sec
 };
 
 
