import { withContext } from '@/common/utils/withContext';
import { getIntegrationConnections} from '@/services/userProfile';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Mercedes from './car/mercedes'; 
import Refund from './refund';

const Connections = (props: any) => {
	const [state, setStateData] = useState({
		connections: {},
		mercedesSetting: {},
		refundSetting: {},
	} as any);

	const userForm = props.userForm;

	const { refetch: fetchConnections } = useQuery('Connections', () => { return getIntegrationConnections(userForm.getValues().id); }, {
		enabled:false,
		onSuccess: (data: any) => {
			setStateData((currentData: any) => {
				return {
					...currentData,
					connections: data?.result,
				};
			});
		}
	});

	useEffect(() => {
		fetchConnections();
	}, []);
  
	return <><Grid container spacing={3}>
		<Grid item xs={4}>
			<Mercedes connections={state.connections} fetchConnections={fetchConnections} />
		</Grid><Grid item xs={4}>
			<Refund connections={state.connections} fetchConnections={fetchConnections} />
		</Grid>
	</Grid>
	</>;
};

export default withContext(Connections);