import cs from 'date-fns/locale/cs';

const Calendar = {
	sunday: 'ne',
	monday: 'po',
	tuesday: 'út',
	wednesday: 'st',
	thursday: 'čt',
	friday: 'pá',
	saturday: 'so',
	ok: 'Uplatnit',
	today: 'Dnes',
	yesterday: 'Včera',
	hours: 'Hodiny',
	minutes: 'Minut',
	seconds: 'Sekundy',
	formattedMonthPattern: 'MMM, yyyy',
	formattedDayPattern: 'MMM dd, yyyy',
	dateLocale: cs as any
};

export default {
	common: {
		loading: 'Načítání...',
		emptyMessage: 'Nenalezena žádná data'
	},
	Plaintext: {
		unfilled: 'Nevyplněno',
		notSelected: 'Nevybráno',
		notUploaded: 'Nenahráno'
	},
	Pagination: {
		more: 'Více',
		prev: 'Předchozí',
		next: 'Další',
		first: 'První',
		last: 'Poslední',
		limit: '{0} / Strana',
		total: 'Celkem řádků: {0}',
		skip: 'Jít do {0}'
	},
	Calendar,
	DatePicker: {
		...Calendar
	},
	DateRangePicker: {
		...Calendar,
		last7Days: 'Posledních 7 dní'
	},
	Picker: {
		noResultsText: 'Nebyly nalezeny žádné výsledky',
		placeholder: 'Zvolte',
		searchPlaceholder: 'Vyhledávání',
		checkAll: 'Všechno'
	},
	InputPicker: {
		newItem: 'Nový předmět',
		createOption: 'Vytvořit možnost "{0}"'
	},
	Uploader: {
		inited: 'Počáteční',
		progress: 'Nahrávání',
		error: 'Chyba',
		complete: 'Dokončeno',
		emptyFile: 'Prázdný',
		upload: 'Nahrát'
	},
	CloseButton: {
		closeLabel: 'Zavřít'
	},
	Breadcrumb: {
		expandText: 'Zobrazit cestu'
	},
	Toggle: {
		on: 'Otevřít',
		off: 'Zavřít'
	}
};