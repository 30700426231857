import { DEFAULT_CURRENCY_CODE, INFINITE_RECORDS } from '@/common/constants/constants';
import { RefundStatus } from '@/common/enums/enums';
import { disableLoaderForOnce } from '@/common/utils/loadingService';
import { getDuration, appUnitFormatter, getCurrencyCode, FormateNumber, handleHttpError,getDurationInHours } from '@/common/utils/utils';
import { IContextPropsModel } from '@/common/utils/withContext';
import { deleteTransactions } from '@/services/session';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export const buildTransactionTableFooterStats = (data: any, tab: 'INPROGRESS' | 'HISTORY' | 'REFUND'): string => {
	const { t: translate } = useTranslation();

	const currencyCode = getCurrencyCode();
	// All records has been retrieved
	if (data.count !== INFINITE_RECORDS) {
		const currencySymbol = getSymbolFromCurrency(data.stats.currency || currencyCode || DEFAULT_CURRENCY_CODE);
		// Stats?
		if (data.stats?.totalConsumptionWattHours && (tab === 'INPROGRESS' || tab === 'HISTORY')) {			
			const percentInactivity = (data.stats.totalDurationSecs > 0 ?
				(Math.floor(data.stats.totalInactivitySecs / data.stats.totalDurationSecs * 100)) : 0);
			// Total Duration
			let stats = `${translate('transactions.duration')}: ${getDuration(data.stats.totalDurationSecs)} | `;
			// Inactivity
			stats += `${translate('transactions.inactivity')}: ${getDuration(data.stats.totalInactivitySecs)} (${percentInactivity}%) | `;
			// Total Consumption
			stats += `${translate('transactions.total_consumption')}: ${appUnitFormatter(data.stats.totalConsumptionWattHours, 'Wh', 'kWh', true, 1, 0, 0, true)}`;
			// Total Price
			stats += ` | ${translate('transactions.price')}: ${currencySymbol} ${data.stats.totalPrice? FormateNumber(data.stats.totalPrice, 2) : ''}`;
			return stats;
		}

		if(data.stats?.totalConsumptionWattHours && tab === 'REFUND') {
			// Total Consumption
			let stats = `| ${translate('transactions.total_consumption')}: ${appUnitFormatter(data.stats.totalConsumptionWattHours, 'Wh', 'kWh', true, 1, 0, 0, true)}`;
			// Refund transactions
			stats += ` | ${translate('transactions.refund_transactions')} : ${data.stats.countRefundTransactions} (${currencySymbol} ${!isNaN(data.stats.totalPriceRefund)? FormateNumber(data.stats.totalPriceRefund) : 0.00})`;
			// Pending transactions 
			stats += ` | ${translate('transactions.pending_transactions')} : ${data.stats.countPendingTransactions} (${currencySymbol} ${!isNaN(data.stats.totalPricePending)? FormateNumber(data.stats.totalPricePending) : 0.00})`;
			// Number of reimbursed reports submitted 
			stats += ` | ${translate('transactions.count_refunded_reports')}: ${data.stats.countRefundedReports}`;
			return stats;
		}
	}
	return '';
};

const showActionsMessage = (actionsResponse: any, props: any, displaySuccessAsInfo = false, translate: any): void => {
	// Success and Error
	if (actionsResponse.inSuccess > 0 && actionsResponse.inError > 0) {
		props.notificationService?.notify({
			message: `${translate('transactions.delete_transactions_partial').replace(/{{inSuccess}}/, `${actionsResponse.inSuccess}`).replace(/{{inError}}/, `${actionsResponse.inError}`)}`,
			type: 'warning'
		});
	// Success
	} else if (actionsResponse.inSuccess > 0) {
		if (displaySuccessAsInfo) {
			props.notificationService?.notify({
				message: `${translate('transactions.delete_transactions_confirm').replace(/{{quantity}}/g, `${actionsResponse.inSuccess}`)}`,
				type: 'info',
			});
		} else {
			props.notificationService?.notify({
				message: `${translate('transactions.delete_transactions_success').replace(/{{inSuccess}}/g, `${actionsResponse.inSuccess}`)}`,
				type: 'success',
			});
		}
	} else if (actionsResponse.inError > 0) {
		props.notificationService?.notify({
			message: `${translate('transactions.delete_transactions_error').replace(/{{inError}}/, `${actionsResponse.inError}`)}`,
			type: 'error',
		});
	} else {
		props.notificationService?.notify({
			message: `${translate('transactions.delete_no_transaction')}`,
			type: 'info',
		});
	}
};

export const handleDeleteTransactions = (
	selectedRecords: any[], 
	props: IContextPropsModel, 
	dataTableRef: MutableRefObject<any>, 
	refresh: any, 
	setState: Dispatch<SetStateAction<any>>,
	translate:(key: string)=>string
) => {
	if (selectedRecords.length === 0){
		props.notificationService?.notify({
			message: `${translate('general.select_at_least_one_record')}`,
			type: 'error',
		});
		return;
	}

	for (const transaction of selectedRecords) {
		if (transaction.refundData && 
			(transaction.refundData.status === RefundStatus.SUBMITTED ||
				transaction.refundData.status === RefundStatus.APPROVED)) {
			props.dialogService?.showConfirm({
				title: `${translate('transactions.delete_transaction_title')}`,
				description: `${translate('transactions.dialog.delete.rejected_refunded_msg')}`,
				confirmType: 'OK',
			});
			return;
		}
	}
	// Delete item
	props.dialogService?.showConfirm({
		title: `${translate('transactions.delete_transactions_title')}`,
		description: `${translate('transactions.delete_transactions_confirm').replace(/{{quantity}}/, `${selectedRecords.length}`)}`,
		confirmType: 'YES_NO',
	},
	(result) => {
		if(result === 'YES') {
			disableLoaderForOnce();
			deleteTransactions(selectedRecords.map((data)=> data.id)).then((responseAction: any) => {
				showActionsMessage(responseAction, props, false, translate);	
				dataTableRef?.current?.resetSelectedRows();				
				refresh();
			}).catch((error) => {
				handleHttpError(error, `${translate('transactions.delete_transactions_unexpected_error')}`);
			});
			setState((currentData) => {
				return {
					...currentData,
					selectedRecords: [],
				};
			});
		}
	});	
};

export const sessionStatsMapping = (stats: any) => {
   const result: any = {
      session: 0
   };

   const currencyCode = getCurrencyCode();
   if (stats.totalSessions !== INFINITE_RECORDS  ) {
      const currencySymbol = getSymbolFromCurrency(stats.currency || currencyCode || DEFAULT_CURRENCY_CODE);
      // const percentInactivity = (stats.totalDurations > 0 ?
      //    (Math.floor(stats.totalInactivityPercentage / stats.totalDurations * 100)) : 0);
      const totalInactivityPercentage = stats?.totalInactivityPercentage ?? 0;
      result.session = stats.totalSessions ? stats.totalSessions :'-';
      result.consumption = stats?.totalConsumptionkWh ? `${stats.totalConsumptionkWh} kWh`: '0 kWh';
      result.price = `${currencySymbol} ${stats.totalFinancial ? FormateNumber(stats.totalFinancial, 2) : '0'}`;
      result.power = stats?.totalPowerkW ? `${stats.totalPowerkW} kW`: '0 kW';
      result.capacity = stats?.totalCapacitykWh ? `${stats.totalCapacitykWh} kW`: '0 kW';
      result.totalEV = stats?.totalEV ? stats.totalEV : 0;
      result.availableCurtailment = stats?.availabelCurtailment ? appUnitFormatter(stats.availabelCurtailment, 'W', 'kW', true, 1, 0, 0, true): '0 kW';
      result.totalDurationSecs = getDurationInHours(stats.totalDurations);
      result.totalInactivitySecs = `${getDurationInHours(stats.totalInactivity)} (${totalInactivityPercentage}%)`;
   }

   return result;
};