import styles from './common.module.scss';
import { ReactComponent as TotalSitesOrgSVG } from '@/assets/img/gridify/orgSites.svg';
import { ReactComponent as SmartChargerSVG } from '@/assets/img/gridify/smartcharger.svg';

interface CommonCardInterface {
   img: any;
   headerText: any;
   footerText?: any;
   historyTab?: boolean;
   headerTextDesc?: string;
   smartCharging?: any;
   orgSites?: any;
   demandView?:boolean;
   numerator?:any;
   denominator?:any;

}

const roundToThree = (num: number) => {
   return Math.round(num * 1000) / 1000;
 };
 
 const CommonCardDemandView = (props: CommonCardInterface) => {
  const roundedSmartCharging = props.smartCharging ? roundToThree(props.smartCharging) : null;
  const roundedOrgSites = props.orgSites ? roundToThree(props.orgSites) : null;
  const footerText = props.footerText ? roundToThree(props.footerText) : null;

  const numerator = props.numerator ? roundToThree(props.numerator) : null;
  const denominator = props.denominator ? roundToThree(props.denominator) : null;
  return (
    <section
      className={`${styles.commonCardContainer}`}
      data-cy="demandview-card-section"
    >
      <div className={styles.commonCardHeaderContainer}>
        <img
          src={props.img}
          className={styles.commonCardImage}
          data-cy="card-svg-icons"
        />
        <div>
          <h2
            className={styles.commonCardHeaderText}
            data-cy={`${props.headerText}`}
          >
            {props.headerText}
          </h2>
          {props.headerTextDesc && (
            <h2 className={styles.commonCardHeaderDescText}>
              {props.headerTextDesc}
            </h2>
          )}
        </div>
      </div>
      <div
        className={
          props.demandView
            ? styles.commonCardFooterText
            : styles.charginConfigFooter
        }
        data-cy="card-footer-section"
      >
        <div style={{ display: 'flex', gap: '12px' }}>
          {roundedSmartCharging !== null && (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SmartChargerSVG width={16} height={16} />
              {roundedSmartCharging}
            </div>
          )}
          {roundedOrgSites  && (
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <TotalSitesOrgSVG width={16} height={16} />
              {roundedOrgSites}
            </div>
          )}
          {numerator || denominator ? (
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <span>{numerator === null ? 0 :numerator}</span>
              <span>/</span>
              <span>{denominator === null ? 0 : denominator}</span>
            </div>
          ) : (
            footerText
          )}
        </div>
      </div>
    </section>
  );
};

 

export default CommonCardDemandView;
