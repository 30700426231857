import { ChargePointStatus, ConnectorType } from '@/common/enums/enums';
import styles from './connectorCell.module.scss';
import {
  IconDomesticUE,
  IconAllConnectors,
  IconType1,
  IconType2,
  IconType2ComboCcs,
  IconChademo,
  IconNoConnector,
  IconType1CcsNew,
  IconNacs,
} from '@/common/components/atoms/icon/icon';
import { Tooltip } from '@mui/material';
import { ConnectorDisplayModel } from '@/modules/chargingStation/shared/models/chargingStation';
import { getConnectorLetterFromConnectorID } from '@/common/utils/utils';

export const CONNECTOR_ALL_TYPES: ConnectorDisplayModel[] = [
  {
    key: ConnectorType.SELECT_ALL,
    description: 'All Connectors',
    svgIconName: 'Type All',
    svgIcon: IconAllConnectors,
  },
  {
    key: ConnectorType.TYPE_2,
    description: 'Type 2',
    svgIconName: 'TYPE 2',
    svgIcon: IconType2,
  },
  {
    key: ConnectorType.COMBO_CCS,
    description: 'Type 2 - Combo CCS',
    svgIconName: 'COMBO CCS',
    svgIcon: IconType2ComboCcs,
  },
  {
    key: ConnectorType.CHADEMO,
    description: 'CHAdeMO',
    svgIconName: 'CHADEMO',
    svgIcon: IconChademo,
  },
  {
    key: ConnectorType.TYPE_1,
    description: 'Type 1',
    svgIconName: 'TYPE 1',
    svgIcon: IconType1,
  },
  {
    key: ConnectorType.TYPE_1_CCS,
    description: 'Type 1 - Combo CCS',
    svgIconName: 'TYPE 1 CCS',
    svgIcon: IconType1CcsNew,
  },
  {
    key: ConnectorType.DOMESTIC,
    description: 'Domestic',
    svgIconName: 'DOMESTIC',
    svgIcon: IconDomesticUE,
  },
  {
    key: ConnectorType.UNKNOWN,
    description: 'Not set',
    svgIconName: 'Type Unknown',
    svgIcon: IconNoConnector,
  },
  {
    key: ConnectorType.NACS,
    description: 'Nacs',
    svgIconName: 'NacsIcon',
    svgIcon: IconNacs,
  },
];

export const buildConnectorClassByStatus = (connector: any): string => {
  let classNames = ` ${styles.chargerConnectorBackground} ${styles.chargerConnectorText} `;
  switch (connector.status) {
    case ChargePointStatus.AVAILABLE: {
      classNames += ` ${styles.chargerConnectorAvailable} ${styles.chargerConnectorChargingAvailableText} `;
      break;
    }
    case ChargePointStatus.PREPARING: {
      classNames += ` ${styles.chargerConnectorPreparing} `;
      break;
    }
    case ChargePointStatus.SUSPENDED_EVSE: {
      classNames += ` ${styles.chargerConnectorSuspendedEvse} `;
      break;
    }
    case ChargePointStatus.SUSPENDED_EV: {
      classNames += ` ${styles.chargerConnectorSuspendedEv} `;
      break;
    }
    case ChargePointStatus.FINISHING: {
      classNames += ` ${styles.chargerConnectorFinishing} `;
      break;
    }
    case ChargePointStatus.RESERVED: {
      classNames += ` ${styles.chargerConnectorReserved} `;
      break;
    }
    case ChargePointStatus.CHARGING:
    case ChargePointStatus.OCCUPIED: {
      // Check if charging
      if (connector.currentInstantWatts > 0) {
        classNames += ` ${styles.chargerConnectorChargingActive} ${styles.chargerConnectorBackgroundSpinner} ${styles.chargerConnectorChargingActiveText} `;
      } else {
        classNames += ` ${styles.chargerConnectorCharging} `;
      }
      break;
    }
    case ChargePointStatus.UNAVAILABLE: {
      classNames += ` ${styles.chargerConnectorUnavailable} `;
      break;
    }
    case ChargePointStatus.FAULTED: {
      classNames += ` ${styles.chargerConnectorFaulted} `;
      break;
    }
    default: {
      classNames += ` ${styles.chargerConnectorChargingInactive} `;
      break;
    }
  }
  return classNames;
};

export const getConnectorDetails = (
  type: ConnectorType
): ConnectorDisplayModel => {
  const foundConnectorType = CONNECTOR_ALL_TYPES.find(
    (connectorType) => connectorType.key === type
  );
  return foundConnectorType
    ? foundConnectorType
    : {
        key: ConnectorType.UNKNOWN,
        description: 'Not set',
        svgIconName: 'Type Unknown',
        svgIcon: IconNoConnector,
      };
};

const Connector = (row: any) => {
  const connectorDetail = row.type ? getConnectorDetails(row.type) : null;
  const connectorClasses = buildConnectorClassByStatus(row);
  return (
    <div className={styles.detailConnectorContainer}>
      <div className={styles.detailConnector}>
        <div className={styles.chargerConnectorContainer}>
          <div
            className={`${styles.chargerConnectorBackground} ${styles.chargerConnectorText} ${connectorClasses}`}
            data-cy='connector-letter-container'
          >
            <span data-cy={`connector-${row.status}`}>
              {getConnectorLetterFromConnectorID(row.connectorId)}
            </span>
          </div>
        </div>
        {connectorDetail && (
          <div>
            <div
              className={`${styles.chargerConnectorContainer} ${styles.detailConnectorContainer} ${styles.chargerConnectorContainerImage} ${styles.chargerConnectorContainerImageSmall} ${styles.chargerConnectorTypeBackground}`}
              data-cy='connector-type-container'
            >
              <Tooltip
                title={connectorDetail.svgIconName}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <>
                  {connectorDetail.img && (
                    <img
                      style={{ height: '24px', width: '24px', fill: 'white' }}
                      src={connectorDetail.img}
                    />
                  )}
                  {connectorDetail.svgIcon && <connectorDetail.svgIcon />}
                </>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Connector;
