import styles from './card.module.scss';

export const Card = (props: any) => {
	const autoTestId = props['data-cy'] ? {'data-cy': props['data-cy']} : {};
	return (
		<>
			<section className={`${styles.card} ${props.isTransparent? styles.cardTransparent :''}`} style={props.sx} {...autoTestId} >
				<header className={styles.cardHeader}>
					{props.icon && <div className={styles.cardIcon}>{props.icon}</div>}
					<div className={styles.cardTitle} data-cy='retrieve-plan-title'>
						{props.title}
					</div>
				</header>
				<div className={styles.cardBody}>
					{props.children}
				</div>
			</section>
		</>
	);
};