import { httpClient } from './httpClient';

export const getChargerConfig = (params: any) => {
	return httpClient.get('/api/gridify-charger-configuration', params);
};

export const getChargerConfigSummary = (params: any) => {
	return httpClient.get('/api/gridify-charger-configuration-summary', params);
};

