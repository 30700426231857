import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Wit = (props: any) => {
	const { t: translate } = useTranslation();
	return (
		<>
			<Grid item xs={6}>	
				<Controller name="witConnection.authenticationUrl" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="authenticationUrl" label={`${translate('settings.asset.connection.authentication_url')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['witConnection.authenticationUrl']}
						variant="standard"
						error={!!props.assetForm.formState?.errors.witConnection?.authenticationUrl}
						helperText={`${translate(props.assetForm.formState?.errors.witConnection?.authenticationUrl?.message || ' ')}`}
					/>
				)} />
			</Grid>	
			<Grid item xs={6}>	
				<Controller name="witConnection.clientId" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="clientId" label={`${translate('settings.asset.connection.client_id')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['witConnection.clientId']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.witConnection?.clientId}
						helperText={`${translate(props.assetForm.formState?.errors.witConnection?.clientId?.message || ' ')}`}
					/>
				)} />
			</Grid>		
			<Grid item xs={6}>	
				<Controller name="witConnection.clientSecret" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="clientSecret" label={`${translate('settings.asset.connection.client_secret')}`}
						fullWidth disabled={props.isDisabled} type='password'
						required={props.requiredFields['witConnection.clientSecret']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.witConnection?.clientSecret}
						helperText={`${translate(props.assetForm.formState?.errors.witConnection?.clientSecret?.message || ' ')}`}
					/>
				)} />
			</Grid>	
			<Grid item xs={6}>	
				<Controller name="witConnection.user" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="user" label={`${translate('settings.asset.connection.user')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['witConnection.user']}
						variant="standard"
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.witConnection?.user}
						helperText={`${translate(props.assetForm.formState?.errors.witConnection?.user?.message || ' ')}`}
					/>
				)} />
			</Grid>	
			<Grid item xs={6}>	
				<Controller name="witConnection.password" control={props.assetForm.control} render={({ field }) => (
					<EvesTextbox {...field} id="password" label={`${translate('settings.asset.connection.password')}`}
						fullWidth disabled={props.isDisabled}
						required={props.requiredFields['witConnection.user']}
						variant="standard" type='password'
						autoComplete="new-password"
						error={!!props.assetForm.formState?.errors.witConnection?.password}
						helperText={`${translate(props.assetForm.formState?.errors.witConnection?.password?.message || ' ')}`}
					/>
				)} />
			</Grid>		
		</>
	);
};

export default Wit;