import { Content } from '@/common/components/templates/content/content';
import { DefaultPageSize, filterSelector } from '@/common/constants/constants';
import { Filters, LookupEntityType } from '@/common/enums/enums';

import { ArrowRight, Close } from '@mui/icons-material';
import { Grid, InputAdornment, useMediaQuery } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import LookupComponent from '@/common/components/lookupComponent';
import { CellStatus } from '@/common/components/organisms/table/dataTableCells';
import { withContext } from '@/common/utils/withContext';
import { OWNER_FILTER_ITEMS as Owner } from '@/common/constants/constants';
import { ReactComponent as SmartCharger } from '@/assets/img/gridify/smartcharger.svg';
import { ReactComponent as EditSVG } from '@/assets/img/gridify/edit.svg';
import { ReactComponent as TotalSitesOrgSVG } from '@/assets/img/gridify/orgSites.svg';
import { useLocation } from 'react-router-dom';
import { columnsType } from '@/common/models/model';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';
import TransactionDetails from '@/common/components/templates/transactionDetails';
import { Loader } from '@/common/components/atoms/loader/loader';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';
import { autoRefresh } from '@/common/utils/autoRefreshConfig';
import { powertabs } from '../../shared/constants';
import TabPanel from '@/modules/dashboard/components/common/dashboard-tabpanel/tabpanel';
import EditChargingStation from '../../../chargingStation/components/chargingStation/components/chargingStationForm';
import SiteAreaForm from '@/modules/organization/components/siteArea/components/siteAreaForm';
import { LoadBalancing } from './components/loadBalancing';
import { PowerManagement } from './components/powerManagement';
import FilterSelector from '@/common/components/filterSelector';
import { getPowerManagementData } from '@/services/gridify';

const ChargerConfiguration = () => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const firstParam = location?.search?.split('&')[0];
  const parameterCharger =
    firstParam !== '' ? firstParam?.slice(1).split('=') : [];

  const [displayLoader, setDisplayLoader] = useState(false);
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  const [selectedTab, setSelectedTab] = useState<string>('Demand (KW)');
  const handleType = (tab: any) => {
    if (tab) {
      setSelectedTab(tab.name);
      setStateData((currentData) => {
        return {
          ...currentData,
          search: '',
          isResetBtnDisabled: true,

          siteID: '',
          siteAreaID: '',

          chargingStationId: '',

          chargingStationName: '',
          connectorID: null,

          siteName: '',
          siteAreaName: '',
        };
      });
    }
  };
  startDate.setSeconds(0);
  const endDate = new Date();
  endDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);
  const [state, setStateData] = useState({
    connectorID: null,
    defaultIssuer: true,
    isResetBtnDisabled: true,
    issuer: true,
    isTransactionDetailModalOpen: false,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    rows: [],
    stats: [],
    search: '',
    selectedChargingStationID: '',
    siteID: '',
    siteAreaID: '',
    sortFields: '-timestamp',
    totalRowsCount: 0,
    isChargingStationLookupModalOpen: false,
    chargingStationName:
      parameterCharger?.length > 0 ? parameterCharger[1] : '',
    chargingStationId: parameterCharger?.length > 0 ? parameterCharger[1] : '',
    inactivityStatus: [],
    userName: '',
    issuerName: '',
    companyName: '',
    siteName: '',
    siteAreaName: '',
    canExport: false,
    canListSiteAreas: false,
    canListSites: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    onlyRecordCount: true,
    showGraph: true,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    editChargingStationForm: false,
    editSiteAreaForm: false,
    mainData: [],
    chargerSiteID: '',
  } as any);
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const [showconnector, setShowconnector] = useState<boolean>(true);
  const [showphase, setShowphase] = useState<boolean>(true);
  const defaultSortColumn = '-timestamp';
  const chargingStationLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  const searchBoxRef = useRef<any>();
  const consumptionChartRef = useRef<any>();
  const isBigScreen = useMediaQuery('(max-width:1500px)');
  let isOnClickSiteFilterClear = false;
  let isOnClickSiteAreaFilterClear = false;
  const { refetch: fetchTransactionsHistory } = useQuery(
    'PowerManagement',
    () => {
      return getPowerManagementData(getTransactionHistoryParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;
          const canExport = data.canExport;
          setStateData((currentData) => {
            return {
              ...currentData,
              rows: getConvertedResponse(data?.result),
              totalRowsCount: data?.count,
              mainData: getConvertedResponse(data?.result),
              // stats: data.stats,
              canListSites,
              canListSiteAreas,
              canExport,
              projectFields: data.projectFields && [...data.projectFields],
            };
          });
          consumptionChartRef?.current?.refreshChart();
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const getConvertedResponse = (data) => {
    const finalData = data?.map((item) => {
      let totalCurrentInstantWatts = 0;
      let totalPower = 0;
      let totalAmp = 0;
  
      item?.chargingStations?.forEach((station) => {
        station?.connectors?.forEach((connector) => {
          totalCurrentInstantWatts += connector?.currentInstantWatts || 0;
          totalPower += connector?.power || 0;
          totalAmp += connector?.amperage || 0 ;
        });
      });
      return {
        siteAreaName: item?.name,
        id: item?.id,
        rating: item?.rating,
        smartCharging: item?.smartCharging,
        power:totalPower,
        currentInstantWatts:totalCurrentInstantWatts,
        amperage:totalAmp,
        childrens: item?.chargingStations?.map((itemCharge) => {
          return {
            chargerName: itemCharge?.id,
            connectors: itemCharge?.connectors?.length,
            childrens: itemCharge?.connectors?.map((itemConnector) => {
              return {
                connectorId: itemConnector?.connectorId || 0,
                voltage: itemConnector?.voltage,
                power: itemConnector?.power,
                amperage: itemConnector?.amperage,
                currentInstantWatts: itemConnector?.currentInstantWatts,
                numberOfConnectedPhase: itemConnector?.numberOfConnectedPhase
              };
            }),
          };
        }),
      };
    });
    return finalData;
  };
  
  

  const loadcolumns: columnsType[] = [
    {
      id: 'siteAreaName',
      label: `${translate('site_areas.title')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 140,
      allowSort: false,
    },
    {
      id: 'chargerName',
      label: `${translate('gridify.demand_view.charger_id')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 80,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'connectors',
      label: `${translate('gridify.charger_configuration.connectors')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 65,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'connectorId',
      label: `${translate('gridify.power_managment.conectorid')}`,
      minWidth: 50,
      textAlign: 'start',
      maxWidth: 60,
      allowSort: false,
      projectField: 'communication',
    },
    {
      id: 'phase',
      label: `${translate('gridify.power_managment.phase')}`,
      minWidth: 40,
      textAlign: 'center',
      maxWidth: 40,
      allowSort: false,
      projectField: 'communication',
    },

    {
      id: 'voltage',
      label: `${translate('gridify.power_managment.voltage')}`,
      minWidth: 50,
      textAlign: 'center',
      maxWidth: 60,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'empty1',
      label: '',
      minWidth: 2,
      textAlign: 'start',
      maxWidth: 2,
    },
    {
      id: 'siteAreaRating',
      label: `${translate('gridify.power_managment.sitearea_rating')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 60,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'SPP',
      label: `${translate('gridify.power_managment.set_point')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 65,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'empty2',
      label: '',
      minWidth: 1,
      textAlign: 'start',
      maxWidth: 1,
    },
    {
      id: 'instantkw',
      label: `${translate('gridify.power_managment.Instant_current')}`,
      minWidth: 60,
      textAlign: 'center',
      tooltip: 'Instant Power vs Installed Capacity',
      maxWidth: 120,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    ...(showphase
      ? [
          {
            id: 'empty3',
            label: '',
            minWidth: 1,
            textAlign: 'start',
            maxWidth: 1,
          },
          {
            id: 'rl1',
            label: `${translate('R(L1)')}`,
            minWidth: 25,
            textAlign: 'center',
            maxWidth: 38,
            allowSort: false,
            projectField: 'siteArea.name',
          },
          {
            id: 'sl2',
            label: `${translate('S(L2)')}`,
            minWidth: 25,
            textAlign: 'center',
            maxWidth: 38,
            allowSort: false,
            projectField: 'siteArea.name',
          },
          {
            id: 'tl3',
            label: `${translate('T(L3)')}`,
            minWidth: 30,
            textAlign: 'center',
            maxWidth: 38,
            allowSort: false,
            projectField: 'siteArea.name',
          },
        ]
      : []),
  ];
  const getTransactionHistoryParams = (): any => {
    const TransactionHistoryGetParams: any = {
      Limit: state.limit,
      Issuer: state.issuer,
      Page: state.page + 1,
      WithChargingStations: true,
      WithParentSiteArea: true,
    };

    if (state.issuer !== null) {
      TransactionHistoryGetParams['Issuer'] = state.issuer;
    }
    if (state.siteID !== '') {
      TransactionHistoryGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      TransactionHistoryGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      TransactionHistoryGetParams['UserID'] = state.userID;
    }
    if (state.chargingStationId !== '') {
      TransactionHistoryGetParams['ChargingStationID'] =
        state.chargingStationId;
    }
    if (state.connectorID && state.connectorID !== '') {
      const connectorIds = state.connectorID.split('|');

      const filteredIds = connectorIds.filter((id) => id !== 'NoSmartCharging');

      if (filteredIds.length > 0) {
        TransactionHistoryGetParams['SmartChargingType'] =
          filteredIds.join('|');
      } else {
        TransactionHistoryGetParams['Enabled'] = true;
      }
    }

    return TransactionHistoryGetParams;
  };

  const ChargerName = (row: any) => {
    const handleCharger = () => {
      setStateData((prev) => {
        return {
          ...prev,
          editChargingStationForm: true,
          chargingStationId: row?.chargerName,
          chargerSiteID: row?.rowIndex,
        };
      });
    };
    return (
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div
          style={{
            color: '#3D3AD3',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => handleCharger()}
        >
          {row?.chargerName}
        </div>
      </div>
    );
  };

  const SiteAreaName = (row: any) => {
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;
    const handleSubSiteAreaRowClick = (rowc: any, name: any) => {
      const getUpdateData = () => {
        if (row.childrens?.length > 0) {
          return [];
        } else {
          const finalData: any = [];
          state.rows?.filter((itemc) => {
            if (itemc.id === rowc.parentSiteAreaId) {
              const data = itemc?.siteAreaChildren?.filter(
                (subSiteArea) => subSiteArea.id === rowc.id
              )[0].childrens;
              finalData.push(data);
            }
          });
          return finalData[0];
        }
      };
      if (name == 'site') {
        const finData: any = state.rows.map((item) => {
          if (item.id === rowc.parentSiteAreaId) {
            const childrenData = item?.siteAreaChildren?.map((item) => {
              return {
                ...item,
                childrens: getUpdateData(),
              };
            });
            return {
              ...item,
              siteAreaChildren: childrenData,
            };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };

    const handleRowClick = (rowc: any, name: any) => {
      if (name == 'site') {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.id) {
            return {
              ...item,
              childrens:
                row.childrens?.length > 0
                  ? []
                  : state.mainData?.filter((itemc) => itemc.id === item.id)[0]
                      ?.childrens,
              siteAreaChildren:
                row.siteAreaChildren?.length > 0
                  ? []
                  : state.mainData?.filter((itemc) => itemc.id === item.id)[0]
                      ?.siteAreaChildren,
            };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };

    const handleCharger = () => {
      setStateData((currentData) => {
        return {
          ...currentData,
          editSiteAreaForm: true,
          siteAreaId: row?.id,
        };
      });
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '3px',
          marginLeft: row?.parentSiteAreaId ? '20px' : '',
        }}
      >
        {row?.siteAreaName && (
          <EditSVG
            style={{ cursor: 'pointer' }}
            width={'16px'}
            height={'16px'}
            onClick={() => handleCharger()}
            className='commonimageicons'
          />
        )}
        <Icon
          style={{ display: row.siteAreaName ? '' : 'none', cursor: 'pointer' }}
          onClick={() =>
            row?.parentSiteAreaId
              ? handleSubSiteAreaRowClick(row, 'site')
              : handleRowClick(row, 'site')
          }
        />
        {row?.smartCharging?.enabled &&
          (selectedTab === 'Demand (KW)' ? (
            <SmartCharger
              width={'20px'}
              height={'20px'}
              className='commonimageicons'
            />
          ) : (
            <TotalSitesOrgSVG
              width={'20px'}
              height={'20px'}
              className='commonimageicons'
            />
          ))}
        {row?.siteAreaName}
      </div>
    );
  };

  const onClickConnectors = () => {
    setShowconnector((prevShowconnector) => {
      const newShowconnector = !prevShowconnector;

      const finData = state.rows?.map((item) => {
        // Update the siteAreaChildren.childrens
        const updatedSiteAreaChildren =
          item.siteAreaChildren?.map((childArea) => ({
            ...childArea,
            childrens: newShowconnector
              ? state.mainData
                  ?.find((data) => data.id === item.id)
                  ?.siteAreaChildren?.find((c) => c.id === childArea.id)
                  ?.childrens.map((child) => ({
                    ...child,
                    childrens: child.childrens.map((grandChild) => ({
                      ...grandChild,
                      childrens: [], // Set childrens.childrens to empty
                    })),
                  })) || []
              : childArea.childrens.map((child) => ({
                  ...child,
                  childrens: [], // Set childrens.childrens to empty
                })),
          })) || [];

        return {
          ...item,
          siteAreaChildren: updatedSiteAreaChildren,
          // Update the main childrens
          childrens:
            item.childrens?.map((child) => ({
              ...child,
              childrens: newShowconnector
                ? state.mainData
                    ?.find((data) => data.id === item.id)
                    ?.childrens?.find(
                      (c) => c.chargerName === child.chargerName
                    )?.childrens || []
                : [],
            })) || [],
        };
      });

      setStateData({ ...state, rows: finData });

      return newShowconnector;
    });
  };

  const onClickPhase = () => {
    setShowphase(!showphase);
  };

  const handleGraph = () => {
    setStateData({ ...state, showGraph: !state.showGraph });
  };
  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onClickresetFilters = () => {
    chargingStationLookupRef?.current?.setFocus();
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: '',
        isResetBtnDisabled: true,
        visualTagID: '',
        siteID: '',
        siteAreaID: '',
        userID: '',
        chargingStationId: '',
        isOnClickSiteFilterClear: true,
        chargingStationName: '',
        connectorID: '',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        siteName: '',
        siteAreaName: '',
        RFIDFilterValue: '',
        userName: '',
        defaultIssuer: true,
        subStationID: '',
        subStationName: '',
        feederID: '',
        feederName: '',
        transformerID: '',
        transformerName: '',
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === '' &&
          currentData.siteName === '' &&
          currentData.siteAreaName === '' &&
          currentData.chargingStationId === '' &&
          (currentData.defaultIssuer ||
            currentData.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  useEffect(() => {
    fetchTransactionsHistory();
  }, [
    state.limit,
    state.page,
    state.sortFields,
    state.chargingStationId,
    state.siteAreaID,
    state.siteAreaName,
    state.siteID,
    state.inactivityStatus,
    state.search,
    state?.connectorID,
    state?.connectorID,
  ]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        chargingStationID: '',
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const getFormatedRFIDCard = (tag: any) => {
    if (tag.length > 1) {
      return `${tag[0].visualID} (+ ${tag.length - 1})`;
    } else {
      return `${tag[0].visualID}`;
    }
  };
  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };
  const onRFIDFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: false,
      };
    });
  };

  const onRFIDCardSelected = (selectedTag: any) => {
    const sortedObj = Object.values(selectedTag).sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
    const selectedItems = sortedObj
      ?.map((sessionTag: any) => sessionTag.visualID)
      .join('|');
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: selectedItems,
        RFIDFilterValue: getFormatedRFIDCard(sortedObj),
        showRFIDFilterModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const StatusCell = (row: any) => {
    return (
      <CellStatus
        label={
          row.active
            ? `${translate('tags.activated')}`
            : `${translate('tags.deactivated')}`
        }
        type={row.active ? 'Available' : 'Faulted'}
      />
    );
  };

  const resetSubStationIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSubStationIdLookupModalOpen: false };
    });
  };
  const onSubStationIdSelect = (substations: any) => {
    const subStationName =
      substations?.length > 1
        ? `${substations[0].name} (+${substations?.length - 1})`
        : substations?.length && substations[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSubStationIdLookupModalOpen: false,
        subStationID: substations
          .map((subStation: any) => subStation.name)
          .join('|'),
        subStationName: subStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetFeederIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isFeederIdLookupModalOpen: false };
    });
  };
  const onFeederIdSelect = (feeders: any) => {
    const feederName =
      feeders?.length > 1
        ? `${feeders[0].name} (+${feeders?.length - 1})`
        : feeders?.length && feeders[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isFeederIdLookupModalOpen: false,
        feederID: feeders.map((feeder: any) => feeder.name).join('|'),
        feederName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetTransformerIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isTransformerIdLookupModalOpen: false };
    });
  };
  const onTransformerIdSelect = (transformer: any) => {
    const feederName =
      transformer?.length > 1
        ? `${transformer[0].name} (+${transformer?.length - 1})`
        : transformer?.length && transformer[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransformerIdLookupModalOpen: false,
        transformerID: transformer.map((trans: any) => trans.name).join('|'),
        transformerName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };
  const onFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        editChargingStationForm: false,
      };
    });
  };

  const onSiteFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        editSiteAreaForm: false,
      };
    });
  };

  const resetSiteAreaForm = (val: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaId: val,
      };
    });
  };

  const onConnectorSelect = (connectorID: string[]) => {
    const filteredItems = connectorID?.map((item) => {
      return filterSelector[Filters.SMART_CHARGING].value[item];
    });
    const invoiceStatus = Object.keys(
      filterSelector[Filters.SMART_CHARGING].value
    ).map((key) => key.toLowerCase().replace(' ', ''));
    const sortedValue = filteredItems.sort(
      (a, b) => invoiceStatus.indexOf(a) - invoiceStatus.indexOf(b)
    );
    const updatedValue = sortedValue.join('|');

    setStateData((currentData) => {
      return {
        ...currentData,
        connectorID: updatedValue,
        isResetBtnDisabled: false,
      };
    });
    if (connectorID.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            !state.search &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.chargingStationId &&
            (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
            (state.startDate.toString() == startDate.toISOString() ||
              state.endDate.toString() == endDate.toISOString()) &&
            !state.visualTagID &&
            !state.userID &&
            state.inactivityStatus.length == 0,
        };
      });
    }
  };
  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  return (
    <>
      {state.editChargingStationForm && (
        <EditChargingStation
          onFormClose={onFormClose}
          chargingStationId={state.chargingStationId}
          siteId={state.chargerSiteID}
          limit={state.limit}
          fetchAllChargingStations={() => {
            /* */
          }}
          canUpdateChargingStation={false}
          editChargingStationForm={state.editChargingStationForm}
        />
      )}

      {state.editSiteAreaForm && (
        <SiteAreaForm
          siteAreaId={state.siteAreaId}
          openSiteArea={state.editSiteAreaForm}
          onFormClose={onSiteFormClose}
          pageLimit={DefaultPageSize}
          // JIRA -[EVES-1680] call api after successfull edit
          fetchAllSiteAreas={ fetchTransactionsHistory } 
          resetSiteAreaForm={resetSiteAreaForm}
          canCreate={state.canCreate}
          isPowerLimit={true}
        />
      )}
      {<Loader showLoader={displayLoader} />}
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}

      {state.showRFIDFilterModal && (
        <LookupComponent
          data-cy='RFIDLookUpModal'
          title={`${translate('tags.select_tags')}`}
          showLookupModal={state.showRFIDFilterModal}
          onModalClose={onRFIDFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_RFID_TAG}
          tableChildren={<StatusCell slot='statusCustomValue' />}
          params={
            state.userID
              ? [
                  { key: 'Issuer', value: true },
                  { key: 'WithUser', value: true },
                  { key: 'UserID', value: state.userID },
                ]
              : [
                  { key: 'Issuer', value: true },
                  { key: 'WithUser', value: true },
                ]
          }
          onSelect={onRFIDCardSelected}
          defaultSortColumn='id'
        />
      )}

      <Content
        contentTop={
          <>
            <Grid
              container
              item
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid
                item
                mt={0}
                ml={1}
                md={9}
                sx={{ display: 'flex', gap: '20px' }}
              >
                <Grid item mt={2} md={3}>
                  <TabPanel
                    data-cy='powerManagement-view-tabs'
                    {...{
                      tabs: powertabs,
                      selectedTab: selectedTab,
                    }}
                    variant='standard'
                    textColor='primary'
                    handleTab={handleType}
                    sx={{
                      height: '36px',
                      '& .MuiTabs-root, .MuiTabs-scroller': {
                        overflow: 'visible',
                        minHeight: '40px',
                      },
                      '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        flexDirection: 'row',
                        margin: 0,
                        padding: 0,
                        borderRadius: '4px',
                        backgroundColor: '#F2F2F2',
                        fontWeight: 400,
                      },
                      '& .MuiToggleButtonGroup-root': {
                        padding: '0px',
                        margin: '0px',
                        border: 'none',
                      },
                      '& .MuiBox-root': {
                        padding: '0px',
                        margin: '0px',
                      },
                      '& .MuiToggleButton-root': {
                        textTransform: 'none',
                        boxShadow: '0px 1px 2px 0px #1018280D',
                        fontWeight: 400,
                        borderRadius: '6px',
                        border: 'none',
                        lineHeight: '24px',
                        fontFamily: 'Roboto',
                        color: '#344054',
                        fontSize: !isBigScreen ? '14px' : '10px',
                        // height:'36px',
                        // padding: isBigScreen ? '7.5px 12px' : '10px 16px',
                        height: !isBigScreen ? '36px' : '28px',

                        // '@media (min-width: 1280px) and (max-width: 1440px)': {
                        //   fontSize: '12px !important',
                        // },
                        // '@media (min-width: 1440px) and (max-width: 1920px)': {
                        //   fontSize: '14px !important',
                        // },
                      },
                      '& .Mui-selected.MuiToggleButton-root': {
                        backgroundColor: '#3D3AD3',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: 'none',
                        // height: '36px',
                        '&:hover': {
                          backgroundColor: '#3D3AD3',
                          opacity: 0.8,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item md={9}>
                  {showfilter && (
                    <Grid
                      container
                      item
                      spacing={2}
                      rowSpacing={1}
                      width={'100%'}
                      sx={alignStyle}
                    >
                      <Grid item xs={12} md={4}>
                        <EvesTextbox
                          value={state.siteAreaName}
                          id='inProgress-standard-search'
                          label={`${translate('site_areas.titles')}`}
                          data-cy='select-site-area'
                          type='text'
                          variant='standard'
                          onClick={setSiteAreaFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={siteAreaLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSiteArea}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <EvesTextbox
                          value={state.chargingStationName}
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                          }}
                          id='charging-station-standard-search'
                          // id='sessions-charging-station-lookup'
                          label={`${translate('chargers.chargers')}`}
                          type='text'
                          variant='standard'
                          data-cy='sessions-charging-station-lookup'
                          onClick={setChargingStationFilter}
                          onChange={onFilterApplied}
                          ref={chargingStationLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedChargingStation}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FilterSelector
                          filterOption={Filters.SMART_CHARGING}
                          defaultIssuer={state.defaultIssuer}
                          onChange={onConnectorSelect}
                          isResetBtnDisabled={state.isResetBtnDisabled}
                          data-cy='connector-filter'
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item gap={'10px'} mt={1} md={2.5}>
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    multiple: true,
                    handleGraph,
                    hideLabel: false,
                    isPowerManage: true,
                    onClickConnectors,
                    onClickPhase,
                    showconnector,
                    hidePhase: selectedTab === 'Demand (KW)',
                    showphase,
                    intervalsAndFunctions: [
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: fetchTransactionsHistory,
                      },
                    ],
                    // onClickExport: exportSession,
                    // isExport: true,
                  }}
                />
              </Grid>
            </Grid>
          </>
        }
        disableOverflow={true}
        contentBody={
          <Grid
            sx={{
              borderRadius: '8px',
              border:
                '1px solid var(--Foundation-Base-on-Light-base-on-light-3, #F5F5F5)',
              background: 'var(--Base-White, #FFF)',
              // height: 'calc(100vh - 290px)',
            }}
          >
            <Grid data-cy={'demand-view-table'}>
              {selectedTab === 'Demand (KW)' ? (
                <PowerManagement
                  {...{
                    defaultSortColumn,
                    onTableChange,
                    state,
                    SiteAreaName,
                    ChargerName,
                    data: state.rows,
                    showconnector,
                    tablePagination: true,
                    onPageChange:onPageChange,
                    onRowsPerPageChange:onRowsPerPageChange,
                    page:state.page
                  }}
                />
              ) : (
                <LoadBalancing
                  {...{
                    defaultSortColumn,
                    onTableChange,
                    state,
                    SiteAreaName,
                    ChargerName,
                    data: state.rows,
                    loadcolumns,
                    tablePagination: true,
                    showconnector,
                    showphase,
                    onPageChange:onPageChange,
                    onRowsPerPageChange:onRowsPerPageChange,
                    page:state.page
                  }}
                />
              )}
            </Grid>
          </Grid>
        }
      ></Content>
      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy='sessions-charging-plan-lookup'          
          title={`${translate('chargers.select_id')}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                  { key: 'Issuer', value: state.issuer },
                  { key: 'SiteAreaID', value: state.siteAreaID },
                ]
              : [{ key: 'Issuer', value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn='id'
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate('sites.select_sites')}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITE}
          params={[{ key: 'Issuer', value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_site_areas')}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: 'Issuer', value: null },
            { key: 'SiteID', value: state.siteID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransactionDetailModalOpen && (
        <TransactionDetails
          transactionId={state.selectedTransactionId}
          chargingStationId={state.selectedChargingStationID}
          connectorId={state.selectedConnectorID}
          onClose={onTransactionDetailModalClose}
          withStopedBy={true}
          popupOpen={state.isTransactionDetailModalOpen}
        />
      )}
      {state.isSubStationIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_substation_id')}`}
          showLookupModal={state.isSubStationIdLookupModalOpen}
          onModalClose={resetSubStationIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SUBSTATIONID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSubStationIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isFeederIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_feeder_id')}`}
          showLookupModal={state.isFeederIdLookupModalOpen}
          onModalClose={resetFeederIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.FEEDERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'WithSite', value: true },
            { key: 'SubStationID', value: state.subStationID || null },
          ]}
          onSelect={onFeederIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransformerIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_transformer_id')}`}
          showLookupModal={state.isTransformerIdLookupModalOpen}
          onModalClose={resetTransformerIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.TRANSFORMERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'TransformerID', value: state.transformerID || null },
            { key: 'WithSite', value: true },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'FeederID', value: state.feederID || null },
          ]}
          onSelect={onTransformerIdSelect}
          defaultSortColumn='name'
        />
      )}
    </>
  );
};

export default withContext(ChargerConfiguration);
