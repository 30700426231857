import { findRequiredFields } from '@/common/utils/validationHelper';
import  * as schema from 'yup';
import { DimensionFormModel, PriceFormModel, RestrictionFormModel, StaticRestrictionFormModel } from '../models/price';
import { isBefore, isAfter, isEqual } from 'date-fns';

const emptyStringHandler = (value: any, originalValue: any) => originalValue === '' ? undefined : value;

export const formSchema = schema.object<Partial<Record<keyof PriceFormModel, schema.AnySchema>>>({
	name: schema.string().required('Field is required'),
	description: schema.string().required('Field is required'),
	dimensions:schema.object<Partial<Record<keyof DimensionFormModel, schema.AnySchema>>>({
		flatFee: schema.object({
			active: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema.string().required('Required field').matches(/^\s*(\d+|(\d*(\.\d*)))([eE]?\d+)?\s*$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			})
		}),
		energy: schema.object({
			active: schema.boolean(),
			stepSizeEnabled: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema.string().required('Required field').matches(/^\s*(\d+|(\d*(\.\d*)))([eE]?\d+)?\s*$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			}),
			stepSize: schema.string().when(['active', 'stepSizeEnabled'], {
				is: (active: boolean, stepSizeEnabled: boolean) => active && stepSizeEnabled,
				then: schema.string().required('Required field').matches(/^[0-9\b]+$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			})
		}),
		chargingTime: schema.object({
			active: schema.boolean(),
			stepSizeEnabled: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema.string().required('Required field').matches(/^\s*(\d+|(\d*(\.\d*)))([eE]?\d+)?\s*$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			}),
			stepSize: schema.string().when(['active', 'stepSizeEnabled'], {
				is: (active: boolean, stepSizeEnabled: boolean) => active && stepSizeEnabled,
				then: schema.string().required('Required field').matches(/^[0-9\b]+$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			})
		}),
		parkingTime: schema.object({
			active: schema.boolean(),
			stepSizeEnabled: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema.string().required('Required field').matches(/^\s*(\d+|(\d*(\.\d*)))([eE]?\d+)?\s*$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			}),
			stepSize: schema.string().when(['active', 'stepSizeEnabled'], {
				is: (active: boolean, stepSizeEnabled: boolean) => active && stepSizeEnabled,
				then: schema.string().required('Required field').matches(/^[0-9\b]+$/,'Invalid Number'),
				otherwise: schema.string().notRequired()
			})
		}),
	}),
	staticRestrictions: schema.object<Partial<Record<keyof StaticRestrictionFormModel, schema.AnySchema>>>({
		connectorPowerEnabled: schema.boolean(),
		connectorPowerkW: schema.string().when('connectorPowerEnabled', {
			is: true,
			then: schema.string().required('Required field').matches(/^\s*(\d+|(\d*(\.\d*)))([eE]?\d+)?\s*$/,'Invalid Number'),
			otherwise: schema.string().notRequired()
		}),
		validFrom: schema.string().notRequired().transform((value, originalValue) => {
			if (value !== 'Invalid Date' && value !== '' && value !== null) {
				return new Date(originalValue).toJSON() ?? 'Invalid date';
			}
			else if(value === 'Invalid Date')
			{
				return 'Invalid date';
			}
			else if(value === null)
			{
				return '';
			}
		}).test({
			name: 'isValidDate',
			message: 'Invalid date',
			test: (value) => {
				return value !== 'Invalid date';
			}
		}).test({
			name: 'validFromLessThanValidTo',
			message: '',
			test: (value, context) => {
				if (!value || !context.parent.validTo) {
					return true;
				}
				const dateFrom = new Date(value);
				const dateTo = new Date(context.parent.validTo);
				return isBefore(dateFrom, dateTo) || isEqual(dateFrom, dateTo);
			}
		}),
		validTo: schema.string().notRequired().transform((value, originalValue) => {
			if (value !== 'Invalid Date' && value !== '' && value !== null) {
				return new Date(originalValue).toJSON() ?? 'Invalid date';
			}
			else if(value === 'Invalid Date')
			{
				return 'Invalid date';
			}
			else if(value === null)
			{
				return '';
			}
		}).test({
			name: 'isValidDate',
			message: 'Invalid date',
			test: (value) => {
				return value !== 'Invalid date';
			}
		}).test({
			name: 'validToGreaterThanValidFrom',
			message: 'The date must be greater than the valid-from date',
			test: (value, context) => {
				if (!value || !context.parent.validFrom) {
					return true;
				}
				const dateTo = new Date(value);
				const dateFrom = new Date(context.parent.validFrom);
				return isAfter(dateTo, dateFrom)||isEqual(dateFrom, dateTo);
			}
		})
	}),
	restrictions: schema.object<Partial<Record<keyof RestrictionFormModel, schema.AnySchema>>>({
		daysOfWeekEnabled: schema.boolean().test({
			name:'daysOfWeekEnabled',
			message:'',
			test:(value,context)=>{
				if(value){
					if(context.parent?.daysOfWeek?.length==0){
						return false;
					}
					else{return true;}
				}
				else{
					return true;
				}
			}
		}),
		daysOfWeek: schema.array().of(schema.string().test({
			name:'daysOfWeek',
			message:'',
			test:(value,context)=>{
				if(value){
					if(context.parent?.daysOfWeek?.length==0){
						return false;
					}
					else{return true;}
				}
				else{
					return true;
				}
			}
		})
		
		),
		minDurationSecsEnabled: schema.boolean(),
		minDurationSecs: schema.number().integer('Invalid Number').transform(emptyStringHandler).when('minDurationSecsEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('Required field').typeError('Invalid Number').test({
					name: 'minDurationCheck',
					message: '',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.maxDurationSecs) || !context.parent.maxDurationSecsEnabled) {
							return true;
						} else {
							return value < context.parent.maxDurationSecs;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		maxDurationSecsEnabled: schema.boolean(),
		maxDurationSecs: schema.number().integer('Invalid Number').transform(emptyStringHandler).when('maxDurationSecsEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('Required field').typeError('Invalid Number').test({
					name: 'maxDurationCheck',
					message: 'Maximum duration must be greater than the minimum duration',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.minDurationSecs) || !context.parent.minDurationSecsEnabled) {
							return true;
						} else {
							return value > context.parent.minDurationSecs;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		minEnergyKWhEnabled: schema.boolean(),
		minEnergyKWh: schema.number().integer('Invalid Number').transform(emptyStringHandler).when('minEnergyKWhEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('Required field').typeError('Invalid Number').test({
					name: 'minEnergyKWhCheck',
					message: '',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.maxEnergyKWh) || !context.parent.maxEnergyKWhEnabled) {
							return true;
						} else {
							return value < context.parent.maxEnergyKWh;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		maxEnergyKWhEnabled: schema.boolean(),
		maxEnergyKWh: schema.number().integer('Invalid Number').transform(emptyStringHandler).when('maxEnergyKWhEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('Required field').typeError('Invalid Number').test({
					name: 'maxEnergyKWhCheck',
					message: 'Maximum energy must be greater than the minimum energy',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.minEnergyKWh) || !context.parent.minEnergyKWhEnabled) {
							return true;
						} else {
							return value > context.parent.minEnergyKWh;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		timeRangeEnabled: schema.boolean(),
		timeFrom: schema.string().when('timeRangeEnabled', {
			is: true,
			then: schema.string().required('Required field').matches(/^$|\d+$/,'Invalid Number').not([schema.ref('timeTo')], ''),
			otherwise: schema.string().notRequired()
		}),
		timeTo: schema.string().when('timeRangeEnabled', {
			is: true,
			then: schema.string().required('Required field').matches(/^$|\d+$/,'Invalid Number').not([schema.ref('timeFrom')], 'Start and end time must be different'),
			otherwise: schema.string().notRequired()
		}),
	})
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);