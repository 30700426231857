import React from 'react';
import { IconButton,Icon } from '@mui/material';
import styles from './iconButton.module.scss';

export function EvesIconButton(props: any) {
	return (
		<>
			<IconButton className={styles.button} onClick={props.onClick} disabled={props.disabled} {...props }>
				<Icon component={props.icon} fontSize={props.size} sx={{ fontSize: props.fontSize, color: props.color }}/>
			</IconButton>
		</>				
	);
}
