import { useState } from 'react';
import { OWNER_FILTER_ITEMS as Owner } from '../constants/constants';
import { SelectChangeEvent } from '@mui/material/Select';
import EvesMultiselect from '@/common/components/atoms/multiselect/multiselect';
import { useTranslation } from 'react-i18next';

const MenuProps = {
	PaperProps: {
		style: {},
	}
};

export default function OwnerFilter(props: any) {
	const { t: translate } = useTranslation();
	const names = [translate(Owner.CurrentOrganization), translate(Owner.ExternalOrganization)];
	const [ownerName, setOwnerName] = useState<string[]>([]);
	const [shrink,setShrink]=useState(true);
	const handleChange = (event: SelectChangeEvent<typeof ownerName>) => {
		const { target: { value } } = event;
		setOwnerName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
		if(event.target.value.length==0){
			setShrink(false);
		}
		else{setShrink(true);}
		const issuerInfo = getIssuerInfo(value);
		props.onChange(issuerInfo);
	};

	const getIssuerInfo = (selectedOwners: any) => {
		if (selectedOwners.length === 0 || selectedOwners.length === 2) {
			return {
				issuerName: '',
				defaultIssuer: false,
			};
		} else if (selectedOwners[0] === translate(Owner.CurrentOrganization)) {
			return {
				issuer: true,
				issuerName: Owner.CurrentOrgAPI,
				defaultIssuer: false,
			};
		} else if (selectedOwners[0] === translate(Owner.ExternalOrganization)) {
			return {
				issuer: false,
				issuerName: Owner.ExternalOrgAPI,
				defaultIssuer: false,
			};
		}
	};

	return (
		<>
			<EvesMultiselect 
				isFilter={true}
				label={`${translate('issuer.title')}`} 
				options={names} 
				checked={(item: any) => { return (props.defaultIssuer ? [translate(Owner.CurrentOrganization)].indexOf(item) : ownerName.indexOf(item)) > -1;}}
				value={props.defaultIssuer ? [translate(Owner.CurrentOrganization)] : ownerName}
				onChange={handleChange}
				renderValue={(selected:any) =>{
					if(selected.length==2){
						return `${translate(Owner.CurrentOrganization)} (+ ${selected.length - 1})`;
					}
					else{					
						return selected.join(', ');
					}
				} }
				MenuProps={MenuProps}
				shrink={props.defaultIssuer ? true : shrink}
			/>
		</>
	);
}
