
import { Bar, Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
export const LiveGraph = ({ demandLimit, instantPower }: any) => {

    const randomNumber = (min, max) => {
        const generateRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        const numbers: any = [];

        for (let i = 0; i < 50; i++) {
            const randomNumber = generateRandomNumber(min, max);
            numbers.push(randomNumber);
        }
        return numbers;
    };

    const [data, setData] = useState([{
        id: '1',
        label: 'Charger 3',
        data: randomNumber(1000, 1100),
        fill: false,
        borderColor: '#EB407A',
        tension: 0.1,
        backgroundColor: ['#EB407A'],
    },
    {
        id: 2,
        label: 'Charger 2',
        data: randomNumber(2000, 2100),
        fill: false,
        borderColor: '#B2DF8A',
        tension: 0.1,
        backgroundColor: ['#B2DF8A'],
    },
    {
        id: 3,
        label: 'Charger 1',
        data: randomNumber(3000, 3100),
        fill: false,
        borderColor: '#B4431F',
        tension: 0.1,
        backgroundColor: ['#B4431F'],
    },
    {
        id: 4,
        label: 'Charger 3',
        data: randomNumber(1600, 2200),
        fill: false,
        borderColor: '#A6CEE3',
        tension: 0.1,
        backgroundColor: ['#A6CEE3'],
    }
    ]);


    useEffect(() => {
        setData((prev) => {
            let data = [...prev];
            const dataExist = data.filter((item) => item.id === 5);
            if (demandLimit && dataExist.length == 0) {
                data.push({
                    id: 5,
                    label: 'Charger 4',
                    data: randomNumber(7000, 7000),
                    fill: false,
                    borderColor: '#F79009',
                    tension: 0.1,
                    backgroundColor: ['#F79009'],
                });
            }
            else if (!demandLimit) {
                data = data.filter((item) => item.id !== 5);
            }
            const dataExistInstant = data.filter((item) => item.id === 6);
            if (instantPower && dataExistInstant.length == 0) {
                data.push({
                    id: 6,
                    label: 'Charger 5',
                    data: randomNumber(2200, 2400),
                    fill: false,
                    borderColor: '#4318FF',
                    tension: 0.1,
                    backgroundColor: ['#4318FF'],
                });
            } else if (!instantPower) {
                data = data.filter((item) => item.id !== 6);
            }
            return [...data];
        });
    }, [demandLimit, instantPower]);

    const getLabels = () => {
        const numbers: any = [];
        for (let i = 1; i <= 50; i++) {
            const number = i < 10 ? `1:0${i}` : `1:${i}`;
            numbers.push(number);
        }
        return numbers;
    };
    const getData = () => {
        const labels: any = getLabels();
        const datasets: any = data;
        return {
            labels: labels,
            datasets: datasets
        };
    };

    const toptions: any = {
        chart: {
            type: 'bar',
        },
        elements: {
            point: {
                radius: 0
            }
        },
        interaction: {
            mode: 'nearest'
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
        responsive: true,
        stacked: false,
        colors: ['#85D8DD', '#427ae3', '#3dc7d1', '#faa93e'],
        fill: {
            colors: ['#85D8DD', '#427ae3', '#3dc7d1', '#faa93e']
        },
        dataLabels: {
            enabled: false,
        },

        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            customLines: {
                x: {
                    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            // find coordinates of tooltip
                            const activePoint = chart.tooltip._active[0];
                            const { ctx } = chart;
                            const { x } = activePoint.element;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // draw vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#1C2128';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
                y: {
                    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            // find coordinates of tooltip
                            const activePoint = chart.tooltip._active[0];
                            const { ctx } = chart;
                            const { x } = activePoint.element;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // draw vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#1C2128';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                position: 'left',
                title: {
                    display: true,
                    text: 'Demand (KW)',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5
                },
                grid: {
                    display: false,
                },
                ticks: {
                    // stepSize: 1000// <----- This prop sets the stepSize
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true,
                autoSkip: false,
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false
                }
            },
        },
        maintainAspectRatio: false,
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0];
                    }
                }
            }
        },
    };

    return (
        <div className={styles.graphContainer} data-cy='live-graph-demand-view'>
            <Chart type='line' data={getData()} style={{ width: '200vh' }} options={{
                ...toptions
            }} />
        </div >
    );
};