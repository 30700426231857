import { httpClient, httpClientV2 } from './httpClient';
import { SiteModel } from './models/organization';
import { IResponseModel } from './models/responseModel';
import { AddUserSitesModel, UserSitesModel } from './models/user';

export const getSiteByUserId = (userId: string, params: any) => {
	return httpClient.get(`/api/users/${userId}/sites`, params);
};

export const assignSite = (siteIds: any, userID: any) => {
	return httpClient.post(`/api/users/${userID}/sites`, siteIds);
};

export const unAssignSite = (siteIds: any, userID: any) => {
	return httpClient.put(`/api/users/${userID}/sites`, siteIds);
};

export const getSites = (siteGetParams: any) => {
	return httpClient.get<IResponseModel<SiteModel>>(
		`/api/users/${siteGetParams.UserID}/users`,
		siteGetParams
	);
};

export const getUserSites = (params: any) => {
	return httpClient.get<IResponseModel<UserSitesModel>>(
		`/api/users/${params.UserID}/sites`, params
	);
};

export const getSelectUserSites = (params: any) => {
	return httpClient.get<IResponseModel<AddUserSitesModel>>(
		'/api/sites', params
	);
};

export const revokeRegistractionToken = (registrationId: string) => {
	return httpClient.put(`/api/registration-tokens/${registrationId}/revoke`);
};

export const getBillingForceSynchronizeUser = (userId: any) => {
	return httpClientV2.post('/api/BillingForceSynchronizeUser', userId);
};
