import { Outlet } from 'react-router-dom';
import { EvStation } from '@mui/icons-material';
import EvesTabs from './../../common/components/molecules/tabs/tabs';
import { Page } from './../../common/components/templates/page/page';
import { useNavigate } from 'react-router-dom';
import { canListChargingStations } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

const EnergyStorage = () => {
	const navigate = useNavigate();
	const { t: translate } = useTranslation();

	const tabsConfig = [
		{
			name:`${translate('general.menu.energy_storages')}`,
			path: '/charging-stations/charging-stations-list',
			icon: <EvStation />,
			visible: canListChargingStations(),
		},
	];

	const onTabChange = (value: any) => {
		const targetPath = value;
		if (targetPath) {
			navigate(targetPath);
		}
	};

	return (
		<Page header={<EvesTabs isTypeComponent={false}  config={tabsConfig} className="charging-station-tabs" onChange={ onTabChange } />}>
			<Outlet />
		</Page>
	);
};

export default EnergyStorage;