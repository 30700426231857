import { EvesButton } from '@/common/components/atoms/button/button';
import { IntegrationConnectionType } from '@/common/enums/enums';
import { withContext } from '@/common/utils/withContext';
import { deleteIntegrationConnection, getRefundSettings } from '@/services/userProfile';
import { IUserContextModel } from '@/store/auth/types';
import { Delete, Launch } from '@mui/icons-material';
import { Box, Stack, Grid } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from '@/common/components/molecules/card/card';
import { useTranslation } from 'react-i18next';

const Refund = (props: any) => {
	const [state, setStateData] = useState({
		isRefundConnectionValid: false,
		refundConnection: {},
		refundSetting: {},
	} as any);

	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);

	const integrationConnections = props.connections;
	const style = {
		connectionCardText: {
			fontSize: '0.9rem', 
			color: 'rgba(0, 0, 0, 0.87)'
		} as CSSProperties,
		cardTitle: {
			fontSize: '20px',
			fontWeight: '500', 
			color: 'rgba(0, 0, 0, 0.87)'
		} as CSSProperties,

		cardimg: {
			border: '1px solid #dee2e6',
			borderRadius: '16px',
			boxShadow: '0 0.125rem 0.25rem rgb(60 72 88 / 8%)',
			backgroundColor: '#fafafa',
			padding: '0.25rem'
		} as CSSProperties,
	};

	const handleUserConnections = () => {
		if (!(integrationConnections === 0)) {
			let refundConnection: any = null;
			let isRefundConnectionValid: boolean = false;
			for (const connection of integrationConnections) {
				if (connection.connectorId === IntegrationConnectionType.CONCUR) {
					refundConnection = connection;
					setStateData((currentData: any) => {
						return {
							...currentData,
							refundConnection
						};
					});
					isRefundConnectionValid =
            refundConnection &&
            refundConnection.validUntil &&
            new Date(refundConnection.validUntil).getTime() > new Date().getTime();
					setStateData((currentData: any) => {
						return {
							...currentData,
							isRefundConnectionValid,
						};
					});
				}
			}
		}
	};
	
	const { t: translate } = useTranslation();

	const linkRefundAccount = () => {
		if (!state.refundSetting || !state.refundSetting?.concur) {
			props.notificationService?.notify({
				message: `${translate('transactions.notification.refund.tenant_concur_connection_invalid')}`,
				type: 'error',
			});
		} else {
			// Concur
			const concurSetting = state.refundSetting.concur;
			const returnedUrl = `${window.location.origin}/users/connections`;
			const concurState = {
				connector: IntegrationConnectionType.CONCUR,
				appId: state.refundSetting.id,
				//TODO: this line gets changed after UI dependency is cleared.
				userId: authContext.userInfo?.userId,
			};
			window.open(`${concurSetting.authenticationUrl}/oauth2/v0/authorize?client_id=${concurSetting.clientId}&response_type=code&scope=EXPRPT&redirect_uri=${returnedUrl}&state=${JSON.stringify(concurState)}`, '_blank');
		}
	};

	const revokeRefundAccount = () => {
		deleteIntegrationConnection(state.refundConnection.id).then(
			(response: any) => {
				if (response.status === 'success') {
					props.notificationService?.notify({
						message: `${translate('settings.refund.concur.revoke_success')}`,
						type: 'success',
					});
				} else {
					props.notificationService?.notify({
						message: `${response} ${translate('settings.refund.concur.revoke_error')}`,
						type: 'error',
					});
				}
				props.fetchConnections();
			}, (error) => {
				props.notificationService?.notify({
					message: `${error} ${translate('settings.refund.concur.revoke_error')}`,
					type: 'error',
				});
				props.fetchConnections();
			}
		);
	};

	if(props.integrationConnections) handleUserConnections();

	const getRefundUrl = (): string => {
		if (state.refundSetting && state.refundSetting?.concur) {
			return state.refundSetting.concur?.apiUrl;
		}
		return '';
	};

	const { refetch: fetchRefundSettings } = useQuery('Connections', () => { return getRefundSettings(); }, {
		enabled:false,
		onSuccess: (data: any) => {
			if(data){
				const refundSetting: any = {};
				refundSetting.id = data.id;
				refundSetting.sensitiveData = data.sensitiveData;
				refundSetting.concur = data.content?.concur;
				setStateData((currentData: any) => {
					return {
						...currentData,
						refundSetting,
					};
				});
			}
		}
	});

	useEffect(() => {
		fetchRefundSettings();
	}, []);
  
	return <>
		{Object.keys(state.refundSetting).length > 0 &&
			<Grid container>
				<Grid item md={12}>
					<Card data-cy="refund" isTransparent={true} sx={{paddingBottom:'0'}}>
						<>
							<a href={getRefundUrl()} target="_blank" rel="noreferrer">
								<Stack direction="row" alignItems={'center'} justifyContent={'flex-start'}  marginBottom={'20px'}>
									<Box height={'72px'} width={'72px'} marginRight={'20px'}>
										<img height={'100%'} width={'100%'} src={require('@/assets/img/integrations/concur-logo.png')} style={style.cardimg}/>
									</Box>
									<h2 data-cy="refund-title" className="card-title" style={style.cardTitle}>Concur</h2>
								</Stack>
							</a>
						</>
						{!state.isRefundConnectionValid && 
						<Stack direction="row" alignItems={'center'} justifyContent={'space-between'} style={style.connectionCardText}>
							<span>{`${translate('users.connectors.not_connected')}`}</span>
							<EvesButton data-cy="refund-button" onClick={linkRefundAccount} startIcon={<Launch />} variant="contained" >{`${translate('users.connectors.connect')}`}</EvesButton>
						</Stack> || 
						<Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
							<span> {state.refundConnection.createdAt }</span>
							<span>{state.refundConnection.validUntil }</span>
							<EvesButton onClick={revokeRefundAccount} startIcon={<Delete />} 
								variant="contained" >{`${translate('users.connectors.revoke')}`}</EvesButton>
						</Stack>}
					</Card>
				</Grid>
			</Grid>}
	</>;
};

export default withContext(Refund);