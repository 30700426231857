
export const sampleDemandViewData = [
    {
        siteName: 'Engineering Lab Public',
        id: 'site1',
        childrens: [
            {
                siteAreaName: 'Engineering (public)',
                siteId: 'site1',
                id: '1',
                childrens: [
                    {
                        chargerName: 'BTC-DC-3PH-0001',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                    {
                        chargerName: 'BTC-DC-3PH-0002',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                ]
            },
            {
                siteAreaName: 'Engineering Lab',
                siteId: 'site1',
                id: '2',
                childrens: [
                    {
                        chargerName: 'BTC-DC-3PH-0003',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                    {
                        chargerName: 'BTC-DC-3PH-0004',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                ]
            },
        ]
    },
    // {
    //     siteName: 'Engineering Lab Public 2',
    //     id: 'site2',
    //     childrens: [
    //         {
    //             siteAreaName: 'Engineering (public)',
    //             siteId: 'site2',
    //             id: '1',
    //             childrens: [
    //                 {
    //                     chargerName: 'BTC-DC-3PH-0001',
    //                     connectors: 4,
    //                     instantkw: '100kw',
    //                     installedkw: '200kw'
    //                 },
    //                 {
    //                     chargerName: 'BTC-DC-3PH-0001',
    //                     connectors: 4,
    //                     instantkw: '100kw',
    //                     installedkw: '200kw'
    //                 },
    //             ]
    //         },
    //         {
    //             siteAreaName: 'Engineering (public)',
    //             siteId: 'site2',
    //             id: '2',
    //             childrens: [
    //                 {
    //                     chargerName: 'BTC-DC-3PH-0001',
    //                     connectors: 4,
    //                     instantkw: '100kw',
    //                     installedkw: '200kw'
    //                 },
    //                 {
    //                     chargerName: 'BTC-DC-3PH-0001',
    //                     connectors: 4,
    //                     instantkw: '100kw',
    //                     installedkw: '200kw'
    //                 },
    //             ]
    //         },
    //     ]
    // }
];

export const buildDemandViewData = (sampleData:any) => {
    const data: any = [];
    sampleData.map((item) => {
        data.push({
            name: item.siteName,
            id: item.id
        });
        if (item.siteArea.length > 0) {
            item.siteArea.map((sitearea: any) => {
                data.push({
                    siteAreaName: sitearea.siteAreaName,
                    id: item.id,
                    siteId: item.id
                });
                if (sitearea.chargers?.length > 0) {
                    sitearea.chargers.map((charger:any) => {
                        data.push({
                            chargerName: charger.chargerName,
                            connectors: charger.connectors,
                            instantkw: charger.instantkw,
                            installedkw:charger.installedkw,
                            id: charger.id,
                            siteAreaId:sitearea.id,
                            siteId: item.id
                        });
                    });
                }
            });

        }
    });
    return data;
};