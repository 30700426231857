import { Icon } from '@mui/material';
import { NavLink as Link, useLocation } from 'react-router-dom';
import styles from './nav.module.scss';
import { IMenuItem } from '@/common/models/menuItem';
import { hasPermission, isActive, isSuperAdmin } from '@/common/utils/utils';
import { TenantComponents } from '@/common/enums/enums';
import { useTranslation } from 'react-i18next';

export const Nav = (props: {
  isSideBarOpen: boolean;
  menuItems: IMenuItem[];
  isPrimary?: boolean;
}) => {
  const { t: translate } = useTranslation();
  const location = useLocation();

  const isComponentActive = (item: any) => {
    if (isSuperAdmin() && item.component === TenantComponents.CAR) {
      return true;
    }
    if (item.component) {
      if (isActive(item.component)) return true;
      return false;
    }
    return true;
  };
  return (
    <ul
      className={`${styles.nav} ${props.isPrimary ? styles.navPrimary : ''}`}
      data-cy='user-controls'
    >
      {props.menuItems.map((item: IMenuItem, index: number) => {
        return (
          hasPermission(item.path, item.isVisibleWithoutPermission) &&
          isComponentActive(item) && (
            <li className={styles.navItem} key={index}>
              <Link
                data-cy={`nav-item-${item.name}`}
                to={
                  location.pathname.includes(item.path)
                    ? location.pathname
                    : item.path
                }
                key={index}
                className={({ isActive }) =>
                  `${styles.navItemLink} ${
                    isActive ? styles.navItemLinkActive : ''
                  }`
                }
                onClick={() => {
                  item.onClick && item.onClick();
                }}
              >
                <div className={styles.navItemIcon}>
                  <Icon
                    component={item.icon}
                    sx={{ fontSize: 24 }}
                    className={styles.navItemIconWidth}
                  ></Icon>
                </div>
                {props.isSideBarOpen && (
                  <div className={styles.navItemText}>
                    {`${translate(item.name)}`}
                  </div>
                )}
              </Link>
            </li>
          )
        );
      })}
    </ul>
  );
};
