import { findRequiredFields } from '@/common/utils/validationHelper';
import * as schema from 'yup';
import { UserFormModel } from '../models/user';
import { PasswordFormModel } from '@/modules/profile/shared/models/user';

export const formSchema = schema.object<Partial<Record<keyof UserFormModel, schema.AnySchema>>>({
	name: schema.string().required('general.mandatory_field'),
	firstName: schema.string().required('general.mandatory_field'),
	email: schema.string().required('general.mandatory_field').matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'authentication.invalid_email'),
	phone: schema.string().matches(/(^\+?([0-9] ?){9,14}[0-9]$)|^$/, 'users.invalid_phone_number').notRequired(),
	mobile: schema.string().matches(/(^\+?([0-9] ?){9,14}[0-9]$)|^$/, 'users.invalid_phone_number').notRequired(),
	plateID: schema.string().matches(/^[A-Z0-9-]*$/, 'users.invalid_plate_id'),
	costCenter: schema.string().matches(/^[0-9]*$|^$/, 'general.invalid_value').notRequired(),
	passwords: schema.object<Partial<Record<keyof PasswordFormModel, schema.AnySchema>>>({
		password: schema.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/]{8,}$|^$/, 'authentication.password_rule').notRequired(),
		repeatPassword: schema.string()
			.oneOf([schema.ref('password'), null], 'authentication.password_not_equal')
	})
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);