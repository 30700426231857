import { IConfig } from '../models/model';

const Config: IConfig = {
	centralSystemServer: {
		pollIntervalSecs: process.env.REACT_APP_CENTRAL_SYSTEM_SERVER_POLL_INTERVAL_SECS,
	},
	asset: {
		maxImageKb: process.env.REACT_APP_ASSET_MAX_IMAGE_KB
	},
	user: {
		maxPictureKb: process.env.REACT_APP_USER_MAX_PICTURE_KB,
		captchaSiteKey: process.env.REACT_APP_RECAPTCHA_KEY,
	},
	tenant: {
		maxLogoKb: process.env.REACT_APP_TENANT_MAX_LOGO_KB,
	},
	company: {
		maxPictureKb: process.env.REACT_APP_COMPANY_MAX_PICTURE_KB,
	},
	site: {
		maxPictureKb: process.env.REACT_APP_SITE_MAX_PICTURE_KB
	},
	siteArea: {
		maxPictureKb: process.env.REACT_APP_SITE_AREA_MAX_PICTURE_KB
	},
	advanced: {
		debounceTimeSearchMillis: process.env.REACT_APP_ADVANCED_DEBOUNCE_TIME_SEARCH_MILLIS,
	},
	showError: {
		hasError:process.env.REACT_APP_SHOW_ERROR_PAGE
	}
};

export default Config;
