const LIVE = 'Live';
const MONTH = '1M';
const YEAR = '1Y';

const tabs = [
  { name: LIVE, id: 0, value: 'live' },
  { name: MONTH, id: 1, value: 'month' },
  { name: YEAR, id: 2, value: 'year' },
];

const powertabs = [
  { name: 'Demand (KW)', id: 0, value: 'demand' },
  { name: 'Load Balancing (A)', id: 1, value: 'balancing' },
];

export { tabs, LIVE, YEAR, MONTH, powertabs };
