import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Save } from '@mui/icons-material';
import { Icon, IconButton, TableCell, TableRow } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EditableTableCell = (props: any) => {
	const { t: translate } = useTranslation();
	const [value, setValue] = useState(props.value);
	const [error, setError] = useState<any>(null);
	const inputRef: any = useRef(null);

	const validateValue = (value: any) => { 
		if (value === '') { 
			setError(`${translate('general.mandatory_field')}`);
		} else {
			setError(null);
			return true;
		}
		return false;
	};

	useEffect(() => {
		validateValue(props.value);
		setValue(props.value);
	}, [props.value]);
	
	const onBlur = (event) => {
		props.onChange(event);
	};

	const onChange = (event) => {
		const isValid = validateValue(event.target.value);
		props.setDisabled(!isValid);
		setValue(event.target.value);
	};
	
	return (
		<TableCell data-cy="ocpp-column">
			{props.isEditable
				? 
				<>
					<EvesTextbox
						className={props.readonly?'readOnly-text':''}
						ref={inputRef}
						name={props.name}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						placeholder={props.placeholder}
						disabled={props.readonly}
						size="small"
						error={ error !== null }
						helperText={error}
					/>
					
				</>
				: <span>{value}</span>
			}
		</TableCell>
	);
};

const EditableTableRow = (props: any) => {
	const [rowData, setRowData] = useState(props.rowData);
	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		setRowData(props.rowData);
	}, [props.rowData]);

	const handleInputChange = (event: any) => {
		const updatedRowData = {...rowData, [event.target.name]: event.target.value};
		setRowData(updatedRowData);
	};

	const handleSaveClick = () => {
		props.onSave(rowData, props.index);
	};

	return (
		<TableRow data-cy={props.dataCy}>
			<TableCell>
				{!rowData.readonly && 
					<IconButton
						data-cy='ocpp-save'
						title={'Save'}
						disabled={disabled}
						onClick={handleSaveClick}
					>
						<Icon component={Save} color={!disabled && 'primary' || 'disabled'} />
					</IconButton>
				}
			</TableCell>
			<EditableTableCell
				name={'key'}
				isEditable={rowData.id === 0}
				value={rowData.key}
				readonly={rowData.readonly}
				setDisabled={setDisabled}
				onChange={handleInputChange}
				placeholder={'Key'}
			/>
			<EditableTableCell
				data-cy="ocpp-value"
				name={'value'}
				isEditable={true}
				value={rowData.value}
				readonly={rowData.readonly}
				setDisabled={setDisabled}
				onChange={handleInputChange}
				placeholder={'Value'}
			/>
		</TableRow>);
};

export default EditableTableRow;