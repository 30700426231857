import { EvesToggleButton } from '@/common/components/molecules/toggleButton/toggleButton';
import { useState } from 'react';
import { ConsumptionUnit } from '../enums/enums';

interface IChargingUnitSelectorPropsModel {
   onUnitChange: (selectedUnit: ConsumptionUnit) => void;
   selectedUnit: ConsumptionUnit;
}

function ChargingUnitSelector(props: IChargingUnitSelectorPropsModel) {
   const [selectedUnit, setSelectedUnit] = useState<ConsumptionUnit>(
      ConsumptionUnit.KILOWATT
   );
   const options = [
      { label: 'kW', value: ConsumptionUnit.KILOWATT },
      { label: 'Amp', value: ConsumptionUnit.AMPERE },
   ];

   const handleToggle = (event: any) => {
      const selectedNewUnit =
         event.target.value === ConsumptionUnit.AMPERE
            ? ConsumptionUnit.AMPERE
            : ConsumptionUnit.KILOWATT;
      setSelectedUnit(selectedNewUnit);
      props.onUnitChange(selectedNewUnit);
   };

   return (
      <EvesToggleButton
         size='small'
         isSwitch={true}
         value={selectedUnit}
         defaultValue={ConsumptionUnit.KILOWATT}
         onChange={handleToggle}
         options={options}
         data-cy='unit-toggle-button'
         exclusive={true}
      />
   );
}

export default ChargingUnitSelector;
